import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status, iInitState, iProductAttributeItem, iResponseListing } from '~/app/models';

interface iState extends iInitState {
  data: iResponseListing<iProductAttributeItem[]>;
  statusCreateEditAttribute: Status;
  message: string;
  urlData: {
    pageSize: number;
    currentPage: number;
  };
  queryString: string;
  isDESC: boolean;
  keySearch: string;
  controller: null | AbortController;
  dataEdit: iProductAttributeItem | null;
}

const initialState: iState = {
  status: '',
  statusCreateEditAttribute: '',
  controller: null,
  data: { items: [], search_criteria: {}, total_count: 0 } as iResponseListing<iProductAttributeItem[]>,
  urlData: { pageSize: 10, currentPage: 1 },
  message: '',
  isDESC: false,
  keySearch: 'attribute_code',
  queryString: '',
  dataEdit: null,
};

const productAttributeSlice = createSlice({
  name: 'REVIEW LIST',
  initialState,
  reducers: {
    // Synchronous Actions
    resetData: () => ({ ...initialState }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),

    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),

    changeController: (state, action) => ({ ...state, controller: action.payload }),

    changeDataEdit: (state, action: PayloadAction<iProductAttributeItem | null>) => ({
      ...state,
      dataEdit: action.payload,
    }),
    resetQueryString: (state) => ({ ...state, queryString: '' }),

    // Asynchronous Actions
    getDataFulfilled: (state, action: PayloadAction<iResponseListing<iProductAttributeItem[]>>) => ({
      ...state,
      data: action.payload,
      status: 'fulfilled',
    }),
    getDataRejected: (state) => ({ ...state, status: 'rejected' }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),

    createAttributeFulfilled: (state, action: PayloadAction<iProductAttributeItem>) => ({
      ...state,
      dataEdit: action.payload,
      statusCreateEditAttribute: 'fulfilled',
    }),
    createAttributeRejected: (state) => ({ ...state, statusCreateEditAttribute: 'rejected' }),
    createAttributePending: (state) => ({ ...state, statusCreateEditAttribute: 'pending' }),

    editAttributeFulfilled: (state, action: PayloadAction<iProductAttributeItem>) => ({
      ...state,
      dataEdit: action.payload,
      statusCreateEditAttribute: 'fulfilled',
    }),
    editAttributeRejected: (state) => ({ ...state, statusCreateEditAttribute: 'rejected' }),
    editAttributePending: (state) => ({ ...state, statusCreateEditAttribute: 'pending' }),
  },
});

export const { ...rest } = productAttributeSlice.actions;
const productAttributeReducer = productAttributeSlice.reducer;

export default productAttributeReducer;
