import { useRef } from 'react';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import Button from '../../Button';
import './style.scss';
import PopupWrap from '../PopupWrap';

interface Props {
  message: string;
  textLink: string;
  onClickOutSide?: any;
  onClickLink?: any;
}

const PopupFailure = (props: Props) => {
  const { message, textLink, onClickOutSide, onClickLink } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  useClickOutside(contentRef, () => {
    !!onClickOutSide ? onClickOutSide() : onClickLink();
  });

  return (
    <PopupWrap className=" d-flex align-items-center justify-content-center ">
      <div className="content-failure bg-white d-flex flex-column gap-3" ref={contentRef}>
        <img src={require('~/app/images/error.png')} alt="failure" className="mx-auto" />
        <p className="message text-center m-0 fw-semibold">{message}</p>

        <Button width="100%" className="btn-custom" onClick={onClickLink}>
          <span>{textLink}</span>
        </Button>
      </div>
    </PopupWrap>
  );
};

export default PopupFailure;
