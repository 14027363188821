import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import AppRoutes from './router';

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <AppRoutes />;
};

export default App;
