import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import './style.scss';
import { ReactNode } from 'react';

interface Props {
  title: string;
  subtitle?: string;
  to?: string;
  textLink?: string;
  children?: ReactNode;
}

const HeaderContent = (props: Props) => {
  const { children, title, subtitle, textLink, to } = props;
  return (
    <div className="mb-3 mw-100 overflow-x-auto">
      <div className="d-flex justify-content-between">
        <div>
          <h3 className={`title fw-semibold mb-${subtitle ? '2' : '0'}`}>{title}</h3>
          <p className="sub-title fw-normal m-0">{subtitle}</p>
        </div>

        {to && (
          <Link to={to} className="fw-medium link">
            <span className="mx-3">{textLink}</span>
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        )}
      </div>

      {children}
    </div>
  );
};

export default HeaderContent;
