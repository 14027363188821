import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { clearMessage, login } from '~/components/layout/FullWidthLayout/redux/actions';

import ButtonForm from '~/components/common/ButtonForm';
import FormWrapper from '~/components/common/FormWrapper';
import { TextField } from '~/components/common/Input';
import Loading from '~/components/common/Loading';
import FullWidthLayout from '~/components/layout/FullWidthLayout';
import LoginSchema from './Schema';

import './style.scss';
import ap_login from '~/app/images/ap_login.svg';

const SignIn: FC = () => {
  const { status, message } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Remove message when reload page
    message && dispatch(clearMessage());
    resetForm();

    return () => {
      // Remove message when component unmounted
      message && dispatch(clearMessage());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, resetForm } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(login(values, navigate));
    },
  });

  const dataInput = [
    {
      name: 'username',
      label: '', //Email
      type: 'text' as const,
      value: values.username,
      textError: errors.username,
      error: errors.username && touched.username,
      placeHolder: 'Username',
      bgIcon: 'user',
    },
    {
      name: 'password',
      label: '', //'Password'
      type: 'password' as const,
      value: values.password,
      textError: errors.password,
      error: errors.password && touched.password,
      shouldShowPassword: values.password.length > 0,
      placeHolder: 'Password',
      bgIcon: 'key',
    },
  ];

  const renderListInput = () => {
    return dataInput.map((input) => {
      const { name, label, type, value, error, textError, shouldShowPassword, placeHolder, bgIcon } = input;

      return (
        <TextField
          type={type}
          name={name}
          value={value}
          label={label}
          error={!!error}
          textError={textError}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            setFieldTouched(name, false, false);
          }}
          key={name}
          shouldShowPassword={!!shouldShowPassword}
          classCss="border-rd-30"
          placeHolder={placeHolder}
          bgIcon={bgIcon}
        />
      );
    });
  };

  return (
    <FullWidthLayout pageTitle="Sign In">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12">
            <div className="row">
              <div className="col-12 col-md-5 d-none d-md-block">
                <img className="w-100" src={ap_login} alt="" />
              </div>
              <div className="col-12 col-md-7">
                <form className="login d-flex flex-column align-items-center" onSubmit={handleSubmit}>
                  <FormWrapper className="pt-0" title="">
                    {/*  <h6 className="sub-title text-center fw-normal">
                      <span>New here? </span>
                      <Link to="/auth/sign-up" className="text-decoration-none link">
                        Create new account
                      </Link>
                    </h6> */}
                    <div className="text-center">
                      <h2>WELCOME TO ADMIN PORTAL!</h2>
                      <h3 className="mb-3">SIGN IN</h3>
                    </div>
                    {status === 'pending' ? <Loading /> : <div className="input-list">{renderListInput()}</div>}

                    <>{status === 'rejected' && message && <p className="message m-0 mt-3 text-danger text-center">{message}</p>}</>

                    <ButtonForm classCss="border-rd-25 background-blue text-uppercase" disabled={status === 'pending'}>
                      Continue
                    </ButtonForm>

                    {/* <div className="text-center mt-3 lh-sm fs-6">
                      <Link
                        to="/auth/forgot-password"
                        className="link fs-6 text-decoration-none d-inline-block fw-medium"
                      >
                        Forgot Password?
                      </Link>
                    </div> */}
                  </FormWrapper>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FullWidthLayout>
  );
};

export default SignIn;
