import { useMemo, useState } from 'react';

import last12Moths from '~/app/utils/last12Moths';
import DefaultLayout from '~/components/layout/DefaultLayout';
import BestSeller from './BestSeller';
import HeaderContent from './HeaderContent';
import Overview from './Overview';
import ProductSold from './ProductSold';
import SalesStatistics from './SalesStatistics';
import TotalSales from './TotalSales';
import { useAppSelector } from '~/app/hooks/hooks';
import OrderReport from './OrderReport';

import './style.scss';
import { iBestSellerOptions } from '~/app/models';

const DashBoard = () => {
  const [viewActionBestSellerId, setViewActionBestSellerId] = useState<1 | 2 | 3>(1);

  const { statusBestSeller } = useAppSelector((state) => state.dashboardReducer);
  const { data } = useAppSelector((state) => state.userReducer);
  const { queryString: orderQuery } = useAppSelector((s) => s.orderReducer);
  const { queryString: productQuery } = useAppSelector((s) => s.productReducer);
  const { first_name, last_name } = data;

  const timeLast12Moths = `${last12Moths[0]} - ${last12Moths[last12Moths.length - 1]}`;

  const fullName = useMemo(() => {
    return first_name && last_name && `${last_name} ${first_name}`;
  }, [first_name, last_name]);

  const actionsData = useMemo<iBestSellerOptions[]>(
    () => [
      {
        id: 1,
        text: '7 days ago',
      },
      {
        id: 2,
        text: 'This Month',
      },
      {
        id: 3,
        text: 'This Year',
      },
    ],
    [],
  );

  const handleChangeView = (id: 1 | 2 | 3) => {
    if (id === viewActionBestSellerId || statusBestSeller === 'pending') {
      return;
    }

    setViewActionBestSellerId(id);
  };

  const renderActionBestSeller = () => {
    return actionsData.map((action) => {
      const { id, text } = action;
      const classNames = `btn-action rounded-pill fs-14 fw-medium btn text-nowrap ${id === viewActionBestSellerId ? 'btn-primary' : 'no-active'}`;

      return (
        <button key={id} className={classNames} onClick={() => handleChangeView(id)}>
          {text}
        </button>
      );
    });
  };

  return (
    <DefaultLayout pageTitle="Dashboard" subTitle={fullName && `Hi ${fullName || 'Developer'}, welcome back!`}>
      <div className="dashboard">
        <div className="row">
          <div className="col-12 col-xl-6 mb-4 d-flex flex-column">
            <Overview />
          </div>
          <div className="col-12 col-xl-6 mb-4 d-flex flex-column">
            <TotalSales />
          </div>
        </div>

        <div className="content w-100">
          <div className="row gy-4">
            <div className="col-sm-12 col-xl-6">
              <SalesStatistics>
                <HeaderContent title="Sales Statistics" subtitle={timeLast12Moths} />
              </SalesStatistics>
            </div>

            <div className="col-sm-12 col-xl-6">
              <OrderReport>
                <HeaderContent title="Orders Report" subtitle={timeLast12Moths} to={`/orders/${orderQuery}`} textLink="View Order Listing" />
              </OrderReport>
            </div>

            <div className="col-sm-12 col-xl-6 d-flex flex-column">
              <ProductSold>
                <HeaderContent title="Products Sold" subtitle={timeLast12Moths} />
              </ProductSold>
            </div>

            <div className="col-sm-12 col-xl-6">
              <div className="best-seller d-flex flex-column h-100">
                <BestSeller id={viewActionBestSellerId}>
                  <HeaderContent title="Bestsellers" subtitle="Top 10 products" to={`/products/${productQuery}`} textLink="View Product Listing">
                    <div className="actions mt-3">{renderActionBestSeller()}</div>
                  </HeaderContent>
                </BestSeller>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default DashBoard;
