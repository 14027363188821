import { createSlice } from '@reduxjs/toolkit';
import { Status, iInitState } from '../../../../app/models';
export interface iStateOfOrder extends iInitState {
  commentStatus: Status;
  cancelOrderStatus: Status;
  holdOrderStatus: Status;
  unHoldOrderStatus: Status;
  trackingNumberStatus: Status;
  deleteTrackingNumberStatus: Status;
  createShipmentStatus: Status;
  createRefundStatus: Status;
  createInvoiceStatus: Status;
  message: string;
  messCreateShipment: string;
  messCreateRefund: string;
  messCreateInvoice: string;
  messCreateTrackingNumber: string;
  data: any;
  dataDetails: any;
  memos: any;
  shipments: any;
  invoices: any;
  memoDetail: any;
  invoiceDetail: any;
  shipmentDetail: any;
  controller: any;
  urlData: {
    pageSize: number;
    currentPage: number;
  };
  isDESC: boolean;
  keySearch: string;
  filterData: {};
  queryString: string;
  commentHistories: [];
}

const initialState: iStateOfOrder = {
  status: '',
  commentStatus: '',
  cancelOrderStatus: '',
  holdOrderStatus: '',
  unHoldOrderStatus: '',
  trackingNumberStatus: '',
  deleteTrackingNumberStatus: '',
  createShipmentStatus: '',
  createRefundStatus: '',
  createInvoiceStatus: '',
  messCreateShipment: '',
  messCreateRefund: '',
  messCreateInvoice: '',
  messCreateTrackingNumber: '',
  queryString: '',
  message: '',
  controller: null,
  urlData: {
    currentPage: 1,
    pageSize: 10,
  },
  commentHistories: [],
  isDESC: false,
  keySearch: 'increment_id',
  filterData: {
    isAdvancedFilter: 'true',
    keyword: '',
    purchaseDateTo: ' ',
    baseGrandTotalFrom: '',
    baseGrandTotalTo: '',
    totalRefundedFrom: '',
    totalRefundedTo: '',
    filterStatus: '',
    customerEmail: '',
    id: '',
  },
  data: {
    items: [],
    search_criteria: {},
  },
  dataDetails: {
    billing_address: {
      street: [],
    },
    items: [],
    status_histories: [],
    payment: {
      additional_information: [],
    },
  },
  invoices: {
    items: [],
    search_criteria: {},
  },
  memos: {
    items: [],
    search_criteria: {},
  },
  invoiceDetail: {
    items: [],
  },
  shipments: {
    items: [],
    search_criteria: {},
  },
  shipmentDetail: {
    items: [],
  },

  memoDetail: {
    items: [],
  },
};

const orderSlice = createSlice({
  name: 'ORDER',
  initialState,
  reducers: {
    // Asynchronous Actions
    getDataFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getDataRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),

    getOrderDetailFulfilled: (state, action) => ({ ...state, dataDetails: action.payload, status: 'fulfilled' }),
    getOrderDetailRejected: (state) => ({ ...state, status: 'rejected' }),
    getOrderDetailPending: (state) => ({ ...state, status: 'pending' }),

    getInvoicesFulfilled: (state, action) => ({ ...state, invoices: action.payload, status: 'fulfilled' }),
    getInvoicesRejected: (state) => ({ ...state, status: 'rejected' }),
    getInvoicesPending: (state) => ({ ...state, status: 'pending' }),

    getInvoiceDetailFulfilled: (state, action) => ({ ...state, status: 'fulfilled', invoiceDetail: action.payload }),
    getInvoiceDetailRejected: (state) => ({ ...state, status: 'rejected' }),
    getInvoiceDetailPending: (state) => ({ ...state, status: 'pending' }),

    getMemosFulfilled: (state, action) => ({ ...state, status: 'fulfilled', memos: action.payload }),
    getMemosRejected: (state) => ({ ...state, status: 'rejected' }),
    getMemosPending: (state) => ({ ...state, status: 'pending' }),

    getMemosDetailFulfilled: (state, action) => ({ ...state, status: 'fulfilled', memoDetail: action.payload }),
    getMemosDetailRejected: (state) => ({ ...state, status: 'rejected' }),
    getMemosDetailPending: (state) => ({ ...state, status: 'pending' }),

    getShipmentFulfilled: (state, action) => ({ ...state, status: 'fulfilled', shipments: action.payload }),
    getShipmentRejected: (state) => ({ ...state, status: 'rejected' }),
    getShipmentPending: (state) => ({ ...state, status: 'pending' }),

    getShipmentDetailFulfilled: (state, action) => ({ ...state, status: 'fulfilled', shipmentDetail: action.payload }),
    getShipmentDetailRejected: (state) => ({ ...state, status: 'rejected' }),
    getShipmentDetailPending: (state) => ({ ...state, status: 'pending' }),

    postOrderCommentFulfilled: (state) => ({ ...state, commentStatus: 'fulfilled' }),
    postOrderCommentRejected: (state) => ({ ...state, commentStatus: 'rejected' }),
    postOrderCommentPending: (state) => ({ ...state, commentStatus: 'pending' }),

    getNotesHistoryFulfilled: (state, action) => ({ ...state, commentHistories: action.payload, status: 'fulfilled' }),
    getNotesHistoryRejected: (state) => ({ ...state, status: 'rejected' }),
    getNotesHistoryPending: (state) => ({ ...state, status: 'pending' }),

    createShipmentFulfilled: (state) => ({ ...state, createShipmentStatus: 'fulfilled' }),
    createShipmentRejected: (state, { payload: message }) => ({ ...state, createShipmentStatus: 'rejected', messCreateShipment: message }),
    createShipmentPending: (state) => ({ ...state, createShipmentStatus: 'pending' }),

    createRefundFulfilled: (state) => ({ ...state, createRefundStatus: 'fulfilled' }),
    createRefundRejected: (state, { payload: message }) => ({ ...state, createRefundStatus: 'rejected', messCreateRefund: message }),
    createRefundPending: (state) => ({ ...state, createRefundStatus: 'pending' }),

    createInvoiceFulfilled: (state) => ({ ...state, createInvoiceStatus: 'fulfilled' }),
    createInvoiceRejected: (state, { payload: message }) => ({ ...state, createInvoiceStatus: 'rejected', messCreateInvoice: message }),
    createInvoicePending: (state) => ({ ...state, createInvoiceStatus: 'pending' }),

    createTrackingNumberFulfilled: (state) => ({ ...state, trackingNumberStatus: 'fulfilled' }),
    createTrackingNumberRejected: (state, { payload: message }) => ({
      ...state,
      trackingNumberStatus: 'rejected',
      messCreateTrackingNumber: message,
    }),
    createTrackingNumberPending: (state) => ({ ...state, trackingNumberStatus: 'pending' }),

    deleteTrackingNumberFulfilled: (state) => ({ ...state, deleteTrackingNumberStatus: 'fulfilled' }),
    deleteTrackingNumberRejected: (state) => ({ ...state, deleteTrackingNumberStatus: 'rejected' }),
    deleteTrackingNumberPending: (state) => ({ ...state, deleteTrackingNumberStatus: 'pending' }),

    cancelOrderFulfilled: (state) => ({ ...state, cancelOrderStatus: 'fulfilled' }),
    cancelOrderRejected: (state) => ({ ...state, cancelOrderStatus: 'rejected' }),
    cancelOrderPending: (state) => ({ ...state, cancelOrderStatus: 'pending' }),

    holdOrderFulfilled: (state) => ({ ...state, holdOrderStatus: 'fulfilled' }),
    holdOrderRejected: (state) => ({ ...state, holdOrderStatus: 'rejected' }),
    holdOrderPending: (state) => ({ ...state, holdOrderStatus: 'pending' }),

    unHoldOrderFulfilled: (state) => ({ ...state, unHoldOrderStatus: 'fulfilled' }),
    unHoldOrderRejected: (state) => ({ ...state, unHoldOrderStatus: 'rejected' }),
    unHoldOrderPending: (state) => ({ ...state, unHoldOrderStatus: 'pending' }),

    // Synchronous Action
    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),
    setFilterData: (state, action) => ({ ...state, filterData: action.payload }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),

    changeController: (state, action) => ({ ...state, controller: action.payload }),
    resetQueryString: (state) => ({ ...state, queryString: '' }),
    clearData: () => ({ ...initialState }),
  },
});

export const { ...rest } = orderSlice.actions;
const orderReducer = orderSlice.reducer;

export default orderReducer;
