import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { ChangeEvent, FC, Fragment, useEffect, useMemo, useRef } from 'react';
import './style.scss';
import Select from '~/components/common/Select';
import { onChangeDataHeader, onChangeListApplyAllStores, onChangeSearchOptimize } from '../redux/actions';
import CheckboxRounded from '~/components/common/CheckboxRounded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import AdvInventory from './AdvInventory';
import AdvPrice from './AdvPrice';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import Button from '~/components/common/Button';
import { iProductAttributeItem, iDataAdvPrice, iKeyAllowApplyAllStores } from '~/app/models';
import CKEditor5 from './Ckeditor5';
import { formatCurrency } from '~/app/utils';
import CheckBoxApplyAllStores from '~/components/common/CheckBox/CheckBoxApplyAllStores';
import { Input, TextArea } from '~/components/common/Input';

interface Props {
  formik: FormikProps<any>;
  action: 'create' | 'edit';
}

const Header: FC<Props> = ({ formik, action }) => {
  const dispatch = useAppDispatch();
  const { currentStore } = useAppSelector((s) => s.authReducer);
  const {
    data,
    dataAttrSet,
    dataRelatedAttrByAttrSet,
    dataHeader,
    dataProductGenerate,
    dataAdvInventory,
    listProductAssociated,
    listTierPrice,
    dataSearchOptimize,
    listApplyAllStores,
  } = useAppSelector((state) => state.createEditProductReducer);
  const { custom_attributes } = data;
  const { values, errors, touched, setFieldTouched, setFieldError, handleChange, handleBlur } = formik;
  const { attribute_set_id } = dataHeader;

  const [showAdvInventory, setShowAdvInventory] = useState<boolean>(false);
  const [showAdvPrice, setShowAdvPrice] = useState<boolean>(false);

  const listAttrHeader = useMemo(() => {
    const codeList = [
      'name',
      'sku',
      'price',
      'tax_class_id',
      'quantity_and_stock_status',
      'weight',
      'visibility',
      'sw_featured',
      'country_of_manufacture',
      'short_description',
      'description',
    ];

    let result: iProductAttributeItem[] = [];

    codeList.forEach((attr_code) => {
      const attr = dataRelatedAttrByAttrSet.find((item) => item.attribute_code === attr_code);

      if (attr) {
        result = [...result, attr];
      }
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRelatedAttrByAttrSet]);

  useEffect(() => {
    if (!+dataAdvInventory.is_qty_decimal && values.qty) {
      (values.qty ? values.qty.toString().includes('.') : null) && setFieldError('qty', 'Please enter a valid integer in this field.');
    } else {
      setFieldError('qty', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAdvInventory.is_qty_decimal]);

  useEffect(() => {
    if (Object.keys(data).length <= 0) return;

    function findCustomAttributes(name: string) {
      const result = custom_attributes?.find((item) => item.attribute_code === name);
      return result ? result.value.split(' ')[0] : 0;
    }

    const special_from_date = findCustomAttributes('special_from_date');
    const special_to_date = findCustomAttributes('special_to_date');

    advPriceFormik.setFieldValue('special_from_date', special_from_date);
    advPriceFormik.setFieldValue('special_to_date', special_to_date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const advPriceFormik = useFormik<iDataAdvPrice>({
    initialValues: {},
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      special_from_date: yup
        .date()
        .default(null)
        .transform((v) => (v instanceof Date && !isNaN(+v) ? v : null))
        .nullable(),
      special_to_date: yup
        .date()
        .default(null)
        .transform((v) => (v instanceof Date && !isNaN(+v) ? v : null))
        .nullable()
        .test({
          test: (_, schema) => {
            const { special_from_date, special_to_date } = schema?.parent || {};

            const { special_from_date: startDate, special_to_date: endDate } = advPriceFormik.values;
            if (startDate && endDate) {
              // If both end date and start date are selected => check if the end date is greater than the start date or not
              return special_to_date.getTime() < special_from_date.getTime() ? false : true;
            } else {
              return true;
            }
          },
          name: 'special_to_date',
          message: 'Make sure the End Date is later than or the same as the Start Date.',
        }),
      special_price: yup.number().min(0, 'Please enter a number 0 or greater, without comma in this field.'),
      cost: yup.number().min(0, 'Please enter a number 0 or greater, without comma in this field.'),
      ...Object.fromEntries(
        listTierPrice
          .map((item: any) => {
            const { id } = item;
            return [
              [
                [`qty${id}`],
                yup
                  .number()
                  .min(0, 'Please enter a number greater than 0, without comma in this field.')
                  .integer('Please enter an integer, without comma in this field.')
                  .required('This field is required'),
              ],
              item[`percentage_value${id}`] === undefined
                ? [[`value${id}`], yup.number().min(0, 'Please enter a number 0 or greater, without comma in this field.')]
                : [
                    [`percentage_value${id}`],
                    yup
                      .number()
                      .required('This is a required field.')
                      .min(0, 'Please enter a valid percentage discount value greater than 0.')
                      .max(100, 'Please enter a valid percentage discount value greater than 0.'),
                  ],
            ];
          })
          .flat(),
      ),
    }),
    onSubmit: () => {
      setShowAdvPrice(false);
    },
  });

  // use Ref
  const advPriceRef = useRef<HTMLDivElement>(null);
  useClickOutside(advPriceRef, () => {
    advPriceFormik.handleSubmit();
  });
  const advInventoryRef = useRef<HTMLDivElement>(null);
  useClickOutside(advInventoryRef, () => {
    setShowAdvInventory(false);
  });

  function handleChangeData(e: any) {
    const newData = {
      ...dataHeader,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    };
    dispatch(onChangeDataHeader(newData));
  }

  function handleCheckApplyAllStores(e: ChangeEvent<HTMLInputElement>, name: iKeyAllowApplyAllStores) {
    dispatch(onChangeListApplyAllStores({ ...listApplyAllStores, [name]: e.target.checked }));
  }

  function renderAttrSet() {
    return (
      <div className="d-flex align-items-center">
        <label className="title text-end fw-normal m-0 me-4">Attribute Set</label>
        <div className="attr-set-wrap">
          <Select className="w-100" value={attribute_set_id} name="attribute_set_id" onChange={handleChangeData} disabled={action === 'edit'}>
            {dataAttrSet.map((item) => (
              <option value={item.attribute_set_id} key={item.attribute_set_id}>
                {item.attribute_set_name}
              </option>
            ))}
          </Select>
        </div>
      </div>
    );
  }

  function renderEnableProduct() {
    return (
      <CheckboxRounded
        check={(dataHeader as any)['status'] || false}
        onChange={handleChangeData}
        name="status"
        label="Enable Product"
        showCheckbox
        onApplyAllStores={(e) => handleCheckApplyAllStores(e, 'status')}
        isApplyAllStores={!!listApplyAllStores['status']}
      />
    );
  }

  function renderContent(listAttrHeader: iProductAttributeItem[]) {
    return listAttrHeader.map((attr, index: number) => {
      const { frontend_input, default_frontend_label, attribute_code, default_value, options } = attr;

      switch (frontend_input) {
        case 'text':
          return (
            <div className="d-flex pdt-title" key={index}>
              <label
                htmlFor={attribute_code}
                className="title center d-flex justify-content-end align-items-center fs-16 m-0 fw-normal me-4 cursor-pointer"
              >
                {default_frontend_label}&nbsp;<span className="text-danger">*</span>
              </label>

              <div className="d-flex flex-column flex-grow-1 gap-2">
                <Input
                  type="text"
                  id={attribute_code}
                  name={attribute_code}
                  className="form-input--custom large"
                  disabled={attribute_code === 'sku' && !!data.sku}
                  value={values[attribute_code] || ''}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    if (name === 'name' && action === 'create') {
                      const { isGeneralMetaTitle, meta_title } = dataSearchOptimize;

                      const newDataSearchOptimize = {
                        ...dataSearchOptimize,
                        meta_keyword: value,
                        meta_title: isGeneralMetaTitle ? value : meta_title,
                      };
                      dispatch(onChangeSearchOptimize(newDataSearchOptimize));
                    }
                    handleChangeData(e);
                    handleChange(e);
                    setFieldTouched(attribute_code, false);
                  }}
                  onBlur={handleBlur}
                />
                {errors[attribute_code] && touched[attribute_code] && <p className="fs-14 m-0 text-danger">{errors[attribute_code] as string}</p>}

                {/* {attribute_code === 'name' && (
                  <CheckBoxApplyAllStores
                    isApplyAllStores={listApplyAllStores[attribute_code]}
                    onApplyAllStores={(e) => handleCheckApplyAllStores(e, attribute_code)}
                  />
                )} */}
              </div>
            </div>
          );
        case 'price':
          return (
            <div key={index}>
              <div className="d-flex price">
                <label
                  htmlFor={attribute_code}
                  className="title center d-flex justify-content-end align-items-center fs-16 m-0 fw-normal me-4 cursor-pointer"
                >
                  {default_frontend_label}&nbsp;<span className="text-danger">*</span>
                </label>

                <div className="d-flex flex-column">
                  <div
                    className={`d-flex align-items-center price-wrap ${
                      listProductAssociated.concat(dataProductGenerate).length > 0 ? 'disable' : ''
                    }`}
                  >
                    <span className="ms-12 pe-2 d-flex h-100 align-items-center">{formatCurrency(currentStore)}</span>
                    <Input
                      type="number"
                      id={attribute_code}
                      name={attribute_code}
                      className="border-0 h-100 flex flex-grow-1 p-0 px-12"
                      disabled={listProductAssociated.concat(dataProductGenerate).length > 0}
                      value={values.price || ''}
                      onChange={(e) => {
                        handleChange(e);
                        handleChangeData(e);
                        setFieldTouched('price', false);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.price && touched.price && <p className="mess-err fs-14 m-0 mt-2 text-danger">{errors.price as string}</p>}
                </div>
              </div>

              {(listProductAssociated.concat(dataProductGenerate).length !== 0 || action !== 'create') && (
                <div className="d-flex align-items-center mt-2 adv-price">
                  <div className="title left me-4"></div>
                  <div className="d-flex flex-column">
                    <h3 className="link fw-medium m-0" onClick={() => setShowAdvPrice(!showAdvPrice)}>
                      <span>Advanced Pricing</span>
                    </h3>
                    {showAdvPrice && (
                      <div className="popup d-flex align-items-center justify-content-center">
                        <div className="popup-content wrapper" ref={advPriceRef}>
                          <div className="text-end">
                            <div className="close-icon cursor-pointer mb-3 d-inline-flex" onClick={() => advPriceFormik.handleSubmit()}>
                              <FontAwesomeIcon icon={faClose} />
                            </div>
                            <div className="done-wrap mb-4 py-3 d-flex justify-content-end">
                              <Button className="me-3 fw-medium" onClick={() => advPriceFormik.handleSubmit()}>
                                Done
                              </Button>
                            </div>
                          </div>
                          <AdvPrice formik={advPriceFormik} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        case 'textarea':
          return attribute_code === 'short_description' ? (
            <div className="d-flex " key={index}>
              <label htmlFor={attribute_code} className="title d-flex justify-content-end fs-16 m-0 fw-normal me-4 cursor-pointer">
                {default_frontend_label}
              </label>

              <div className="d-flex flex-column gap-2 w-100">
                <TextArea
                  className="form-control-short-desc flex-grow-1"
                  name={attribute_code}
                  id={attribute_code}
                  value={dataHeader[attribute_code] || ''}
                  onChange={handleChangeData}
                />

                <CheckBoxApplyAllStores
                  isApplyAllStores={listApplyAllStores[attribute_code]}
                  onApplyAllStores={(e) => handleCheckApplyAllStores(e, attribute_code)}
                />
              </div>
            </div>
          ) : (
            <div className="d-flex desc" key={index}>
              <label className="title d-flex justify-content-end fs-16 m-0 fw-normal me-4">{default_frontend_label}</label>
              <div className="d-flex flex-column gap-2 w-100">
                <div className="ckeditor-wrap">
                  <CKEditor5 handleChange={handleChangeData} />
                </div>
                {attribute_code === 'description' && (
                  <CheckBoxApplyAllStores
                    isApplyAllStores={listApplyAllStores[attribute_code]}
                    onApplyAllStores={(e) => handleCheckApplyAllStores(e, attribute_code)}
                  />
                )}
              </div>
            </div>
          );
        case 'select':
          return attribute_code === 'quantity_and_stock_status' ? (
            <Fragment key={index}>
              <div className="d-flex flex-column quantity">
                <div className="d-flex">
                  <label htmlFor="qty" className="title center d-flex justify-content-end align-items-center text-end fs-16 m-0 fw-normal me-4">
                    Quantity
                  </label>

                  <div className="d-flex flex-column">
                    <Input
                      type="number"
                      id="qty"
                      name="qty"
                      disabled={listProductAssociated.concat(dataProductGenerate).length > 0}
                      value={values.qty || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.qty && touched.qty && <p className="mess-err fs-14 m-0 mt-2 text-danger">{errors.qty as string}</p>}
                  </div>
                </div>
                {action !== 'create' && (
                  <div className="d-flex align-items-center mt-2 adv-inventory">
                    <div className="title left me-4"></div>
                    <div className="d-flex flex-column">
                      <h3 className="link fw-medium m-0" onClick={() => setShowAdvInventory(!showAdvInventory)}>
                        <span>Advanced Inventory</span>
                      </h3>
                      {showAdvInventory && (
                        <div className="popup d-flex justify-content-center align-items-center">
                          <div className="popup-content wrapper text-end" ref={advInventoryRef}>
                            <div className="close-icon cursor-pointer mb-3 d-inline-block" onClick={() => setShowAdvInventory(false)}>
                              <FontAwesomeIcon icon={faClose} />
                            </div>
                            <div className="done-wrap mb-4 py-3 d-flex justify-content-end">
                              <Button className="me-3 fw-medium" onClick={() => setShowAdvInventory(false)}>
                                Done
                              </Button>
                            </div>
                            <AdvInventory />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex" key={index}>
                <label className="title d-flex justify-content-end align-items-center fs-16 m-0 fw-normal me-4 cursor-pointer">Stock Status</label>
                <div className="d-flex flex-column gap-2">
                  <Select value={dataHeader[attribute_code] === '0' ? '0' : default_value} onChange={handleChangeData} name={attribute_code}>
                    {options.map((item: any) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="d-flex align-items-center" key={index}>
              <h3 className="title text-end fs-16 m-0 fw-normal me-4">{default_frontend_label}</h3>
              <Select
                className="fs-16 ps-3"
                value={
                  (dataHeader as any)[attribute_code] === '0'
                    ? '0'
                    : !!(dataHeader as any)[attribute_code]
                    ? (dataHeader as any)[attribute_code]
                    : default_value
                }
                onChange={handleChangeData}
                name={attribute_code}
              >
                {options.map((item: any) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
          );
        case 'boolean':
          return (
            <CheckboxRounded
              key={index}
              check={(dataHeader as any)[attribute_code] || false}
              onChange={handleChangeData}
              name={attribute_code}
              label={default_frontend_label}
            />
          );
        case 'weight':
          return (
            <div className="d-flex weight-and-has-weight w-fit-content" key={index}>
              <div className="d-flex weight">
                <div className="d-flex">
                  <label
                    htmlFor={attribute_code}
                    className="title center d-flex justify-content-end align-items-center fs-16 m-0 fw-normal me-4 cursor-pointer"
                  >
                    {default_frontend_label}
                  </label>

                  <div>
                    <div className={`d-flex align-items-center border weight-wrap ${dataHeader['product_has_weight'] === '0' ? 'disable' : ''}`}>
                      <span className="ms-12 pe-2 d-flex h-100 align-items-center">lbs</span>
                      <Input
                        type="number"
                        id={attribute_code}
                        name={attribute_code}
                        className="border-0 h-100 flex flex-grow-1"
                        value={values[attribute_code] || ''}
                        disabled={dataHeader['product_has_weight'] === '0'}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldTouched(attribute_code, false);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors[attribute_code] && touched[attribute_code] && (
                      <p className="fs-14 m-0 mt-2 text-danger mess-err">{errors[attribute_code] as string}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 ms-4">
                <Select value={dataHeader['product_has_weight'] || '1'} onChange={handleChangeData} name="product_has_weight">
                  <option value="1">This item has weight</option>
                  <option value="0">This item has no weight</option>
                </Select>
              </div>
            </div>
          );
        default:
          return <></>;
      }
    });
  }

  return (
    <div className="wrapper d-flex flex-column gap-3 header mb-4">
      {renderEnableProduct()}
      {renderAttrSet()}
      {renderContent(listAttrHeader)}
    </div>
  );
};

export default Header;
