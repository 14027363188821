import { faAngleRight, faAnglesRight, faAngleLeft, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { PAGES } from '~/app/constants';
import './style.scss';
interface Props {
  onChangePageSizeEvent: (e: any) => any;
  onChangePageEvent: (page: any) => any;
  pageSize: number;
  currentPage: number;
  totalCount: number;
  status: string;
  className?: string;
  disableSelectChangePageSize?: boolean;
}

const Pagination: FC<Props> = (props: Props) => {
  const {
    pageSize,
    currentPage,
    totalCount = 0,
    status,
    disableSelectChangePageSize,
    className = '',
    onChangePageEvent,
    onChangePageSizeEvent,
  } = props;

  const findPageBeginEnd = (currentPage: number = 1, maxPage: number = 1) => {
    const step: number = 3;
    let beginBlock: number = 1;
    let begin: number = 1;
    let nextEnd = step * beginBlock;

    while (currentPage > nextEnd) {
      beginBlock++; //next with 5 items
      nextEnd = step * beginBlock;
    }

    begin = nextEnd - step + 1;
    let end: number = nextEnd;
    end = end > maxPage ? maxPage : end;

    const listPages = [];
    //fist
    listPages.push({ label: <FontAwesomeIcon icon={faAnglesLeft} />, page: 1, class: 'btn-light fs-7' });
    //previous
    listPages.push({
      label: <FontAwesomeIcon icon={faAngleLeft} />,
      page: currentPage - 1 <= 0 ? 1 : currentPage - 1,
      class: 'btn-light fs-7',
    });
    //list page with 5 items
    for (let index = begin; index <= end; index++) {
      listPages.push({ label: index, page: index, class: currentPage === index ? 'active' : '' });
    }
    //next
    listPages.push({
      label: <FontAwesomeIcon icon={faAngleRight} />,
      page: currentPage + 1 > maxPage ? maxPage : currentPage + 1,
      class: 'btn-light fs-7',
    });
    //last
    listPages.push({ label: <FontAwesomeIcon icon={faAnglesRight} />, page: maxPage, class: 'btn-light fs-7' });

    return listPages;
  };
  const listPages = findPageBeginEnd(currentPage, +totalCount / +pageSize > 1 ? Math.ceil(+totalCount / +pageSize) : 1);

  const onChangeHandler = (e: any) => {
    e.preventDefault();
    onChangePageSizeEvent(e);
  };

  const onChangePage = (page: string | number) => {
    onChangePageEvent(page);
  };

  const renderPagination = () => {
    return (
      <div className={`${className} pagination-section mt-3 d-flex justify-content-end justify-content-lg-between align-items-center`}>
        <div className="pages d-none d-lg-block">
          Showing
          <select
            className="item-per-pages fs-6"
            name="pageSize"
            onChange={(e) => onChangeHandler(e)}
            value={pageSize}
            disabled={status === 'pending' ? true : false || disableSelectChangePageSize}
          >
            {PAGES.map((page, i: number) => (
              <option key={i} value={page}>
                {page}
              </option>
            ))}
          </select>
          item(s)/page - Displaying {currentPage} of {+totalCount / +pageSize > 1 ? Math.ceil(+totalCount / +pageSize) : 1} pages
        </div>
        <div className={`pagination ${totalCount <= pageSize ? 'd-none' : ''}`}>
          {listPages &&
            listPages.map((item, index) => (
              <span
                key={index}
                onClick={() => onChangePage(item.page)}
                className={`pagination-item mx-2 cursor-pointer text-decoration-none  ${item.class}`}
              >
                {item.label}
              </span>
            ))}
        </div>
      </div>
    );
  };
  return <>{renderPagination()}</>;
};

export default Pagination;
