import { FC, useEffect } from 'react';
import './style.scss';
import Settings from './Settings';
import Info from './Info';
import SignInMethod from './SignInMethod';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import 'sweetalert2/src/sweetalert2.scss';
import { logOutAction, resetPathname } from '~/components/layout/FullWidthLayout/redux/actions';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

type Props = {};

const Setting: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { updateStatus, updatePasswordStatus, updateAvatarStatus, message } = useAppSelector((state) => state.userReducer);

  const swalToast = Swal.mixin({
    toast: true,
    position: 'top-right',
    buttonsStyling: false,
    showConfirmButton: false,
    timer: 2500,
  });

  useEffect(() => {
    if (updateStatus === 'fulfilled' || updateAvatarStatus === 'fulfilled') {
      swalToast
        .fire({
          title: 'Save Successfully!',
          icon: 'success',
        })
        .then(function () {
          window.scrollTo(0, 0);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    } else if (updateStatus === 'rejected' || updateAvatarStatus === 'rejected') {
      swalToast.fire({
        title: message,
        icon: 'error',
      });
    } else if (updatePasswordStatus === 'fulfilled') {
      swalToast
        .fire({
          title: 'Save Successfully!',
          icon: 'success',
        })
        .then(function () {
          dispatch(logOutAction(navigate));
          dispatch(resetPathname());
        });
    } else if (updatePasswordStatus === 'rejected') {
      swalToast.fire({
        title: message,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, updateAvatarStatus, updatePasswordStatus]);

  return (
    <>
      {/* Render Info Section */}
      <div className="col-12 col-xxl-5">
        <Info />
      </div>
      <div className="col-12 col-xxl-7">
        <div className="row mt-3 mt-xxl-0">
          {/* Render Settings Section */}
          <Settings />
          {/* Render Sign-In Method Section */}
          <SignInMethod />
        </div>
      </div>
    </>
  );
};

export default Setting;
