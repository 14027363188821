import { createSlice } from '@reduxjs/toolkit';
import { iInitState } from '~/app/models';

interface iState extends iInitState {
  status: '' | 'rejected' | 'fulfilled' | 'pending';
  message: string;
  isDESC: boolean;
  keySearch: string;
  filterData: {};
  queryString: string;
  queryOrderString: string;
  queryCartString: string;
  queryReviewString: string;
  isEnableFilter: boolean;
  urlData: {
    pageSize: number;
    currentPage: number;
  };
  data: any;
}

const initialState: iState = {
  status: '',
  isDESC: false,
  isEnableFilter: false,
  keySearch: 'vendor_id',
  filterData: {},
  queryReviewString: '',
  queryString: '',
  queryCartString: '',
  queryOrderString: '',
  message: '',
  urlData: {
    currentPage: 1,
    pageSize: 10,
  },
  data: { items: [], fieldName: {} },
};

const sellerSlice = createSlice({
  name: 'SELLER',
  initialState,
  reducers: {
    getDataFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getDataRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),

    getUpdateStatusFulfilled: (state) => ({ ...state, status: 'fulfilled' }),
    getUpdateStatusRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getUpdateStatusPending: (state) => ({ ...state, status: 'pending' }),

    changeController: (state, action) => ({ ...state, controller: action.payload }),

    resetQueryString: (state) => ({ ...state, queryString: '' }),

    // Synchonous Ation
    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    setIsEnableFilter: (state, action) => ({ ...state, isEnableFilter: action.payload }),
    setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),
    setFilterData: (state, action) => ({ ...state, filterData: action.payload }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),
    setQueryCartString: (state, action) => ({ ...state, queryCartString: action.payload }),
    setQueryOrderString: (state, action) => ({ ...state, queryOrderString: action.payload }),
    setQueryReviewString: (state, action) => ({ ...state, queryReviewString: action.payload }),
    clearData: () => ({ ...initialState }),
  },
});

export const { ...rest } = sellerSlice.actions;
const sellerReducer = sellerSlice.reducer;

export default sellerReducer;
