import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import CategoriesAndAttributes from './CategoriesAndAttributes';
import Configurations from './Configurations';
import ImageAndProduct from './ImageAndVideo';
import RelatedProduct from './RelatedProduct';
import SearchEngineOptimization from './SearchEngineOptimization';
import ProductInWebsites from './ProductInWebsites/ProductInWebsites';

const ContentToggle = ({ formik }: any) => {
  const { status, message } = useAppSelector((s) => s.createEditProductReducer);
  const [toggleContent, setToggleContent] = useState<number | undefined>();

  useEffect(() => {
    if (message.includes('URL key for specified store already exists.')) {
      setToggleContent(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const DATA_CONTENT = [
    {
      id: 1,
      title: 'Categories & Attributes',
      component: CategoriesAndAttributes,
      props: {},
    },
    {
      id: 2,
      title: 'Search Engine Optimization',
      component: SearchEngineOptimization,
      props: {},
    },
    {
      id: 3,
      title: 'Images & Videos',
      component: ImageAndProduct,
      props: {},
    },
    {
      id: 4,
      title: 'Related Products',
      component: RelatedProduct,
      props: {},
    },
    {
      id: 5,
      title: 'Configurations',
      component: Configurations,
      props: { formik: formik },
    },
    {
      id: 6,
      title: 'Product in Websites',
      component: ProductInWebsites,
      props: {},
    },
  ];

  const renderContent = () => {
    return DATA_CONTENT.map((item) => {
      const { id, title, component, props } = item;
      const Component: React.FC<any> = component;

      const active = toggleContent === id;

      return (
        <div key={id}>
          <h3
            className="heading-text m-0 cursor-pointer d-flex align-items-center fw-normal"
            onClick={() => setToggleContent(toggleContent === id ? undefined : id)}
          >
            <span>{title}</span> <FontAwesomeIcon icon={active ? faChevronUp : faChevronDown} className="ms-3" />
          </h3>
          {!active && <hr className="mb-0" />}
          {active && (
            <div className="content-wrap mt-3">
              <Component {...props} />
            </div>
          )}
        </div>
      );
    });
  };

  return <div className="d-flex flex-column gap-4">{renderContent()}</div>;
};

export default ContentToggle;
