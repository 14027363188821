import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { addFilterGroup, addFilterItem, addFilters, convertDescriptionToPayload, searchCriteriaBuilder, slugify } from '~/app/utils';
import Button from '~/components/common/Button';
import Loading from '~/components/common/Loading';
import DefaultLayout from '~/components/layout/DefaultLayout';
import ContentToggle from './ContentToggle';
import Header from './Header';
import {
  actionCreateEditConfigProduct,
  actionCreateEditProduct,
  actionReset,
  getListProductAssociated,
  getAttrListingProduct,
  onChangeDataAttr,
  onChangeDataHeader,
  onChangeListImage,
  onChangeListProductAssociated,
  onChangeListTierPrice,
  onChangeDataVideo,
  onChangeAction,
  onChangeType,
  onChangeProductGenerate,
  onChangeDataValuesConfigSelected,
  onChangeDataAttrConfigSelected,
  deleteProduct,
  getProductDetail,
  getListAttSet,
  getCategories,
  getCategoriesTree,
  onChangeIdCategoriesSelected,
  onChangeProductInWebsites,
  onChangeCurrentWebsiteId,
} from './redux/actions';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import './style.scss';
import RenderPopup from './Popup';
import {
  handleChangeCustomAttr,
  handleChangeImageProductChildOfConfig,
  handleChangeListConfigOption,
  handleChangeListMedia,
  handleChangeListTierPrice,
  handleChangeProductLink,
  handleChangeStockItem,
} from './convertPayload';
import {
  getListCrossSellProduct,
  getListRelatedProduct,
  getListUpSellProduct,
  handleCovertConfigProductOption,
  handleCovertDataAdvancedInventory,
  handleCovertDataAdvancedPrice,
  handleCovertDataCustomAttr,
  handleCovertDataSearchOptimize,
} from './covertDataProduct';
import Swal from 'sweetalert2';
import successImg from '~/app/images/success.png';
import Separator from '~/components/common/Separator';
import useScript from '~/app/hooks/useScript';
import { iDataCreateListSubProduct, iInfoWebsitesAndStoreItem, iProduct } from '~/app/models';
import { defaultMessageError } from '~/app/constants';

interface Props {
  type?: 'simple' | 'config';
  action: 'create' | 'edit';
}

export interface inItialSateHeaderFormik {
  name: string;
  sku: string;
  price: number | string;
  weight: number | string;
  qty: number | string;
}

const CreateEditProduct = ({ type, action }: Props) => {
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { queryString: productQuery } = useAppSelector((s) => s.productReducer);

  useScript(`${process.env.REACT_APP_URL}ckfinder/ckfinder.js`);

  const {
    dataCate,
    data,
    dataVideo,
    status,
    statusGetAttrSetList,
    statusGetProductDetail,
    dataHeader,
    dataImage,
    dataCrossSellSelected,
    dataRelatedSelected,
    dataUpSellSelected,
    idCategoriesSelected,
    dataAdvInventory,
    dataRelatedAttrByAttrSet,
    dataAdvPrice,
    dataAttrList,
    dataSearchOptimize,
    dataProductGenerate,
    messageGetProductDetail,
    dataValuesConfigSelected,
    dataAttrConfigSelected,
    dataProductsAssociatedUnConverted,
    listProductAssociated,
    listTierPrice,
    listAttrOfProductConfig,
    deleteProductStatus,
    listApplyAllStores,
    message,
    action: currentAction,
    statusGetAttributeOfAttrSetId,
    shouldShowLoadingWhenAttrSetIdChange,
    messageGetAttributeOfAttrSetId,
    productInWebsites,
  } = useAppSelector((state) => state.createEditProductReducer);
  const { data: dataUser } = useAppSelector((s) => s.userReducer);

  const {
    name,
    sku,
    price,
    custom_attributes = [],
    extension_attributes,
    media_gallery_entries,
    product_links,
    visibility,
    weight,
    attribute_set_id,
    type_id,
    tier_prices,
  } = data;
  const {
    stock_item,
    configurable_product_options,
    configurable_product_links,
    use_default = [],
    category_links = [],
    website_ids = [],
  } = extension_attributes ?? {};
  const { qty, is_in_stock } = stock_item ?? {};

  const formik = useFormik<inItialSateHeaderFormik>({
    initialValues: {
      name: '',
      sku: '',
      price: '',
      weight: '',
      qty: '',
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: yup.object().shape({
      price:
        listProductAssociated.concat(dataProductGenerate).length > 0
          ? yup.number()
          : yup
              .number()
              .required('Price is required')
              .min(0, 'Please enter a number 0 or greater, without comma in this field.')
              .typeError('Please enter a number 0 or greater, without comma in this field.'),
      name: yup.string().required('Product name is required').max(255, 'Product Name maximum 255 characters'),
      sku: yup.string().required('SKU is required').max(64, 'SKU maximum 64 characters'),
      // .matches(/^[^:/.#!?&@%+~]*$/, "SKU can't contain the following characters: '~', '!', '@', '#', '%', '&', '+', ':', '.', '?', '/' or '\\'"),
      weight: yup
        .number()
        .min(0, 'Please enter a number 0 or greater, without comma in this field.')
        .typeError('Please enter a number 0 or greater, without comma in this field.'),
      qty: !!+dataAdvInventory.is_qty_decimal ? yup.string() : yup.string().matches(/^[^.]+$/g, 'Please enter a valid integer in this field.'),
    }),
    onSubmit: handleSubmitForm,
  });

  const { idProduct = 0 } = useParams();
  const navigate = useNavigate();

  const swalConfirmDelete = Swal.mixin({
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: 'ok',
    cancelButtonText: 'cancel',
    customClass: {
      popup: 'p-32px border-rd-25',
      closeButton: 'mb-n4 text-dark btn-not-hover',
      title: 'h3 text-dark text-center m-0 fw-semibold p-0',
      htmlContainer: 'text-dark text-center mt-3 mb-0 mx-0 fw-normal',
      actions: 'mt-3 w-100',
      confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-40',
      cancelButton: 'btn btn-primary btn-cancel fs-14 fw-medium btn-border-radius border-rd-25 order-0 w-40',
    },
  });

  const onClickDeleteProduct = () => {
    swalConfirmDelete
      .fire({
        title: 'Delete Product',
        html: 'Are you sure?<br/>This product will also be deleted from other stores.',
        width: 'auto',
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteProduct(+idProduct, currentStore));
        }
      });
  };

  useEffect(() => {
    if (deleteProductStatus === 'fulfilled') {
      swalConfirmDelete
        .fire({
          title: `<img src="${successImg}" alt="success" className="mx-auto" />`,
          text: 'You have successfully deleted product',
          showCancelButton: false,
          showCloseButton: false,
          confirmButtonText: 'ok',
          customClass: {
            popup: 'p-32px border-rd-25 content-success bg-white d-flex flex-column gap-3',
            title: 'h3 text-dark text-center m-0 fw-semibold p-0',
            htmlContainer: 'message text-center m-0 fw-semibold',
            actions: 'mt-0 w-100',
            confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-100',
          },
        })
        .then(() => {
          navigate('/products');
        });
    } else if (deleteProductStatus === 'rejected') {
      swalConfirmDelete.fire({
        title: 'Delete Product',
        text: defaultMessageError,
        showCancelButton: false,
        showCloseButton: false,
        confirmButtonText: 'ok',
        customClass: {
          popup: 'p-32px border-rd-25 content-success bg-white d-flex flex-column gap-3',
          title: 'h3 text-dark text-center m-0 fw-semibold p-0',
          htmlContainer: 'message text-center m-0 fw-semibold',
          actions: 'mt-0 w-100',
          confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-100',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProductStatus]);

  useEffect(() => {
    dispatch(
      getListAttSet({ currentStore, searchURL: searchCriteriaBuilder(Number.MAX_VALUE, 1), idProductEdit: idProduct ? +idProduct : undefined }),
    );

    if (idProduct) {
      dispatch(getProductDetail({ currentStore, id: +idProduct }));
    }

    dispatch(getCategories({ currentStore, searchURL: searchCriteriaBuilder(Number.MAX_VALUE, 1) }));
    dispatch(getCategoriesTree({ currentStore, onlyActive: true }));

    return () => {
      dispatch(actionReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dataUser.website_ids) return;
    const websiteList = JSON.parse(dataUser.website_ids || '') as { [key: string]: iInfoWebsitesAndStoreItem };
    const websiteToArray = Object.entries(websiteList);

    const currentWebsite = websiteToArray.find((website) => {
      const storeList = website[1];
      const isDuplicated = storeList.stores.some((store) => store.code === currentStore);
      return isDuplicated;
    });

    if (!currentWebsite) return;

    const currentWebsiteId = +currentWebsite[0];

    currentAction === 'create' && dispatch(onChangeProductInWebsites([currentWebsiteId]));
    dispatch(onChangeCurrentWebsiteId(currentWebsiteId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser.website_ids, data]);

  useEffect(() => {
    if (dataHeader.attribute_set_id) {
      dispatch(
        getAttrListingProduct({
          attributeSetId: +dataHeader.attribute_set_id,
          currentStore,
          shouldShowLoadingWhenAttrSetIdChange,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHeader.attribute_set_id]);

  useEffect(() => {
    if (Object.entries(data).length === 0) return;

    formik.setValues({ name, sku, price: price?.toFixed(2), qty: qty || 0, weight });
    dispatch(onChangeProductInWebsites(website_ids));

    if (tier_prices?.length > 0) {
      const newTierPrice = tier_prices.map((item) => {
        const id = uuidv4();
        return {
          id,
          website_id: item.extension_attributes.website_id,
          customer_group_id: item.customer_group_id,
          [`percentage_value${id}`]: item.extension_attributes.percentage_value,
          [`qty${id}`]: item.qty,
          [`value${id}`]: (+item.value).toFixed(2),
        };
      });
      dispatch(onChangeListTierPrice(newTierPrice));
    }

    if (configurable_product_links) {
      dispatch(
        getListProductAssociated(
          searchCriteriaBuilder(
            configurable_product_links.length,
            1,
            addFilterGroup(addFilters(addFilterItem('entity_id', configurable_product_links.join(','), 'in'))),
          ),
          accessToken,
          currentStore,
        ),
      );
    }

    // Change data header
    const listNameAttrHeader = ['short_description', 'description', 'sw_featured', 'country_of_manufacture', 'tax_class_id'];
    const listAttrHeader = custom_attributes?.filter((item) => listNameAttrHeader.includes(item.attribute_code));
    const listEntriesAttrHeader = listAttrHeader?.map((item) => {
      const { attribute_code, value } = item;
      return [attribute_code, value || ''];
    });
    const newListAttrHeader = listEntriesAttrHeader ? Object.fromEntries(listEntriesAttrHeader) : {};

    const newDescription = newListAttrHeader.description
      ? newListAttrHeader.description
          .replaceAll(/<style>.*<\/style>/g, '')
          .replaceAll(/src="{{media\surl=&quot;/g, `src="${process.env.REACT_APP_IMAGE_URL}/`)
          .replaceAll(/&quot;}}"\s*alt/g, '" alt')
          .replaceAll(/src="{{media\surl=/g, `src="${process.env.REACT_APP_IMAGE_URL}/`)
          .replaceAll(/}}"\s*alt/g, '" alt')
          .replaceAll(/&lt;/g, '<')
          .replaceAll(/&gt;/g, '>')
          .replaceAll('class="pagebuilder-mobile-hidden"', 'alt="desktop-image"')
          .replaceAll('class="pagebuilder-mobile-only"', 'alt="mobile_image"')
          .replaceAll('<figcaption data-element="caption">', '</br><figcaption data-element="caption">')
      : '';

    dispatch(
      onChangeDataHeader({
        ...dataHeader,
        ...newListAttrHeader,
        attribute_set_id,
        visibility,
        status: data.status === 1 ? true : false,
        weight,
        product_has_weight: type_id === 'virtual' ? '0' : '1',
        sw_featured: !!+newListAttrHeader.sw_featured ? true : false,
        quantity_and_stock_status: is_in_stock ? '1' : '0',
        name,
        sku,
        price,
        description: newDescription,
      }),
    );

    // Change data advanced price
    handleCovertDataAdvancedPrice(custom_attributes, dispatch);

    // Change search optimize
    handleCovertDataSearchOptimize(dataSearchOptimize, custom_attributes, dispatch);

    // Change advanced inventory
    stock_item && handleCovertDataAdvancedInventory(stock_item, dataAdvInventory, dispatch);

    // Change list image and video
    const listImg = media_gallery_entries.filter((item) => item.media_type === 'image');
    const listVideo = media_gallery_entries.filter((item) => item.media_type === 'external-video');
    dispatch(onChangeListImage(listImg));
    dispatch(onChangeDataVideo(listVideo));

    // Change list attributes
    const newListAttrCateAndAttr = handleCovertDataCustomAttr(dataRelatedAttrByAttrSet, custom_attributes);
    dispatch(onChangeDataAttr(newListAttrCateAndAttr));

    // Get list product related, up sell and cross sell
    if (product_links && product_links.length > 0) {
      getListRelatedProduct(product_links, dispatch, accessToken, currentStore);
      getListUpSellProduct(product_links, dispatch, accessToken, currentStore);
      getListCrossSellProduct(product_links, dispatch, accessToken, currentStore);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!category_links.length) return;

    const idCategoriesSelected = category_links.map((item) => +item.category_id);
    const newIdCategoriesSelected = dataCate.filter((item) => idCategoriesSelected.includes(item.id)).map((item) => item.id.toString());
    dispatch(onChangeIdCategoriesSelected(newIdCategoriesSelected));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCate, data]);

  useEffect(() => {
    dispatch(onChangeAction(action));
    dispatch(onChangeType(type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (action === 'create') {
      dispatch(onChangeProductGenerate([]));
      dispatch(onChangeDataValuesConfigSelected({}));
      dispatch(onChangeDataAttrConfigSelected([]));
    }

    // Change list attributes when edit
    const newListAttrCateAndAttr = handleCovertDataCustomAttr(dataRelatedAttrByAttrSet, custom_attributes);
    dispatch(onChangeDataAttr(newListAttrCateAndAttr));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRelatedAttrByAttrSet]);

  useEffect(() => {
    if (!configurable_product_links || !configurable_product_options || !dataProductsAssociatedUnConverted.length || !dataRelatedAttrByAttrSet.length)
      return;

    // this function return data selected
    const dataValuesConfigSelected = handleCovertConfigProductOption(dataRelatedAttrByAttrSet, configurable_product_options, dispatch);

    // Covert list product associated
    const listAttrConfigOption = dataRelatedAttrByAttrSet?.filter((attr) =>
      Object.keys(dataValuesConfigSelected).includes(`${attr.default_frontend_label}-id${attr.attribute_id}`),
    );

    if (!listAttrConfigOption.length) return;

    const listAttrCodeConfigOption = listAttrConfigOption.map((attr: any) => attr.attribute_code);

    let newListProductAssociated: any[] = [];

    dataProductsAssociatedUnConverted.forEach((product) => {
      if (product.type_id === 'configurable') return;
      const listAttrFound = product.custom_attributes?.filter((attr) => listAttrCodeConfigOption.includes(attr.attribute_code));
      const listAttrCodeFound = listAttrFound?.map((attr) => attr.attribute_code);

      // Pass if list length attribute found not equal list configurable_product_options
      if (listAttrCodeFound.length !== listAttrCodeConfigOption.length) return;
      newListProductAssociated = [...newListProductAssociated, product];
    });

    let newList: any[] = [];
    newListProductAssociated.forEach((product: any, i: number, originArr: any) => {
      const listAttrFound = product.custom_attributes.filter((attr: any) => listAttrCodeConfigOption.includes(attr.attribute_code));
      const listAttrCodeFound = listAttrFound.map((attr: any) => attr.attribute_code);
      const listProductCheck = originArr.filter((_: any, index: number) => index !== i);

      const isDuplicate =
        listProductCheck.length === 0
          ? false
          : listProductCheck.every((p: any) => {
              const listAttrFoundCheck = p.custom_attributes.filter((attr: any) => listAttrCodeConfigOption.includes(attr.attribute_code));

              const isValueDuplicate = listAttrFoundCheck.every((item: any) => {
                const valueCheck = listAttrFound.find((attr: any) => attr.attribute_code === item.attribute_code)?.value;

                return valueCheck === item.value;
              });

              if (isValueDuplicate) {
                const find = newList.find((item: any) => item.id === p.id || item.id === product.id);

                if (!find) {
                  const newListAttr = dataRelatedAttrByAttrSet
                    .filter((attr: any) => listAttrCodeFound.includes(attr.attribute_code))
                    .map((attr: any) => {
                      const valueAttr = listAttrFound.find((item: any) => item.attribute_code === attr.attribute_code)?.value;
                      const newValue = attr.options.find((option: any) => option.value === valueAttr);
                      return [`${attr.default_frontend_label}-id${attr.attribute_id}`, newValue];
                    });

                  const newProduct = {
                    id: product.id,
                    name: product.name,
                    sku: product.sku,
                    price: product.price,
                    qty: product.extension_attributes?.stock_item?.qty || '',
                    weight: product.weight || '',
                    attributes: Object.fromEntries(newListAttr),
                  };

                  newList = [...newList, newProduct];
                }
              }

              return isValueDuplicate;
            });

      if (!isDuplicate) {
        const newListAttr = dataRelatedAttrByAttrSet
          .filter((attr: any) => listAttrCodeFound.includes(attr.attribute_code))
          .map((attr: any) => {
            const valueAttr = listAttrFound.find((item: any) => item.attribute_code === attr.attribute_code)?.value;

            const newValue = attr.options.find((option: any) => option.value === valueAttr);
            return [`${attr.default_frontend_label}-id${attr.attribute_id}`, newValue];
          });

        const image = product.media_gallery_entries.find((img: any) => img?.types?.includes('thumbnail'));

        const newProduct = {
          id: product.id,
          image: image
            ? {
                label: image.label,
                file: `${process.env.REACT_APP_IMAGE_CATALOG_URL}${image.file}`,
              }
            : null,
          name: product.name,
          sku: product.sku,
          price: product.price,
          qty: product.extension_attributes?.stock_item?.qty,
          weight: product.weight || '',
          attributes: Object.fromEntries(newListAttr),
        };

        newList = [...newList, newProduct];
      }
    });

    dispatch(onChangeListProductAssociated(newList));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductsAssociatedUnConverted, dataRelatedAttrByAttrSet]);

  useEffect(() => {
    // When remove all product associated -> required type price
    if (listProductAssociated.length === 0 && type_id === 'configurable') {
      formik.setFieldValue('price', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProductAssociated]);

  function handleSubmitForm(values: inItialSateHeaderFormik) {
    const { name, price, sku, weight, qty } = values;
    const { status, visibility, attribute_set_id, product_has_weight, quantity_and_stock_status } = dataHeader;

    const newCateList = idCategoriesSelected.map((item: any) => ({
      position: 0,
      category_id: +item,
    }));

    const newCustomAttrList = handleChangeCustomAttr(
      dataRelatedAttrByAttrSet,
      dataAdvPrice,
      dataHeader,
      dataAttrList,
      dataSearchOptimize,
      listApplyAllStores,
      currentAction,
    );
    const newProductLink = handleChangeProductLink(dataRelatedSelected, dataUpSellSelected, dataCrossSellSelected, sku);
    const newListTierPrice = handleChangeListTierPrice(listTierPrice, price);
    const newStockItem = handleChangeStockItem(dataAdvInventory, quantity_and_stock_status, qty, dataProductGenerate, listProductAssociated);
    const newMediaGalleryEntries = handleChangeListMedia(dataImage, dataVideo);
    const listConfigOption = handleChangeListConfigOption(listAttrOfProductConfig, dataRelatedAttrByAttrSet, dataValuesConfigSelected);

    let payload: any = {
      name,
      sku,
      price: +price,
      weight: +weight || 0,
      visibility: +visibility || 4,
      status: currentAction === 'edit' && listApplyAllStores.status ? null : status ? 1 : 2,
      type_id: product_has_weight === '0' ? 'virtual' : 'simple',
      attribute_set_id: +attribute_set_id,
      ...newListTierPrice,
      extension_attributes: {
        websiteIds: productInWebsites,
        category_links: newCateList,
        stock_item: newStockItem,
      },
      custom_attributes: newCustomAttrList,
    };

    let payloadApplyAllStores: any = { sku };

    if (currentAction === 'edit') {
      const oldStatus = data.status === 1 ? true : false;
      const oldShortDesc = data.custom_attributes.find((attr) => attr.attribute_code === 'short_description')?.value || '';
      const oldDesc = data.custom_attributes.find((attr) => attr.attribute_code === 'description')?.value || '';
      const oldUrlKey = data.custom_attributes.find((attr) => attr.attribute_code === 'url_key')?.value || '';
      const oldMetaKeyword = data.custom_attributes.find((attr) => attr.attribute_code === 'meta_keyword')?.value || '';
      const oldMetaTitle = data.custom_attributes.find((attr) => attr.attribute_code === 'meta_title')?.value || '';

      if (listApplyAllStores.status && (!use_default.find((key) => key === 'status') || oldStatus !== dataHeader.status)) {
        payloadApplyAllStores = { ...payloadApplyAllStores, status: status ? 1 : 2 };
      }

      const { short_description, description: descriptionCk5 } = dataHeader;
      const { meta_keyword, meta_title, url_key } = dataSearchOptimize;

      const description = convertDescriptionToPayload(descriptionCk5);
      const custom_attributes = [];
      listApplyAllStores.description &&
        (!use_default.find((key) => key === 'description') || oldDesc !== (dataHeader.description || '')) &&
        custom_attributes.push({ attribute_code: 'description', value: description || null });
      listApplyAllStores.short_description &&
        (!use_default.find((key) => key === 'short_description') || oldShortDesc !== (dataHeader.short_description || '')) &&
        custom_attributes.push({ attribute_code: 'short_description', value: short_description || null });
      listApplyAllStores.meta_title &&
        (!use_default.find((key) => key === 'meta_title') || oldMetaTitle !== meta_title) &&
        custom_attributes.push({ attribute_code: 'meta_title', value: meta_title || null });
      listApplyAllStores.meta_keyword &&
        (!use_default.find((key) => key === 'meta_keyword') || oldMetaKeyword !== meta_keyword) &&
        custom_attributes.push({ attribute_code: 'meta_keyword', value: meta_keyword || null });

      listApplyAllStores.url_key &&
        (!use_default.find((key) => key === 'url_key') || oldUrlKey !== url_key) &&
        custom_attributes.push({ attribute_code: 'url_key', value: url_key ? slugify(url_key) : null });

      payloadApplyAllStores = custom_attributes.length > 0 ? { ...payloadApplyAllStores, custom_attributes } : { ...payloadApplyAllStores };
    }

    if (dataProductGenerate.length > 0) {
      let timesDuplicateSku = 0;
      let listSimpleProduct: iProduct[] = [];
      dataProductGenerate.forEach((item: any) => {
        const newItem = JSON.parse(JSON.stringify(item).replaceAll(`${item.id}":`, '":'));
        const { image, name, price, weight, qty: qtyOfChild, attributes } = newItem;

        const newAttr = Object.entries(attributes).map((item: any) => {
          const attribute_code = dataAttrConfigSelected.find((attr: any) => `${attr.name}-id${attr.id}` === item[0])?.attribute_code;

          return {
            attribute_code,
            value: item[1].value,
          };
        });
        const newImg = handleChangeImageProductChildOfConfig(image);

        let skuProduct = newItem.sku;

        const isDuplicateSku = newItem.sku === sku;
        if (isDuplicateSku) {
          skuProduct = timesDuplicateSku === 0 ? skuProduct : `${skuProduct}-${timesDuplicateSku}`;
          timesDuplicateSku = timesDuplicateSku + 1;
        } else {
          const listSku = listSimpleProduct.map((p) => p.sku);
          const skuDuplicate = listSku.find((sku) => sku === skuProduct);
          if (skuDuplicate) {
            let times = 1;
            const checkValid: any = () => {
              const isNotDuplicate = listSku.every((sku) => sku !== `${skuProduct}-${times}`);
              if (!isNotDuplicate) {
                times = times + 1;
                checkValid();
              }
              return `${skuProduct}-${times}`;
            };
            skuProduct = checkValid();
          }
        }

        const payloadSimple: iProduct = {
          ...payload,
          name,
          sku: skuProduct,
          price: +price,
          weight: +weight || 0,
          visibility: 1,
          status: 1,
          custom_attributes: [...payload.custom_attributes, ...newAttr],
          media_gallery_entries: newImg,
          extension_attributes: {
            ...payload.extension_attributes,
            stock_item: {
              ...payload.extension_attributes.stock_item,
              qty: +qtyOfChild || 0,
              is_in_stock: quantity_and_stock_status === '1' ? (+qtyOfChild > 0 ? true : false) : false,
            },
          },
        };

        listSimpleProduct = [...listSimpleProduct, payloadSimple];
      });

      const payloadListSubProduct: iDataCreateListSubProduct = {
        products: listSimpleProduct,
      };

      // Product config
      const productConfig = {
        product: {
          ...payload,
          type_id: 'configurable',
          sku: timesDuplicateSku === 0 ? sku : `${sku}-${timesDuplicateSku}`,
          price: 0,
          weight: +weight || 0,
          media_gallery_entries: newMediaGalleryEntries,
          ...newProductLink,
          extension_attributes: {
            configurable_product_options: listConfigOption,
            configurable_product_links: listProductAssociated.map((p: any) => p.id),
            ...payload.extension_attributes,
            stock_item: {
              ...payload.extension_attributes.stock_item,
              qty: 0,
            },
          },
        },
      };

      return dispatch(
        actionCreateEditConfigProduct(
          payloadListSubProduct,
          productConfig,
          accessToken,
          currentStore,
          action === 'edit' ? +idProduct : undefined,
          payloadApplyAllStores,
        ),
      );
    }

    if (listProductAssociated.length > 0 && dataProductGenerate.length === 0) {
      const newPayload = {
        product: {
          ...payload,
          media_gallery_entries: newMediaGalleryEntries,
          type_id: 'configurable',
          extension_attributes: {
            ...payload.extension_attributes,
            configurable_product_options: listConfigOption,
            configurable_product_links: listProductAssociated.map((p: any) => p.id),
          },
          ...newProductLink,
        },
      };

      dispatch(actionCreateEditProduct(newPayload, accessToken, currentStore, action === 'create' ? undefined : +idProduct, payloadApplyAllStores));
      return;
    }

    const newPayload = {
      product: {
        ...payload,
        media_gallery_entries: newMediaGalleryEntries,
        ...newProductLink,
      },
    };

    dispatch(actionCreateEditProduct(newPayload, accessToken, currentStore, action === 'create' ? undefined : +idProduct, payloadApplyAllStores));
  }

  function renderContent() {
    if (
      (statusGetProductDetail === 'rejected' && messageGetProductDetail) ||
      (statusGetAttrSetList === 'rejected' && message) ||
      (statusGetAttributeOfAttrSetId === 'rejected' && messageGetAttributeOfAttrSetId)
    ) {
      return <p className="m-0 text-dark-600 text-center fs-6">{message || messageGetProductDetail}</p>;
    }

    if (
      status === 'pending' ||
      statusGetAttrSetList === 'pending' ||
      statusGetProductDetail === 'pending' ||
      statusGetAttributeOfAttrSetId === 'pending'
    ) {
      return <Loading />;
    }
    return (
      <form id="create-edit__product">
        <Header formik={formik} action={action} />

        <ContentToggle formik={formik} />

        <div className="action d-flex">
          <Button
            className="mt-3 text-uppercase"
            onClick={() => {
              formik.handleSubmit();

              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }, 0);
            }}
            width="200px"
          >
            {action === 'create' ? 'Create Product' : 'Update'}
          </Button>

          {action === 'edit' && (
            <Button className="mt-3 ms-3 text-uppercase" onClick={() => onClickDeleteProduct()} width="200px">
              Delete Product
            </Button>
          )}
        </div>
      </form>
    );
  }

  return (
    <DefaultLayout pageTitle={action === 'create' ? 'Create New Product' : 'Edit Product'}>
      <Separator className="mb-4">
        <Link
          to={`/products/${productQuery}`}
          className="back-product-list d-inline-block text-uppercase w-fit-content rounded-pill fs-14 px-32 text-decoration-none d-flex align-items-center fw-medium"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="ms-3 text-nowrap">Back To Product Listing</span>
        </Link>
      </Separator>
      {<RenderPopup action={action} formik={formik} />}
      {renderContent()}
    </DefaultLayout>
  );
};

export default CreateEditProduct;
