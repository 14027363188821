import {
  iGetAllOrder,
  iGetCreditMemosDetail,
  iGetDataById,
  iGetInvoiceDetail,
  iGetOrderData,
  iGetShipmentDetail,
  iOrderCommnent,
  iPayloadChangeStatusOrder,
  iTrackingNumber,
} from '~/app/models';
import orderApi from '~/app/apis/order.api';
import * as types from './index';
import { NavigateFunction } from 'react-router-dom';
import { defaultMessageError } from '~/app/constants';

const { rest } = types;

export const getAllOrders = (payload: iGetAllOrder, cancelController: AbortController) => async (dispatch: any) => {
  cancelController && cancelController.abort();
  dispatch(rest.getDataPending());
  const controller = new AbortController();
  dispatch(rest.changeController(controller));
  try {
    const res = await orderApi.getAllOrders(payload, controller);
    const { data } = res;
    !data ? dispatch(rest.getDataRejected(defaultMessageError)) : dispatch(rest.getDataFulfilled(data));
  } catch (error: any) {
    if (error.message === 'canceled') return;
    const { message } = error?.response?.data || '';
    dispatch(rest.getDataRejected(message));
  }
};

export const getDataById =
  (payload: iGetDataById, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getOrderDetailPending());

    try {
      const res = await orderApi.getOrderDetails(payload, store);

      const { data } = res;
      !data ? dispatch(rest.getOrderDetailRejected()) : dispatch(rest.getOrderDetailFulfilled(data));
    } catch (error) {
      dispatch(rest.getOrderDetailRejected());
    }
  };

export const getInvoices =
  (payload: iGetOrderData, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getInvoicesPending());

    try {
      const res = await orderApi.getInvoicesByOrderId(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getInvoicesRejected()) : dispatch(rest.getInvoicesFulfilled(data));
    } catch (error) {
      dispatch(rest.getInvoicesRejected());
    }
  };

export const getInvoiceDetail =
  (payload: iGetInvoiceDetail, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getInvoiceDetailPending());

    try {
      const res = await orderApi.getInvoiveDetail(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getInvoiceDetailRejected()) : dispatch(rest.getInvoiceDetailFulfilled(data));
    } catch (error) {
      dispatch(rest.getInvoiceDetailRejected());
    }
  };

export const getMemos =
  (payload: iGetOrderData, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getMemosPending());
    try {
      const res = await orderApi.getCreditMemos(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getMemosRejected()) : dispatch(rest.getMemosFulfilled(data));
    } catch (error) {
      dispatch(rest.getMemosRejected());
    }
  };

export const getMemosDetail =
  (payload: iGetCreditMemosDetail, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getMemosDetailPending());

    try {
      const res = await orderApi.getCreditMemosDetail(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getMemosDetailRejected()) : dispatch(rest.getMemosDetailFulfilled(data));
    } catch (error) {
      dispatch(rest.getMemosDetailRejected());
    }
  };

export const cancelOrder = (payload: iPayloadChangeStatusOrder, navigate: NavigateFunction) => async (dispatch: any) => {
  const { orderId, accessToken, currentStore } = payload;
  dispatch(rest.cancelOrderPending());

  try {
    await orderApi.cancelOrder(payload);
    // Redirect information order and get again information
    dispatch(getDataById({ id: orderId, accessToken }, currentStore));
    navigate(`/orders/${orderId}/information`);

    // Show toast success
    dispatch(rest.cancelOrderFulfilled());
  } catch (error) {
    dispatch(rest.cancelOrderRejected());
  }
};
export const holdOrder = (payload: iPayloadChangeStatusOrder, navigate: NavigateFunction) => async (dispatch: any) => {
  const { orderId, accessToken, currentStore } = payload;
  dispatch(rest.holdOrderPending());

  try {
    await orderApi.holdOrder(payload);
    // Redirect information order and get again information
    dispatch(getDataById({ id: orderId, accessToken }, currentStore));
    navigate(`/orders/${orderId}/information`);

    // Show toast success
    dispatch(rest.holdOrderFulfilled());
  } catch (error) {
    dispatch(rest.holdOrderRejected());
  }
};
export const unHoldOrder = (payload: iPayloadChangeStatusOrder, navigate: NavigateFunction) => async (dispatch: any) => {
  const { orderId, accessToken, currentStore } = payload;
  dispatch(rest.unHoldOrderPending());

  try {
    await orderApi.unHoldOrder(payload);
    // Redirect information order and get again information
    dispatch(getDataById({ id: orderId, accessToken }, currentStore));
    navigate(`/orders/${orderId}/information`);

    // Show toast success
    dispatch(rest.unHoldOrderFulfilled());
  } catch (error) {
    dispatch(rest.unHoldOrderRejected());
  }
};

export const getShipments =
  (payload: iGetOrderData, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getShipmentPending());
    try {
      const res = await orderApi.getShipments(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getShipmentRejected()) : dispatch(rest.getShipmentFulfilled(data));
    } catch (error) {
      dispatch(rest.getShipmentRejected());
    }
  };

export const getShipmentsDetail =
  (payload: iGetShipmentDetail, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.getShipmentDetailPending());

    try {
      const res = await orderApi.getShipmentsDetail(payload, store);
      const { data } = res;
      !data ? dispatch(rest.getShipmentDetailRejected()) : dispatch(rest.getShipmentDetailFulfilled(data));
    } catch (error) {
      dispatch(rest.getShipmentDetailRejected());
    }
  };

export const createTrackingNumber =
  (payload: iTrackingNumber, accessToken: string, currentStore: string, resetForm: any, setShowFormCreateTranckingNumber: any) =>
  async (dispatch: any) => {
    dispatch(rest.createTrackingNumberPending());

    try {
      const res = await orderApi.createTrackingNumber(payload, accessToken, currentStore);
      const { parent_id } = res.data;

      try {
        const resGetShipmentDetail = await orderApi.getShipmentsDetail({ entity_id: parent_id, accessToken }, currentStore);
        const { data } = resGetShipmentDetail;
        !data ? dispatch(rest.getShipmentDetailRejected()) : dispatch(rest.getShipmentDetailFulfilled(data));
        resetForm();
        setShowFormCreateTranckingNumber(false);
        dispatch(rest.createTrackingNumberFulfilled());
      } catch (error: any) {
        let message = error?.response?.data?.message || '';
        dispatch(rest.createTrackingNumberRejected(message));
      }
    } catch (error: any) {
      let message = error?.response?.data?.message || '';

      dispatch(rest.createTrackingNumberRejected(message));
    }
  };

export const createShipment = (order_id: number, payload: any, accessToken: string, currentStore: string, navigate: any) => async (dispatch: any) => {
  dispatch(rest.createShipmentPending());
  try {
    await orderApi.createShipment(order_id, payload, accessToken, currentStore);
    dispatch(rest.createShipmentFulfilled());
    dispatch(getDataById({ id: order_id, accessToken }, currentStore));
    navigate(`/orders/${order_id}/information`);
  } catch (error: any) {
    let message = error?.response?.data?.message || '';
    message = message.includes("You can't create a shipment without products.") ? "You can't create a shipment without products." : message;

    dispatch(rest.createShipmentRejected(message));
  }
};

export const createRefund =
  (order_id: number, payload: any, accessToken: string, currentStore: string, navigate: NavigateFunction) => async (dispatch: any) => {
    dispatch(rest.createRefundPending());
    try {
      await orderApi.createRefund(order_id, payload, accessToken, currentStore);
      dispatch(rest.createRefundFulfilled());

      dispatch(getDataById({ id: order_id, accessToken }, currentStore));
      navigate(`/orders/${order_id}/information`);
    } catch (error: any) {
      let message = error?.response?.data?.message || '';
      message = message.includes("Creditmemo Document Validation Error(s):\nThe credit memo's total must be positive.")
        ? "The credit memo's total must be positive."
        : message;
      message = message.includes(
        "Creditmemo Document Validation Error(s):\nA creditmemo can not be created when an order has a status of closed\nThe credit memo's total must be positive.",
      )
        ? "A credit memo cannot be created when the order's status is close"
        : message;

      dispatch(rest.createRefundRejected(message));
    }
  };

export const createInvoice =
  (order_id: number, payload: any, accessToken: string, currentStore: string, navigate: NavigateFunction) => async (dispatch: any) => {
    dispatch(rest.createInvoicePending());
    try {
      await orderApi.createInvoice(order_id, payload, accessToken, currentStore);
      dispatch(rest.createInvoiceFulfilled());

      dispatch(getDataById({ id: order_id, accessToken }, currentStore));
      navigate(`/orders/${order_id}/information`);
    } catch (error: any) {
      let message = error?.response?.data?.message || '';

      message = message.includes("The invoice can't be created without products. Add products and try again.")
        ? "The invoice can't be created without products. Add products and try again."
        : message;

      dispatch(rest.createInvoiceRejected(message));
    }
  };

export const deleteTrackingNumber = (id: number, parent_id: number, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(rest.deleteTrackingNumberPending());
  try {
    await orderApi.deleteTrackingNumber(id, accessToken, currentStore);

    try {
      const resGetShipmentDetail = await orderApi.getShipmentsDetail({ entity_id: parent_id, accessToken }, currentStore);
      const { data } = resGetShipmentDetail;
      !data ? dispatch(rest.getShipmentDetailRejected()) : dispatch(rest.getShipmentDetailFulfilled(data));
      dispatch(rest.deleteTrackingNumberFulfilled());
    } catch (error) {
      dispatch(rest.deleteTrackingNumberRejected());
    }
  } catch (error) {
    dispatch(rest.deleteTrackingNumberRejected());
  }
};

export const postOrderComment =
  (payload: iOrderCommnent, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.postOrderCommentPending());

    try {
      const res: any = await orderApi.postOrderComment(payload, store);
      res ? dispatch(rest.postOrderCommentFulfilled()) : rest.postOrderCommentRejected();
    } catch {
      dispatch(rest.postOrderCommentRejected());
    }
  };

export const invoiceComment =
  (payload: iOrderCommnent, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.postOrderCommentPending());

    try {
      const res: any = await orderApi.postInvoiceComment(payload, store);
      res ? dispatch(rest.postOrderCommentFulfilled()) : rest.postOrderCommentRejected();
    } catch {
      dispatch(rest.postOrderCommentRejected());
    }
  };

export const memoComment =
  (payload: iOrderCommnent, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.postOrderCommentPending());

    try {
      const res: any = await orderApi.postMemoComment(payload, store);
      res ? dispatch(rest.postOrderCommentFulfilled()) : rest.postOrderCommentRejected();
    } catch {
      dispatch(rest.postOrderCommentRejected());
    }
  };

export const shipmentComment =
  (payload: iOrderCommnent, store: string = 'all') =>
  async (dispatch: any) => {
    dispatch(rest.postOrderCommentPending());

    try {
      const res: any = await orderApi.postShipmentComment(payload, store);
      res ? dispatch(rest.postOrderCommentFulfilled()) : rest.postOrderCommentRejected();
    } catch {
      dispatch(rest.postOrderCommentRejected());
    }
  };

export const getNotesHistoty = (payload: { id: number; accessToken: string }) => async (dispatch: any) => {
  dispatch(rest.getNotesHistoryPending());
  try {
    const res: any = await orderApi.getNotesHistoty(payload);
    const { data } = res;
    data ? dispatch(rest.getNotesHistoryFulfilled(data)) : dispatch(rest.getNotesHistoryRejected());
  } catch (error) {
    dispatch(rest.getNotesHistoryRejected());
  }
};

export const setUrlData = (payload: { pageSize: number; currentPage: number }) => (dispatch: any) => {
  dispatch(rest.setUrlData(payload));
};

export const setIsDESC = (isDESC: boolean) => (dispatch: any) => {
  dispatch(rest.setIsDESC(isDESC));
};

export const setKeySearch = (keySearch: string) => (dispatch: any) => {
  dispatch(rest.setKeySearch(keySearch));
};

export const setFilterData = (filterData: any) => (dispatch: any) => {
  dispatch(rest.setFilterData(filterData));
};

export const setQueryString = (queryString: string) => (dispatch: any) => {
  dispatch(rest.setQueryString(queryString));
};

export const resetOrderQueryString = () => (dispatch: any) => {
  dispatch(rest.resetQueryString());
};

export const clearData = () => (dispatch: any) => {
  dispatch(rest.clearData());
};
