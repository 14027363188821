import { faClose, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import ReactPlayer from 'react-player';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import Button from '~/components/common/Button';
import { getInfoVideoVimeo } from './infoVideo';
import { onChangeDataVideo, onChangeListImage } from '../../redux/actions';
import closeImg from '~/app/images/closeRec.png';
import { iVideo } from '~/app/models';
import axiosConfig from '~/app/apis/axiosConfig';
import axios from 'axios';
import { Input, TextArea } from '~/components/common/Input';

interface inItialValues {
  video_url: string;
  video_title: string;
  video_description: string;
  base64_encoded_data: string;
  types: string[];
}
const Video = () => {
  const dispatch = useAppDispatch();
  const { dataImage, dataVideo, type, action, dataProductGenerate, listProductAssociated } = useAppSelector(
    (state) => state.createEditProductReducer,
  );
  const [showPopupVideo, setShowPopupVideo] = useState<boolean>(false);
  const [urlVideo, setUrlVideo] = useState<string>('');
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [showDetailVideo, setShowDetailVideo] = useState<undefined | number>();
  const [isChangeDataVideo, setIsChangeDataVideo] = useState<boolean>(true);

  const popupVideoRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<any>(null);

  useClickOutside(popupVideoRef, handleClosePopup);

  const formik = useFormik<inItialValues>({
    initialValues: {
      video_url: '',
      video_title: '',
      video_description: '',
      base64_encoded_data: '',
      types: [],
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: yup.object().shape({
      video_url: yup.string().url('Invalid video url').required('This field is required'),
      video_title: yup.string().required('This field is required'),
    }),
    onSubmit: handleSubmit,
  });

  const { values, errors, touched, handleBlur, handleChange, setFieldTouched, setFieldError, setFieldValue, setValues, setErrors } = formik;

  useEffect(() => {
    canSubmit && setCanSubmit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.video_url]);

  const showSwatch = useMemo(
    () => type !== 'config' && dataProductGenerate.concat(listProductAssociated).length === 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataProductGenerate, listProductAssociated, action, type],
  );

  function handleChangeRole(e: React.ChangeEvent<HTMLInputElement>) {
    values.types.includes(e.target.name)
      ? setFieldValue(
          'types',
          values.types.filter((role: string) => role !== e.target.name),
        )
      : setFieldValue('types', [...values.types, e.target.name]);
  }

  function handleClosePopup() {
    typeof showDetailVideo === 'number' && setShowDetailVideo(undefined);
    showPopupVideo && setShowPopupVideo(false);
    setUrlVideo('');
    formik.resetForm();
  }

  function handleShowPopupVideoEdit(indexVideo: number) {
    setShowDetailVideo(indexVideo);
    setIsChangeDataVideo(false);
    setErrors({});
    const infoVideo = dataVideo.find((_, index: number) => index === indexVideo);

    if (infoVideo) {
      const { file, types, extension_attributes, id } = infoVideo;

      setValues({
        base64_encoded_data: id ? `${process.env.REACT_APP_IMAGE_CATALOG_URL}${file}` : file,
        types,
        video_description: extension_attributes.video_content.video_description,
        video_title: extension_attributes.video_content.video_title,
        video_url: extension_attributes.video_content.video_url,
      });
      setUrlVideo(extension_attributes.video_content.video_url);
    }
  }

  const handleRemoveImage = (i: number) => {
    dispatch(onChangeDataVideo(dataVideo.filter((_, index: number) => index !== i)));
  };

  function handleSubmit(values: inItialValues) {
    const { base64_encoded_data, types, video_description, video_title, video_url } = values;

    const newVideo: iVideo = {
      media_type: 'external-video',
      label: null,
      disabled: false,
      types,
      file: base64_encoded_data,
      extension_attributes: {
        video_content: {
          media_type: 'external-video',
          video_metadata: '',
          video_provider: '',
          video_url,
          video_title,
          video_description,
        },
      },
    };

    const newList = [...dataImage, ...dataVideo].map((item) => ({
      ...item,
      types: item.types.filter((role: any) => !types.includes(role)),
    }));

    const listImg = newList.filter((item) => item.media_type !== 'external-video');
    const listVideo = newList.filter((item) => item.media_type === 'external-video');
    dispatch(onChangeListImage(listImg));

    if (typeof showDetailVideo === 'number') {
      const indexVideo = listVideo.findIndex((_: any, index: number) => index === showDetailVideo);
      const newListVideo = listVideo.filter((_: any, index: number) => index !== showDetailVideo);
      newListVideo.splice(indexVideo, 0, newVideo);

      dispatch(onChangeDataVideo(newListVideo));
    } else {
      dispatch(onChangeDataVideo([...listVideo, newVideo]));
    }

    return handleClosePopup();
  }

  function renderPopupVideo() {
    return (
      <div className="popup d-flex justify-content-center align-items-center">
        <div className="popup-content wrapper d-flex flex-column position-relative" ref={popupVideoRef}>
          <div className="text-end">
            <div className="close-icon cursor-pointer mb-4 d-inline-flex" onClick={handleClosePopup}>
              <FontAwesomeIcon icon={faClose} />
            </div>
            <div className="done-wrap mb-4 py-3 d-flex justify-content-end">
              <Button className="me-3 fw-medium" onClick={() => formik.handleSubmit()} disabled={!canSubmit}>
                Done
              </Button>
            </div>
          </div>
          <div className="row w-100">
            <div className="video-content d-flex flex-column gap-4 col-6">
              <div className="d-flex">
                <h3 className=" me-4 fs-16 fw-normal text-end title center">
                  URL <span className="text-danger">*</span>
                </h3>
                <div className="flex-grow-1">
                  <Input
                    className="w-100"
                    type="text"
                    name="video_url"
                    value={(values['video_url' as keyof typeof values] as string) || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setFieldTouched('video_url', false);
                    }}
                    onBlur={(e: any) => {
                      setUrlVideo(e.target.value);
                      handleBlur(e);
                    }}
                  />
                  {errors['video_url' as keyof typeof errors] && touched['video_url' as keyof typeof errors] && (
                    <p className="fs-14 mb-0 mt-2 text-danger">{errors['video_url' as keyof typeof errors]}</p>
                  )}
                  <p className="fs-14 mb-0 mt-2 text-dark-600">Vimeo and Youtube supported</p>
                </div>
              </div>

              <div className="d-flex">
                <h3 className="me-4 fs-16 fw-normal text-end title center">
                  Title <span className="text-danger">*</span>
                </h3>
                <div className="flex-grow-1">
                  <Input
                    type="text"
                    className="w-100"
                    name="video_title"
                    value={(values['video_title' as keyof typeof values] as string) || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setFieldTouched('video_title', false);
                    }}
                    onBlur={handleBlur}
                  />

                  {errors['video_title' as keyof typeof errors] && touched['video_title' as keyof typeof errors] && (
                    <p className="fs-14 mb-0 mt-2 text-danger">{errors['video_title' as keyof typeof errors]}</p>
                  )}
                </div>
              </div>

              <div className="d-flex">
                <h3 className="me-4 fs-16 fw-normal text-end title">Description</h3>
                <TextArea name="video_description" value={values.video_description} onChange={handleChange} onBlur={handleBlur} />
              </div>

              <div className="d-flex">
                <h3 className="me-4 fs-16 fw-normal text-end title">Preview Image</h3>
                <div>
                  <div className="item-select">
                    <img src={values.base64_encoded_data || require('~/app/images/thumbnail.png')} alt="preview" className="image preview" />
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <h3 className="me-4 fs-16 fw-normal text-end title">Role</h3>
                <div className="d-flex flex-column gap-3 border p-12 rounded list-role">
                  <div className="d-flex align-items-center checkbox-custom">
                    <input
                      className="form-check-input m-0 cursor-pointer"
                      type="checkbox"
                      id="image"
                      name="image"
                      checked={values.types.includes('image')}
                      onChange={handleChangeRole}
                    />
                    <label htmlFor="image" className="fs-6 fw-normal ms-2 cursor-pointer">
                      Base
                    </label>
                  </div>

                  <div className="d-flex align-items-center checkbox-custom">
                    <input
                      className="form-check-input m-0 cursor-pointer"
                      type="checkbox"
                      id="small_image"
                      name="small_image"
                      checked={values.types.includes('small_image')}
                      onChange={handleChangeRole}
                    />
                    <label htmlFor="small_image" className="fs-6 fw-normal ms-2 cursor-pointer">
                      Small
                    </label>
                  </div>

                  <div className="d-flex align-items-center checkbox-custom">
                    <input
                      className="form-check-input m-0 cursor-pointer"
                      type="checkbox"
                      name="thumbnail"
                      id="thumbnail"
                      checked={values.types.includes('thumbnail')}
                      onChange={handleChangeRole}
                    />
                    <label htmlFor="thumbnail" className="fs-6 fw-normal ms-2 cursor-pointer">
                      Thumbnail
                    </label>
                  </div>

                  {showSwatch && (
                    <div className="d-flex align-items-center checkbox-custom">
                      <input
                        className="form-check-input m-0 cursor-pointer"
                        type="checkbox"
                        id="swatch_image"
                        name="swatch_image"
                        checked={values.types.includes('swatch_image')}
                        onChange={handleChangeRole}
                      />
                      <label htmlFor="swatch_image" className="fs-6 fw-normal ms-2 cursor-pointer">
                        Swatch
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-6 d-flex justify-content-end">
              <div className="d-flex justify-content-center align-items-center video-wrap position-relative">
                <FontAwesomeIcon icon={faVideo} className="position-absolute top-50 start-50 translate-middle" />
                {!errors['video_url' as keyof typeof errors] && (
                  <div className="video w-100 h-100">
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      url={urlVideo}
                      ref={videoRef}
                      controls
                      onError={() => {
                        setUrlVideo('');
                        setFieldError('video_url', 'Video not found');
                      }}
                      onReady={async () => {
                        setCanSubmit(true);
                        if (urlVideo.startsWith('https://www.youtube.com')) {
                          const param = new URLSearchParams(urlVideo.split('?')[1]);
                          const idVideo = param.get('v');
                          const res = await axiosConfig.get(`/V1/product/thumbnail/${idVideo}`);
                          const thumb = res.data;
                          setFieldValue('base64_encoded_data', thumb);

                          // Get title and description
                          const urlGetInfo = `https://www.googleapis.com/youtube/v3/videos?key=${process.env.REACT_APP_YOUTUBE_KEY}&part=snippet&id=${idVideo}`;

                          try {
                            const res = await axios.get(urlGetInfo);
                            const { title, description } = res.data.items[0].snippet;
                            if (isChangeDataVideo) {
                              setFieldValue('video_title', title ?? '');
                              setFieldValue('video_description', description ?? '');
                            } else {
                              setIsChangeDataVideo(true);
                            }
                            setFieldTouched('video_title', false);
                            setFieldTouched('video_description', false);
                          } catch (error) {}
                        }

                        if (urlVideo.startsWith('https://vimeo.com/')) {
                          const infoVideo = await getInfoVideoVimeo(urlVideo);

                          const { thumbBase64: thumb, title, description } = infoVideo;
                          setFieldValue('base64_encoded_data', thumb ?? '');
                          if (isChangeDataVideo) {
                            setFieldValue('video_title', title ?? '');
                            setFieldValue('video_description', description ?? '');
                          } else {
                            setIsChangeDataVideo(true);
                          }
                          setFieldTouched('video_title', false);
                          setFieldTouched('video_description', false);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderListVideo() {
    return (
      <>
        {dataVideo.map((item: any, index: number) => {
          const { file, label, id, types } = item;

          return (
            <div key={index} className="item-select">
              <div className="position-relative">
                <img
                  src={closeImg}
                  alt="close-img"
                  className="remove cursor-pointer"
                  onClick={() => {
                    handleRemoveImage(index);
                  }}
                />
                <FontAwesomeIcon
                  icon={faVideo}
                  className="position-absolute top-50 start-50 translate-middle text-dark-400 cursor-pointer"
                  size="3x"
                  onClick={() => handleShowPopupVideoEdit(index)}
                />
                <img
                  className="image cursor-pointer"
                  src={id ? `${process.env.REACT_APP_IMAGE_CATALOG_URL}${file}` : file}
                  alt={label}
                  onClick={() => handleShowPopupVideoEdit(index)}
                />
              </div>
              {types?.length > 0 && (
                <div className="d-flex flex-wrap mt-2 gap-1">
                  {types.map((item: any) => (
                    <span key={item} className="fs-8 bg-primary text-white px-3 py-2 rounded-pill">
                      {item === 'image' && 'Base'}
                      {item === 'small_image' && 'Small'}
                      {item === 'thumbnail' && 'Thumbnail'}
                      {item === 'swatch_image' && 'Swatch'}
                    </span>
                  ))}
                </div>
              )}
            </div>
          );
        })}
        {dataVideo.length < 2 && (
          <div
            className="upload d-flex flex-column justify-content-center align-items-center gap-3 cursor-pointer"
            onClick={() => setShowPopupVideo(true)}
          >
            <FontAwesomeIcon icon={faVideo} size="2x" className="upload-img" />
            <span>(max 2 video)</span>
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <div className="d-flex flex-wrap gap-3">{renderListVideo()}</div>
      {showPopupVideo && renderPopupVideo()}
      {typeof showDetailVideo === 'number' && renderPopupVideo()}
    </div>
  );
};

export default Video;
