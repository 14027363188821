import Skeleton from 'react-loading-skeleton';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { formatMoney } from '~/app/utils';
import { useEffect, useMemo } from 'react';
import { getDataTotalSales } from './redux/actions';

import './style.scss';
import 'react-loading-skeleton/dist/skeleton.css';

const TotalSales = () => {
  const dispatch = useAppDispatch();
  const { currentStore, accessToken } = useAppSelector((s) => s.authReducer);
  const { dataTotalSales, statusTotalSales: status } = useAppSelector((s) => s.dashboardReducer);
  const { lifetime, average } = dataTotalSales;

  const totalSalesData = useMemo(
    () => [
      {
        title: 'Lifetime Sales',
        price: lifetime || 0,
      },
      {
        title: 'Average Order',
        price: average || 0,
      },
    ],
    [lifetime, average],
  );

  useEffect(() => {
    dispatch(getDataTotalSales({ accessToken, currentStore }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  const renderData = () =>
    totalSalesData.map((item, index) => {
      const { title, price } = item;
      return (
        <div key={index} className="total-sales__item d-flex align-items-center">
          <div className="text-and-number">
            <h4 className="m-0 title fw-semibold">{status !== 'pending' ? title : <Skeleton width={200} height={22} />}</h4>
            <h3 className="price fw-semibold">
              {status !== 'pending' ? formatMoney(+price, currentStore) : <Skeleton width={'70%'} height={32} className="price mt-2" />}
            </h3>
          </div>
        </div>
      );
    });

  return (
    <div className="total-sales flex-grow-1  mw-100 overflow-x-auto">
      <div className="total-sales__list">{renderData()}</div>
    </div>
  );
};

export default TotalSales;
