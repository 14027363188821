import { iTableHead } from '../models';

export const SIDEBAR = [
  { title: 'All', quantity: 7, value: '' },
  { title: 'Processing', quantity: 7, value: 'processing' },
  { title: 'Pending', quantity: 7, value: 'pending' },
  { title: 'Draft', quantity: 7, value: 'draft' },
  { title: 'Approved', quantity: 7, value: 'approved' },
];

export const visibility = ['Not Visible Individually', 'Catalog', 'Search', 'Catalog, Search'];

export const dataTableHead: iTableHead[] = [
  { name: '#ID', className: 'p-0 pb-4 ' },
  { name: 'Product Name', className: 'p-0 pb-4 w-25' },
  { name: 'Type', className: 'p-0 pb-4 text-center' },
  { name: 'SKU', className: 'p-0 pb-4 text-center' },
  { name: 'Price', className: 'p-0 pb-4 text-center' },
  { name: 'Status', className: 'p-0 pb-4 text-center' },
  { name: 'Visibility', className: 'p-0 pb-4 text-center' },
  { name: 'Actions', className: 'p-0 pb-4 text-center' },
];

export const dataOrderHead: iTableHead[] = [
  { name: 'ID', className: 'td-item text-start cursor-pointer px-4', key: 'increment_id' },
  { name: 'Purchase Point', className: 'td-item', key: '' },
  { name: 'Customer Email', className: 'td-item cursor-pointer', key: 'customer_email' },
  { name: 'Customer Name', className: 'td-item' },
  { name: 'Grand Total', className: 'td-item text-end cursor-pointer', key: 'base_grand_total' },
  { name: 'Total Refunded', className: 'td-item text-end cursor-pointer', key: 'base_total_refunded' },
  { name: 'Payment Method', className: 'td-item' },
  { name: 'Purchase Date', className: 'td-item text-end cursor-pointer', key: 'created_at' },
  { name: 'Status', className: 'td-item text-center cursor-pointer', key: 'status' },
  { name: 'Action', className: 'td-item text-center' },
];

export const dataProductHead: iTableHead[] = [
  { name: 'ID', className: 'w-5 text-center td-item cursor-pointer', key: 'entity_id' },
  { name: 'Product Name', className: 'w-30 td-item cursor-pointer', key: 'name' },
  { name: 'Type', className: 'td-item cursor-pointer', key: 'type_id' },
  { name: 'SKU', className: 'td-item cursor-pointer', key: 'sku' },
  { name: 'Price', className: 'td-item text-end' },
  { name: 'Quantity', className: 'w-10 td-item text-end' },
  { name: 'Salable Quantity', className: 'w-10 td-item text-end' },
  { name: 'Visibility', className: 'w-5 td-item ' },
  { name: 'Websites', className: 'td-item' },
  { name: 'Status', className: 'text-center td-item cursor-pointer', key: 'status' },
  { name: 'Action', className: 'text-center td-item' },
];

export const dataCustomerHead: iTableHead[] = [
  // { name: '', className: 'w-2 td-item text-center cursor-pointer', key: '', isCheckbox: true, checkboxName: 'id' },
  { name: 'ID', className: 'w-5 td-item cursor-pointer text-center', key: 'entity_id' },
  { name: 'Customer Name', className: 'w-15 td-item cursor-pointer', key: 'name' },
  { name: 'Email', className: 'w-15 td-item cursor-pointer', key: 'email' },
  { name: 'Phone', className: 'w-10 td-item cursor-pointer text-end', key: 'billing_telephone' },
  { name: 'Province', className: 'w-10 td-item cursor-pointer text-start', key: 'billing_region' },
  { name: 'Country', className: 'w-10 td-item cursor-pointer text-start', key: 'billing_country_id' },
  { name: 'Zip', className: 'w-5 td-item cursor-pointer text-end', key: 'billing_postcode' },
  { name: 'Websites', className: 'w-10 td-item cursor-pointer', key: '' },
  { name: 'Customer Since', className: 'w-10 text-end td-item cursor-pointer', key: 'created_at' },
  { name: 'Gender', className: 'w-10 td-item cursor-pointer', key: 'gender' },
  { name: 'Action', className: 'td-item text-center', key: '' },
];

export const dataCustomerOrderHead: iTableHead[] = [
  { name: 'Order', className: 'w-5 td-item cursor-pointer', key: 'increment_id' },
  { name: 'Purchased', className: 'w-15 td-item text-end cursor-pointer', key: 'created_at' },
  { name: 'Bill-to Name', className: 'w-15 td-item', key: '' },
  { name: 'Ship-to Name', className: 'w-15 td-item', key: '' },
  { name: 'Order Total', className: 'w-10 td-item text-end', key: '' },
  { name: 'Purchase Point', className: 'w-10 td-item text-start', key: '' },
];

export const dataCustomerCartHead: iTableHead[] = [
  { name: 'Product Name', className: 'w-40 td-item text-start cursor-pointer', key: '' },
  { name: 'Price', className: 'w-15 td-item cursor-pointer text-end', key: '' },
  { name: 'Quantity', className: 'w-15 td-item cursor-pointer text-end', key: '' },
  { name: 'Total', className: 'w-15 td-item cursor-pointer text-end', key: '' },
  { name: 'Action', className: 'w-15 td-item text-center', key: '' },
];

export const dataSellerHead: iTableHead[] = [
  // { name: '', className: 'w-2 td-item text-center cursor-pointer', key: '', isCheckbox: true, checkboxName: 'id' },
  { name: 'ID', className: 'w-5 td-item text-center cursor-pointer', key: 'vendor_id' },
  { name: 'Firstname', className: 'w-10 td-item cursor-pointer', key: 'firstname' },
  { name: 'Lastname', className: 'w-10 td-item cursor-pointer', key: 'lastname' },
  { name: 'Email', className: 'w-15 td-item cursor-pointer', key: 'email' },
  { name: 'Websites', className: 'w-10 td-item', key: '' },
  { name: 'Total Products', className: 'w-10 td-item text-end', key: '' },
  { name: 'Total Orders', className: 'w-10 td-item text-end', key: '' },
  { name: 'Status', className: 'w-5 td-item text-center cursor-pointer', key: 'is_active' },
  { name: 'Action', className: 'w-5 td-item text-center', key: '' },
];

export const dataStatus = [
  { title: 'In Stock', className: 'pills pills-success' },
  { title: 'Out of Stock', className: 'pills pills-danger' },
];

export const PAGES: number[] = [10, 20, 30, 50, 100, 200];

export const dataSaleReportHead: iTableHead[] = [
  { name: 'Interval', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Orders', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end ' },
  { name: 'Sales Items', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Sales Total', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Invoiced', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Refunded', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Sales Tax', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Sales Shipping', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Sales Discount', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Canceled', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
];

export const dataCustomerReportHead: iTableHead[] = [
  { name: 'Interval', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end w-10' },
  { name: 'Customer Name', className: 'text-white bg-primary fs-14 fw-medium py-3 ps-2 text-start' },
  { name: 'Email', className: 'text-white bg-primary fs-14 fw-medium py-3 ps-2 text-start' },
  { name: 'Orders', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Total', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Average', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
];

export const dataInvoicedReportHead: iTableHead[] = [
  { name: 'Interval', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Orders', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Invoiced Orders', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Total Invoiced', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Paid Invoices', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Unpaid Invoices', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
];

export const dataRefundedReportHead: iTableHead[] = [
  { name: 'Interval', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Refunded Orders', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Total Refunded', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Online Refunds', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
  { name: 'Offline Refunds', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end' },
];

export const dataProductReportHead: iTableHead[] = [
  { name: 'Product', className: 'text-white bg-primary fs-14 fw-medium py-3' },
  { name: 'SKU', className: 'text-white bg-primary fs-14 fw-medium py-3 text-start' },
  { name: 'Ordered Quantity', className: 'text-white bg-primary fs-14 fw-medium py-3 text-end text-nowrap' },
];

export const dataTrackingNumerHead: iTableHead[] = [
  { name: 'Carrier', className: 'fs-14 fw-medium ps-4 py-3' },
  { name: 'Title', className: 'fs-14 fw-medium p-3' },
  { name: 'Number', className: 'fs-14 fw-medium text-end p-3' },
  { name: 'Action', className: 'fs-14 fw-medium text-end pe-4 py-3' },
];

export const dataPromotionHead: iTableHead[] = [
  { name: 'ID', className: 'w-6 text-center td-item cursor-pointer text-nowrap', key: 'rule_id' },
  { name: 'Promotion Name', className: 'w-25 td-item cursor-pointer', key: 'name' },
  { name: 'Coupon Code', className: 'td-item cursor-pointer w-15 text-nowrap', key: 'code' },
  { name: 'Start Date', className: 'td-item cursor-pointer text-end text-nowrap', key: 'from_date' },
  { name: 'End Date', className: 'td-item cursor-pointer text-end text-nowrap', key: 'to_date' },
  { name: 'Priority', className: ' td-item cursor-pointer text-center text-nowrap', key: 'sort_order' },
  { name: 'Websites', className: ' td-item cursor-pointer text-center text-nowrap', key: '' },
  { name: 'Status', className: 'w-10 td-item cursor-pointer text-center text-nowrap', key: 'is_active' },
  { name: 'Action', className: 'text-center td-item' },
];

export const dataProductHeadForSkuOfPromotion: iTableHead[] = [
  { name: '', className: 'text-center td-item rounded-top-left', isCheckbox: true, checkboxName: '' },
  { name: 'Product Name', className: 'w-30 td-item  cursor-pointer', key: 'name' },
  { name: 'SKU', className: ' td-item cursor-pointer', key: 'sku' },
  { name: 'Type', className: 'w-20 td-item  cursor-pointer', key: 'type_id' },
  { name: 'Attribute Set', className: 'w-20 td-item cursor-pointer', key: 'attribute_set_id' },
];

export const dataHeadOfProductInCategory: iTableHead[] = [
  { name: '', className: 'w-60px text-center td-item rounded-top-left', isCheckbox: true },
  { name: 'ID', className: 'td-item text-center cursor-pointer', key: 'entity_id' },
  { name: 'Product Name', className: 'w-30 td-item cursor-pointer', key: 'name' },
  { name: 'SKU', className: 'w-20 td-item cursor-pointer', key: 'sku' },
  { name: 'Visibility', className: 'td-item cursor-pointer', key: 'visibility' },
  { name: 'Status', className: 'td-item text-center cursor-pointer', key: 'status' },
  { name: 'Price', className: 'td-item text-end cursor-pointer pe-4', key: 'price' },
];

export const TABLE_HEAD_PRODUCT_ATTRIBUTES: iTableHead[] = [
  { name: 'Product Attribute Name', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Code', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Scope', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Required', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'System', className: 'fs-14 fw-medium pe-3 py-3' },
  // { name: 'Visible', className: 'fs-14 fw-medium pe-3 py-3' },
  // { name: 'Searchable', className: 'fs-14 fw-medium pe-3 py-3' },
  // { name: 'Comparable', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Action', className: 'fs-14 fw-medium py-3 text-end' },
];

export const TABLE_HEAD_OPTIONS_ATTRIBUTE: iTableHead[] = [
  { name: 'Display Position', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Is Default', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Description', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Action', className: 'fs-14 fw-medium py-3 text-end' },
];

export const TABLE_HEAD_OPTIONS_ATTRIBUTE_NO_ACTION: iTableHead[] = [
  { name: 'Is Default', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Description', className: 'fs-14 fw-medium py-3 text-end' },
];

export const TABLE_HEAD_FORM_FIELD: iTableHead[] = [
  { name: 'Display Position', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Is Required', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Field Label', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Input Block Type', className: 'fs-14 fw-medium pe-3 py-3' },
  { name: 'Action', className: 'fs-14 fw-medium py-3 text-end' },
];
