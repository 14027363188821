import { createSlice } from '@reduxjs/toolkit';
import { Status, iInitState, iOrderDetails, iProduct } from '~/app/models';
import { iAttachmentOfMessageId, iDataGetTicketListing, iInfoTicketDetails, iMessageOfTicketId, iQuickAnswer, iVender } from '~/app/models/ticket';

interface iState extends iInitState {
  statusGetTicketDetails: Status;
  statusGetMessageOfTicketId: Status;
  statusGetQuickAnswer: Status;
  statusPostMessage: Status;
  statusPostQuickAnswer: Status;
  statusPutTicket: Status;
  statusPutQuickAnswer: Status;
  statusDeleteQuickAnswer: Status;

  queryString: string;
  controller: AbortController | null;

  messagePostMessage: string;

  listQuickAnswer: iQuickAnswer[];

  dataTicketListing: iDataGetTicketListing;
  dataOrderDetails: iOrderDetails;
  dataTicketDetails: iInfoTicketDetails;
  dataMessageOfTicketId: iMessageOfTicketId[];
  dataAttachment: iAttachmentOfMessageId[];
  dataVender: iVender[];
  dataProductListing: iProduct[];
}

const initialState: iState = {
  status: '',
  statusGetTicketDetails: '',
  statusGetMessageOfTicketId: '',
  statusGetQuickAnswer: '',
  statusPostQuickAnswer: '',
  statusPostMessage: '',
  statusPutTicket: '',
  statusPutQuickAnswer: '',
  statusDeleteQuickAnswer: '',

  queryString: '',
  controller: null,

  messagePostMessage: '',

  listQuickAnswer: [],

  dataTicketListing: {} as iDataGetTicketListing,
  dataOrderDetails: {} as iOrderDetails,
  dataTicketDetails: {} as iInfoTicketDetails,
  dataMessageOfTicketId: [],
  dataAttachment: [],
  dataVender: [],
  dataProductListing: [],
};

const ticketSlice = createSlice({
  name: 'TICKET LIST',
  initialState,
  reducers: {
    // Synchronous Actions
    resetData: () => ({ ...initialState }),
    resetStatusQuickAnswer: (state) => ({
      ...state,
      statusGetQuickAnswer: '',
      statusPostQuickAnswer: '',
      statusPutQuickAnswer: '',
      statusDeleteQuickAnswer: '',
    }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),

    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    // setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    // setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),

    changeController: (state, action) => ({ ...state, controller: action.payload }),

    // Asynchronous Actions
    getTicketListingFulfilled: (state, action) => ({ ...state, dataTicketListing: action.payload, status: 'fulfilled' }),
    getTicketListingRejected: (state) => ({ ...state, status: 'rejected' }),
    getTicketListingPending: (state) => ({ ...state, status: 'pending' }),
    resetQueryString: (state) => ({ ...state, queryString: '' }),

    getTicketDetailsFulfilled: (state, action) => ({
      ...state,
      statusGetTicketDetails: 'fulfilled',
      dataTicketDetails: { ...state.dataTicketDetails, ...action.payload },
    }),
    getTicketDetailsRejected: (state) => ({
      ...state,
      statusGetTicketDetails: 'rejected',
    }),
    getTicketDetailsPending: (state) => ({ ...state, statusGetTicketDetails: 'pending' }),

    getListQuickAnswerFulfilled: (state, action) => ({ ...state, listQuickAnswer: action.payload, statusGetQuickAnswer: 'fulfilled' }),
    getListQuickAnswerRejected: (state) => ({ ...state, statusGetQuickAnswer: 'rejected' }),
    getListQuickAnswerPending: (state) => ({ ...state, statusGetQuickAnswer: 'pending' }),

    getOrderByIdFulfilled: (state, action) => ({ ...state, dataOrderDetails: action.payload }),
    getProductListingOfTicketFulfilled: (state, action) => ({ ...state, dataProductListing: action.payload }),

    getListMessageByTicketIdFulfilled: (state, action) => ({
      ...state,
      statusGetMessageOfTicketId: 'fulfilled',
      dataMessageOfTicketId: action.payload,
    }),
    getListMessageByTicketIdRejected: (state) => ({ ...state, statusGetMessageOfTicketId: 'rejected' }),
    getListMessageByTicketIdPending: (state) => ({ ...state, statusGetMessageOfTicketId: 'pending' }),
    getListAttachmentByMessageId: (state, action) => ({ ...state, dataAttachment: action.payload }),

    getAllVenderFulfilled: (state, action) => ({ ...state, dataVender: action.payload }),
    getAllVenderRejected: (state) => ({ ...state }),
    getAllVenderPending: (state) => ({ ...state }),

    postQuickAnswerFulfilled: (state) => ({ ...state, statusPostQuickAnswer: 'fulfilled' }),
    postQuickAnswerRejected: (state) => ({ ...state, statusPostQuickAnswer: 'rejected' }),
    postQuickAnswerPending: (state) => ({ ...state, statusPostQuickAnswer: 'pending' }),

    postMessageFulfilled: (state) => ({ ...state, statusPostMessage: 'fulfilled' }),
    postMessageRejected: (state, action) => ({ ...state, statusPostMessage: 'rejected', messagePostMessage: action.payload }),
    postMessagePending: (state) => ({ ...state, statusPostMessage: 'pending' }),

    putTicketFulfilled: (state) => ({ ...state, statusPutTicket: 'fulfilled' }),
    putTicketRejected: (state) => ({ ...state, statusPutTicket: 'rejected' }),
    putTicketPending: (state) => ({ ...state, statusPutTicket: 'pending' }),

    putQuickAnswerFulfilled: (state) => ({ ...state, statusPutQuickAnswer: 'fulfilled' }),
    putQuickAnswerRejected: (state) => ({ ...state, statusPutQuickAnswer: 'rejected' }),
    putQuickAnswerPending: (state) => ({ ...state, statusPutQuickAnswer: 'pending' }),

    deleteQuickAnswerFulfilled: (state) => ({ ...state, statusDeleteQuickAnswer: 'fulfilled' }),
    deleteQuickAnswerRejected: (state) => ({ ...state, statusDeleteQuickAnswer: 'rejected' }),
    deleteQuickAnswerPending: (state) => ({ ...state, statusDeleteQuickAnswer: 'pending' }),
  },
});

export const { ...rest } = ticketSlice.actions;
const ticketReducer = ticketSlice.reducer;

export default ticketReducer;
