import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { dataCustomerCartHead } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { filterEmptyProperties } from '~/app/utils';
import Pagination from '~/components/common/Pagination';
import { Table } from '~/components/common/Table';
import { setIsDESC, setKeySearch, setQueryCartString, setIsEnableFilter } from '../redux/action';
import { NoRecord } from '../../Err';
import { NumberFromToFilter, NumberFilter, TextFilter, FilterButtons, ResetFilterButton } from '~/components/common/Filter/filterInput';
import { formatFilterActive } from '~/components/common/Filter/formatFilter';

type FormFilterValues = {
  id: string;
  productName: string;
  sku: string;
  qtyFrom: string;
  qtyTo: string;
  priceFrom: string;
  priceTo: string;
  totalFrom: string;
  totalTo: string;
};

function Cart() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));
  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;

  const id = searchParams.get('id') || '';
  const productName = searchParams.get('productName') || '';
  const sku = searchParams.get('sku') || '';
  const qtyFrom = searchParams.get('qtyFrom') || '';
  const qtyTo = searchParams.get('qtyTo') || '';
  const priceFrom = searchParams.get('priceFrom') || '';
  const priceTo = searchParams.get('priceTo') || '';
  const totalFrom = searchParams.get('totalFrom') || '';
  const totalTo = searchParams.get('totalTo') || '';

  const dispatch = useAppDispatch();
  const { data, status, message, isDESC, keySearch, isEnableFilter } = useAppSelector((state) => state.customerReducer);
  const { items, total_count } = data;

  const initialFilterPayload = {
    id,
    productName,
    sku,
    qtyFrom,
    qtyTo,
    priceFrom,
    priceTo,
    totalFrom,
    totalTo,
  } as FormFilterValues;

  // Declare useState
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>();
  const [filterPayload, setFilterPayload] = useState<FormFilterValues>(initialFilterPayload);
  const [query, setQuery] = useState<string>(window.location.search);
  const [dataPayload, setDataPayload] = useState({
    pageSize: 10,
    currentPage: 1,
  });

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setDataPayload({ ...dataPayload, [name]: value });
    if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(filterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  // Change current page
  const onChangePage = (currentPage: number) => {
    if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(filterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setDataPayload({ ...dataPayload, currentPage });
      setSearchParams({ pageSize: pageSize.toString() || '', currentPage: currentPage.toString() || '' });
      resetUrlData();
    }
  };

  // reset fillter and search
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      id: '',
      productName: '',
      sku: '',
      qtyFrom: '',
      qtyTo: '',
      priceFrom: '',
      priceTo: '',
      totalFrom: '',
      totalTo: '',
    });
    setIsFilterSubmit(false);
    setSearchParams({});
    resetUrlData();
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${key}`]: '',
    });
    setFilterPayload({
      ...filterPayload,
      [`${key}`]: '',
    });
    resetUrlData();
  };

  const resetFilterFromToSection = (keyFrom: string, keyTo: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${keyFrom}`]: '',
      [`${keyTo}`]: '',
    });
    setFilterPayload({
      ...filterPayload,
      [`${keyFrom}`]: '',
      [`${keyTo}`]: '',
    });
    resetUrlData();
  };

  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const filteredObject = filterEmptyProperties(filterPayload);

    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    const { search } = window.location;
    setQuery(search);
    setIsFilterSubmit(true);
  };

  const sortOrder = (key: string) => {
    if (!key) return;
    keySearch !== key ? dispatch(setIsDESC(false)) : dispatch(setIsDESC(!isDESC));
    dispatch(setKeySearch(key));
  };

  useEffect(() => {
    dispatch(setQueryCartString(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (Object.keys(filterEmptyProperties(filterPayload)).length === 0) {
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, isFilterSubmit]);

  const renderFilterActive = () => {
    const filterSections = [
      { label: 'ID', value: id, resetHandler: () => resetFilterSection('id') },
      { label: 'Product Name', value: productName, resetHandler: () => resetFilterSection('productName') },
      { label: 'SKU', value: sku, resetHandler: () => resetFilterSection('sku') },
      {
        label: 'Quantity',
        value: qtyFrom + qtyTo && `${qtyFrom ? qtyFrom : '...'} - ${qtyTo ? qtyTo : '...'}`,
        resetHandler: () => resetFilterFromToSection('qtyFrom', 'qtyTo'),
      },
      {
        label: 'Price',
        value: priceFrom + priceTo && `${priceFrom ? priceFrom : '...'} - ${priceTo ? priceTo : '...'}`,
        resetHandler: () => resetFilterFromToSection('priceFrom', 'priceTo'),
      },
      {
        label: 'Total',
        value: totalFrom + totalTo && `${totalFrom ? totalFrom : '...'} - ${totalTo ? totalTo : '...'}`,
        resetHandler: () => resetFilterFromToSection('totalFrom', 'totalTo'),
      },
    ];

    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={() => resetFilter()} />}
          </div>
          <div className="border-top border-bottom p-3 align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  const renderFilterBoard = () => {
    return (
      <div className={`bg-white filter-board${isEnableFilter ? '' : ' d-none'} p-4 wrapper my-4 border-rd-20`}>
        <div className="filter-section bg-white">
          <div className="row">
            <h5 className="col-12 mb-4 text-dark filter-title">Filters</h5>
            <NumberFilter filterClass="col-lg-4 col-xl-4" label="ID" name="id" value={filterPayload.id} onChange={onFilterChangeHandler} />
            <TextFilter
              filterClass="col-lg-4 col-xl-4 mt-3 mt-lg-0"
              label="Product Name"
              name="productName"
              value={filterPayload.productName}
              onChange={onFilterChangeHandler}
            />
            <TextFilter
              filterClass="col-lg-4 col-xl-4 mt-3 mt-lg-0"
              label="SKU"
              name="sku"
              value={filterPayload.sku}
              onChange={onFilterChangeHandler}
            />
            <NumberFromToFilter
              filterClass="col-sm-12 col-lg-4 col-xl-4 mt-3"
              label={'Quantity'}
              name={'qty'}
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <NumberFromToFilter
              filterClass="col-sm-12 col-lg-4 col-xl-4 mt-3"
              label={'Price'}
              name={'price'}
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <NumberFromToFilter
              filterClass="col-sm-12 col-lg-4 col-xl-4 mt-3"
              label={'Total'}
              name={'total'}
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
          </div>
          <FilterButtons onSubmit={onSubmit} status={status} setIsEnableFilter={() => dispatch(setIsEnableFilter(!isEnableFilter))} />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <>
        <div className="table-section bg-white mt-4 wrapper overflow-auto border-rd-20">
          <Table
            keySearch={keySearch}
            isDESC={isDESC}
            dataTableHead={dataCustomerCartHead}
            status={status}
            message={message}
            sortOrder={sortOrder}
            className="order-table"
          >
            {items.length > 0 ? (
              items.map((item: any, i: number) => {
                return <tr key={i}></tr>;
              })
            ) : (
              <NoRecord tableHeaders={dataCustomerCartHead} />
            )}
          </Table>
        </div>
        <Pagination
          className="p-0"
          currentPage={+currentPage}
          pageSize={+pageSize}
          onChangePageSizeEvent={onChangePageSizeEvent}
          onChangePageEvent={onChangePage}
          status={status}
          totalCount={total_count}
        />
      </>
    );
  };

  return (
    <>
      {pathname.split('/').includes('cart') && renderFilterBoard()}
      {pathname.split('/').includes('cart') && renderFilterActive()}
      {renderTable()}
    </>
  );
}

export default Cart;
