import { ChangeEvent, ChangeEventHandler, FC } from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import { iTableHead } from '~/app/models';
import { formatMoney } from '~/app/utils';
import { Input } from '~/components/common/Input';
import { Table } from '~/components/common/Table';
import { NoRecord } from '~/components/pages/Err';

const CREATE_MEMO: iTableHead[] = [
  { name: 'Product', className: 'ps-0 fs-14 fw-medium py-3 w-35' },
  { name: 'Price', className: 'fs-14 fw-medium py-3 text-end' },
  { name: 'Qty', className: 'fs-14 fw-medium py-3 text-end' },
  { name: 'Return to Stock', className: 'return-stock fs-14 fw-medium p-3' },
  { name: 'Qty to Refund', className: 'fs-14 fw-medium py-3' },
  { name: 'Subtotal', className: 'fs-14 fw-medium py-3 text-end' },
  { name: 'Tax Amount', className: 'fs-14 fw-medium py-3 text-end' },
  { name: 'Discount', className: 'fs-14 fw-medium py-3 text-end' },
  { name: 'Row Total', className: 'fs-14 fw-medium py-3 text-end' },
];

interface Props {
  formik: any;
}
export const TableCreateCreditMemo: FC<Props> = (props: Props) => {
  const { formik } = props;
  const { values, errors, handleChange, setFieldError, setFieldValue } = formik;

  const { status, dataDetails } = useAppSelector((state) => state.orderReducer);
  const { currentStore } = useAppSelector((state) => state.authReducer);

  const convertDeliveryCode = (data: string[]) => {
    if (!data) return undefined;
    const deliveryString = JSON.parse(JSON.stringify(data));

    const result = deliveryString.map((item: any) => JSON.parse(item));

    return result;
  };

  const handleChangeReturnToStock = (e: ChangeEvent<HTMLInputElement>, item_id: number) => {
    const isChecked = e.target.checked;

    setFieldValue(
      'return_to_stock_items',
      isChecked ? [...values.return_to_stock_items, item_id] : values.return_to_stock_items.filter((item: number) => item !== item_id),
    );
  };

  const renderTableRow = (item: any) => {
    const itemCheck = item.parent_item ? item.parent_item : item || {};

    const options = convertDeliveryCode(item?.parent_item?.extension_attributes?.deliverycode);
    const itemId = item.parent_item ? item.parent_item.item_id : item.item_id;
    const name = `qty-pdt-${itemId}`;
    const qtyToRefund = values[name];

    const subTotal = itemCheck.price_incl_tax * (qtyToRefund || 0);
    const tax = (itemCheck.tax_amount / itemCheck.qty_ordered) * (qtyToRefund || 0);
    const discount = (itemCheck.discount_amount / itemCheck.qty_ordered) * (qtyToRefund || 0);
    const rowTotal = (itemCheck.row_total_incl_tax / itemCheck.qty_ordered) * (qtyToRefund || 0);

    return (
      <>
        <td className="fs-14 text-start ps-0">
          <p className="mb-0 fw-medium" dangerouslySetInnerHTML={{ __html: item?.parent_item?.name ? item?.parent_item?.name : item.name }} />
          <p className="mb-0 sku-product">SKU: {item.sku}</p>
          {options &&
            options.map((option: any, i: number) => (
              <p key={i} className="mb-0 option-product">
                {option.label}: {option.value}
              </p>
            ))}
        </td>
        <td className="vertical-middle fs-14 text-end">{formatMoney(+itemCheck.price_incl_tax, currentStore)}</td>
        <td className=" fs-14 text-end text-nowrap vertical-middle">
          <p className="mb-1">{itemCheck.qty_ordered ? `Ordered ${itemCheck.qty_ordered}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_invoiced ? `Invoiced ${itemCheck.qty_invoiced}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_canceled ? `Canceled ${itemCheck.qty_canceled}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_refunded ? `Refunded ${itemCheck.qty_refunded}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_shipped ? `Shipped ${itemCheck.qty_shipped}` : ''}</p>
        </td>
        <td className="vertical-middle px-3">
          <input
            className="form-check-input mt-0"
            type="checkbox"
            checked={values.return_to_stock_items.includes(item.item_id)}
            onChange={(e) => handleChangeReturnToStock(e, item.item_id)}
          />
        </td>
        <td className="vertical-middle">
          <div className="d-flex flex-column input-qty-to-refund">
            {renderInputQtyToRefund(qtyToRefund, name, (e) => {
              handleChange(e);
              setFieldError(name, undefined);
            })}
          </div>
        </td>

        <td className="vertical-middle fs-14 text-end">{formatMoney(subTotal, currentStore)}</td>
        <td className="vertical-middle fs-14 text-end">{formatMoney(tax, currentStore)}</td>
        <td className="vertical-middle fs-14 text-end">{formatMoney(discount, currentStore)}</td>
        <td className="vertical-middle fs-14 text-end">{formatMoney(rowTotal, currentStore)}</td>
      </>
    );
  };

  const renderInputQtyToRefund = (value: string = '', name: string, onChange: ChangeEventHandler<HTMLInputElement>) => {
    return (
      <>
        <Input
          className="form-control--short fs-14"
          type="number"
          name={name}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const isUserRemove = value.toString().length >= e.target.value.length;
            if (!errors[name]) {
              onChange(e);
            } else {
              errors[name] === 'This field is required' ? onChange(e) : isUserRemove && onChange(e);
            }
          }}
          onlyInteger
        />

        {errors[name] && <p className="fs-14 text-danger m-0 p-0 mt-2">{errors[name]}</p>}
      </>
    );
  };

  function renderBodyTable() {
    const listProduct = dataDetails.items;
    const listProductSimple = listProduct.filter((item: any) => item.product_type !== 'configurable');
    const newList = listProductSimple.filter((item: any) => {
      if (item.parent_item) {
        return (item.parent_item.qty_invoiced || 0) - (item.parent_item.qty_refunded || 0) > 0;
      }
      return (item.qty_invoiced || 0) - (item.qty_refunded || 0) > 0;
    });
    return newList.length ? (
      newList.map((item: any, i: number) => {
        return <tr key={i}>{renderTableRow(item)}</tr>;
      })
    ) : (
      <NoRecord tableHeaders={CREATE_MEMO} />
    );
  }

  return (
    <div className="info-table bg-white p-4 pb-0 wrapper">
      <p className="title pb-4 mb-0 fs-16 fw-medium text-uppercase">Items to Refunded</p>
      <Table status={status} dataTableHead={CREATE_MEMO} className="">
        {renderBodyTable()}
      </Table>
    </div>
  );
};
