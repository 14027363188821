import { Option } from '../models/filter';

export const productTypeOptions: Option[] = [
  { value: '', label: 'All Type' },
  { value: 'simple', label: 'Simple Product' },
  { value: 'configurable', label: 'Configurable Product' },
  { value: 'virtual', label: 'Virtual Product' },
  // { value: 'grouped', label: 'Grouped Product' },
  // { value: 'bundle', label: 'Bundle Product' },
  // { value: 'downloadable', label: 'Downloadable Product' },
];

export const visibilityOptions: Option[] = [
  { value: '', label: 'All Visibility' },
  { value: '1', label: 'Not Visible Individually' },
  { value: '2', label: 'Catalog' },
  { value: '3', label: 'Search' },
  { value: '4', label: 'Catalog, Search' },
];

export const STATUS_OPTIONS: Option[] = [
  { value: '', label: 'All Status' },
  { value: '1', label: 'Enabled' },
  { value: '0', label: 'Disabled' },
];

export const paymentStatusOptions = [
  { value: '', label: 'All Status' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'closed', label: 'Closed' },
  { value: 'complete', label: 'Complete' },
  { value: 'fraud', label: 'Suspected Fraud' },
  { value: 'holded', label: 'On Hold' },
  { value: 'payment_review', label: 'Payment Review' },
  { value: 'paypal_canceled_reversal', label: 'PayPal Canceled Reversal' },
  { value: 'paypal_reversed', label: 'PayPal Reversal' },
  { value: 'pending', label: 'Pending' },
  { value: 'pending_payment', label: 'Pending Payment' },
  { value: 'pending_paypal', label: 'Pending PayPal' },
  { value: 'processing', label: 'Processing' },
];

export const priorityOptions: Option[] = [
  { value: '', label: 'All Priority' },
  { value: '0', label: 'Low' },
  { value: '1', label: 'Medium' },
  { value: '2', label: 'High' },
  { value: '3', label: 'Ugent' },
];

export const ticketStatusOptions: Option[] = [
  { value: '', label: 'All Status' },
  { value: '0', label: 'Disabled' },
  { value: '1', label: 'Enabled' },
];

export const promotionStatusFilter: Option[] = [
  { value: '', label: 'All Status' },
  { value: '1', label: 'Enable' },
  { value: '0', label: 'Disabled' },
];

export const REVIEW_STATUS_OPTIONS: Option[] = [
  { value: '1', label: 'Approved' },
  { value: '2', label: 'Pending' },
  { value: '3', label: 'Not Approved' },
];

export const REVIEW_TYPE_OPTIONS: Option[] = [
  { value: '', label: 'All Type' },
  { value: '1', label: 'Administrator' },
  { value: '2', label: 'Customer' },
  { value: '3', label: 'Guest' },
];

export const PRODUCT_IN_CATEGORY_STATUS: Option[] = [
  { value: '', label: 'Any' },
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
];

export const CATEGORY_DISPLAY_MODE_OPTION = [
  { value: 'PRODUCTS', label: 'Products only' },
  { value: 'PAGE', label: 'Static block only' },
  { value: 'PRODUCTS_AND_PAGE', label: 'Static block and products' },
];

export const CATEGORY_AVAILABLE_PRODUCT__SHORT_BY = [
  { value: 'position', label: 'Position' },
  { value: 'name', label: 'Product Name' },
  { value: 'price', label: 'Price' },
  { value: 'review_count', label: 'Review Count' },
  { value: 'best_seller', label: 'Best Seller' },
  { value: 'top_rated', label: 'Top Rated' },
  { value: 'most_viewed', label: 'Most Viewed' },
];

export const CATEGORY_HIDE_FILTER_ATTRIBUTE = [
  { value: 'price', label: 'Price' },
  { value: 'series', label: 'Series' },
  { value: 'manufacturer', label: 'Manufacturer' },
];

export const FILTER_YES_NO_OPTIONS: Option[] = [
  { value: '', label: 'All' },
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
];

export const FILTER_SCOPE_OPTIONS: Option[] = [
  { value: '', label: 'All' },
  { value: 'scope', label: 'Store View' },
  { value: 'website', label: 'Website' },
  { value: 'global', label: 'Global' },
];

export const CATALOG_INPUT_TYPE: Option[] = [
  { value: 'text', label: 'Text Field' },
  { value: 'textarea', label: 'Text Area' },
  { value: 'texteditor', label: 'Text Editor' },
  { value: 'pagebuilder', label: 'Page Builder' },
  { value: 'date', label: 'Date' },
  { value: 'boolean', label: 'Yes/No' },
  { value: 'multiselect', label: 'Multiple Select' },
  { value: 'select', label: 'Dropdown' },
  { value: 'price', label: 'Price' },
  { value: 'media_image', label: 'Media Image' },
  { value: 'swatch_visual', label: 'Visual Swatch' },
  { value: 'swatch_text', label: 'Text Swatch' },
  { value: 'weee', label: 'Fixed Product Tax' },
];

export function dynamicCatalog(isEdit: boolean, valueEdit: string): Option[] {
  if (!isEdit) return CATALOG_INPUT_TYPE;

  if (['textarea', 'texteditor', 'pagebuilder'].includes(valueEdit)) {
    return [
      { value: 'textarea', label: 'Text Area' },
      { value: 'texteditor', label: 'Text Editor' },
      { value: 'pagebuilder', label: 'Page Builder' },
    ];
  }

  if (['select', 'swatch_visual', 'swatch_text'].includes(valueEdit)) {
    return [
      { value: 'select', label: 'Dropdown' },
      { value: 'swatch_visual', label: 'Visual Swatch' },
      { value: 'swatch_text', label: 'Text Swatch' },
    ];
  }

  return CATALOG_INPUT_TYPE;
}

export const SCOPE_OF_ATTRIBUTE: Option[] = [
  { value: 'store', label: 'Store View' },
  { value: 'website', label: 'Website' },
  { value: 'global', label: 'Global' },
];

export const STATUS_MANAGE_BLOG = [
  { value: '', label: '' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Disapproved', label: 'Disapproved' },
];

export const BANNER_STATUS: Option[] = [
  { value: 'All Status', label: 'All Status' },
  { value: 'Enable', label: 'Enable' },
  { value: 'Disabled', label: 'Disabled' },
];

export const TYPE_BANNER: Option[] = [
  { value: 'All Type', label: 'All Type' },
  { value: 'Image', label: 'Image' },
  { value: 'Advanced', label: 'Advanced' },
];

export const TYPE_FORM_FIELD_ITEM: Option[] = [
  { value: 'text', label: 'Text Field' },
  { value: 'textarea', label: 'Text Area' },
  { value: 'boolean', label: 'Yes/No' },
  { value: 'select', label: 'Dropdown' },
  { value: 'multiselect', label: 'Multiple Select' },
  { value: 'date', label: 'Date' },
  { value: 'image', label: 'Image' },
  { value: 'rating', label: 'Rating' },
];
