import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignInMethodSchema } from '../Schema';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { updatePasswordUser } from '../../redux/action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { iPayloadChangePassword, iPayloadUpdateInfoUser } from '~/app/models';

type Props = {};

type FormSignInValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const SignInMethod = (props: Props) => {
  const dispatch = useAppDispatch();
  const { updatePasswordStatus, updateStatus, updateAvatarStatus } = useAppSelector((state) => state.userReducer);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const {
    register: registerSignInMethod,
    formState: { errors: errorsSignInMethod },
    handleSubmit: handleSubmitSignInMethod,
    reset,
  } = useForm<FormSignInValues>({
    resolver: yupResolver(SignInMethodSchema),
  });

  registerSignInMethod('currentPassword', {
    onChange: (e) => {
      setPassword(e.target.value);
    },
  });

  registerSignInMethod('newPassword', {
    onChange: (e) => {
      setNewPassword(e.target.value);
    },
  });

  registerSignInMethod('confirmNewPassword', {
    onChange: (e) => {
      setConfirmPassword(e.target.value);
    },
  });

  const onSubmitSignIn = (data: any) => {
    const dataPayload: iPayloadUpdateInfoUser<iPayloadChangePassword> = {
      data: {
        current_password: data.currentPassword,
        password: data.newPassword,
        password_confirmation: data.confirmNewPassword,
      },
    };
    dispatch(updatePasswordUser(dataPayload));
    window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    if (
      (updateStatus === 'fulfilled' && updatePasswordStatus !== 'fulfilled') ||
      (updateAvatarStatus === 'fulfilled' && updatePasswordStatus !== 'fulfilled')
    ) {
      reset({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
      setPassword('');
      setNewPassword('');
      setConfirmPassword('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, updatePasswordStatus, updateAvatarStatus, password, newPassword, confirmPassword]);

  const resetUpdate = () => {
    reset({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
    setPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  return (
    <>
      <div className="col-lg-12 col-xl-6 col-xxl-12 mt-3 mt-xl-0 mt-xxl-3">
        <div className="card bg-white justify-content-center shadow-sm border-0 border-rd-20">
          <div className="card-body d-flex flex-wrap align-items-center p-4">
            <h5 className="fw-semibold mb-19 lh-30 pb-2 pb-xxl-0">Sign-in Method</h5>
            <form className="w-100" onSubmit={handleSubmitSignInMethod(onSubmitSignIn)}>
              <div className={`flex-row-fluid mt-3`}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-3 align-items-center">
                      <label htmlFor="" className="col-12 col-xxl-4 fw-normal text-start py-2">
                        Current Password
                      </label>
                      <div className="col-12 col-xxl-8 position-relative">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className={`form-control form-control-solid w-100 ${errorsSignInMethod.currentPassword ? 'is-invalid' : ''}`}
                          {...registerSignInMethod('currentPassword')}
                        />
                        {password.length > 0 && !showPassword && (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            className="hide-pwd cursor-pointer text-muted"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                        {password.length > 0 && showPassword && (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="show-pwd cursor-pointer text-muted"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                        <div className="invalid-feedback">{errorsSignInMethod.currentPassword?.message}</div>
                      </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                      <label htmlFor="" className="col-12 col-xxl-4 fw-normal text-start py-2">
                        New Password
                      </label>
                      <div className="col-12 col-xxl-8 position-relative">
                        <input
                          type={showNewPassword ? 'text' : 'password'}
                          className={`form-control form-control-solid w-100 ${errorsSignInMethod.newPassword ? 'is-invalid' : ''}`}
                          {...registerSignInMethod('newPassword')}
                        />
                        {newPassword.length > 0 && !showNewPassword && (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            className="hide-pwd cursor-pointer text-muted"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          />
                        )}
                        {newPassword.length > 0 && showNewPassword && (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="show-pwd cursor-pointer text-muted"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          />
                        )}
                        <div className="invalid-feedback">{errorsSignInMethod.newPassword?.message}</div>
                      </div>
                    </div>
                    <div className="row mb-2 align-items-center">
                      <label htmlFor="" className="col-12 col-xxl-4 fw-normal text-start py-2">
                        Confirm New Password
                      </label>
                      <div className="col-12 col-xxl-8 position-relative">
                        <input
                          type={showConfirmPassword ? 'text' : 'password'}
                          className={`form-control form-control-solid w-100 ${errorsSignInMethod.confirmNewPassword ? 'is-invalid' : ''}`}
                          {...registerSignInMethod('confirmNewPassword')}
                        />
                        {confirmPassword.length > 0 && !showConfirmPassword && (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            className="hide-pwd cursor-pointer text-muted"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          />
                        )}
                        {confirmPassword.length > 0 && showConfirmPassword && (
                          <FontAwesomeIcon
                            icon={faEye}
                            className="show-pwd cursor-pointer text-muted"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          />
                        )}
                        <div className="invalid-feedback">{errorsSignInMethod.confirmNewPassword?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row justify-content-end align-items-end h-xl-155px mt-2">
                      <div className="col-12 col-lg-6 col-xl-12 col-xxl-6">
                        <span className="fw-normal fs-7">Note:</span>
                        <div className="fw-normal fs-7 text-danger mt-2 mb-3 mb-xxl-0">
                          Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and
                          one special character.
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 col-xl-12 col-xxl-6">
                        <div className="pt-1 d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-apply btn-primary btn-update-password fw-medium border-rd-25 btn-border-radius me-3"
                            disabled={updatePasswordStatus === 'pending'}
                          >
                            {updatePasswordStatus === 'pending' && (
                              <span className="indicator-progress me-2">
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                              </span>
                            )}
                            Update Password
                          </button>
                          <button
                            id="password_cancel"
                            type="button"
                            className="btn btn-cancel border-rd-25 btn-border-radius fs-14 fw-medium"
                            onClick={() => resetUpdate()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInMethod;
