import { useId, useState } from 'react';
import { faEye, faEyeSlash, faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent } from 'react';
import pro_icon from '../../../app/images/protect.svg';
import './style.scss';

interface Props {
  name: string;
  label: string;
  type: 'text' | 'password';
  error: boolean;
  textError: string | undefined;
  value: string;
  onChange: (event: ChangeEvent) => void;
  onBlur: (event: ChangeEvent) => void;
  shouldShowPassword?: boolean;
  classCss?: string | '';
  placeHolder?: string | '';
  bgIcon?: string | '';
}

const TextField = (props: Props) => {
  const { name, label, type, error, textError, value, classCss, bgIcon, placeHolder, shouldShowPassword, onChange, onBlur } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const typeInput = type === 'password' && shouldShowPassword ? (showPassword ? 'text' : 'password') : type;

  const id = useId();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderIcon = () => {
    return (
      shouldShowPassword &&
      type === 'password' && (
        <>
          {showPassword ? (
            <FontAwesomeIcon icon={faEye} className="show-pwd" onClick={handleShowPassword} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} className="hide-pwd" onClick={handleShowPassword} />
          )}
        </>
      )
    );
  };

  const preIcon = (icon: any) => {
    let awsIcon: any;

    switch (icon) {
      case 'key':
        awsIcon = faKey;
        break;

      case 'user':
        awsIcon = faUser;
        break;
    }

    return (
      !!awsIcon && (
        <span className="pre-icon">
          <FontAwesomeIcon icon={awsIcon} />
        </span>
      )
    );
  };

  return (
    <div className="input-wrap mb-3">
      <div className="input-field w-100">
        {preIcon(bgIcon)}
        <input
          className={`w-100 ${error ? 'error' : ''} ${shouldShowPassword && type === 'password' ? 'has-icon' : ''} ${!!classCss ? classCss : ''}`}
          type={typeInput}
          placeholder={placeHolder}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete="off"
          id={id}
        />

        {!!label && (
          <label className="user-select-none pe-none" htmlFor={id}>
            {label}
          </label>
        )}

        {renderIcon()}
      </div>

      {error && (
        <div className="input-field__text-error text-danger d-flex">
          <img src={pro_icon} alt="" className="pe-2" />
          {textError}
        </div>
      )}
    </div>
  );
};

export default TextField;
