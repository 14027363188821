import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  // , FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import authReducer from '~/components/layout/FullWidthLayout/redux';
import orderReducer from '~/components/pages/Orders/redux';
import userReducer from '~/components/pages/Account/redux';
import productReducer from '~/components/pages/ProductListing/redux';
import dashboardReducer from '~/components/pages/DashBoard/redux';
import createEditProductReducer from '~/components/pages/CreateEditProduct/redux';
import customerReducer from '~/components/pages/Customers/redux';
import pageReducer from '~/components/layout/DefaultLayout/redux';
import sellerReducer from '~/components/pages/Seller/redux';
import reportReducer from '~/components/pages/SalesReport/redux';
import ticketReducer from '~/components/pages/TicketListing/redux';
import promotionReducer from '~/components/pages/PromotionListing/redux';
import createEditPromotionReducer from '~/components/pages/CreateEditPromotion/redux';
import reviewReducer from '~/components/pages/ReviewListing/redux';
import brandReducer from '~/components/pages/BrandListing/redux';
import categoryReducer from '~/components/pages/CategoryListing/redux';
import attributeReducer from '~/components/pages/Attribute/redux';
import productAttributeReducer from '~/components/pages/ProductAttributes/redux';
import createBlogReducer from '~/components/pages/Marketing/ManageBlog/redux';
import customFormReducer from '~/components/pages/CustomForm/redux';

const reducer = combineReducers({
  pageReducer,
  authReducer,
  orderReducer,
  userReducer,
  sellerReducer,
  productReducer,
  customerReducer,
  dashboardReducer,
  createEditProductReducer,
  reportReducer,
  ticketReducer,
  promotionReducer,
  createEditPromotionReducer,
  reviewReducer,
  brandReducer,
  categoryReducer,
  attributeReducer,
  productAttributeReducer,
  createBlogReducer,
  customFormReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const persistor: any = persistStore(store);
