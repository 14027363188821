import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status, iAttribute, iDataAttribute, iPayload } from '~/app/models';
// import  StatusAttribute  from '~/app/models';

interface iState {
  data: iAttribute;
  statusGetAttributeById: Status;
  statusCreateAttribute: Status;
  statusEditAttributeById: Status;
  statusDeleteAttributeById: Status;
  dataValue: iDataAttribute;
  message: string;
  urlData: {
    pageSize: number;
    currentPage: number;
  };
  queryString: string;
  isDESC: boolean;
  keySearch: string;
  dataOfAttributeSetId: any;
  newDataAttribute: any;
}

const initialState: iState = {
  statusGetAttributeById: '',
  statusCreateAttribute: '',
  statusEditAttributeById: '',
  statusDeleteAttributeById: '',
  data: { items: [], search_criteria: {}, total_count: 0 } as iAttribute,
  isDESC: false,
  keySearch: 'attribute_set_id',
  queryString: '',
  message: '',
  urlData: { pageSize: 10, currentPage: 1 },
  dataValue: {} as iDataAttribute,
  dataOfAttributeSetId: {},
  newDataAttribute: {},
};

const attributeSlice = createSlice({
  name: 'Attribute',
  initialState,
  reducers: {
    reset: () => initialState,
    createAttributePending: (state) => ({ ...state, statusCreateAttribute: 'pending' }),
    createAttributeRejected: (state) => ({ ...state, statusCreateAttribute: 'rejected' }),
    createAttributeFulfilled: (state) => ({ ...state, statusCreateAttribute: 'fulfilled' }),

    getAttributeByIdPending: (state) => ({ ...state, statusGetAttributeById: 'pending' }),
    getAttributeByIdRejected: (state) => ({ ...state, statusGetAttributeById: 'rejected' }),
    getAttributeByIdFulfilled: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      dataOfAttributeSetId: payload,
      statusGetAttributeById: 'fulfilled',
    }),

    deleteDataAttributeById: (state) => ({
      ...state,
      dataOfAttributeSetId: {},
    }),

    editAttributeByIdPending: (state) => ({ ...state, statusEditAttributeById: 'pending' }),
    editAttributeByIdRejected: (state) => ({ ...state, statusEditAttributeById: 'rejected' }),
    editAttributeByIdFulfilled: (state) => ({ ...state, statusEditAttributeById: 'fulfilled' }),

    // Synchronous Actions
    resetData: () => ({ ...initialState }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),
    onchangeDataAttribute: (state, { payload }: iPayload<any>) => ({ ...state, newDataAttribute: payload }),

    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),
    changeController: (state, action) => ({ ...state, controller: action.payload }),

    // Asynchronous Actions
    getDataFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getDataRejected: (state, action) => ({ ...state, status: 'rejected', message: action.payload }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),
    resetQueryString: (state) => ({ ...state, queryString: '' }),

    deleteAttributePending: (state) => ({ ...state, statusDeleteAttributeById: 'pending' }),
    deleteAttributeFulfilled: (state) => ({ ...state, statusDeleteAttributeById: 'fulfilled' }),
    deleteAttributeReject: (state, action) => ({
      ...state,
      message: action.payload,
      statusDeleteAttributeById: 'rejected',
    }),
    deleteAttributeInitState: (state) => ({ ...state, statusDeleteAttributeById: '' }),

    setCurrentAction: (state, action) => ({ ...state, currentAction: action.payload }),

    clearMessage: (state: any) => {
      state.statusCreateAttribute = '';
      state.statusEditAttributeById = '';
      state.statusDeleteAttributeById = '';
      state.message = '';
    },
  },
});

export const { ...actions } = attributeSlice.actions;
const attributeReducer = attributeSlice.reducer;

export default attributeReducer;
