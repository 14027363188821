import { FC, useMemo } from 'react';
import { faUser, faEnvelope, faTriangleExclamation, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '~/app/hooks/hooks';
import { Link } from 'react-router-dom';
import Loading from '~/components/common/Loading';

import './style.scss';

type Props = {};

const Overview: FC<Props> = () => {
  const imgPath = process.env.REACT_APP_IMAGE_URL;

  const { data: infoUser, status, message } = useAppSelector((state) => state.userReducer);
  const { user_name, email, user_type, phone_number, first_name, last_name, image } = infoUser;

  const infoFields = useMemo(() => {
    const arr = [
      {
        icon: faUser,
        content: user_name,
      },
      {
        icon: faEnvelope,
        content: email,
      },
    ];

    user_type !== null &&
      arr.splice(2, 0, {
        icon: faPhone,
        content: phone_number ? phone_number : 'N/A',
      });

    return arr;
  }, [email, phone_number, user_name, user_type]);

  const overviewFields = useMemo(() => {
    const arr = [
      {
        title: 'User Name',
        content: user_name,
      },
      {
        title: 'First Name',
        content: first_name,
      },
      {
        title: 'Last Name',
        content: last_name,
      },
      {
        title: 'Contact Email',
        content: email,
      },

      // {
      //   title: 'Address',
      //   content: phone_number,
      // },
    ];

    user_type !== null &&
      arr.push({
        title: 'Contact Phone',
        content: phone_number ? phone_number : 'N/A',
      });

    return arr;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoUser]);

  const overviewContent = (key: number, title: string, content: string) => {
    return (
      <div className="row mb-4 align-items-center" key={key}>
        <label htmlFor="" className="col-3 fw-medium">
          {title}
        </label>
        <div className="col-7">
          <span className="me-2">{content}</span>
        </div>
      </div>
    );
  };

  const infoContent = (key: number, icon: any, content: string) => {
    return (
      <li className="d-flex mb-3" key={key}>
        <div className="text-left me-3">
          <FontAwesomeIcon icon={icon} />
        </div>
        <span className="title-inline">{content}</span>
      </li>
    );
  };

  const renderInfo = () => {
    return (
      <>
        {status === 'pending' && (
          <div className="card bg-white h-100 border-rd-20">
            <div className="card-body d-flex flex-column align-items-center justify-content-center mt-2">
              <Loading />
            </div>
          </div>
        )}
        {status === 'fulfilled' && (
          <div className="card bg-white border-rd-20 h-100 p-0 align-items-center justify-content-center shadow-sm border-0">
            <div className="row w-100 p-4 p-xxl-40">
              <div className="col-12 col-sm-6 px-0 d-flex justify-content-center justify-content-xxl-start">
                <div className="thumbnail overview">
                  <img className="avatar" src={image ? imgPath + image : require('~/app/images/no-avatar.png')} alt="avatar" />
                </div>
              </div>
              <div className="col-12 col-sm-6 d-flex flex-column justify-content-center">
                <h3 className="text-start fw-semibold text-uppercase mb-0 lh-42 title-inline">
                  {last_name} {first_name}
                </h3>
                <ul className="list-unstyled mt-3 mb-0">{infoFields.map((field, i) => infoContent(i, field.icon, field.content))}</ul>
              </div>
            </div>
          </div>
        )}
        {status === 'rejected' && (
          <div className="card bg-white border-rd-20 h-100 flex-row align-items-center justify-content-center text-danger">
            <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
            {message}
          </div>
        )}
      </>
    );
  };

  const renderOverview = () => {
    return (
      <div className="card bg-white border-rd-20 h-100 shadow-sm border-0">
        <div className="pt-4 pb-2 mx-4 border-bottom d-flex justify-content-between align-items-center mb-3">
          <div>
            <Link
              className="account-tab fs-16 fw-medium active text-primary text-decoration-none me-3 border-bottom border-3 border-primary pt-4 pb-2"
              to=""
            >
              Overview
            </Link>
            <Link className="account-tab-setting fs-16 fw-medium text-dark-600 text-decoration-none" to="../settings">
              Information
            </Link>
          </div>
        </div>

        {status === 'pending' && (
          <div className="bg-white h-100">
            <div className="d-flex flex-column align-items-center justify-content-center mt-2">
              <Loading />
            </div>
          </div>
        )}
        {status === 'fulfilled' && <div className="p-4">{overviewFields.map((field, i) => overviewContent(i, field.title, field.content))}</div>}
        {status === 'rejected' && (
          <div className="card-body px-4 d-flex align-items-center justify-content-center text-danger">
            <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
            {message}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {/* Render Info Section */}
      <div className="col-xl-12 col-xxl-5">{renderInfo()}</div>
      {/* Render Overview Section */}
      <div className="col-xl-12 col-xxl-7 mt-4 mt-xxl-0">{renderOverview()}</div>
    </>
  );
};

export default Overview;
