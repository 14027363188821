import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status, iCurrentAction, iInitState, iManageBlog, iResponseListing } from '~/app/models';

interface iState extends iInitState {
  action: 'create' | 'edit';
  dataWebsiteSelect: any;
  statusCreateBlog: Status;
  statusEditBlog: Status;
  statusDeleteBlog: Status;
  data: iResponseListing<iManageBlog[]>;
  currentAction: iCurrentAction;
}

const initialState: iState = {
  action: 'create',
  status: '',
  dataWebsiteSelect: [],
  statusCreateBlog: '',
  statusEditBlog: '',
  statusDeleteBlog: '',
  data: { items: [], search_criteria: {}, total_count: 0 } as iResponseListing<iManageBlog[]>,
  currentAction: 'create',
};

const createBlogReducer = createSlice({
  name: 'Create/Edit Blog',
  initialState,
  reducers: {
    resetData: () => ({ ...initialState }),
    setCurrentAction: (state, action: PayloadAction<iCurrentAction>) => ({ ...state, currentAction: action.payload }),
    setFormData: (state, action: PayloadAction<iManageBlog | null>) => ({ ...state, formData: action.payload }),

    getDataFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getDataRejected: (state) => ({ ...state, status: 'rejected' }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),

    deleteBlogFulfilled: (state) => ({ ...state, statusDeleteBlog: 'fulfilled' }),
    deleteBlogRejected: (state) => ({ ...state, statusDeleteBlog: 'rejected' }),
    deleteBlogPending: (state) => ({ ...state, statusDeleteBlog: 'pending' }),

    createBlogFulfilled: (state) => ({ ...state, statusCreateBlog: 'fulfilled' }),
    createBlogRejected: (state) => ({ ...state, statusCreateBlog: 'rejected' }),
    createBlogPending: (state) => ({ ...state, statusCreateBlog: 'pending' }),

    editBlogFulfilled: (state) => ({ ...state, statusEditBlog: 'fulfilled' }),
    editBlogRejected: (state) => ({ ...state, statusEditBlog: 'rejected' }),
    editBlogPending: (state) => ({ ...state, statusEditBlog: 'pending' }),

    getDataWebsiteSelect: (state: any, { payload }) => {
      state.dataWebsiteSelect = payload;
    },
    onChangeListWebsiteSelected: (state, { payload }) => {
      state.dataWebsiteSelect = payload;
    },
  },
});

export const { ...actions } = createBlogReducer.actions;
export default createBlogReducer.reducer;
