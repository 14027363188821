import axiosConfig from './axiosConfig';
import {
  iAttrSetItem,
  iProductAttributeItem,
  iCategoryItem,
  iCategoryTreeItem,
  iGetAllProduct,
  iGetAttributesOfAttributeSetId,
  iGetCategories,
  iGetCategoryTree,
  iGetListAttrSet,
  iGetProductDetail,
  iProduct,
  iResponseListing,
} from '../models';
import { addFilterGroup, addFilterItem, addFilters, searchCriteriaBuilder } from '../utils';
import { AxiosResponse } from 'axios';

const productApi = {
  getAll: (payload: iGetAllProduct, controller: AbortController) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/products?${searchUrl}`;
    return axiosConfig.get(url, {
      signal: controller.signal,
    });
  },
  getCategories: ({ currentStore, searchURL }: iGetCategories): Promise<AxiosResponse<iResponseListing<iCategoryItem[]>>> => {
    const url = `${currentStore}/V1/categories/list?${searchURL}`;
    return axiosConfig.get(url);
  },
  getCategoriesTree: (payload: iGetCategoryTree): Promise<AxiosResponse<iCategoryTreeItem>> => {
    const { currentStore, rootCategoryId } = payload;

    const url = `${currentStore}/V1/categories?rootCategoryId=${rootCategoryId || 2}`;
    return axiosConfig.get(url);
  },
  getProductList: (searchURL: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/products?${searchURL}`;
    return axiosConfig.get(url);
  },
  getProductDetails: ({ id, currentStore }: iGetProductDetail): Promise<AxiosResponse<iProduct>> => {
    const url = `${currentStore}/V1/addin/product/${id}`;
    return axiosConfig.get(url);
  },
  createProduct: (payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/products`;
    return axiosConfig.post(url, payload);
  },
  createListSubProduct: (payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/addin/create/products`;
    return axiosConfig.post(url, payload);
  },
  removeImage: (sku: string, idImage: number, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/products/${sku}/media/${idImage}`;
    return axiosConfig.delete(url);
  },
  editProduct: (idProductEdit: number, payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/addin/product/${idProductEdit}`;
    return axiosConfig.put(url, payload);
  },
  assignProductChildToProductConfig: (skuProductConfig: string, payload: any, accessToken: string, store: string = 'all') => {
    const url = `all/V1/configurable-products/${skuProductConfig}/child`;
    return axiosConfig.post(url, payload);
  },
  editProductAllStore: (idProductEdit: number, payload: any, accessToken: string) => {
    const url = `all/V1/addin/product/${idProductEdit}`;
    return axiosConfig.put(url, payload);
  },
  getWebsitesId: (accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/store/websites`;
    return axiosConfig.get(url);
  },
  getAttrSet: (searchURL: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/products/attribute-sets/sets/list?${searchURL}`;
    return axiosConfig.get(url);
  },
  getListImageBySku: (sku: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/products/${sku}/media`;
    return axiosConfig.get(url);
  },
  getAttributesOfAttributeSetId: (payload: iGetAttributesOfAttributeSetId): Promise<AxiosResponse<iResponseListing<iProductAttributeItem[]>>> => {
    const { attributeSetId, currentStore } = payload;

    const query = searchCriteriaBuilder(
      Number.MAX_SAFE_INTEGER,
      1,
      addFilterGroup(addFilters(addFilterItem('eav_entity_attribute.attribute_set_id', `${attributeSetId}`, 'eq'))),
    );

    const url = `${currentStore}/V1/products/attributes/?${query}`;

    return axiosConfig.get(url);
  },
  getListStore: (store: string = 'all') => {
    const url = `${store}/V1/store/websites`;
    return axiosConfig.get(url);
  },
  getListAttrSet: ({ currentStore, searchURL }: iGetListAttrSet): Promise<AxiosResponse<iResponseListing<iAttrSetItem[]>>> => {
    const url = `${currentStore}/V1/products/attribute-sets/sets/list?${searchURL}`;
    return axiosConfig.get(url);
  },
  deleteProduct: (id: number, store: string = 'all') => {
    const url = `${store}/V1/addin/product/${id}`;
    return axiosConfig.delete(url);
  },
};

export default productApi;
