/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { MultiSelect } from '~/components/common/MultiSelect';
import { onChangeDataAttrConfigSelected, onChangeDataValuesConfigSelected } from '../../redux/actions';
import { Input } from '~/components/common/Input';

interface iOption {
  label: string;
  value: string;
}

const Step1 = () => {
  const dispatch = useAppDispatch();
  const { dataValuesConfigSelected, dataAttrConfigSelected, listAttrOfProductConfig } = useAppSelector((state) => state.createEditProductReducer);

  const [query, setQuery] = useState<string>('');
  const [search, setSearch] = useState<{ [key: string]: string }>({});

  const listAttrConfigReplaceToName = useMemo(() => {
    if (!listAttrOfProductConfig.length) return [];

    return JSON.parse(JSON.stringify(listAttrOfProductConfig).replaceAll('attribute_id', 'id').replaceAll('default_frontend_label', 'name'));
  }, [listAttrOfProductConfig]);

  useEffect(() => {
    if (dataAttrConfigSelected) {
      const listNameWithIdAttrSelected = dataAttrConfigSelected.map((item: any) => `${item.name}-id${item.id}`);
      const listValuesSelected = Object.entries(dataValuesConfigSelected).filter((item) => listNameWithIdAttrSelected.includes(item[0]));

      dispatch(onChangeDataValuesConfigSelected(Object.fromEntries(listValuesSelected)));
      dispatch(onChangeDataAttrConfigSelected(dataAttrConfigSelected));
    }
  }, [dataAttrConfigSelected]);

  function handleChange(nameAttr: any, idAttr: number, attrClicked: any) {
    const newNameAttr = `${nameAttr}-id${idAttr}`;

    if (dataValuesConfigSelected[newNameAttr]) {
      const attrFind = dataValuesConfigSelected[newNameAttr]?.find((item: any) => item.label === attrClicked.label);

      const newListSelect = attrFind
        ? dataValuesConfigSelected[newNameAttr].filter((item: any) => item.label !== attrFind.label)
        : [...dataValuesConfigSelected[newNameAttr], attrClicked].sort((f: any, s: any) => {
            if (f.label > s.label) return 1;
            if (f.label < s.label) return -1;
            return 0;
          });

      const newData = Object.fromEntries(
        Object.entries({
          ...dataValuesConfigSelected,
          [newNameAttr]: newListSelect,
        })
          .filter((item: any) => item[1].length > 0)
          .sort((f: any, s: any) => {
            const fItemName = f[0];
            const sItemName = s[0];
            if (fItemName > sItemName) return 1;
            if (fItemName < sItemName) return -1;
            return 0;
          }),
      );

      dispatch(onChangeDataValuesConfigSelected(newData));
      return;
    }

    dispatch(
      onChangeDataValuesConfigSelected({
        ...dataValuesConfigSelected,
        [newNameAttr]: [attrClicked],
      }),
    );
  }

  function handleSelectAll(idAttr: number, nameAttr: string) {
    const itemByNameAttr = listAttrConfigReplaceToName.find((attr: any) => attr.id === idAttr);

    if (itemByNameAttr) {
      const listOption = itemByNameAttr.options.filter((option: any) => !!option.value);

      if (listOption.length) {
        const listOptionSort = listOption.sort((f: any, s: any) => {
          if (f.label > s.label) return 1;
          if (f.label < s.label) return -1;
          return 0;
        });

        const newData = { ...dataValuesConfigSelected, [`${nameAttr}-id${idAttr}`]: listOptionSort };
        dispatch(onChangeDataValuesConfigSelected(newData));
      }
    }
  }

  function handleDeselectAll(idAttr: number, nameAttr: string) {
    const data = { ...dataValuesConfigSelected };
    delete data[`${nameAttr}-id${idAttr}`];
    dispatch(onChangeDataValuesConfigSelected(data));
  }

  function handleChangeValueSearch(valueChange: { [key: string]: string }) {
    setSearch((prev) => ({ ...prev, ...valueChange }));
  }

  return (
    <div className="content-step1">
      <h3 className="title fs-16 fw-normal m-0">Select Attributes</h3>
      <div className="multi-select-attr flex-grow-1">
        <MultiSelect
          removeSearchWhenSelected
          options={
            query
              ? listAttrConfigReplaceToName.filter((item: any) => item.name.toLowerCase().trim().includes(query.toLowerCase().trim()))
              : listAttrConfigReplaceToName
          }
          listSelected={dataAttrConfigSelected || []}
          query={query}
          setQuery={setQuery}
          sortBy="attribute_code"
          onRemoveAllItem={() => {
            dispatch(onChangeDataAttrConfigSelected([]));
          }}
          onRemoveItem={(itemRemove) => {
            dispatch(onChangeDataAttrConfigSelected(dataAttrConfigSelected.filter((item) => item.id !== itemRemove.id)));
          }}
          onSelectItem={(item) => {
            dispatch(onChangeDataAttrConfigSelected([...dataAttrConfigSelected, item]));
          }}
        />
      </div>
      <div className="attr-value">
        {dataAttrConfigSelected?.map((item: any, index: number) => {
          const valueSearch = search[item.id] || '';

          return (
            <div key={index}>
              <div className="d-flex align-items-center justify-content-between mb-3 mt-4">
                <div className="d-flex align-items-center">
                  <h3 className="attr-name title fs-16 fw-normal m-0 me-4">{item.name}</h3>
                  <Input
                    className="form-input--search-attr"
                    type="search"
                    placeholder="Search here..."
                    value={valueSearch}
                    onChange={(e) => handleChangeValueSearch({ [item.id]: e.target.value })}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="m-0 fs-14 link" onClick={() => handleSelectAll(item.id, item.name)}>
                    Select All
                  </h4>
                  <span className="pointer-event-none user-select-none mx-2 text-dark-600">|</span>
                  <h4 className="m-0 fs-14 link" onClick={() => handleDeselectAll(item.id, item.name)}>
                    Deselect All
                  </h4>
                </div>
              </div>
              <div className="list-child-attr p-4 mw-100 overflow-x-auto">
                {item.options
                  .filter((option: iOption) =>
                    valueSearch ? option.label.toLowerCase().includes(valueSearch.toLowerCase()) && option.value !== '' : option.value !== '',
                  )
                  .sort((f: any, s: any) => {
                    if (f.label > s.label) return 1;
                    if (f.label < s.label) return -1;
                    return 0;
                  })
                  .map((option: iOption, index: number) => (
                    <div className="d-flex align-items-center checkbox-custom" key={index}>
                      <input
                        className="form-check-input m-0 cursor-pointer"
                        type="checkbox"
                        name={option.label}
                        id={option.value}
                        checked={
                          dataValuesConfigSelected[`${item.name}-id${item.id}`]?.map((item: any) => item.label)?.includes(option.label) || false
                        }
                        onChange={() => handleChange(item.name, item.id, option)}
                      />

                      <label htmlFor={option.value} className="fs-6 fw-normal ms-2 cursor-pointer">
                        {option.label}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Step1;
