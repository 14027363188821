import { iGetAllCustomers, iGetCustomer } from '../models';
import axiosConfig from './axiosConfig';

const customerApi = {
  getAllCustomers: (payload: iGetAllCustomers, controller: AbortController) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/customers/search?${searchUrl}`;
    return axiosConfig.get(url, { signal: controller.signal });
  },

  getCustomer: (payload: iGetCustomer) => {
    const { currentStore = 'all', customerId } = payload;
    const url = `${currentStore}/V1/customers/${customerId}`;
    return axiosConfig.get(url);
  },

  getCustomerConfirmStatus: (payload: iGetCustomer) => {
    const { currentStore = 'all', customerId } = payload;
    const url = `${currentStore}/V1/customers/${customerId}/confirm`;
    return axiosConfig.get(url);
  },
};

export default customerApi;
