import { ChangeEvent, FC } from 'react';
import CustomCheckBox from '../CustomCheckBox';
import { useAppSelector } from '~/app/hooks/hooks';

interface Props {
  isApplyAllStores?: boolean;
  onApplyAllStores?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CheckBoxApplyAllStores: FC<Props> = ({ isApplyAllStores, onApplyAllStores }) => {
  const { action: currentAction } = useAppSelector((state) => state.createEditProductReducer);
  return (
    <>
      {currentAction === 'edit' && (
        <CustomCheckBox
          onChange={(e) => onApplyAllStores && onApplyAllStores(e)}
          checked={!!isApplyAllStores}
          label="Apply to all stores"
          checkBoxClass="fs-14"
        />
      )}
    </>
  );
};

export default CheckBoxApplyAllStores;
