import promotionApi from '~/app/apis/promotion.api';
import productApi from '~/app/apis/product.api';
import * as types from '.';
import { iCategoryTreeItem, iCouponCode, iGetAllPromotion, iPromotion, iResponseAxios } from '~/app/models';
import { addFilterGroup, addFilterItem, addFilters, searchCriteriaBuilder } from '~/app/utils';
import { AppDispatch } from '~/store';

const { actions } = types;

export const deletePromotion = (iPromotionDelelte: number, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.deletePromotionPending());

  try {
    const res = await promotionApi.deletePromotion(iPromotionDelelte, accessToken, currentStore);
    const { data } = res;
    !data ? dispatch(actions.deletePromotionRejected('Wrong Params')) : dispatch(actions.deletePromotionFulfilled());
    setTimeout(() => {
      dispatch(actions.deletePromotionInitState());
    }, 500);
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.deletePromotionRejected(message || 'Something went wrong. Please wait and try again.'));
    setTimeout(() => {
      dispatch(actions.deletePromotionInitState());
    }, 500);
  }
};

export const actionCreateEditPromotion =
  (payload: any, accessToken: string, currentStore: string, idPromotionEdit?: number, payloadEditAllStore?: any) => async (dispatch: any) => {
    dispatch(actions.createEditPromotionPending());
    try {
      if (idPromotionEdit) {
        // Case: Edit Promotion
        try {
          const res = await promotionApi.editPromotion(idPromotionEdit, payload, accessToken, currentStore);
          const { data } = res;
          dispatch(actions.createEditPromotionFulfilled(data.rule_id));
        } catch (error: any) {
          const { message } = error?.response?.data || {};
          dispatch(actions.createEditPromotionRejected(message || 'Something went wrong. Please wait and try again'));
        }
      } else {
        // Case: Create New Promotion
        try {
          const res = await promotionApi.createPromotion(payload, accessToken, currentStore);
          const { data }: iResponseAxios<iPromotion> = res;
          dispatch(actions.createEditPromotionFulfilled(data.rule_id));

          // if (data.coupon_type === 'SPECIFIC_COUPON' && !data.use_auto_generation) {
          //   try {
          //     // const getPayload = {
          //     //   searchUrl: searchCriteriaBuilder(Number.MAX_SAFE_INTEGER, 1),
          //     //   currentStore: payload.currentStore,
          //     // };
          //     // const { data } = await promotionApi.getCouponCode(getPayload);
          //   } catch (error) {
          //   } finally {
          //     dispatch(actions.createEditPromotionFulfilled(data));
          //   }
          // } else {
          //   dispatch(actions.createEditPromotionFulfilled(data));
          // }
        } catch (error: any) {
          const { message } = error?.response?.data || {};
          dispatch(actions.createEditPromotionRejected(message || 'Something went wrong. Please wait and try again'));
        }
      }
    } catch (error: any) {
      const { message } = error?.response?.data || {};
      dispatch(actions.createEditPromotionRejected(message || 'Something went wrong. Please wait and try again.'));
    }
  };

export const getData = (payload: iGetAllPromotion, cancelController: AbortController) => async (dispatch: any) => {
  cancelController && cancelController.abort();
  dispatch(actions.getDataPending());
  const controller = new AbortController();
  dispatch(actions.changeController(controller));
  try {
    const { data } = await promotionApi.getAll(payload, controller);

    const { items: promotionList } = data;

    const listRuleId = promotionList
      .filter((item: any) => item.coupon_type === 'SPECIFIC_COUPON' && !item.use_auto_generation)
      .map((item: any) => item.rule_id);

    try {
      const getPayload = {
        searchUrl: searchCriteriaBuilder(
          Number.MAX_SAFE_INTEGER,
          1,
          addFilterGroup(addFilters(addFilterItem('rule_id', listRuleId.join(','), 'in'))),
        ),
        currentStore: payload.currentStore,
      };
      const { data } = await promotionApi.getCouponCode(getPayload);
      dispatch(actions.getCouponCode(data));
    } catch (err) {
    } finally {
      !data ? dispatch(actions.getDataRejected('')) : dispatch(actions.getDataFulfilled(data));
    }
  } catch (error: any) {
    if (error.message === 'canceled') return;
    dispatch(actions.getDataRejected(''));
  }
};

export const getPromotionId = (rule_id: number, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.getPromotionDetailPending());
  try {
    const { data }: iResponseAxios<iPromotion> = await promotionApi.getPromotionId(rule_id, currentStore);

    if (data.coupon_type === 'SPECIFIC_COUPON' && !data.use_auto_generation) {
      try {
        const getPayload = {
          searchUrl: searchCriteriaBuilder(
            Number.MAX_SAFE_INTEGER,
            1,
            addFilterGroup(addFilters(addFilterItem('rule_id', data.rule_id.toString(), 'eq'))),
          ),
          currentStore: currentStore,
        };
        const { data: dataCouponCode } = await promotionApi.getCouponCode(getPayload);
        const { items }: { items: iCouponCode[] } = dataCouponCode;
        dispatch(actions.getCouponCodeById(items[0]));
      } catch (err) {
      } finally {
        dispatch(actions.getPromotionDetailFulfilled(data));
      }
    } else {
      dispatch(actions.getPromotionDetailFulfilled(data));
    }
  } catch (error: any) {
    const { message } = error?.response?.data || {};

    dispatch(actions.getPromotionDetailRejected(message || 'Something went wrong. Please try again.'));
  }
};

export const getCateList = (searchUrl: string, accessToken: string, currentStore: string, showLoading?: boolean) => async (dispatch: any) => {
  // const getCateSelect
  dispatch(actions.getCatePending());
  showLoading && dispatch(actions.getDataPending());

  try {
    const res = await promotionApi.getCateList(searchUrl, accessToken, currentStore);

    const { items: data } = res.data;
    dispatch(actions.getCateFulfilled(data.filter((item: any) => item.id > 1 && item.is_active === true)));
  } catch (error: any) {
    dispatch(actions.getCateRejected());
  } finally {
    showLoading && dispatch(actions.getDataFulfilled());
  }
};

// export const getCategories = (payload: iGetCategories) => async (dispatch: any) => {
//   try {
//     const { data } = await productApi.getCategories(payload);
//     dispatch(actions.getCateListFulfilled(data.items.filter((item) => item.id > 2 && item.is_active === true)));
//   } catch (error: any) {}
// };

export const getCategoriesTree = (currentStore: string) => async (dispatch: AppDispatch) => {
  dispatch(actions.getCategoriesTreePending());
  try {
    const { data } = await productApi.getCategoriesTree({ currentStore });

    // logic filter category enabled
    const handleFilter = (item: iCategoryTreeItem) => item.is_active === true;
    const handleMap = (item: iCategoryTreeItem) => {
      if (item.children_data.length === 0) {
        return { ...item, children_data: [] };
      }

      const recursiveCategoriesTree: iCategoryTreeItem[] = item.children_data.filter(handleFilter).map(handleMap);
      return { ...item, children_data: recursiveCategoriesTree };
    };

    const categoriesTreeActiveAfterRecursive = data.children_data.filter(handleFilter).map(handleMap);
    dispatch(actions.getCategoriesTreeFulfilled(categoriesTreeActiveAfterRecursive));
  } catch (error: any) {
    dispatch(actions.getCategoriesTreeReject());
  }
};

export const actionClearMessage = () => (dispatch: any) => {
  dispatch(actions.clearMessage());
};

export const actionReset = () => (dispatch: any) => {
  dispatch(actions.reset());
};

export const onChangeAction = (action: any) => (dispatch: any) => {
  dispatch(actions.onChangeAction(action));
};
export const onChangeType = (type: any) => (dispatch: any) => {
  dispatch(actions.onChangeType(type));
};
export const onChangeListCateSelected = (newData: any[]) => (dispatch: any) => {
  dispatch(actions.onChangeListCateSelected(newData));
};

export const onChangeDataHeader = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeDataHeader(newData));
};
export const onChangeListWebsiteSelected = (newData: any) => (dispatch: any) => {
  dispatch(actions.onChangeListWebsiteSelected(newData));
};
