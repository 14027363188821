import { Outlet, useLocation } from 'react-router-dom';
import DefaultLayout from '~/components/layout/DefaultLayout';

type Props = {};

const Account = (props: Props) => {
  const { pathname } = useLocation();
  return (
    <DefaultLayout pageTitle={`${pathname === '/profile/overview' ? 'My Profile' : 'Profile Settings'}`}>
      <div className="row mb-4">
        <Outlet />
      </div>
    </DefaultLayout>
  );
};

export default Account;
