import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { searchCriteriaBuilder } from '~/app/utils';
import { actionClearMessage, actionReset, getListAttSet, getCategories, getCategoriesTree, getProductDetail } from './redux/actions';
import { PopupFailure, PopupSuccess } from '~/components/common/Popup';
import { defaultMessageError } from '~/app/constants';
import { FormikProps } from 'formik';

interface Props {
  action: 'create' | 'edit';
  formik: FormikProps<any>;
}
const RenderPopup = ({ action, formik }: Props) => {
  const dispatch = useAppDispatch();
  const { currentStore } = useAppSelector((s) => s.authReducer);
  const { status, message, idProductRedirect, messageCreateSubProduct } = useAppSelector((s) => s.createEditProductReducer);

  const { idProduct = 0 } = useParams();
  const navigate = useNavigate();

  function renderPopup() {
    if (status === 'fulfilled') {
      return (
        <PopupSuccess
          textLink="OK"
          message={`You have successfully ${action === 'create' ? 'created' : 'edited'} product`}
          onClickLink={() => {
            if (action === 'create') {
              // setTimeOut to eliminate ckeditor's error
              setTimeout(() => {
                navigate(`/product/edit/${idProductRedirect}`);
                dispatch(actionClearMessage());
              }, 0);
              return;
            }

            dispatch(actionReset());
            dispatch(actionClearMessage());

            dispatch(
              getListAttSet({
                currentStore,
                searchURL: searchCriteriaBuilder(Number.MAX_VALUE, 1),
                idProductEdit: idProduct ? +idProduct : undefined,
              }),
            );
            dispatch(getCategories({ searchURL: searchCriteriaBuilder(Number.MAX_VALUE, 1), currentStore }));
            dispatch(getCategoriesTree({ currentStore, onlyActive: true }));
            dispatch(getProductDetail({ currentStore, id: +idProduct }));
            formik.resetForm();
          }}
        />
      );
    } else if (status === 'rejected') {
      const mess = message.includes('URL key for specified store already exists.') ? `${message} Please change your URL Key.` : message;

      return (
        <PopupFailure
          message={messageCreateSubProduct ? messageCreateSubProduct : mess || defaultMessageError}
          textLink="OK"
          onClickLink={() => {
            const searchOptimizeElement = document.querySelector('#search-optimize');
            searchOptimizeElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });

            if (messageCreateSubProduct) {
              if (action === 'create') {
                // setTimeOut to eliminate ckeditor's error
                setTimeout(() => {
                  navigate(`/product/edit/${idProductRedirect}`);
                  dispatch(actionClearMessage());
                }, 0);
                return;
              }

              dispatch(actionReset());
              dispatch(actionClearMessage());

              dispatch(
                getListAttSet({
                  currentStore,
                  searchURL: searchCriteriaBuilder(Number.MAX_VALUE, 1),
                  idProductEdit: idProduct ? +idProduct : undefined,
                }),
              );
              dispatch(getCategories({ searchURL: searchCriteriaBuilder(Number.MAX_VALUE, 1), currentStore }));
              dispatch(getCategoriesTree({ currentStore, onlyActive: true }));
              dispatch(getProductDetail({ currentStore, id: +idProduct }));
              formik.resetForm();
            } else {
              dispatch(actionClearMessage());
            }
          }}
        />
      );
    }
  }
  return <>{renderPopup()}</>;
};

export default RenderPopup;
