import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import countryList from 'react-select-country-list';
import { useAppSelector } from '~/app/hooks/hooks';
interface Country {
  value: string;
  label: string;
}

export const formatFilterActive = (label: string, value: any, resetHandler: any) =>
  value && (
    <div className="filter-active" key={label}>
      <span className="fw-medium me-1 text-capitalize">{label}: </span>
      <div>
        <span className="fw-light me-1">{value}</span>
        <FontAwesomeIcon className="btn btn-gray reset-filter-active fs-7 rounded-circle" icon={faXmark} onClick={resetHandler} />
      </div>
    </div>
  );

export const formatFilterStatus = (value: string) => {
  if (value === 'fraud') {
    return 'Suspected Fraud';
  } else if (value === 'holded') {
    return 'On Hold';
  } else if (value === 'payment_review') {
    return 'Payment Review';
  } else if (value === 'paypal_canceled_reversal') {
    return 'Paypal Canceled Reversal';
  } else if (value === 'paypal_reversed') {
    return 'Paypal Reversed';
  } else if (value === 'pending_payment') {
    return 'Pending Payment';
  } else if (value === 'pending_paypal') {
    return 'Pending Paypal';
  } else if (value === 'processing') {
    return 'Processing';
  } else if (value === 'pending') {
    return 'Pending';
  } else if (value === 'complete') {
    return 'Complete';
  } else if (value === 'closed') {
    return 'Closed';
  } else if (value === 'canceled') {
    return 'Canceled';
  } else {
    return value;
  }
};

export const formatStatus = (status: string) => {
  if (!status) return;
  if (['closed', 'canceled'].includes(status))
    return <span className="bg-danger text-capitalize fw-medium badge fs-14 px-3 py-2 border-rd-25">{status}</span>;

  if (['processing', 'complete'].includes(status))
    return <span className="bg-success text-capitalize fw-medium badge fs-14 px-3 py-2 border-rd-25">{status}</span>;

  return (
    <span className="bg-warning text-capitalize fw-medium badge fs-14 px-3 py-2 border-rd-25">
      {status === 'holded' ? 'On Hold' : status === 'pending_payment' ? 'Pending Payment' : status}
    </span>
  );
};

export const formatEnableStatus = (status: any) => {
  if (status === 1) {
    return <span className="text-success text-capitalize fw-medium badge bg-success text-white border-rd-25 p-1 px-3 fs-14 ">Enabled</span>;
  } else {
    return <span className="text-danger text-capitalize fw-medium badge bg-danger text-white border-rd-25 p-1 px-3 fs-14 ">Disabled</span>;
  }
};
//for promotion active
export const formatIsActive = (status: any) => {
  if (status === true) {
    return <span className="text-success text-capitalize fw-medium badge bg-success text-white border-rd-25 p-1 px-3 fs-14 ">Enable</span>;
  } else {
    return <span className="text-danger text-capitalize fw-medium badge bg-danger text-white border-rd-25 p-1 px-3 fs-14 ">Disabled</span>;
  }
};

export const formatType = (type: string) => {
  if (type === 'simple') {
    return <span className="text-capitalize fw-normal">simple product</span>;
  } else if (type === 'configurable') {
    return <span className="text-capitalize fw-normal">configurable product</span>;
  } else if (type === 'grouped') {
    return <span className="text-capitalize fw-normal">grouped product</span>;
  } else if (type === 'bundle') {
    return <span className="text-capitalize fw-normal">bundle product</span>;
  } else if (type === 'virtual') {
    return <span className="text-capitalize fw-normal">virtual product</span>;
  } else if (type === 'downloadable') {
    return <span className="text-capitalize fw-normal">downloadable product</span>;
  }
};

export const formatVisibility = (type: number) => {
  if (type === 1) {
    return <span className="text-capitalize fw-normal">not visible individually</span>;
  } else if (type === 2) {
    return <span className="text-capitalize fw-normal">catalog</span>;
  } else if (type === 3) {
    return <span className="text-capitalize fw-normal">search</span>;
  } else if (type === 4) {
    return <span className="text-capitalize fw-normal">catalog, search</span>;
  }
};

export const formatFilterVisibility = (type: string) => {
  if (type === '1') {
    return <span className="text-capitalize fw-light">not visible individually</span>;
  } else if (type === '2') {
    return <span className="text-capitalize fw-light">catalog</span>;
  } else if (type === '3') {
    return <span className="text-capitalize fw-light">search</span>;
  } else if (type === '4') {
    return <span className="text-capitalize fw-light">catalog, search</span>;
  }
};

export const formatFilterEnableStatus = (status: string) => {
  if (status === '1') {
    return 'Enabled';
  } else if (status === '0') {
    return 'Disabled';
  }
};

export const formatFilterEnableStatusPromotion = (status: string) => {
  if (status === '1') {
    return 'Enable';
  } else if (status === '0') {
    return 'Disabled';
  }
};

export const formatOrderStatus = (status: string) => {
  let statusFlag = 'warning';
  if (!status) return;
  if (['canceled', 'failed', 'closed'].includes(status)) statusFlag = 'danger';
  else if (['complete', 'processing'].includes(status)) statusFlag = 'success';
  else statusFlag = 'warning';

  return (
    <span className={`page-status table-status fs-5 table-status-${statusFlag} text-capitalize fw-semibold`}>
      {status === 'holded' ? 'On Hold' : status}
    </span>
  );
};

export const formatGender = (gender: number) => {
  if (gender === 1) {
    return 'Male';
  } else if (gender === 2) {
    return 'Female';
  } else {
    return 'Not Specified';
  }
};

export const formatGroup = (groupId: number) => {
  if (groupId === 1) {
    return 'General';
  } else if (groupId === 2) {
    return 'Wholesale';
  } else if (groupId === 3) {
    return 'Retailer';
  }
};

export const formatEmailStatus = (emailStatus: string) => {
  if (emailStatus === 'account_confirmation_not_required') {
    return 'Confirmation Not Required';
  }
};

export const formatCountry = (data: string) => {
  const options: Country[] = countryList().getData();
  const selectedOption: Country | undefined = options.find((option: Country) => option.value === data);

  if (selectedOption) {
    // Return the label of the selected country option
    return selectedOption.label;
  } else {
    // If the value is not a valid country value, throw an error or return a default value
    throw new Error(`Invalid country value: ${data}`);
    // Or return a default value like "Unknown country"
    // return "Unknown country";
  }
};

export const FormatStore = (data: string) => {
  const { storeData } = useAppSelector((s) => s.userReducer);
  const matchedData = storeData.find((item: any) => item.id === data);
  if (matchedData) {
    return matchedData.name;
  }
};

export const FormatWebsite = (data: string) => {
  const { websiteData } = useAppSelector((s) => s.userReducer);
  const matchedData = websiteData.find((item: any) => item.id === data);
  if (matchedData) {
    return matchedData.name;
  }
};

export const formatLastLogin = (data: string) => {
  const lastLogin = data.split(' ');
  if (lastLogin[0] === '') {
    return `Never ${lastLogin[1]}`;
  } else {
    return `${moment(`${lastLogin[0]} ${lastLogin[1]}`).format('MMM DD, YYYY hh:mm:ss A')} ${lastLogin[2]}`;
  }
};

export const formatFilterEnableStatusTicket = (status: string) => {
  if (status === '1') {
    return 'Enabled';
  }
  if (status === '0') {
    return 'Disabled';
  }
};

export const formatFilterPriority = (priority_id: string) => {
  if (priority_id === '0') {
    return <span className="text-capitalize fw-light">Low</span>;
  } else if (priority_id === '1') {
    return <span className="text-capitalize fw-light">Medium</span>;
  } else if (priority_id === '2') {
    return <span className="text-capitalize fw-light">High</span>;
  } else if (priority_id === '3') {
    return <span className="text-capitalize fw-light">Ugent</span>;
  }
};

export const formatYesNo = (value: string): string => {
  switch (value) {
    case '1':
      return 'Yes';
    case '0':
      return 'No';
    default:
      return value;
  }
};
