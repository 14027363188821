import { FC } from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import { iTableHead } from '~/app/models';
import { formatMoney } from '~/app/utils';
import { Input } from '~/components/common/Input';
import { Table } from '~/components/common/Table';
import { NoRecord } from '~/components/pages/Err';

const INVOICE_TABLE_HEAD: iTableHead[] = [
  { name: 'Product', className: 'py-3 ps-0 fs-14 fw-medium w-35' },
  { name: 'Price', className: 'py-3 fs-14 fw-medium text-end' },
  { name: 'Qty', className: 'py-3 fs-14 fw-medium text-end' },
  { name: 'Qty to Invoice', className: 'py-3 fs-14 fw-medium' },
  { name: 'Subtotal', className: 'py-3 fs-14 fw-medium text-end' },
  { name: 'Tax Amount', className: 'py-3 fs-14 fw-medium text-end' },
  { name: 'Discount', className: 'py-3 fs-14 fw-medium text-end' },
  { name: 'Row Total', className: 'py-3 fs-14 fw-medium text-end' },
];

interface Props {
  formik: any;
}

const TableCreateInvoice: FC<Props> = ({ formik }) => {
  const { status, dataDetails } = useAppSelector((state) => state.orderReducer);
  const { currentStore } = useAppSelector((state) => state.authReducer);

  const { values, errors, handleChange } = formik;

  const itemWithOutConfigurable = dataDetails.items
    .filter((item: any) => item.product_type !== 'configurable')
    .filter((item: any) => {
      const itemCheck = item.parent_item ? item.parent_item : item;

      return itemCheck.qty_ordered > itemCheck.qty_invoiced;
    });

  const convertDeliveryCode = (data: string[]) => {
    if (!data) return undefined;
    const deliveryString = JSON.parse(JSON.stringify(data));

    const result = deliveryString.map((item: any) => JSON.parse(item));

    return result;
  };

  function renderTableRow(item: any, index: number) {
    const itemCheck = item.parent_item ? item.parent_item : item;
    const { name, sku, price_incl_tax, qty_ordered, qty_invoiced, qty_canceled, qty_refunded, qty_shipped, tax_amount, discount_amount, item_id } =
      itemCheck || {};

    const options = convertDeliveryCode(item?.parent_item?.extension_attributes?.deliverycode);

    const qtyToInvoice = values[`qty-pdt-${item_id}`];
    const nameInput = `qty-pdt-${item_id}`;

    const subTotal = price_incl_tax * qtyToInvoice;
    const tax = (tax_amount / qty_ordered) * qtyToInvoice || 0;
    const discount = (discount_amount / qty_ordered) * qtyToInvoice || 0;
    const rowTotal = subTotal - discount;

    return (
      <tr key={index}>
        <td className="ps-0 fs-14 vertical-middle">
          <p className="mb-0 fw-medium" dangerouslySetInnerHTML={{ __html: name }} />
          <p className="mb-0 sku-product">SKU: {sku}</p>
          {options
            ? options.map((option: any, i: number) => {
                return (
                  <p key={i} className="mb-0 option-product">
                    {option.label}: {option.value}
                  </p>
                );
              })
            : ''}
        </td>
        <td className="fs-14 vertical-middle text-end">{formatMoney(price_incl_tax, currentStore)}</td>
        <td className="vertical-middle fs-14 text-end text-nowrap">
          <p className="mb-1">{qty_ordered ? `Ordered ${qty_ordered}` : ''}</p>
          <p className="mb-1">{qty_invoiced ? `Invoiced ${qty_invoiced}` : ''}</p>
          <p className="mb-1">{qty_canceled ? `Canceled ${qty_canceled}` : ''}</p>
          <p className="mb-1">{qty_refunded ? `Refunded ${qty_refunded}` : ''}</p>
          <p className="mb-1">{qty_shipped ? `Shipped ${qty_shipped}` : ''}</p>
        </td>
        <td className="vertical-middle">
          <div className="d-flex flex-column input-qty-to-refund">
            <>
              <Input
                type="number"
                className="form-control--short fs-14"
                name={nameInput}
                value={qtyToInvoice || ''}
                onChange={(e) => {
                  const isUserRemove = qtyToInvoice.toString().length >= e.target.value.length;

                  if (!errors[nameInput]) {
                    handleChange(e);
                  } else {
                    errors[nameInput] === 'This field is required' ? handleChange(e) : isUserRemove && handleChange(e);
                  }
                }}
                onlyInteger
              />

              {errors[nameInput] && <p className="fs-14 text-danger m-0 p-0 mt-2">{errors[nameInput]}</p>}
            </>
          </div>
        </td>
        <td className="fs-14 vertical-middle text-end">{formatMoney(subTotal, currentStore)}</td>
        <td className="fs-14 vertical-middle text-end">{formatMoney(tax, currentStore)}</td>
        <td className="fs-14 vertical-middle text-end">{formatMoney(discount, currentStore)}</td>
        <td className="fs-14 vertical-middle text-end">{formatMoney(rowTotal, currentStore)}</td>
      </tr>
    );
  }

  return (
    <div className="info-table bg-white p-4 pb-0 wrapper">
      <p className="title pb-4 fs-16 m-0 fw-medium text-uppercase">Items to Invoiced</p>
      <Table status={status} dataTableHead={INVOICE_TABLE_HEAD} className=" ">
        {itemWithOutConfigurable.length ? (
          itemWithOutConfigurable.map((item: any, i: number) => {
            return renderTableRow(item, i);
          })
        ) : (
          <NoRecord tableHeaders={INVOICE_TABLE_HEAD} />
        )}
      </Table>
    </div>
  );
};

export default TableCreateInvoice;
