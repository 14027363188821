import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  iDataStore,
  iProduct,
  iCategoryItem,
  iCategoryTreeItem,
  iDataHeader,
  iImage,
  iVideo,
  iDataAdvPrice,
  iDataAttrConfigSelected,
  iSearchOptimize,
  Status,
  iListProductAssociated,
  iApplyAllStore,
  iProductAttributeItem,
  iAttrSetItem,
} from '~/app/models';
interface iState {
  productInWebsites: number[];
  currentWebsiteId: number;
  idProductRedirect: number | null;
  action: 'create' | 'edit';
  type: 'simple' | 'config' | undefined;
  listApplyAllStores: iApplyAllStore;
  status: Status;
  statusGetAttrSetList: Status;
  statusGetProduct: Status;
  statusGetProductDetail: Status;
  statusGetAttrProductConfig: Status;
  statusGetAttributeOfAttrSetId: Status;
  statusGetCategoriesTree: Status;
  deleteProductStatus: Status;
  message: string;
  messageCreateSubProduct: string;
  messageGetProductDetail: string;
  messageGetAttributeOfAttrSetId: string;
  data: iProduct;
  dataRelatedAttrByAttrSet: iProductAttributeItem[];
  dataStore: iDataStore[];
  dataAttrSet: iAttrSetItem[];
  dataCate: iCategoryItem[];
  dataCateTree: iCategoryTreeItem[];
  dataHeader: iDataHeader;
  dataImage: iImage[];
  dataVideo: iVideo[];
  dataProductList: iProduct[];
  dataRelatedSelected: iProduct[];
  dataUpSellSelected: iProduct[];
  dataCrossSellSelected: iProduct[];
  idCategoriesSelected: number[];
  dataAdvInventory: any;
  dataAdvPrice: iDataAdvPrice;
  dataAttrList: any;
  dataAttrConfigSelected: iDataAttrConfigSelected[];
  dataValuesConfigSelected: any;
  dataProductGenerate: any;
  listTierPrice: any[];
  dataProductsAssociatedUnConverted: iProduct[];
  listProductAssociated: iListProductAssociated[];
  dataSearchOptimize: iSearchOptimize;
  listAttrOfProductConfig: iProductAttributeItem[];
  productListController: null | AbortController;
  shouldShowLoadingWhenAttrSetIdChange: boolean;
}

const initialState: iState = {
  productInWebsites: [],
  currentWebsiteId: 0,
  action: 'create',
  type: 'simple',
  idProductRedirect: null,
  status: '',
  statusGetAttrSetList: '',
  statusGetProduct: '',
  statusGetProductDetail: '',
  statusGetAttrProductConfig: '',
  statusGetAttributeOfAttrSetId: '',
  statusGetCategoriesTree: '',
  deleteProductStatus: '',
  message: '',
  messageCreateSubProduct: '',
  messageGetProductDetail: '',
  messageGetAttributeOfAttrSetId: '',
  data: {} as iProduct,
  dataRelatedAttrByAttrSet: [],
  dataStore: [],
  dataAttrSet: [],
  dataCate: [],
  dataCateTree: [],
  dataProductList: [],
  dataHeader: {} as iDataHeader,
  dataAdvPrice: {} as iDataAdvPrice,
  dataAdvInventory: {
    use_config_manage_stock: true,
    manage_stock: '1',
    use_config_min_qty: true,
    min_qty: 0,
    use_config_min_sale_qty: true,
    min_sale_qty: 1,
    use_config_max_sale_qty: true,
    max_sale_qty: 1000,
    use_config_backorders: true,
    backorders: 0,
    use_config_notify_stock_qty: true,
    notify_stock_qty: 1,
    use_config_enable_qty_inc: true,
    enable_qty_increments: '0',
    use_config_qty_increments: true,
    qty_increments: 1,
    is_qty_decimal: '0',
    is_decimal_divided: '0',
  },
  dataSearchOptimize: {
    isGeneralMetaTitle: true,
  } as iSearchOptimize,
  dataImage: [],
  dataRelatedSelected: [],
  dataUpSellSelected: [],
  dataCrossSellSelected: [],
  idCategoriesSelected: [],
  dataAttrConfigSelected: [],
  dataValuesConfigSelected: {},
  dataAttrList: {},
  dataProductGenerate: [],
  dataProductsAssociatedUnConverted: [],
  listProductAssociated: [],
  listTierPrice: [],
  dataVideo: [],
  listAttrOfProductConfig: [],
  listApplyAllStores: {
    status: false,
    short_description: false,
    description: false,
    meta_keyword: false,
    meta_title: false,
    url_key: false,
  },
  productListController: null,
  shouldShowLoadingWhenAttrSetIdChange: true,
};

const createProductReducer = createSlice({
  name: 'Create/Edit Product',
  initialState,
  reducers: {
    reset: () => initialState,
    changeController: (state, action) => ({ ...state, productListController: action.payload }),

    createEditProductPending: (state) => {
      state.status = 'pending';
    },
    createSubProductRejected: (state, { payload }) => {
      state.status = 'rejected';
      state.messageCreateSubProduct = payload;
    },
    createEditProductRejected: (state, { payload }) => {
      state.status = 'rejected';
      state.message = payload;
    },
    createEditProductFulfilled: (state, { payload }: { payload: number }) => {
      state.status = 'fulfilled';
      state.idProductRedirect = payload;
      state.message = 'Successfully';
    },
    updateIdProduct: (state, { payload }: { payload: number }) => {
      state.idProductRedirect = payload;
    },

    getDataRelatedSelected: (state, { payload }) => {
      state.dataRelatedSelected = payload;
    },
    getDataUpSellSelected: (state, { payload }) => {
      state.dataUpSellSelected = payload;
    },
    getDataCrossSellSelected: (state, { payload }) => {
      state.dataCrossSellSelected = payload;
    },
    getDataCateSelected: (state, { payload }) => {
      state.idCategoriesSelected = payload;
    },

    getDataPending: (state) => {
      state.statusGetAttrSetList = 'pending';
    },
    getDataRejected: (state, { payload }) => {
      state.statusGetAttrSetList = 'rejected';
      state.message = payload;
    },
    getDataFulfilled: (state) => {
      state.statusGetAttrSetList = 'fulfilled';
    },

    getAttributesOfAttributeSetIdPending: (state) => {
      state.statusGetAttributeOfAttrSetId = 'pending';
    },
    getAttributesOfAttributeSetIdRejected: (state, { payload }: PayloadAction<string>) => {
      state.statusGetAttributeOfAttrSetId = 'rejected';
      state.messageGetAttributeOfAttrSetId = payload;
    },
    getAttributesOfAttributeSetIdFulfilled: (state, { payload }: PayloadAction<iProductAttributeItem[]>) => {
      state.statusGetAttributeOfAttrSetId = 'fulfilled';
      state.dataRelatedAttrByAttrSet = payload;
    },

    getProductListPending: (state) => {
      state.statusGetProduct = 'pending';
    },
    getProductListRejected: (state) => {
      state.statusGetProduct = 'rejected';
    },
    getProductListFulfilled: (state, { payload }) => {
      state.statusGetProduct = 'fulfilled';
      state.dataProductList = payload;
    },

    getProductDetailPending: (state) => {
      state.statusGetProductDetail = 'pending';
    },
    getProductDetailRejected: (state, { payload }) => {
      state.statusGetProductDetail = 'rejected';
      state.messageGetProductDetail = payload;
    },
    getProductDetailFulfilled: (state, { payload }) => {
      state.statusGetProductDetail = 'fulfilled';
      state.data = payload;
    },

    clearMessage: (state) => {
      state.status = '';
      state.message = '';
    },

    getRelatedAttributeFulfilled: (state, { payload }) => {
      state.dataRelatedAttrByAttrSet = payload;
    },
    getListStoreFulfilled: (state, { payload }) => {
      state.dataStore = payload;
    },
    getListAttrSetFulfilled: (state, { payload }) => {
      state.dataAttrSet = payload;
    },

    getCategoriesTreeFulfilled: (state, { payload }: PayloadAction<iCategoryTreeItem[]>) => ({
      ...state,
      dataCateTree: payload,
      statusGetCategoriesTree: 'fulfilled',
    }),
    getCategoriesTreeReject: (state) => ({
      ...state,
      statusGetCategoriesTree: 'rejected',
    }),
    getCategoriesTreePending: (state) => ({
      ...state,
      statusGetCategoriesTree: 'pending',
    }),

    getCateListFulfilled: (state, { payload }) => {
      state.dataCate = payload;
    },

    getListProductAssociated: (state, { payload }) => {
      state.dataProductsAssociatedUnConverted = payload;
    },

    onChangeAction: (state, { payload }) => {
      state.action = payload;
    },
    onChangeType: (state, { payload }) => {
      state.type = payload;
    },
    onChangeListProductAssociated: (state, { payload }) => {
      state.listProductAssociated = payload;
    },
    onChangeListTierPrice: (state, { payload }) => {
      state.listTierPrice = payload;
    },
    onChangeDataHeader: (state, { payload }) => {
      state.dataHeader = payload;
    },
    onChangeListImage: (state, { payload }) => {
      state.dataImage = payload;
    },
    onChangeListRelatedSelected: (state, { payload }) => {
      state.dataRelatedSelected = payload;
    },
    onChangeListUpSellSelected: (state, { payload }) => {
      state.dataUpSellSelected = payload;
    },
    onChangeListCrossSellSelected: (state, { payload }) => {
      state.dataCrossSellSelected = payload;
    },
    onChangeIdCategoriesSelected: (state, { payload }) => {
      state.idCategoriesSelected = payload;
    },
    onChangeDataAdvInventory: (state, { payload }) => {
      state.dataAdvInventory = payload;
    },
    onChangeDataAdvPrice: (state, { payload }) => {
      state.dataAdvPrice = payload;
    },
    onChangeDataAttr: (state, { payload }) => {
      state.dataAttrList = payload;
    },
    onChangeAttrSelect: (state, { payload }) => {
      state.dataAttrConfigSelected = payload;
    },
    onChangeDataValuesConfigSelected: (state, { payload }) => {
      state.dataValuesConfigSelected = payload;
    },
    onChangeProductGenerate: (state, { payload }) => {
      state.dataProductGenerate = payload;
    },
    onChangeDataVideo: (state, { payload }) => {
      state.dataVideo = payload;
    },
    onChangeSearchOptimize: (state, { payload }) => {
      state.dataSearchOptimize = payload;
    },
    onChangeListApplyAllStores: (state, { payload }) => {
      state.listApplyAllStores = { ...state.listApplyAllStores, ...payload };
    },
    onRejectLoadingWhenAttrSetChange: (state) => ({ ...state, shouldShowLoadingWhenAttrSetIdChange: false }),
    onChangeListAttrProductConfig: (state, { payload }: PayloadAction<iProductAttributeItem[]>) => ({
      ...state,
      listAttrOfProductConfig: payload,
    }),
    onChangeProductInWebsites: (state, { payload }: PayloadAction<number[]>) => ({ ...state, productInWebsites: payload }),
    onChangeCurrentWebsiteId: (state, { payload }: PayloadAction<number>) => ({ ...state, currentWebsiteId: payload }),
    deleteProductFulfilled: (state) => ({ ...state, deleteProductStatus: 'fulfilled' }),
    deleteProductRejected: (state, action) => ({ ...state, message: action.payload, deleteProductStatus: 'rejected' }),
    deleteProductPending: (state) => ({ ...state, deleteProductStatus: 'pending' }),
    deleteProductInitState: (state) => ({ ...state, deleteProductStatus: '' }),
  },
});

export const { ...actions } = createProductReducer.actions;

export default createProductReducer.reducer;
