import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { dataCustomerOrderHead } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { addFilterGroup, addFilterItem, addFilters, addSortOrder, filterEmptyProperties, formatMoney, searchCriteriaBuilder } from '~/app/utils';
import Pagination from '~/components/common/Pagination';
import { Table } from '~/components/common/Table';
import { NoRecord } from '../../Err';
import { setQueryOrderString, setIsEnableFilter } from '../redux/action';
import { NumberFromToFilter, NumberFilter, DateRangeFilter, FilterButtons, ResetFilterButton } from '~/components/common/Filter/filterInput';
import { formatFilterActive } from '~/components/common/Filter/formatFilter';
import { getAllOrders, setKeySearch, setIsDESC } from '../../Orders/redux/actions';
import { convertStringToNumber } from '~/app/utils/convertBase64';
import { builderCriteriaCustomer } from '~/app/utils/builderCriteria';
import { sorterCriteria } from '~/app/utils/sorterCriteria';
import { iGetAllOrder } from '~/app/models';

interface Props {}

type FormFilterValues = {
  customerPurchaseOrderFrom: string;
  customerPurchaseOrderTo: string;
  customerOrderTotalFrom: string;
  customerOrderTotalTo: string;
  customerOrderId: string;
  customerShipToName: string;
  customerBillToName: string;
};

const Orders: React.FC<Props> = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { customerId } = useParams();

  const customerPurchaseOrderFrom = searchParams.get('customerPurchaseOrderFrom') || '';
  const customerPurchaseOrderTo = searchParams.get('customerPurchaseOrderTo') || '';
  const customerOrderTotalFrom = searchParams.get('customerOrderTotalFrom') || '';
  const customerOrderTotalTo = searchParams.get('customerOrderTotalTo') || '';
  const customerOrderId = searchParams.get('customerOrderId') || '';
  const customerShipToName = searchParams.get('customerShipToName') || '';
  const customerBillToName = searchParams.get('customerBillToName') || '';

  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));
  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;

  const initialFilterPayload = {
    customerPurchaseOrderFrom,
    customerPurchaseOrderTo,
    customerOrderTotalFrom,
    customerOrderTotalTo,
    customerOrderId,
    customerShipToName,
    customerBillToName,
  } as FormFilterValues;

  const dispatch = useAppDispatch();
  const { status, isEnableFilter } = useAppSelector((state) => state.customerReducer);
  const {
    data: orderData,
    status: orderStatus,
    message: orderMessage,
    isDESC: orderIsDESC,
    keySearch: orderKeySearch,
    controller,
  } = useAppSelector((state) => state.orderReducer);
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { items: orderItems, total_count: orderTotalCount } = orderData;
  const sort = sorterCriteria(orderKeySearch, orderIsDESC);

  // Declare useState
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>();
  const [filterPayload, setFilterPayload] = useState<FormFilterValues>(initialFilterPayload);
  const [submitFilterPayload, setSubmitFilterPayload] = useState<any>(initialFilterPayload);
  const [query, setQuery] = useState<string>(window.location.search);

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  // Change current page
  const onChangePage = (currentPage: number) => {
    if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: pageSize.toString(), currentPage: currentPage.toString() });
      resetUrlData();
    }
  };

  // reset fillter and search
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      customerPurchaseOrderFrom: '',
      customerPurchaseOrderTo: '',
      customerOrderTotalFrom: '',
      customerOrderTotalTo: '',
      customerOrderId: '',
      customerShipToName: '',
      customerBillToName: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      customerPurchaseOrderFrom: '',
      customerPurchaseOrderTo: '',
      customerOrderTotalFrom: '',
      customerOrderTotalTo: '',
      customerOrderId: '',
      customerShipToName: '',
      customerBillToName: '',
    });
    setSearchParams({});
    resetUrlData();
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${key}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${key}`]: '' });
    setSubmitFilterPayload({ ...filteredObject, [`${key}`]: '' });
    resetUrlData();
  };

  const resetFilterFromToSection = (keyFrom: string, keyTo: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${keyFrom}`]: '',
      [`${keyTo}`]: '',
      sAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    setSubmitFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    resetUrlData();
  };

  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitFilterPayload({ ...filterPayload });
    const filteredObject = filterEmptyProperties(filterPayload);

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
    setIsFilterSubmit(true);
  };

  const sortOrder = (key: string) => {
    if (!key) return;
    orderKeySearch !== key ? dispatch(setIsDESC(false)) : dispatch(setIsDESC(!orderIsDESC));
    dispatch(setKeySearch(key));
  };

  useEffect(() => {
    dispatch(setQueryOrderString(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const filter = builderCriteriaCustomer(submitFilterPayload);

    const payload: iGetAllOrder = {
      accessToken: accessToken,
      searchUrl: searchCriteriaBuilder(
        convertStringToNumber(pageSize.toString(), 10),
        convertStringToNumber(currentPage.toString(), 1),
        addFilterGroup(addFilters(addFilterItem('customer_id', `${customerId}`, 'eq')), ...filter),
        addSortOrder(...sort),
      ),
      currentStore: currentStore,
    };

    dispatch(getAllOrders(payload, controller));

    if (Object.keys(filterEmptyProperties(submitFilterPayload)).length === 0) {
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
      });
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, currentStore, orderKeySearch, orderIsDESC, query, isFilterSubmit]);

  const renderFilterActive = () => {
    const filterSections = [
      {
        label: 'Purchase Date',
        value:
          customerPurchaseOrderFrom + customerPurchaseOrderTo &&
          `${customerPurchaseOrderFrom ? moment(customerPurchaseOrderFrom).format('MM/DD/YYYY') : '...'} - ${
            customerPurchaseOrderTo ? moment(customerPurchaseOrderTo).format('MM/DD/YYYY') : '...'
          }`,
        resetHandler: () => resetFilterFromToSection('customerPurchaseOrderFrom', 'customerPurchaseOrderTo'),
      },
      {
        label: 'Order Total',
        value:
          customerOrderTotalFrom + customerOrderTotalTo &&
          `${customerOrderTotalFrom ? customerOrderTotalFrom : '...'} - ${customerOrderTotalTo ? customerOrderTotalTo : '...'}`,
        resetHandler: () => resetFilterFromToSection('customerOrderTotalFrom', 'customerOrderTotalTo'),
      },
      { label: 'Order', value: customerOrderId, resetHandler: () => resetFilterSection('customerOrderId') },
      { label: 'Ship-to Name', value: customerShipToName, resetHandler: () => resetFilterSection('customerShipToName') },
      { label: 'Bill-to Name', value: customerBillToName, resetHandler: () => resetFilterSection('customerBillToName') },
    ];
    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={() => resetFilter()} />}
          </div>
          <div className="border-top border-bottom p-3 align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  const renderFilterBoard = () => {
    return (
      <div className={`bg-white filter-board${isEnableFilter ? '' : ' d-none'} p-4 wrapper my-4 border-rd-20`}>
        <div className="filter-section bg-white">
          <div className="row">
            <h5 className="col-12 mb-4 text-dark filter-title">Filters</h5>
            <DateRangeFilter
              filterClass="col-lg-4 col-xl-4"
              label="Purchase Date"
              name="customerPurchaseOrder"
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <NumberFromToFilter
              filterClass="col-lg-4 col-xl-4 mt-3 mt-lg-0"
              label="Order Total"
              name="customerOrderTotal"
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <NumberFilter
              filterClass="col-lg-4 col-xl-4 mt-3 mt-lg-0"
              label="Order"
              name="customerOrderId"
              value={filterPayload.customerOrderId}
              onChange={onFilterChangeHandler}
            />
            {/* <TextFilter
              filterClass="col-lg-4 col-xl-4 mt-3"
              label="Ship-to Name"
              name="customerShipToName"
              value={filterPayload.customerShipToName}
              onChange={onFilterChangeHandler}
            />
            <TextFilter
              filterClass="col-lg-4 col-xl-4 mt-3"
              label=" Bill-to Name"
              name="customerBillToName"
              value={filterPayload.customerBillToName}
              onChange={onFilterChangeHandler}
            /> */}
          </div>
          <FilterButtons onSubmit={onSubmit} status={status} setIsEnableFilter={() => dispatch(setIsEnableFilter(!isEnableFilter))} />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <>
        <div className="table-section bg-white mt-4 wrapper overflow-auto border-rd-20">
          <Table
            keySearch={orderKeySearch}
            isDESC={orderIsDESC}
            dataTableHead={dataCustomerOrderHead}
            status={orderStatus}
            message={orderMessage}
            sortOrder={sortOrder}
            className="order-table"
          >
            {orderItems.length > 0 ? (
              orderItems.map((item: any, i: number) => {
                return (
                  <tr key={i}>
                    <td className="td-item px-2 py-3 fs-14 text-start fw-normal">{item.increment_id}</td>
                    <td className="td-item px-2 py-3 fs-14 text-end fw-normal">{moment(item.created_at).format('MMM DD, YYYY hh:mm:ss A')}</td>
                    <td className="td-item px-2 py-3 fs-14 text-start fw-normal">{`${item.billing_address?.firstname} ${
                      item.billing_address.middlename ? item.billing_address.middlename : ''
                    } ${item.billing_address?.lastname}`}</td>
                    <td className="td-item px-2 fs-14 text-start fw-normal">
                      {item.extension_attributes.shipping_assignments
                        .filter((item: any) => item.shipping.address?.address_type === 'shipping')
                        .map((item: any, i: number) => `${item.shipping.address?.firstname} ${item.shipping.address?.lastname}`)}
                    </td>
                    <td className="td-item px-2 fs-14 text-end">{formatMoney(item.grand_total, currentStore)}</td>
                    <td className="td-item px-2 py-3">
                      <p className="mb-0 fw-normal fs-14 text-start white-space-pre">{item.store_name}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecord tableHeaders={dataCustomerOrderHead} />
            )}
          </Table>
        </div>
        <Pagination
          className="p-0"
          currentPage={+currentPage}
          pageSize={+pageSize}
          onChangePageSizeEvent={onChangePageSizeEvent}
          onChangePageEvent={onChangePage}
          status={orderStatus}
          totalCount={orderTotalCount}
        />
      </>
    );
  };

  return (
    <>
      {pathname.split('/').includes('customer-orders') && renderFilterBoard()}
      {pathname.split('/').includes('customer-orders') && renderFilterActive()}
      {renderTable()}
    </>
  );
};

export default Orders;
