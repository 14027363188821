import customerApi from '~/app/apis/customer.api';
import { iGetAllCustomers, iGetCustomer } from '~/app/models';
import * as types from './index';
import { defaultMessageError } from '~/app/constants';

const { rest } = types;

export const getData = (payload: iGetAllCustomers, cancelController: AbortController) => async (dispatch: any) => {
  cancelController && cancelController.abort();
  dispatch(rest.getDataPending());
  const controller = new AbortController();
  dispatch(rest.changeController(controller));
  try {
    const res = await customerApi.getAllCustomers(payload, controller);
    const { data } = res;
    !data ? dispatch(rest.getDataRejected(defaultMessageError)) : dispatch(rest.getDataFulfilled(data));
  } catch (error: any) {
    if ((error.name = 'CanceledError')) {
      return dispatch(rest.getDataPending());
    }
    const { message } = error?.response?.data || defaultMessageError;
    dispatch(rest.getDataRejected(message));
  }
};

export const getDataById = (payload: iGetCustomer) => async (dispatch: any) => {
  dispatch(rest.getCustomerDetailPending());
  try {
    const res = await customerApi.getCustomer(payload);
    const { data } = res;
    !data ? dispatch(rest.getCustomerDetailRejected(defaultMessageError)) : dispatch(rest.getCustomerDetailFulfilled(data));
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(rest.getCustomerDetailRejected(message));
  }
};

export const getDataStatusById = (payload: iGetCustomer) => async (dispatch: any) => {
  dispatch(rest.getCustomerStatusPending());
  try {
    const res = await customerApi.getCustomerConfirmStatus(payload);
    const { data } = res;
    !data ? dispatch(rest.getCustomerStatusRejected(defaultMessageError)) : dispatch(rest.getCustomerStatusFulfilled(data));
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(rest.getCustomerStatusRejected(message));
  }
};

export const setUrlData = (payload: { pageSize: number; currentPage: number }) => (dispatch: any) => {
  dispatch(rest.setUrlData(payload));
};

export const setIsDESC = (isDESC: boolean) => (dispatch: any) => {
  dispatch(rest.setIsDESC(isDESC));
};

export const setKeySearch = (keySearch: string) => (dispatch: any) => {
  dispatch(rest.setKeySearch(keySearch));
};

export const setFilterData = (filterData: any) => (dispatch: any) => {
  dispatch(rest.setFilterData(filterData));
};

export const setQueryString = (queryString: string) => (dispatch: any) => {
  dispatch(rest.setQueryString(queryString));
};

export const setQueryOrderString = (queryString: string) => (dispatch: any) => {
  dispatch(rest.setQueryOrderString(queryString));
};

export const setQueryCartString = (queryString: string) => (dispatch: any) => {
  dispatch(rest.setQueryCartString(queryString));
};

export const setQueryReviewString = (queryString: string) => (dispatch: any) => {
  dispatch(rest.setQueryReviewString(queryString));
};

export const resetCustomerQueryString = () => (dispatch: any) => {
  dispatch(rest.resetQueryString());
};

export const clearData = () => (dispatch: any) => {
  dispatch(rest.clearData());
};

export const setIsEnableFilter = (isEnableFilter: boolean) => (dispatch: any) => {
  dispatch(rest.setIsEnableFilter(isEnableFilter));
};
