import { iReport } from '~/app/models/report';
import axiosConfig from './axiosConfig';

const reportApi = {
  getOrderReport: (payload: iReport) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/report/order?${searchUrl}`;
    return axiosConfig.get(url);
  },
  getInvoiceReport: (payload: iReport) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/report/invoice?${searchUrl}`;
    return axiosConfig.get(url);
  },
  getReFundedReport: (payload: iReport) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/report/refund?${searchUrl}`;
    return axiosConfig.get(url);
  },
  getProductReport: (payload: iReport) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/report/listproductsold?${searchUrl}`;
    return axiosConfig.get(url);
  },
  getCustomerReport: (payload: iReport) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/report/customer?${searchUrl}`;
    return axiosConfig.get(url);
  },
};

export default reportApi;
