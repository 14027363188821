import Loading from '~/components/common/Loading';
import TrackingNumber from '../TrackingNumber';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { formatMoney } from '~/app/utils';
import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { deleteTrackingNumber } from '../../redux/actions';
import { Table } from '~/components/common/Table';
import { dataTrackingNumerHead } from '~/app/constants';

interface Props {
  shouldFormTrackingNumber: boolean;
  formik: any;
  isCreateShipment: boolean;
}
const OrderShipmentMethod: FC<Props> = ({ shouldFormTrackingNumber, formik, isCreateShipment }) => {
  const dispatch = useAppDispatch();
  const { status, dataDetails, shipmentDetail, trackingNumberStatus, deleteTrackingNumberStatus } = useAppSelector((state) => state.orderReducer);
  const { currentStore, accessToken } = useAppSelector((state) => state.authReducer);
  const { tracks } = shipmentDetail;

  const [showFormTrackingNumber, setShowFormTrackingNumber] = useState<boolean>(false);

  const swalConfirmDisable = Swal.mixin({
    title: 'Delete Tracking Number',
    text: 'Are you sure?',
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: 'ok',
    cancelButtonText: 'cancel',
    customClass: {
      popup: 'p-32px border-rd-25',
      closeButton: 'mb-n4 text-dark',
      title: 'h3 text-dark text-center m-0 fw-semibold p-0',
      htmlContainer: 'text-dark text-center mt-3 mb-0 mx-0 fw-normal',
      actions: 'mt-4 w-100',
      confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-40',
      cancelButton: 'btn btn-primary btn-cancel fs-14 fw-medium btn-border-radius border-rd-25 order-0 w-40',
    },
  });

  function handleRemoveTrackingNumber(id: number, parent_id: number): void {
    swalConfirmDisable.fire({}).then(function (result) {
      if (result.isConfirmed) {
        dispatch(deleteTrackingNumber(id, parent_id, accessToken, currentStore));
      }
    });
  }

  function convertCarrier(carrier_code: string) {
    switch (carrier_code) {
      case 'custom':
        return 'Custom Value';
      case 'zto':
        return 'ZTO';
      case 'zot':
        return 'ZOT';
      case 'dhl':
        return 'DHL';
      case 'fedex':
        return 'Federal Express';
      case 'ups':
        return 'United Parcel Service';
      case 'usps':
        return 'United States Postal Service';
      default:
        throw new Error("Carrier isn't defined");
    }
  }

  return (
    <div className="info-shipping wrapper px-4 py-3 bg-white h-100">
      <p className="mb-4 fs-16 fw-medium">SHIPPING METHOD</p>
      <div className="d-flex align-items-center mb-3">
        <span className="fs-14 me-4">{dataDetails.shipping_description}</span>
        <span className="fs-14 fw-medidum">{formatMoney(dataDetails.shipping_amount, currentStore)}</span>
      </div>

      {shouldFormTrackingNumber && (
        <>
          {trackingNumberStatus === 'pending' || deleteTrackingNumberStatus === 'pending' ? (
            <Loading />
          ) : (
            <>
              {!tracks?.length && (
                <TrackingNumber
                  showForm={showFormTrackingNumber}
                  setShowForm={setShowFormTrackingNumber}
                  formik={formik}
                  isCreateShipment={isCreateShipment}
                />
              )}
              {tracks && tracks.length > 0 && (
                <Table status={status} dataTableHead={dataTrackingNumerHead} className="tracks-table">
                  {tracks.map((item: any, i: number) => (
                    <tr key={i}>
                      <td className="ps-4 py-3 border-0 vertical-middle fs-14">{convertCarrier(item.carrier_code)}</td>
                      <td className="p-3 border-0 vertical-middle fs-14 text-capitalize">{item.title}</td>
                      <td className="p-3 border-0 vertical-middle text-link text-end fs-14 text-capitalize">{item.track_number}</td>
                      <td className="pe-4 py-3 border-0 vertical-middle fs-14 text-end text-capitalize">
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="me-3 fs-6 icon-trash"
                          onClick={() => handleRemoveTrackingNumber(item.entity_id, item.parent_id)}
                        />
                      </td>
                    </tr>
                  ))}
                </Table>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OrderShipmentMethod;
