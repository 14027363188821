import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { actionChangeLastPathName, tokenExpired } from '~/components/layout/FullWidthLayout/redux/actions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/hooks';
import jwtDecode from 'jwt-decode';

interface MyToken {
  uid: number;
  utypid: number;
  iat: number;
  exp: number;
}

const PrivateRoute = () => {
  const { accessToken, isLogin } = useAppSelector((state) => state.authReducer, shallowEqual);
  const dispatch = useAppDispatch();

  const location = useLocation();

  useEffect(() => {
    if (!accessToken || Date.now() / 1000 >= jwtDecode<MyToken>(accessToken)?.exp) {
      dispatch(tokenExpired());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    dispatch(actionChangeLastPathName(location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <>{isLogin ? <Outlet /> : <Navigate to={'/auth/sign-in'} replace />}</>;
};

export default PrivateRoute;
