import { iGetAllSeller, iUpdateStatusSeller } from '../models';
import axiosConfig from './axiosConfig';

const sellerApi = {
  getAllSeller: (payload: iGetAllSeller) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/addin/allvendor?${searchUrl}`;
    return axiosConfig.get(url);
  },
  updateStatusSeller: (payload: iUpdateStatusSeller) => {
    const { currentStore = 'all', vendorId } = payload;
    const url = `${currentStore}/V1/addin/updatestatus/${vendorId}`;
    return axiosConfig.post(url, {});
  },
};

export default sellerApi;
