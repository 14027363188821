import { timeConvert } from '~/app/constants';
import { useAppSelector } from '~/app/hooks/hooks';

const OrderInfo = () => {
  const { dataDetails } = useAppSelector((state) => state.orderReducer);

  const { customer_firstname, customer_lastname, customer_email, created_at, store_name } = dataDetails || {};

  return (
    <div className="order-info bg-white px-4 py-3 wrapper h-100">
      <p className="order-info__title mb-4 fs-16 fw-medium">ORDER INFORMATION</p>
      <div className="order__info-wrap">
        <span className="fs-14 fw-medium">Customer Name</span>
        <span className="fs-14">
          {customer_firstname} {customer_lastname}
        </span>
        <span className="fs-14 fw-medium">Email</span>
        <span className="fs-14">{customer_email}</span>
        <span className="fs-14 fw-medium">Order Date</span>
        <span className="fs-14">{timeConvert(created_at)}</span>
        <span className="fs-14 fw-medium">Purchased From</span>
        <span className="fs-14 white-space-pre">{store_name}</span>
      </div>
    </div>
  );
};

export default OrderInfo;
