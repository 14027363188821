import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { MultiSelectTree } from '~/components/common/MultiSelect';
import Select from '~/components/common/Select';
import { onChangeDataAttr, onChangeIdCategoriesSelected } from '../../redux/actions';

import './style.scss';

const CategoriesAndAttributes = () => {
  const dispatch = useAppDispatch();
  const { idCategoriesSelected, dataCateTree, dataCate, dataRelatedAttrByAttrSet, dataAttrList, dataValuesConfigSelected, statusGetCategoriesTree } =
    useAppSelector((state) => state.createEditProductReducer);

  const [check, setCheck] = useState<any>(idCategoriesSelected);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [query, setQuery] = useState<string>('');
  const [categoriesSelected, setCategoriesSelected] = useState<any[]>([]);

  useEffect(() => {
    setCategoriesSelected(() => {
      const categoriesSelected = check.map((item: any) => {
        return dataCate.find((cate: any) => cate.id.toString() === item);
      });
      const result = categoriesSelected.filter(Boolean);

      return result;
    });

    dispatch(onChangeIdCategoriesSelected(check));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);

  const dataAttr = useMemo(() => {
    const list = [
      'status',
      'shipment_type',
      'page_layout',
      'gift_message_available',
      'options_container',
      'custom_layout_update_file',
      'custom_design',
      'custom_layout',
      'tax_class_id',
      'msrp_display_actual_price_type',
      'quantity_and_stock_status',
      'price_view',
      'visibility',
      'country_of_manufacture',
      'product_image_size',
      'product_page_type',
    ];

    return dataRelatedAttrByAttrSet.filter((attr) => attr.frontend_input === 'select' && !list.includes(attr.attribute_code));
  }, [dataRelatedAttrByAttrSet]);

  function handleChangeAttrList(e: React.ChangeEvent<HTMLSelectElement>) {
    const name = e.target.name;
    const value = !!e.target.value ? e.target.value : '';
    dispatch(onChangeDataAttr({ ...dataAttrList, [name]: value }));
  }

  function renderCategories() {
    return (
      <div className="categories mb-4">
        <h3 className="title fs-16 m-0 mb-4 fw-medium">Categories</h3>
        <div className="d-flex align-items-center">
          <h3 className="label-left m-0 fs-16 fw-normal me-4 text-end">Select</h3>
          <div className="multi-select-wrap flex-grow-1">
            <MultiSelectTree
              options={
                query
                  ? dataCate.filter((item) => item.name.toLowerCase().includes(query.toLowerCase().trim()) && !check.includes(item.id.toString()))
                  : JSON.parse(
                      JSON.stringify(dataCateTree)
                        .replace(/,"children_data":\[\]/g, '')
                        .replace(/"id"/g, '"value"')
                        .replace(/"name"/g, '"label"')
                        .replace(/"children_data"/g, '"children"'),
                    )
              }
              status={statusGetCategoriesTree}
              listSelected={categoriesSelected || []}
              check={check}
              setCheck={setCheck}
              expanded={expanded}
              query={query}
              setQuery={setQuery}
              setExpanded={setExpanded}
              setListSelected={setCategoriesSelected}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderAttr() {
    return (
      <>
        <h3 className="title fs-16 m-0 mb-4 fw-medium">Attributes</h3>
        <div className="attr-list">
          {dataAttr.map((item, index) => {
            const { default_frontend_label, options, attribute_code, attribute_id, default_value } = item;

            return (
              <div className={`d-flex align-item-center justify-content-end`} key={index}>
                <h3
                  className={`${
                    index % 2 === 0 ? 'label-left' : ''
                  } text-end d-flex justify-content-end align-items-center fs-16 m-0 fw-normal me-4 text-capitalize`}
                >
                  {default_frontend_label}
                </h3>
                <Select
                  value={dataAttrList[attribute_code] || default_value}
                  onChange={handleChangeAttrList}
                  name={attribute_code}
                  disabled={Object.keys(dataValuesConfigSelected).includes(`${default_frontend_label}-id${attribute_id}`)}
                >
                  {options.map((option: any, index: number) => (
                    <option value={option.value} key={index}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <div className="cate-attr wrapper mw-100 overflow-x-auto">
      {renderCategories()}
      {renderAttr()}
    </div>
  );
};

export default CategoriesAndAttributes;
