import DefaultLayout from '~/components/layout/DefaultLayout';

import './style.scss';
import { NoRecord } from '../../Err';
import HelpDesk from './HelpDesk';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { getTicketDetails, getListQuickAnswer, resetData, getMessageByTicketId, putTicket, getAllVender, putTicketToRead } from '../redux/action';
import {
  addFilterGroup,
  addFilterItem,
  addFilters,
  addSortOrder,
  capitalizeFirstLetter,
  criteriaBuilder,
  formatMoney,
  searchCriteriaBuilder,
} from '~/app/utils';
import { Link, useParams } from 'react-router-dom';

import Loading from '~/components/common/Loading';
import Swal from 'sweetalert2';
import { defaultMessageError, timeConvertNotShowHour } from '~/app/constants';
import Select from '~/components/common/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from '~/components/common/Button';
import { TextArea } from '~/components/common/Input';

interface iValuesUpdate {
  admin_note: string;
  status_id: string;
  fp_user_id: string;
}

const TicketDetails = () => {
  const dispatch = useAppDispatch();
  const {
    dataTicketDetails,
    dataOrderDetails,
    // dataVender,
    statusGetTicketDetails,
    statusPostMessage,
    messagePostMessage,
    statusPutTicket,
    queryString,
  } = useAppSelector((s) => s.ticketReducer);
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { storeData } = useAppSelector((s) => s.userReducer);
  const { increment_id, created_at, grand_total, status: statusOfOrder, items: dataProductOrdered, state: stateOfOrder } = dataOrderDetails;
  const { ticket_id, customer_email, customer_name, category_name, department_name, status_id, admin_note, fp_user_id, is_read, product_id } =
    dataTicketDetails;

  const [valuesTicket, setValuesTicket] = useState<iValuesUpdate>({
    admin_note: '',
    status_id: '1',
    fp_user_id: '',
  });
  const listProductOrderedWithoutConfigurable = useMemo(() => {
    return dataProductOrdered?.length
      ? dataProductOrdered
          .filter((item: any) => item.product_type !== 'configurable')
          .filter((item) => {
            return product_id.includes(item.product_id.toString());
          })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProductOrdered]);

  const { ticketId = '0' } = useParams();

  const swalToast = Swal.mixin({
    toast: true,
    position: 'top-right',
    buttonsStyling: false,
    showConfirmButton: false,
    timer: 2000,
  });

  useEffect(() => {
    dispatch(
      getListQuickAnswer(
        searchCriteriaBuilder(Number.MAX_VALUE, 1, undefined, addSortOrder({ field: 'quickanswer_id', direction: 'DESC' })),
        accessToken,
        currentStore,
      ),
    );
    dispatch(getTicketDetails(ticketId, accessToken, currentStore));

    // Get message by id
    dispatch(
      getMessageByTicketId(
        searchCriteriaBuilder(Number.MAX_VALUE, 1, addFilterGroup(addFilters(addFilterItem('ticket_id', ticketId, 'eq')))),
        accessToken,
        currentStore,
      ),
    );

    // Get all vender
    dispatch(
      getAllVender(
        criteriaBuilder(
          Number.MAX_VALUE,
          1,
          undefined,
          addSortOrder({
            field: 'firstname',
            direction: 'ASC',
          }),
        ),
        accessToken,
        currentStore,
      ),
    );

    return () => dispatch(resetData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (is_read === '0') {
      const updateTicketPayload = {
        Ticket: {
          is_read: '1',
        },
      };
      dispatch(putTicketToRead(ticketId, updateTicketPayload, accessToken, currentStore, storeData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_read]);

  useLayoutEffect(() => {
    setValuesTicket({
      ...valuesTicket,
      fp_user_id,
      status_id,
      admin_note: admin_note || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTicketDetails]);

  useEffect(() => {
    if (statusPostMessage === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (statusPostMessage === 'rejected') {
      swalToast.fire({
        title: messagePostMessage,
        icon: 'error',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPostMessage]);

  useEffect(() => {
    if (statusPutTicket === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (statusPutTicket === 'rejected') {
      swalToast.fire({
        title: defaultMessageError,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPutTicket]);

  function handleChangeValueTicket(e: React.ChangeEvent<any>) {
    const { value, name } = e.target;

    setValuesTicket({ ...valuesTicket, [name]: value });
  }

  function handleUpdateTicket() {
    if (valuesTicket.admin_note === admin_note && valuesTicket.fp_user_id === fp_user_id && valuesTicket.status_id === status_id) return;

    const payload = {
      Ticket: {
        ticket_id: ticketId,
        ...valuesTicket,
      },
    };
    dispatch(putTicket(ticketId, payload, accessToken, currentStore));
  }

  function renderInfo() {
    return (
      <div className="info wrapper d-flex flex-column bg-white px-4 py-3 gap-4 mw-100 overflow-x-auto">
        <div className="info-heading d-flex align-items-center justify-content-between gap-3">
          <div className="d-flex flex-column gap-2">
            <h3 className="mb-0 fs-6 fw-medium">TICKET ID #{ticket_id}</h3>
            {!!Object.keys(dataOrderDetails).length && (
              <>
                <h3 className="mb-0 fs-6 fw-medium">ORDER #{increment_id}</h3>
                <h4 className="mb-0 fs-14 text-dark-600 fw-normal">
                  at {timeConvertNotShowHour(created_at)} ({formatMoney(grand_total, currentStore)}) -{' '}
                  {capitalizeFirstLetter(statusOfOrder || stateOfOrder || '')}
                </h4>
              </>
            )}
          </div>

          <Button
            className="update-ticket d-flex align-items-center gap-2 ms-auto"
            onClick={handleUpdateTicket}
            disabled={
              statusPutTicket === 'pending' ||
              (valuesTicket.admin_note === admin_note && valuesTicket.fp_user_id === fp_user_id && valuesTicket.status_id === status_id)
            }
          >
            {statusPutTicket === 'pending' ? (
              <>
                Updating
                <FontAwesomeIcon icon={faSpinner} className="loading-icon" />
              </>
            ) : (
              'Update'
            )}
          </Button>
        </div>

        <div className="info-body fs-14">
          <p className="m-0 fw-medium label w-fit-content">Customer Email</p>
          <p className="m-0 text-break">{customer_email}</p>

          <p className="m-0 fw-medium label w-fit-content">Customer Name</p>
          <p className="m-0 text-capitalize">{customer_name}</p>

          <p className="m-0 fw-medium label w-fit-content">Category</p>
          <p className="m-0">{category_name}</p>

          <p className="m-0 fw-medium label w-fit-content">Department</p>
          <p className="m-0">{department_name}</p>

          <p className="m-0 fw-medium label w-fit-content">Status</p>
          <Select
            onChange={handleChangeValueTicket}
            name="status_id"
            value={valuesTicket.status_id || '1'}
            className="form-control--custom-select flex-grow-1"
          >
            <option value="1">Open</option>
            <option value="0">Close</option>
            <option value="2">Processing</option>
            <option value="3">Done</option>
          </Select>

          {/* <p className="m-0 fw-medium label w-fit-content">Assign</p>
          <Select onChange={handleChangeValueTicket} name="fp_user_id" defaultValue={valuesTicket.fp_user_id} className="flex-grow-1 select-custom">
            <option value="" hidden>
              Choose An User
            </option>
            <>
              {dataVender.map((vender) => {
                const { user_id, firstname, lastname, email } = vender;
                return (
                  <option value={user_id} key={user_id}>
                    {`${firstname} ${lastname} <${email}>`}
                  </option>
                );
              })}
            </>
          </Select> */}

          <label htmlFor="admin_note" className="m-0 fw-medium label w-fit-content cursor-pointer">
            Note
          </label>
          <TextArea className="note" id="admin_note" name="admin_note" value={valuesTicket.admin_note} onChange={handleChangeValueTicket} />
        </div>
      </div>
    );
  }

  function renderProductListing() {
    return (
      <div className="product-wrap wrapper bg-white px-4 py-3 mt-4 flex-grow-1 d-flex flex-column overflow-auto">
        <div className="product-heading">
          <h3 className="mb-4 fs-6 fw-medium">PRODUCT LISTING</h3>
        </div>

        <div className="product-list flex-grow-1">
          {listProductOrderedWithoutConfigurable.length ? (
            listProductOrderedWithoutConfigurable.map((item, index: number) => {
              const itemCheck = item.parent_item ? item.parent_item : item;

              const { name, sku, qty_ordered } = itemCheck;

              return (
                <div className="product-item d-flex flex-column gap-2 fs-14 py-3" key={index}>
                  <p className="m-0" dangerouslySetInnerHTML={{ __html: `${name} - SKU: ${sku}` }} />
                  <p className="m-0 text-dark-600 ordered-qty">Ordered QTY: {qty_ordered} items</p>
                </div>
              );
            })
          ) : (
            <table className="w-100">
              <tbody>
                <NoRecord tableHeaders={[]} />
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }

  function renderContent() {
    if (statusGetTicketDetails === 'pending') {
      return <Loading />;
    }

    if (statusGetTicketDetails === 'rejected') {
      return <p className="m-0 text-dark-600 text-center">The ticket that was requested doesn't exist. Verify the ticket and try again.</p>;
    }

    return (
      <div className="row h-100">
        <div className="col-12 col-xl-5 d-flex flex-column h-100">
          {renderInfo()}
          {renderProductListing()}
        </div>

        <div className="col-12 col-xl-7 mt-4 mt-xl-0 h-100">
          <HelpDesk />
        </div>
      </div>
    );
  }

  return (
    <DefaultLayout pageTitle="Ticket Details">
      <div className="previous-link-wrap py-3 mb-4">
        <Link
          className="previous-link text-uppercase btn btn-outline-primary w-fit-content fw-medium rounded-pill fs-14 px-32 text-decoration-none d-flex align-items-center text-nowrap"
          to={`/tickets/${queryString}`}
        >
          <FontAwesomeIcon className="me-3" icon={faArrowLeft} />
          Back To Ticket Listing
        </Link>
      </div>
      <div className="ticket-detail">{renderContent()}</div>
    </DefaultLayout>
  );
};

export default TicketDetails;
