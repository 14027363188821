import { createSlice } from '@reduxjs/toolkit';
import { Status, iCustomerListing, iInitState } from '~/app/models';

interface iState extends iInitState {
  status: Status;
  message: string;
  isDESC: boolean;
  keySearch: string;
  customerDetail: any;
  customerStatus: string;
  filterData: {};
  queryString: string;
  queryOrderString: string;
  queryCartString: string;
  queryReviewString: string;
  isEnableFilter: boolean;
  urlData: {
    pageSize: number;
    currentPage: number;
  };
  data: iCustomerListing;
  controller: any;
}

const initialState: iState = {
  controller: null,
  status: '',
  isEnableFilter: false,
  customerStatus: '',
  filterData: {},
  queryString: '',
  queryReviewString: '',
  queryCartString: '',
  queryOrderString: '',
  message: '',
  data: { items: [], search_criteria: {}, total_count: 0 } as iCustomerListing,
  urlData: { currentPage: 1, pageSize: 10 },
  customerDetail: { addresses: [] },
  isDESC: false,
  keySearch: 'entity_id',
};

const customerSlice = createSlice({
  name: 'CUSTOMER',
  initialState,
  reducers: {
    getDataFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getDataRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),

    getCustomerDetailFulfilled: (state, action) => ({ ...state, customerDetail: action.payload, status: 'fulfilled' }),
    getCustomerDetailRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getCustomerDetailPending: (state) => ({ ...state, status: 'pending' }),

    getCustomerStatusFulfilled: (state, action) => ({ ...state, customerStatus: action.payload, status: 'fulfilled' }),
    getCustomerStatusRejected: (state, action) => ({ ...state, message: action.payload, status: 'rejected' }),
    getCustomerStatusPending: (state) => ({ ...state, status: 'pending' }),

    changeController: (state, action) => ({ ...state, controller: action.payload }),

    // Synchonous Ation
    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    setIsEnableFilter: (state, action) => ({ ...state, isEnableFilter: action.payload }),
    setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),
    setFilterData: (state, action) => ({ ...state, filterData: action.payload }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),
    setQueryCartString: (state, action) => ({ ...state, queryCartString: action.payload }),
    setQueryOrderString: (state, action) => ({ ...state, queryOrderString: action.payload }),
    setQueryReviewString: (state, action) => ({ ...state, queryReviewString: action.payload }),
    resetQueryString: (state) => ({ ...state, queryString: '' }),
    clearData: () => ({ ...initialState }),
  },
});

export const { ...rest } = customerSlice.actions;
const customerReducer = customerSlice.reducer;

export default customerReducer;
