import Swal from 'sweetalert2';

export const swalToast = Swal.mixin({
  toast: true,
  position: 'top-right',
  buttonsStyling: false,
  showConfirmButton: false,
  timer: 3000,
  customClass: {
    title: '',
  },
});

export const swalConfirmDelete = Swal.mixin({
  showCancelButton: true,
  showCloseButton: true,
  confirmButtonText: 'ok',
  cancelButtonText: 'cancel',
  focusCancel: true,
  customClass: {
    popup: 'swal-popup-custom p-32px border-rd-25',
    closeButton: 'mb-n4 text-dark btn-not-hover',
    title: 'h3 text-dark text-center m-0 fw-semibold p-0',
    htmlContainer: 'text-dark text-center mt-3 mb-0 mx-0 fw-normal',
    actions: 'mt-3 w-100',
    confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-40',
    cancelButton: 'btn btn-primary btn-cancel fs-14 fw-medium btn-border-radius border-rd-25 order-0 w-40',
  },
});
