import { COUNTRIES } from '~/app/constants';
import { useAppSelector } from '~/app/hooks/hooks';
import dollar from '~/app/images/dolla-sign.svg';
import location from '~/app/images/location-icon.png';
import phone from '~/app/images/phone.svg';

const OrderAddress = () => {
  const { dataDetails } = useAppSelector((state) => state.orderReducer);
  const { billing_address, extension_attributes } = dataDetails || {};

  const billAddress = ProcessAddress(billing_address, 'bill');
  const shipAddress = ProcessAddress(extension_attributes, 'ship');

  const { firstname, lastname, telephone } = billing_address || {};

  //Process Bill Address
  function ProcessAddress(address: any, type: string) {
    const Address: any = { street: '', city: '', country: '' };

    if (typeof address === 'undefined') return Address;

    if (type === 'bill') {
      Address.street = !!address.street[0] ? address.street[0] : '';

      const city: any = [];
      if (!!address.city) city.push(address.city);
      if (!!address.postcode) city.push(address.postcode);
      if (!!city && city.length > 0) {
        Address.city = city.join(', ');
      }

      Address.country = !!address.country_id ? COUNTRIES[address.country_id] : '';
    } else {
      if (!!address && !!address.shipping_assignments && !!address.shipping_assignments[0].shipping.address) {
        const __address = { ...address.shipping_assignments[0].shipping.address };

        Address.street = !!__address.street ? __address.street[0] : '';
        const city: any = [];
        if (!!__address.city) city.push(__address.city);
        if (!!__address.postcode) city.push(__address.postcode);
        if (!!city && city.length > 0) {
          Address.city = city.join(', ');
        }

        Address.country = !!__address.country_id ? COUNTRIES[__address.country_id] : '';
      }
    }

    return Address;
  }

  return (
    <div className="info-address bg-white p-4 wrapper h-100">
      <p className="fs-16 fw-medium mb-4">ADDRESS</p>
      <div className="row">
        <div className="col-sm-6">
          <div className="fs-14 fw-medium mb-2">
            <img className="me-2" src={dollar} alt="" /> Billing Address
          </div>
          <p className="info-address-item fs-14 fw-medium ps-4 mb-2">
            {firstname} {lastname}
          </p>
          <p className="info-address-item fs-14 ps-4 mb-0">{!!billAddress?.street && billAddress?.street}</p>
          <p className="info-address-item fs-14 ps-4 mb-0">{!!billAddress?.city && billAddress?.city}</p>
          <p className="info-address-item fs-14 ps-4 mb-0">{!!billAddress?.country && billAddress?.country}</p>
          <div className="fs-14 fw-medium mt-3 mb-2">
            <img className="me-2" src={phone} alt="" /> Phone Number
          </div>
          <p className="info-address-item fs-16 ps-4 mb-0">
            <a href={`tel:${telephone}`} className="text-decoration-none text-dark">
              {telephone}
            </a>
          </p>
        </div>
        <div className="col-sm-6">
          <div className="fs-14 fw-medium mb-2">
            <img className="me-2" src={location} alt="" />
            Shipping Address
          </div>
          <p className="info-address-item fw-medium fs-14 ps-4 mb-2">
            {`${extension_attributes?.shipping_assignments[0]?.shipping.address?.firstname} ${extension_attributes?.shipping_assignments[0]?.shipping?.address?.lastname}`}
          </p>
          <p className="info-address-item fs-14 ps-4 mb-0">{!!shipAddress?.street && shipAddress?.street}</p>
          <p className="info-address-item fs-14 ps-4 mb-0">{!!shipAddress?.city && shipAddress?.city}</p>
          <p className="info-address-item fs-14 ps-4 mb-0">{!!shipAddress?.country && shipAddress?.country}</p>
          <div className="fs-14 fw-medium mt-3 mb-2">
            <img className="me-2" src={phone} alt="" /> Phone Number
          </div>
          <p className="info-address-item fs-16 ps-4 mb-0">
            <a href={`tel:${extension_attributes?.shipping_assignments[0]?.shipping?.address?.telephone}`} className="text-decoration-none text-dark">
              {extension_attributes?.shipping_assignments[0]?.shipping?.address?.telephone}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderAddress;
