import { FC, useEffect, useMemo, useState } from 'react';
import { faUser, faEnvelope, faPhone, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'sweetalert2/src/sweetalert2.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { updateAvatarUser } from '../../redux/action';
import { convertBase64 } from '~/app/utils/convertBase64';
import { uploadAvatarSchema } from '../Schema';
import Loading from '~/components/common/Loading';
import { iPayloadUpdateInfoUser } from '~/app/models';

type Props = {};

const Info: FC<Props> = () => {
  const dispatch = useAppDispatch();

  const { data: infoUser, status, message, updateAvatarStatus, updatePasswordStatus, updateStatus } = useAppSelector((state) => state.userReducer);
  const { email, first_name, image, last_name, phone_number, user_name, user_type } = infoUser;

  const imgPath = process.env.REACT_APP_IMAGE_URL;

  const [thumbnail, setThumbnail] = useState<string>('');

  // const swalConfirm = Swal.mixin({
  //   title: 'Delete Avatar',
  //   text: 'Are you sure?',
  //   showCancelButton: true,
  //   showCloseButton: true,
  //   confirmButtonText: 'ok',
  //   cancelButtonText: 'cancel',
  //   customClass: {
  //     popup: 'p-32px border-rd-25',
  //     closeButton: 'mb-n4 text-dark',
  //     title: 'h3 text-dark text-center m-0 fw-semibold p-0',
  //     htmlContainer: 'text-dark text-center mt-3 mb-0 mx-0 fw-normal',
  //     actions: 'mt-4 w-100',
  //     confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-40',
  //     cancelButton: 'btn btn-primary btn-cancel fs-14 fw-medium btn-border-radius border-rd-25 order-0 w-40',
  //   },
  // });

  const infoFields = useMemo(() => {
    const arr = [
      {
        icon: faUser,
        content: user_name,
      },
      {
        icon: faEnvelope,
        content: email,
      },
    ];

    user_type !== null &&
      arr.splice(2, 0, {
        icon: faPhone,
        content: phone_number ? phone_number : 'N/A',
      });

    return arr;
  }, [email, phone_number, user_name, user_type]);

  const {
    register: registerAvatar,
    // formState: { errors },
    reset,
    handleSubmit: handleSubmitAvatar,
  } = useForm({
    resolver: yupResolver(uploadAvatarSchema),
  });

  useEffect(() => {
    if (
      (updateStatus === 'fulfilled' && updateAvatarStatus !== 'fulfilled') ||
      (updatePasswordStatus === 'fulfilled' && updateAvatarStatus !== 'fulfilled')
    ) {
      reset({ image: '' });
      setThumbnail('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, updatePasswordStatus, updateAvatarStatus, thumbnail, image]);

  registerAvatar('image', {
    onChange: async (e: any) => {
      const file = e.target.files[0];
      try {
        const base64: any = await convertBase64(file);
        setThumbnail(base64);
      } catch (error) {}
    },
  });

  // const handleRemoveAvt = () => {
  //   reset({ image: '' });
  //   setThumbnail('');
  //   swalConfirm.fire({}).then(function (result) {
  //     if (result.isConfirmed) {
  //       dispatch(deleteAvatar());
  //     }
  //   });
  // };

  // const handleRemoveTempAvt = () => {
  //   reset({ image: '' });
  //   setThumbnail('');
  // };

  const onSubmit = () => {
    const dataPayload: iPayloadUpdateInfoUser<{ image: string }> = {
      data: {
        image: thumbnail.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
      },
    };
    dispatch(updateAvatarUser(dataPayload)).then(function () {
      setTimeout(() => {
        reset({ image: '' });
      }, 1000);
    });
    window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const infoContent = (key: number, icon: IconProp, content: string) => {
    return (
      <li className="d-flex mb-3" key={key}>
        <div className="text-left me-3">
          <FontAwesomeIcon icon={icon} />
        </div>
        <span className="title-inline">{content}</span>
      </li>
    );
  };

  return (
    <>
      {status === 'pending' && (
        <div className="card bg-white h-100 border-rd-20">
          <div className="card-body d-flex flex-column align-items-center justify-content-center mt-2">
            <Loading />
          </div>
        </div>
      )}
      {status === 'fulfilled' && (
        <div className="bg-white h-100 p-40 shadow-sm border-0 border-rd-20">
          <div className="row justify-content-center align-content-xl-center">
            <form onSubmit={handleSubmitAvatar(onSubmit)} className="col-6 col-xxl-12 d-flex justify-content-center">
              <div className="d-flex flex-column justify-content-center">
                <div className="thumbnail">
                  <img
                    className="avatar"
                    src={thumbnail && thumbnail ? thumbnail : image && image ? imgPath + image : require('~/app/images/no-avatar.png')}
                    alt="avatar"
                  />
                </div>
                {/* {user_type !== null && (
                  <label className="btn btn-outline-primary rounded-circle p-2 position-relative btn-upload-img">
                    <FontAwesomeIcon icon={faCamera} />
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      className="position-absolute cursor-pointer d-none"
                      {...registerAvatar('image')}
                    />
                  </label>
                )} */}
                {/* {user_type !== null && (
                  <div className="d-flex justify-content-center align-items-center mb-xxl-4 pb-0 pb-xxl-3">
                    <button
                      type="submit"
                      className="btn btn-apply btn-update-profile btn-primary me-3 fw-medium btn-border-radius border-rd-25"
                      disabled={updateAvatarStatus === 'pending'}
                    >
                      {updateAvatarStatus === 'pending' && (
                        <span className="indicator-progress me-2">
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      )}
                      Save Avatar
                    </button>
                    <span
                      className="btn btn-outline-primary btn-square-light px-5 border-rd-25 btn-remove"
                      onClick={image !== null ? () => handleRemoveAvt() : () => handleRemoveTempAvt()}
                    >
                      <FontAwesomeIcon icon={faTrash} className="p-1 m-0 fs-7 border border-2 border-primary rounded-circle" />
                    </span>
                  </div>
                )} */}
              </div>
            </form>
            <div className="col-6 col-xxl-12 d-flex flex-column mt-xxl-4 justify-content-center">
              <h3 className="text-xxl-center fw-semibold text-uppercase mb-0 lh-42">
                {last_name} {first_name}
              </h3>
              <ul className="mx-xxl-auto list-unstyled mt-4">{infoFields.map((field, i) => infoContent(i, field.icon, field.content))}</ul>
            </div>
          </div>
        </div>
      )}
      {status === 'rejected' && (
        <div className="card bg-white h-100 flex-row align-items-center justify-content-center text-danger">
          <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
          {message}
        </div>
      )}
    </>
  );
};

export default Info;
