import { faPaperclip, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NoRecord } from '../../Err';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import AddNewQuickAnswer from './AddNewQuickAnswer';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import moment from 'moment';
import { convertBase64 } from '~/app/utils/convertBase64';
import { formatDate, swalToast } from '~/app/utils';
import { iPostAttachment, iPostMessage } from '~/app/models';
import { postMessage } from '../redux/action';
import { ReactComponent as TicketSend } from '~/app/images/tickets-send.svg';

const HelpDesk = () => {
  const dispatch = useAppDispatch();
  const { listQuickAnswer, dataTicketDetails, dataMessageOfTicketId, statusGetMessageOfTicketId, dataAttachment, statusPostMessage } = useAppSelector(
    (s) => s.ticketReducer,
  );
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { data: dataUser } = useAppSelector((s) => s.userReducer);
  const { subject, customer_id, status_id } = dataTicketDetails;
  const { id: user_id, email: user_email, first_name, last_name } = dataUser;

  const [disabledSendMess, setDisabledSendMess] = useState<boolean>(true);
  const [showPopupAddQuickAnswer, setShowPopupAddQuickAnswer] = useState<boolean>(false);
  const [attachment, setAttachment] = useState<any>();

  const { ticketId = '0' } = useParams();

  const messageEditorRef = useRef<HTMLParagraphElement>(null);
  const messageListRef = useRef<HTMLDivElement>(null);

  const fullName = useMemo(() => last_name && first_name && `${last_name} ${first_name}`, [last_name, first_name]);

  useEffect(() => {
    messageListRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [dataMessageOfTicketId]);

  function handleChangeQuickAnswer(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;

    if (value === 'add-new-quick-answer') {
      setShowPopupAddQuickAnswer(true);
      document.body.style.overflow = 'hidden';
    } else {
      if (messageEditorRef.current) {
        messageEditorRef.current.innerHTML = value;
        handleDisabledButtonSendMess();
      }
    }
  }

  function handleDisabledButtonSendMess() {
    disabledSendMess && setDisabledSendMess(false);
  }

  function handleChangeFileAttachment(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    async function convertBase64Image() {
      if (file) {
        const typeFileAccept = ['image/png', 'image/jpeg'];
        const { type } = file;

        if (!typeFileAccept.includes(type)) {
          swalToast.fire({
            title: "Your photos couldn't be uploaded. Photos should be saved as JPG, PNG, JPEG.",
            icon: 'error',
          });

          // Remove file when have error
          e.target.value = '';
          return;
        }
        try {
          const tailTypeFile = type.split('/')?.[1];

          const fileCovert = await convertBase64(file);
          const newName = `image_${formatDate()}.${tailTypeFile}`;

          const newAttachment = {
            name: newName,
            type: type,
            body: fileCovert,
          };

          setAttachment(newAttachment);
        } catch (error) {}
      }
    }
    convertBase64Image();
  }

  function handlePostMessage() {
    const message = messageEditorRef.current?.innerHTML || '';
    const newMessage = message.replace(/<div><br><\/div>+/g, '');

    if (!newMessage) {
      return;
    }

    setDisabledSendMess(true);

    const payloadMessage: iPostMessage = {
      Message: {
        customer_id: customer_id,
        ticket_id: ticketId,
        user_email,
        user_id: user_id.toString(),
        user_name: fullName,
        body: newMessage,
        body_format: '1',
      },
    };

    let payloadAttachment: iPostAttachment | undefined;
    if (attachment) {
      const { name, type, body } = attachment;
      payloadAttachment = {
        attachment: {
          type,
          name,
          image: body.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
        },
      };
    }

    dispatch(
      postMessage(payloadMessage, payloadAttachment, accessToken, currentStore, messageEditorRef, ticketId, setAttachment, setDisabledSendMess),
    );
  }

  return (
    <>
      {showPopupAddQuickAnswer && (
        <AddNewQuickAnswer
          closePopup={() => {
            setShowPopupAddQuickAnswer(false);
            document.body.style.overflow = 'auto';
            document.body.style.overflow = 'overlay';
          }}
        />
      )}

      <div className="help-desk wrapper d-flex flex-column gap-4 bg-white py-3 h-100">
        {/* Heading */}
        <div className="help-desk-heading d-flex justify-content-between align-items-center gap-4 pb-3 px-3">
          <div className="d-flex align-items-center gap-3 left">
            <FontAwesomeIcon icon={faCircleQuestion} />
            <p className="m-0 fw-medium">{subject}</p>
          </div>
        </div>

        {/* List message */}
        <div className="help-desk-body d-flex flex-column gap-4 flex-grow-1 ps-3 pe-12 me-2">
          {dataMessageOfTicketId.length
            ? dataMessageOfTicketId.map((item, index: number) => {
                const { user_name, customer_name, created_at, body, message_id } = item;
                const isAdmin = user_name ? true : false;
                const name = isAdmin ? user_name : customer_name;

                const attachment = dataAttachment.find((attachment) => attachment.message_id === message_id);

                return (
                  <div className={`help-desk-message d-flex gap-3 ${isAdmin ? 'admin' : 'customer'}`} key={index} ref={messageListRef}>
                    <div className="d-flex flex-column gap-3 mess-info">
                      <p className="m-0 fw-medium fs-14 mess-heading d-flex align-items-end text-nowrap gap-3">
                        <span className="name">
                          {isAdmin ? ' User: ' : 'Customer: '}
                          {name}
                        </span>
                        <span className="time-at text-dark-600 fw-normal">
                          add {moment(created_at).fromNow()} ({moment(created_at).format('MM/DD/YYYY LTS')})
                        </span>
                      </p>

                      <div className="mess p-3 pe-4 fs-14 ">
                        <p className="mb-0 text-dark-600" dangerouslySetInnerHTML={{ __html: body }} />
                        {attachment && (
                          <a href={`${process.env.REACT_APP_IMAGE_URL}/${attachment.body}`} target="_blank" rel="noopener noreferrer">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}/${attachment.body}`}
                              alt={attachment.name}
                              className="image-attachment mt-12"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            : statusGetMessageOfTicketId && (
                <table className="w-100">
                  <tbody>
                    <NoRecord tableHeaders={[]} />
                  </tbody>
                </table>
              )}
        </div>

        {/* Editor */}
        {(status_id === '1' || status_id === '2') && (
          <div className="help-desk-footer mt-auto p-3 mx-3">
            <div className="heading d-flex justify-content-between align-items-center gap-2 gap-xl-4">
              <div className="actions d-flex align-items-center">
                <div>
                  <input type="file" hidden accept="image/png, image/jpeg" id="upload-attachment" onChange={handleChangeFileAttachment} />
                  <label htmlFor="upload-attachment">
                    <FontAwesomeIcon icon={faPaperclip} className="cursor-pointer add-link text-dark-600" />
                  </label>
                </div>

                <select value="" className="fs-14 text-dark-600 quick-answer" onChange={handleChangeQuickAnswer}>
                  <option hidden>Quick Answer</option>
                  {listQuickAnswer
                    .filter((item) => item.is_active === '1')
                    .map((item) => {
                      const { content, title, quickanswer_id } = item;
                      const label = title.length > 40 ? `${title.substring(0, 40)}...` : title;
                      return (
                        <option value={content} key={quickanswer_id}>
                          {label}
                        </option>
                      );
                    })}
                  <option value="add-new-quick-answer">+ Add New</option>
                </select>
              </div>
            </div>

            <div className="footer-edit d-flex align-items-center justify-content-between gap-4 w-100">
              <div className="flex-grow-1">
                <p
                  className="border-1 message fs-14 m-0 py-2 disabled"
                  id="edit-message"
                  contentEditable={statusPostMessage === 'pending' ? false : true}
                  data-placeholder="Message..."
                  ref={messageEditorRef}
                  onKeyPress={(e: any) => {
                    const value = e.target.innerText;

                    disabledSendMess && value && setDisabledSendMess(false);

                    if (e.key === 'Enter' && value.trim() === '') {
                      e.preventDefault();
                      e.stopPropagation(); // Stop default handling
                    }
                  }}
                  onPaste={(e: any) => {
                    e.preventDefault();

                    const text = e.clipboardData ? (e.originalEvent || e).clipboardData.getData('text/plain') : '';

                    text && setDisabledSendMess(false);
                    if (document.queryCommandSupported('insertText')) {
                      document.execCommand('insertText', false, text);
                    } else {
                      // Insert text at the current position of caret
                      const range = document.getSelection()?.getRangeAt(0);

                      if (range) {
                        range.deleteContents();

                        const textNode = document.createTextNode(text);
                        range.insertNode(textNode);
                        range.selectNodeContents(textNode);
                        range.collapse(false);

                        const selection = window.getSelection();
                        selection?.removeAllRanges();
                        selection?.addRange(range);
                      }
                    }
                  }}
                  onInput={(e: any) => {
                    const value = e.target.innerText;

                    if (value.trim()) {
                      disabledSendMess && setDisabledSendMess(false);
                    } else {
                      !disabledSendMess && setDisabledSendMess(true);
                    }
                  }}
                  onBlur={(e) => {
                    const value = e.target.innerText;

                    if (value.trim() === '') {
                      e.target.innerHTML = '';
                      messageEditorRef.current?.setAttribute('data-placeholder', 'Message...');
                    }
                  }}
                />
                {attachment && (
                  <p className="attachment mb-0 fs-7 p-2 py-1 rounded-pill d-flex align-items-center gap-2 w-fit-content">
                    {attachment.name}
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="close-icon cursor-pointer"
                      onClick={() => {
                        setAttachment(undefined);
                      }}
                    />
                  </p>
                )}
              </div>
              <button
                className="plane-icon d-flex justify-content-center align-items-center cursor-pointer"
                disabled={disabledSendMess}
                onClick={handlePostMessage}
              >
                <TicketSend />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HelpDesk;
