import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status, iCategoryItem, iCategoryTreeItem, iCurrentAction, iInitState, iProductListing } from '~/app/models';

interface iState extends iInitState {
  statusCreateCategory: Status;
  statusGetCategoryById: Status;
  statusEditCategoryById: Status;
  statusDeleteCategoryById: Status;
  statusSaveChangeProductsInCategory: Status;
  data: iProductListing;
  message: string;
  urlData: {
    pageSize: number;
    currentPage: number;
  };
  queryString: string;
  isDESC: boolean;
  keySearch: string;
  controller: null | AbortController;
  formData: iCategoryItem | null;
  currentAction: iCurrentAction;
  parentCategory: iCategoryTreeItem | null;
  currentCategoryId: number | null;
  oldListSkuOfCategory: null | string[];
  currentListSkuOfCategory: string[];
}

const initialState: iState = {
  status: '',
  statusCreateCategory: '',
  statusGetCategoryById: '',
  statusEditCategoryById: '',
  statusDeleteCategoryById: '',
  statusSaveChangeProductsInCategory: '',
  controller: null,
  data: { items: [], search_criteria: {}, total_count: 0 } as iProductListing,
  urlData: { pageSize: 10, currentPage: 1 },
  message: '',
  isDESC: false,
  keySearch: 'entity_id',
  queryString: '',
  formData: null,
  currentAction: 'create',
  parentCategory: null,
  currentCategoryId: null,
  oldListSkuOfCategory: null,
  currentListSkuOfCategory: [],
};

const categorySlice = createSlice({
  name: 'CATEGORY LIST',
  initialState,
  reducers: {
    createCategoryPending: (state) => ({ ...state, statusCreateCategory: 'pending' }),
    createCategoryRejected: (state) => ({ ...state, statusCreateCategory: 'rejected' }),
    createCategoryFulfilled: (state) => ({ ...state, statusCreateCategory: 'fulfilled' }),

    getCategoryByIdPending: (state) => ({ ...state, statusGetCategoryById: 'pending' }),
    getCategoryByIdRejected: (state) => ({ ...state, statusGetCategoryById: 'rejected' }),
    getCategoryByIdFulfilled: (state) => ({ ...state, statusGetCategoryById: 'fulfilled' }),

    editCategoryByIdPending: (state) => ({ ...state, statusEditCategoryById: 'pending' }),
    editCategoryByIdRejected: (state) => ({ ...state, statusEditCategoryById: 'rejected' }),
    editCategoryByIdFulfilled: (state) => ({ ...state, statusEditCategoryById: 'fulfilled' }),

    deleteCategoryByIdPending: (state) => ({ ...state, statusDeleteCategoryById: 'pending' }),
    deleteCategoryByIdRejected: (state) => ({ ...state, statusDeleteCategoryById: 'rejected' }),
    deleteCategoryByIdFulfilled: (state) => ({ ...state, statusDeleteCategoryById: 'fulfilled' }),

    saveChangeProductsInCategoryPending: (state) => ({ ...state, statusSaveChangeProductsInCategory: 'pending' }),
    saveChangeProductsInCategoryRejected: (state) => ({ ...state, statusSaveChangeProductsInCategory: 'rejected' }),
    saveChangeProductsInCategoryFulfilled: (state) => ({ ...state, statusSaveChangeProductsInCategory: 'fulfilled' }),

    setOldListSkuOfCategory: (state, { payload }: PayloadAction<string[] | null>) => ({ ...state, oldListSkuOfCategory: payload }),
    setCurrentListSkuOfCategory: (state, { payload }: PayloadAction<string[]>) => ({ ...state, currentListSkuOfCategory: payload }),
    setCurrentCategoryId: (state, { payload }: PayloadAction<number>) => ({ ...state, currentCategoryId: payload }),
    // Synchronous Actions
    resetData: () => ({ ...initialState }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),

    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),
    setCurrentAction: (state, action: PayloadAction<iCurrentAction>) => ({ ...state, currentAction: action.payload }),
    setFormCategory: (state, action: PayloadAction<iCategoryItem | null>) => ({ ...state, formData: action.payload }),
    setParentCategory: (state, action: PayloadAction<iCategoryTreeItem | null>) => ({ ...state, parentCategory: action.payload }),
    changeController: (state, action) => ({ ...state, controller: action.payload }),

    // Asynchronous Actions
    getDataFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getDataRejected: (state, action) => ({ ...state, status: 'rejected', message: action.payload }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),
    resetQueryString: (state) => ({ ...state, queryString: '' }),
  },
});

export const { ...rest } = categorySlice.actions;
const categoryReducer = categorySlice.reducer;

export default categoryReducer;
