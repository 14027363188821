import {
  iGetAllOrder,
  iGetCreditMemosDetail,
  iGetDataById,
  iGetInvoiceDetail,
  iGetOrderData,
  iGetShipmentDetail,
  iOrderCommnent,
  iPayloadChangeStatusOrder,
  iTrackingNumber,
} from '../models';
import axiosConfig from './axiosConfig';

const orderApi = {
  getAllOrders: (payload: iGetAllOrder, controller: AbortController) => {
    const { searchUrl, currentStore } = payload;
    const url = `${currentStore}/V1/orders?${searchUrl}`;
    return axiosConfig.get(url, { signal: controller.signal });
  },

  getOrderDetails: (payload: iGetDataById, store: string = 'all') => {
    const { id } = payload;
    const url = `${store}/V1/orders/${id}`;
    return axiosConfig.get(url);
  },

  getInvoicesByOrderId: (payload: iGetOrderData, store: string = 'all') => {
    const { searchURL } = payload;
    const url = `${store}/V1/invoices?${searchURL}`;
    return axiosConfig.get(url);
  },

  getInvoiveDetail: (payload: iGetInvoiceDetail, store: string = 'all') => {
    const { entity_id } = payload;
    const url = `${store}/V1/invoices/${entity_id}`;
    return axiosConfig.get(url);
  },

  getCreditMemos: (payload: iGetOrderData, store: string = 'all') => {
    const { searchURL } = payload;
    const url = `${store}/V1/creditmemos?${searchURL}`;
    return axiosConfig.get(url);
  },

  getCreditMemosDetail: (payload: iGetCreditMemosDetail, store: string = 'all') => {
    const { entity_id } = payload;
    const url = `${store}/V1/creditmemo/${entity_id}`;
    return axiosConfig.get(url);
  },

  getShipments: (payload: iGetOrderData, store: string = 'all') => {
    const { searchURL } = payload;
    const url = `${store}/V1/shipments?${searchURL}`;
    return axiosConfig.get(url);
  },

  getShipmentsDetail: (payload: iGetShipmentDetail, store: string = 'all') => {
    const { entity_id } = payload;
    const url = `${store}/V1/shipment/${entity_id}`;
    return axiosConfig.get(url);
  },

  getOrderItems: (payload: any, store: string = 'all') => {
    const { entity_id } = payload;
    const url = `${store}/orders/items/${entity_id}`;
    return axiosConfig.get(url);
  },
  postOrderComment: (payload: iOrderCommnent, store: string = 'all') => {
    const { id, is_customer_notified, comment, is_visible_on_front, status } = payload;
    const url = `${store}/V1/orders/${id}/comments`;
    const dataPayload = {
      statusHistory: {
        comment,
        is_customer_notified: is_customer_notified ? 1 : 0,
        is_visible_on_front: is_visible_on_front ? 1 : 0,
        status,
      },
    };
    return axiosConfig.post(url, dataPayload);
  },

  postInvoiceComment: (payload: iOrderCommnent, store: string = 'all') => {
    const { is_customer_notified, comment, is_visible_on_front, entity_id } = payload;
    const url = `${store}/V1/invoices/comments`;
    const dataPayload = {
      entity: {
        comment,
        is_customer_notified: is_customer_notified ? 1 : 0,
        is_visible_on_front: is_visible_on_front ? 1 : 0,
        parent_id: entity_id,
      },
    };
    return axiosConfig.post(url, dataPayload);
  },

  postMemoComment: (payload: iOrderCommnent, store: string = 'all') => {
    const { is_customer_notified, comment, is_visible_on_front, entity_id } = payload;
    const url = `${store}/V1/creditmemo/${entity_id}/comments`;
    const dataPayload = {
      entity: {
        comment,
        is_customer_notified: is_customer_notified ? 1 : 0,
        is_visible_on_front: is_visible_on_front ? 1 : 0,
        parent_id: entity_id,
      },
    };
    return axiosConfig.post(url, dataPayload);
  },

  postShipmentComment: (payload: iOrderCommnent, store: string = 'all') => {
    const { is_customer_notified, comment, is_visible_on_front, entity_id } = payload;
    const url = `${store}/V1/shipment/${entity_id}/comments`;
    const dataPayload = {
      entity: {
        comment,
        is_customer_notified: is_customer_notified ? 1 : 0,
        is_visible_on_front: is_visible_on_front ? 1 : 0,
        parent_id: entity_id,
      },
    };
    return axiosConfig.post(url, dataPayload);
  },

  getNotesHistoty: (payload: { id: number; accessToken: string }) => {
    const { id } = payload;
    const url = `all/V1/addin/${id}/comments`;
    return axiosConfig.get(url);
  },

  createTrackingNumber: (payload: iTrackingNumber, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/shipment/track`;
    return axiosConfig.post(url, payload);
  },

  deleteTrackingNumber: (id: number, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/shipment/track/${id}`;
    return axiosConfig.delete(url);
  },

  createShipment: (order_id: number, payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/order/${order_id}/ship`;
    return axiosConfig.post(url, payload);
  },

  createRefund: (order_id: number, payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/order/${order_id}/refund`;
    return axiosConfig.post(url, payload);
  },

  createInvoice: (order_id: number, payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/order/${order_id}/invoice`;
    return axiosConfig.post(url, payload);
  },

  cancelOrder: (payload: iPayloadChangeStatusOrder) => {
    const { currentStore, orderId } = payload;
    const url = `${currentStore}/V1/orders/${orderId}/cancel`;
    return axiosConfig.post(url, null);
  },
  holdOrder: (payload: iPayloadChangeStatusOrder) => {
    const { currentStore, orderId } = payload;
    const url = `${currentStore}/V1/orders/${orderId}/hold`;
    return axiosConfig.post(url, null);
  },
  unHoldOrder: (payload: iPayloadChangeStatusOrder) => {
    const { currentStore, orderId } = payload;
    const url = `${currentStore}/V1/orders/${orderId}/unhold`;
    return axiosConfig.post(url, null);
  },
};

export default orderApi;
