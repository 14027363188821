import { iPayloadUpdateInfoUser } from '../models';
import axiosConfig from './axiosConfig';

const userApi = {
  getInfo: () => {
    const url = `all/V1/addin/user/me`;
    return axiosConfig.get(url);
  },
  updateInfo: (payload: iPayloadUpdateInfoUser) => {
    const url = `all/V1/addin/user/update`;
    return axiosConfig.post(url, payload);
  },
  deleteAvatar: () => {
    const url = `all/V1/addin/user/delete/avata`;
    return axiosConfig.post(url);
  },
  getAllStores: () => {
    const url = 'all/V1/store/storeViews';
    return axiosConfig.get(url);
  },
  getAllWebsites: () => {
    const url = 'all/V1/store/websites';
    return axiosConfig.get(url);
  },
};

export default userApi;
