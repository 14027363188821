import { createSlice } from '@reduxjs/toolkit';
import { iInitState, iProductListing } from '~/app/models';

interface iState extends iInitState {
  data: iProductListing;
  dataDetails: any;
  message: string;
  urlData: {
    pageSize: number;
    currentPage: number;
  };
  queryString: string;
  isDESC: boolean;
  keySearch: string;
  controller: any;
}

const initialState: iState = {
  status: '',
  controller: null,
  data: { items: [], search_criteria: {}, total_count: 0 } as iProductListing,
  urlData: { pageSize: 10, currentPage: 1 },
  dataDetails: {},
  message: '',
  isDESC: false,
  keySearch: 'entity_id',
  queryString: '',
};

const productSlice = createSlice({
  name: 'PRODUCT LIST',
  initialState,
  reducers: {
    // Synchronous Actions
    resetData: () => ({ ...initialState }),
    resetDataAndStatus: (state) => ({ ...state, status: '', data: initialState.data }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),

    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),

    changeController: (state, action) => ({ ...state, controller: action.payload }),

    // Asynchronous Actions
    getDataFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getDataRejected: (state, action) => ({ ...state, status: 'rejected', message: action.payload }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),
    resetQueryString: (state) => ({ ...state, queryString: '' }),
  },
});

export const { ...rest } = productSlice.actions;
const productReducer = productSlice.reducer;

export default productReducer;
