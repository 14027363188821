import { iApplyAllStore, iProductAttributeItem, iDataAdvPrice, iDataHeader, iImage, iProduct, iSearchOptimize, iVideo } from '~/app/models';
import { v4 as uuidv4 } from 'uuid';
import { convertDescriptionToPayload, slugify } from '~/app/utils';

export function handleChangeProductLink(
  dataRelatedSelected: iProduct[],
  dataUpSellSelected: iProduct[],
  dataCrossSellSelected: iProduct[],
  skuProduct: string,
) {
  const newRelatedList = dataRelatedSelected.map((item, index: number) => ({
    sku: skuProduct,
    linked_product_type: item.type_id,
    link_type: 'related',
    linked_product_sku: item.sku,
    position: index,
  }));
  const newUpSellList = dataUpSellSelected.map((item, index: number) => ({
    sku: skuProduct,
    linked_product_type: item.type_id,
    link_type: 'upsell',
    linked_product_sku: item.sku,
    position: index,
  }));
  const newCrossSellList = dataCrossSellSelected.map((item, index: number) => ({
    sku: skuProduct,
    linked_product_type: item.type_id,
    link_type: 'crosssell',
    linked_product_sku: item.sku,
    position: index,
  }));

  return { product_links: [...newRelatedList, ...newUpSellList, ...newCrossSellList] };
}

export function handleChangeListTierPrice(listTierPrice: any, priceProduct: string | number) {
  const newListTierPrice = listTierPrice.map((item: any) => {
    const { id, customer_group_id, website_id } = item;
    const percent = item[`percentage_value${id}`];
    const value = item[`value${id}`];
    const qty = item[`qty${id}`];

    return {
      customer_group_id,
      qty: +qty,
      value: percent === undefined ? +value : +priceProduct - +priceProduct * percent,
      extension_attributes: {
        website_id: website_id,
        percentage_value: percent,
      },
    };
  });

  return { tier_prices: newListTierPrice };
}

export function handleChangeStockItem(
  dataAdvInventory: any,
  quantity_and_stock_status: string | number,
  qty: string | number,
  dataProductGenerate: any[],
  listProductAssociated: any[],
) {
  const {
    backorders,
    enable_qty_increments,
    is_decimal_divided,
    is_qty_decimal,
    manage_stock,
    max_sale_qty,
    min_qty,
    min_sale_qty,
    notify_stock_qty,
    qty_increments,
    use_config_min_sale_qty,
  } = dataAdvInventory;

  return {
    ...dataAdvInventory,
    manage_stock: !!+manage_stock,
    min_qty: +min_qty,
    use_config_min_sale_qty: use_config_min_sale_qty ? 1 : 0,
    min_sale_qty: +min_sale_qty,
    max_sale_qty: +max_sale_qty,
    is_decimal_divided: !!+is_decimal_divided,
    is_qty_decimal: !!+is_qty_decimal,
    backorders: +backorders,
    notify_stock_qty: +notify_stock_qty,
    qty_increments: +qty_increments,
    enable_qty_increments: !!+enable_qty_increments,
    is_in_stock:
      dataProductGenerate.concat(listProductAssociated).length > 0
        ? !!+quantity_and_stock_status
        : +qty - +min_qty <= 0
        ? false
        : !!+quantity_and_stock_status,
    qty: +qty || 0,
  };
}

export function handleChangeCustomAttr(
  dataRelatedAttrByAttrSet: iProductAttributeItem[],
  dataAdvPrice: iDataAdvPrice,
  dataHeader: iDataHeader,
  dataAttrList: any,
  dataSearchOptimize: iSearchOptimize,
  listApplyAllStores: iApplyAllStore,
  currentAction: 'create' | 'edit',
) {
  const { meta_keyword, meta_title, url_key } = dataSearchOptimize;

  const newDataSearchOptimize = {
    meta_keyword: currentAction === 'edit' && listApplyAllStores.meta_keyword ? null : meta_keyword,
    meta_title: currentAction === 'edit' && listApplyAllStores.meta_title ? null : meta_title,
    url_key: currentAction === 'edit' && listApplyAllStores.url_key ? null : url_key ? slugify(url_key) : null,
  };

  const list = [
    'status',
    'shipment_type',
    'page_layout',
    'gift_message_available',
    'options_container',
    'custom_layout_update_file',
    'custom_design',
    'custom_layout',
    'tax_class_id',
    'msrp_display_actual_price_type',
    'quantity_and_stock_status',
    'price_view',
    'visibility',
    'country_of_manufacture',
    'product_image_size',
    'product_page_type',
  ];

  const { sw_featured, tax_class_id, country_of_manufacture, short_description, description: descriptionCk5 } = dataHeader;

  const description = convertDescriptionToPayload(descriptionCk5);

  const listAttrCode = dataRelatedAttrByAttrSet
    .filter((attr: any) => attr.frontend_input === 'select')
    .filter((attr: any) => !list.includes(attr.attribute_code))
    .map((attr: any) => attr.attribute_code);

  const listAttr = {
    sw_featured,
    tax_class_id,
    country_of_manufacture,
    short_description: currentAction === 'edit' && listApplyAllStores.description ? null : short_description,
    description: currentAction === 'edit' && listApplyAllStores.short_description ? null : description,
    ...newDataSearchOptimize,
    ...dataAdvPrice,
    ...(Object.fromEntries(Object.entries(dataAttrList).filter((item) => listAttrCode.includes(item[0]))) || {}),
  };

  const newCustomAttrList = Object.entries(listAttr).map((item) => ({
    attribute_code: item[0],
    value: item[1] === '' || item[1] === undefined || item[1] === null ? null : item[1] === true ? '1' : item[1] === false ? '0' : item[1],
  }));

  return newCustomAttrList;
}

export function handleChangeListMedia(dataImage: iImage[], dataVideo: iVideo[]) {
  const newListImg = dataImage.map((item: any, index: number) => {
    const { file, type, label, id, types, name } = item;
    return id
      ? { ...item, position: index }
      : {
          media_type: 'image',
          label: label ? label : name?.split('.')[0] || '',
          position: index,
          disabled: false,
          types,
          content: {
            type: type,
            name: name,
            base64_encoded_data: file.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
          },
        };
  });

  const newListVideo = dataVideo.map((item: any, index: number) => {
    const { file, id, types, extension_attributes, label } = item;

    const videoTitle = extension_attributes.video_content.video_title;
    const name =
      videoTitle.replace(/[&/\\#,+()$~%'":*?<>{}|]/g, '').split('.')[0].length === 0
        ? `${uuidv4()}`
        : videoTitle.replace(/[&/\\#,+()$~%'":*?<>{}|]/g, 'default_name');

    return id
      ? item
      : {
          media_type: 'external-video',
          label,
          position: index,
          disabled: false,
          types,
          content: {
            type: 'image/jpeg',
            name: `${name.split(' ').join('-')}.jpg`,
            base64_encoded_data: file.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
          },
          extension_attributes: { ...extension_attributes },
        };
  });

  const newMedia = [...newListImg, ...newListVideo].map((item: any, index: number) => ({ ...item, position: index }));

  return newMedia;
}

export function handleChangeListConfigOption(
  listAttrOfProductConfig: iProductAttributeItem[],
  dataRelatedAttrByAttrSet: iProductAttributeItem[],
  dataValuesConfigSelected: any,
) {
  function filterAttrOfProductConfigExistAttrSet() {
    const list = [
      'status',
      'shipment_type',
      'page_layout',
      'gift_message_available',
      'options_container',
      'custom_layout_update_file',
      'custom_design',
      'custom_layout',
      'tax_class_id',
      'msrp_display_actual_price_type',
      'quantity_and_stock_status',
      'price_view',
      'visibility',
      'country_of_manufacture',
      'product_image_size',
      'product_page_type',
    ];

    const listAttrIdOfAttrSet = dataRelatedAttrByAttrSet
      .filter((attr: any) => attr.frontend_input === 'select' && !list.includes(attr.attribute_code))
      .map((attr: any) => attr.attribute_id);

    const listAttrOfProductConfigExistAttrSet = listAttrOfProductConfig.filter((attr) => listAttrIdOfAttrSet.includes(attr.attribute_id));

    return listAttrOfProductConfigExistAttrSet;
  }

  const listAttrOfProductConfigExistAttrSet = filterAttrOfProductConfigExistAttrSet();

  const listConfigOption = listAttrOfProductConfigExistAttrSet
    .filter((item) => Object.keys(dataValuesConfigSelected).includes(`${item.default_frontend_label}-id${item.attribute_id}`))
    .map((item, index: number) => {
      const listValue: any = dataValuesConfigSelected[`${item.default_frontend_label}-id${item.attribute_id}`] || [];

      const newListValue = listValue.map((option: any) => ({ value_index: option.value }));
      return {
        attribute_id: item.attribute_id,
        label: item.default_frontend_label,
        position: index,
        values: newListValue,
      };
    });

  return listConfigOption;
}

export function handleChangeImageProductChildOfConfig(image: any) {
  return image
    ? [
        {
          media_type: 'image',
          label: image.label ? image.label : image.name?.split('.')[0] || '',
          position: 0,
          types: ['image', 'small_image', 'thumbnail'],
          disabled: false,
          content: {
            type: image.type,
            name: image.name,
            base64_encoded_data: image.file.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
          },
        },
      ]
    : [];
}
