import { iStoreViewItem } from '../models';

export function checkSite(code_store: string = '') {
  const code = code_store?.split('_')[0];
  switch (code) {
    case 'sg':
      return process.env.REACT_APP_URL;
    case 'my':
      return process.env.REACT_APP_URL_MY;
    case 'hk':
      return process.env.REACT_APP_URL_HK;
    case 'vn':
      return process.env.REACT_APP_URL_VN;
    case 'tw':
      return process.env.REACT_APP_URL_TW;
    case 'id':
      return process.env.REACT_APP_URL_ID;
    case 'th':
      return process.env.REACT_APP_URL_TH;
    case 'ph':
      return process.env.REACT_APP_URL_PH;
    default:
      return process.env.REACT_APP_URL;
  }
}

//Declare get Store ID function
export function getStoreId(allStore: iStoreViewItem[], currentStore: string) {
  if (allStore && Array.isArray(allStore)) {
    const store = allStore.find((store: any) => store.code === currentStore);
    return store?.id;
  }
}

//Declare get Website ID function
export function getWebsiteId(allStore: iStoreViewItem[], currentStore: string) {
  if (allStore && Array.isArray(allStore)) {
    const store = allStore.find((store: any) => store.code === currentStore);
    return store?.website_id;
  }
}
