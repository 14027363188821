import { FC, ReactNode } from 'react';
import './style.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const Separator: FC<Props> = ({ children, className = '' }) => {
  return <div className={`separator py-3 ${className}`}>{children}</div>;
};

export default Separator;
