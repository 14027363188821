import promotionApi from '~/app/apis/promotion.api';
import { iGetAllPromotion } from './../../../../app/models/promotion';
import * as types from './index';

const { rest } = types;

export const getData = (payload: iGetAllPromotion, cancelController: AbortController) => async (dispatch: any) => {
  cancelController && cancelController.abort();
  dispatch(rest.getDataPending());
  const controller = new AbortController();
  dispatch(rest.changeController(controller));
  try {
    const { data } = await promotionApi.getAll(payload, controller);

    // const { items: promotionList } = data;

    // const listRuleId = promotionList
    //   .filter((item: any) => item.coupon_type === 'SPECIFIC_COUPON' && !item.use_auto_generation)
    //   .map((item: any) => item.rule_id);

    // try {
    //   const getPayload = {
    //     searchUrl: searchCriteriaBuilder(
    //       Number.MAX_SAFE_INTEGER,
    //       1,
    //       addFilterGroup(addFilters(addFilterItem('rule_id', listRuleId.join(','), 'in'))),
    //     ),
    //     currentStore: payload.currentStore,
    //   };
    //   const { data } = await promotionApi.getCouponCode(getPayload);
    //   dispatch(rest.getCouponCode(data));
    // } catch (err) {
    // } finally {
    // }
    !data ? dispatch(rest.getDataRejected('')) : dispatch(rest.getDataFulfilled(data));
  } catch (error: any) {
    if (error.message === 'canceled') return;
    dispatch(rest.getDataRejected(''));
  }
};

export const setUrlData = (payload: { pageSize: number; currentPage: number }) => (dispatch: any) => {
  dispatch(rest.setUrlData(payload));
};
export const setIsDESC = (isDESC: boolean) => (dispatch: any) => {
  dispatch(rest.setIsDESC(isDESC));
};

export const setKeySearch = (keySearch: string) => (dispatch: any) => {
  dispatch(rest.setKeySearch(keySearch));
};

export const setQueryString = (q: string) => (dispatch: any) => {
  dispatch(rest.setQueryString(q));
};

export const resetPromotionQueryString = () => (dispatch: any) => {
  dispatch(rest.resetQueryString());
};
