import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { dataOrderHead, timeConvert } from '~/app/constants';
import {
  addFilterGroup,
  addFilterItem,
  addFilters,
  addSortOrder,
  filterEmptyProperties,
  formatMoney,
  getStoreId,
  searchCriteriaBuilder,
} from '~/app/utils';
import { builderCriteriaOrder } from '~/app/utils/builderCriteria';
import { convertStringToNumber } from '~/app/utils/convertBase64';
import { sorterCriteria } from '~/app/utils/sorterCriteria';
import Pagination from '~/components/common/Pagination';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { Table } from '../../common/Table';
import DefaultLayout from '../../layout/DefaultLayout';
import { NoRecord } from '../Err';
import { getAllOrders, setIsDESC, setKeySearch, setQueryString } from './redux/actions';
import {
  NumberFromToFilter,
  TextFilter,
  SelectFilter,
  FilterButtons,
  DateRangeFilter,
  NumberFilter,
  ResetFilterButton,
} from '~/components/common/Filter/filterInput';
import { formatFilterActive, formatStatus } from '~/components/common/Filter/formatFilter';
import { paymentStatusOptions } from '~/app/constants/filter';
import SearchBar from '~/components/common/Filter/searchBar';
import { iGetAllOrder } from '~/app/models';
import './style.scss';
import Separator from '~/components/common/Separator';

type FormFilterValues = {
  id: string;
  customerEmail: string;
  purchaseDateFrom: string;
  purchaseDateTo: string;
  baseGrandTotalFrom: string;
  baseGrandTotalTo: string;
  totalRefundedFrom: string;
  totalRefundedTo: string;
  filterStatus: string;
  searchTerm: string;
};

const Order: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Declare data from Reducers
  const { data, status, message, isDESC, keySearch, queryString, controller } = useAppSelector((state) => state.orderReducer);
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { storeData } = useAppSelector((s) => s.userReducer);
  const { items, total_count } = data;

  const sort = sorterCriteria(keySearch, isDESC);

  // Declare data from Query String
  const isFilter = Boolean(searchParams.get('isFilter'));
  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));

  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;
  const id = searchParams.get('id') || '';
  const customerEmail = searchParams.get('customerEmail') || '';
  const purchaseDateFrom = searchParams.get('purchaseDateFrom') || '';
  const purchaseDateTo = searchParams.get('purchaseDateTo') || '';
  const baseGrandTotalFrom = searchParams.get('baseGrandTotalFrom') || '';
  const baseGrandTotalTo = searchParams.get('baseGrandTotalTo') || '';
  const totalRefundedFrom = searchParams.get('totalRefundedFrom') || '';
  const totalRefundedTo = searchParams.get('totalRefundedTo') || '';
  const filterStatus = searchParams.get('filterStatus') || '';
  const searchTerm = searchParams.get('searchTerm') || '';

  // Declare initial Filter Payload
  const initialFilterPayload = {
    id,
    customerEmail,
    purchaseDateFrom,
    purchaseDateTo,
    baseGrandTotalFrom,
    baseGrandTotalTo,
    totalRefundedFrom,
    totalRefundedTo,
    filterStatus,
    searchTerm,
  } as FormFilterValues;

  // Declare useState
  const [filterPayload, setFilterPayload] = useState<any>(initialFilterPayload);
  const [submitFilterPayload, setSubmitFilterPayload] = useState<any>(initialFilterPayload);
  const [query, setQuery] = useState<string>(window.location.search);
  const [firstMounted, setFirstMounted] = useState<Boolean>(true);
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>(false);
  const [isEnableFilterBoard, setIsEnableFilterBoard] = useState<boolean>(false);
  const [storeId, setStoreId] = useState<number>();

  //Change Filter Handler
  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  // Declare useEffect
  useEffect(() => {
    setStoreId(getStoreId(storeData, currentStore));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, currentStore]);

  useEffect(() => {
    setFirstMounted(false);
    return () => {
      controller && controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstMounted) return;
    setFilterPayload({
      ...filterPayload,
      id: '',
      customerEmail: '',
      purchaseDateFrom: '',
      purchaseDateTo: '',
      baseGrandTotalFrom: '',
      baseGrandTotalTo: '',
      totalRefundedFrom: '',
      totalRefundedTo: '',
      filterStatus: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      id: '',
      customerEmail: '',
      purchaseDateFrom: '',
      purchaseDateTo: '',
      baseGrandTotalFrom: '',
      baseGrandTotalTo: '',
      totalRefundedFrom: '',
      totalRefundedTo: '',
      filterStatus: '',
      searchTerm: '',
    });
    setIsEnableFilterBoard(false);
    setSearchParams({});
    setQuery('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    dispatch(setQueryString(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, queryString]);

  useEffect(() => {
    const filter = builderCriteriaOrder(submitFilterPayload);

    const payload: iGetAllOrder = {
      accessToken: accessToken,
      searchUrl: searchCriteriaBuilder(
        convertStringToNumber(pageSize.toString(), 10),
        convertStringToNumber(currentPage.toString(), 1),
        addFilterGroup(...filter, addFilters(addFilterItem('store_id', `${storeId}`, 'eq'))),
        addSortOrder(...sort),
      ),
      currentStore: currentStore,
    };

    if ((isFilter || isAdvancedFilter || (!isFilter && !isAdvancedFilter)) && storeId) {
      dispatch(getAllOrders(payload, controller));
    }

    if (Object.keys(filterEmptyProperties(submitFilterPayload)).length === 0) {
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, storeData, currentStore, storeId, isDESC, query, keySearch, isFilterSubmit, queryString]);

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isFilter) {
      setSearchParams({
        pageSize: value,
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  // Change current Page
  const onChangePage = (currentPage: number) => {
    if (isFilter) {
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: pageSize.toString(), currentPage: currentPage.toString() });
      resetUrlData();
    }
  };

  //Declare reset function
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      id: '',
      customerEmail: '',
      purchaseDateFrom: '',
      purchaseDateTo: '',
      baseGrandTotalFrom: '',
      baseGrandTotalTo: '',
      totalRefundedFrom: '',
      totalRefundedTo: '',
      filterStatus: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      id: '',
      customerEmail: '',
      purchaseDateFrom: '',
      purchaseDateTo: '',
      baseGrandTotalFrom: '',
      baseGrandTotalTo: '',
      totalRefundedFrom: '',
      totalRefundedTo: '',
      filterStatus: '',
      searchTerm: '',
    });
    setSearchParams({});
    resetUrlData();
    !isEnableFilterBoard && setIsEnableFilterBoard(true);
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${key}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${key}`]: '' });
    setSubmitFilterPayload({ ...filterPayload, [`${key}`]: '' });
    resetUrlData();
  };

  const resetFilterFromToSection = (keyFrom: string, keyTo: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${keyFrom}`]: '',
      [`${keyTo}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    setSubmitFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    resetUrlData();
  };

  //Declare submit filter function
  const searchKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = e;
    const filteredObject = filterEmptyProperties(filterPayload);
    if (key === 'Enter') {
      if (target instanceof HTMLInputElement) {
        setFilterPayload({ ...filterPayload, searchTerm: target.value });
        setSubmitFilterPayload(filterPayload);
        setSearchParams({
          currentPage: '1',
          pageSize: pageSize.toString(),
          searchTerm: target.value,
          ...filteredObject,
          isFilter: 'true',
        });
        resetUrlData();
        setIsFilterSubmit(true);
      }
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitFilterPayload({ ...filterPayload });
    const filteredObject = filterEmptyProperties(filterPayload);

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
    setIsFilterSubmit(true);
  };

  //Declare sort order function
  const sortOrder = (key: string) => {
    if (!key) return;
    keySearch !== key ? dispatch(setIsDESC(false)) : dispatch(setIsDESC(!isDESC));
    dispatch(setKeySearch(key));
  };

  const formatFilterStatus = (value: string) => {
    if (value === 'fraud') {
      return 'Suspected Fraud';
    } else if (value === 'holded') {
      return 'On Hold';
    } else if (value === 'payment_review') {
      return 'Payment Review';
    } else if (value === 'paypal_canceled_reversal') {
      return 'Paypal Canceled Reversal';
    } else if (value === 'paypal_reversed') {
      return 'Paypal Reversed';
    } else if (value === 'pending_payment') {
      return 'Pending Payment';
    } else if (value === 'pending_paypal') {
      return 'Pending Paypal';
    } else if (value === 'processing') {
      return 'Processing';
    } else if (value === 'pending') {
      return 'Pending';
    } else if (value === 'complete') {
      return 'Complete';
    } else if (value === 'closed') {
      return 'Closed';
    } else if (value === 'canceled') {
      return 'Canceled';
    } else {
      return value;
    }
  };

  const renderFilterActive = () => {
    const filterSections = [
      { label: 'Keyword', value: searchTerm, resetHandler: () => resetFilterSection('searchTerm') },
      {
        label: 'Purchase Date',
        value:
          purchaseDateFrom + purchaseDateTo &&
          `${purchaseDateFrom ? moment(purchaseDateFrom).format('MM/DD/YYYY') : '...'} - ${
            purchaseDateTo ? moment(purchaseDateTo).format('MM/DD/YYYY') : '...'
          } `,
        resetHandler: () => resetFilterFromToSection('purchaseDateFrom', 'purchaseDateTo'),
      },
      {
        label: 'Grand Total',
        value:
          baseGrandTotalFrom + baseGrandTotalTo &&
          `${baseGrandTotalFrom ? baseGrandTotalFrom : '...'} - ${baseGrandTotalTo ? baseGrandTotalTo : '...'}`,
        resetHandler: () => resetFilterFromToSection('baseGrandTotalFrom', 'baseGrandTotalTo'),
      },
      {
        label: 'Total Refunded',
        value:
          totalRefundedFrom + totalRefundedTo && `${totalRefundedFrom ? totalRefundedFrom : '...'} - ${totalRefundedTo ? totalRefundedTo : '...'}`,
        resetHandler: () => resetFilterFromToSection('totalRefundedFrom', 'totalRefundedTo'),
      },
      {
        label: 'Status',
        value: formatFilterStatus(filterStatus),
        resetHandler: () => resetFilterSection('filterStatus'),
      },
      { label: 'Customer Email', value: customerEmail, resetHandler: () => resetFilterSection('customerEmail') },
      { label: 'ID', value: id, resetHandler: () => resetFilterSection('id') },
    ];

    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={() => resetFilter()} />}
          </div>
          <div className="border-top border-bottom p-3 align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  const renderContentSection = () => {
    return (
      <div className="col-sm-12 col-lg-12">
        <Separator className="mb-4 d-flex flex-column flex-lg-row justify-content-end justify-content-lg-between align-items-end align-items-lg-center gap-3">
          <SearchBar
            name="searchTerm"
            status={status}
            isTotalCount={true}
            totalCount={total_count}
            searchKeyPressHandler={searchKeyPressHandler}
            onFilterChangeHandler={onFilterChangeHandler}
            value={filterPayload.searchTerm}
          />
          <div className="text-end w-fit-content d-flex align-items-center justify-content-end justify-content-md-start">
            {/* <button className="download-order btn border-rd-25 btn-primary export text-white fw-medium me-2 border-0 fs-5">
                  <FontAwesomeIcon icon={faArrowUpFromBracket} />
                </button> */}
            <div
              className="filters--button  btn btn-border-radius fw-medium fs-14 border-rd-25 text-uppercase"
              onClick={() => setIsEnableFilterBoard(!isEnableFilterBoard)}
            >
              <FontAwesomeIcon icon={faFilter} /> Filters
            </div>
          </div>
        </Separator>
        {renderFilterBoard()}
        {renderFilterActive()}
        <div className="table-section bg-white mt-4 wrapper overflow-auto border-rd-20">{renderTable()}</div>
        <Pagination
          className="p-0"
          currentPage={+currentPage}
          pageSize={+pageSize}
          onChangePageSizeEvent={onChangePageSizeEvent}
          onChangePageEvent={onChangePage}
          status={status}
          totalCount={total_count}
        />
      </div>
    );
  };

  const renderPaymentMethod = (item: any) => {
    const paymentTitleObj = item.extension_attributes.payment_additional_info.find((item: any) => item.key === 'method_title');
    const paymentTitle = paymentTitleObj?.value === 'null' || !paymentTitleObj?.value ? '' : paymentTitleObj.value;

    return <p className="fs-14 m-0 fw-normal">{paymentTitle}</p>;
  };

  // Belong to renderContentSection
  const renderTable = () => {
    return (
      <Table
        keySearch={keySearch}
        isDESC={isDESC}
        dataTableHead={dataOrderHead}
        status={status}
        message={message}
        sortOrder={sortOrder}
        className="order-table"
      >
        {items.length > 0 ? (
          items.map((item: any, i: number) => {
            return (
              <tr key={i}>
                <td className="td-item ps-4 pe-2 py-3 fs-14 text-start fw-normal">{item.increment_id}</td>
                <td className="td-item px-2 py-3">
                  <p className="mb-0 fw-normal fs-14 text-start white-space-pre">{item.store_name}</p>
                </td>
                <td className="td-item px-2 py-3">
                  <p className="mb-0 fw-normal fs-14">{item.customer_email}</p>
                </td>
                <td className="td-item px-2 py-3">
                  <p className="mb-0 fw-normal fs-14">
                    {item.customer_firstname} {item.customer_lastname}
                  </p>
                </td>
                <td className="td-item px-2 py-3">
                  <p className="mb-0 fw-normal fs-14 text-end">{formatMoney(item.base_grand_total, currentStore)}</p>
                </td>
                <td className="td-item px-2 py-3">
                  <p className="mb-0 fw-normal fs-14 text-end">{formatMoney(item.base_total_refunded || 0, currentStore)}</p>
                </td>
                <td className="td-item px-2 py-3">{renderPaymentMethod(item)}</td>
                <td className="td-item px-2 py-3">
                  <p className="mb-0 fw-normal fs-14 text-end white-space-pre">{timeConvert(item.created_at)}</p>
                </td>
                <td className="td-item px-2 py-3 fs-14 text-center">{formatStatus(item.status)}</td>
                <td className="td-item px-2 py-3 text-center">
                  <Link
                    to={`/orders/${item.entity_id}/information`}
                    className="fs-14 fw-light text-link text-decoration-none mb-0 cursor-pointer view-icon"
                  />
                </td>
              </tr>
            );
          })
        ) : (
          <NoRecord tableHeaders={dataOrderHead} />
        )}
      </Table>
    );
  };

  // Belong to renderContentSection
  const renderFilterBoard = () => {
    return (
      <div className={`bg-white filter-board${isEnableFilterBoard ? '' : ' d-none'} p-4 wrapper mb-5 border-rd-20`}>
        <div className="filter-section bg-white">
          <div className="row">
            <h5 className="col-12 mb-4 text-dark filter-title">Filters</h5>
            <DateRangeFilter
              filterClass="col-lg-4 col-xl-3"
              label="Purchase Date"
              name="purchaseDate"
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <NumberFromToFilter
              filterClass="col-lg-4 col-xl-3"
              label="Grand Total"
              name={'baseGrandTotal'}
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <NumberFromToFilter
              filterClass="col-lg-4 col-xl-3"
              label="Total Refunded"
              name={'totalRefunded'}
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <SelectFilter
              filterClass="col-lg-4 col-xl-3 mt-3 mt-xl-0"
              label="Status"
              name="filterStatus"
              options={paymentStatusOptions}
              value={filterPayload.filterStatus}
              onChange={onFilterChangeHandler}
            />
            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              label="Customer Email"
              name="customerEmail"
              value={filterPayload.customerEmail}
              onChange={onFilterChangeHandler}
            />
            <NumberFilter filterClass="col-lg-4 col-xl-3 mt-3" label="ID" name="id" value={filterPayload.id} onChange={onFilterChangeHandler} />
          </div>
          <FilterButtons onSubmit={onSubmit} status={status} setIsEnableFilter={() => setIsEnableFilterBoard(false)} />
        </div>
      </div>
    );
  };

  return (
    <DefaultLayout pageTitle="Order Listing">
      <div className="content-wrapper">
        <div className="page-header">
          <div className="row">{renderContentSection()}</div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Order;
