import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { setIsDisplayNav } from './redux/actions';
import Footer from './Footer';
import Header from './Header';
import SiderBar from './SiderBar';
import './style.scss';

interface Props {
  children: JSX.Element[] | JSX.Element;
  pageTitle: string;
  subTitle?: string;
}

const DefaultLayout: React.FC<Props> = (props: Props) => {
  const { children, pageTitle, subTitle } = props;
  const dispatch = useAppDispatch();
  const { isDisplayNav } = useAppSelector((state) => state.pageReducer);
  const { currentStoreName } = useAppSelector((s) => s.authReducer);

  const handleResize = () => {
    if (window.innerWidth <= 1200) {
      dispatch(setIsDisplayNav(false));
    } else {
      dispatch(setIsDisplayNav(true));
    }
  };

  useEffect(() => {
    document.title = `${pageTitle} | ${process.env.REACT_APP_PRE_PAGE_TITLE} | ${currentStoreName || 'Singapore'}`;

    if (window.innerWidth <= 1200) {
      dispatch(setIsDisplayNav(false));
    } else {
      dispatch(setIsDisplayNav(true));
    }
    window.addEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle, currentStoreName]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className={`${isDisplayNav ? 'col-12 col-md-3 col-lg-3 col-xl-2' : 'col-12 col-md-1 col-lg-1 col-xl-1 sidebar-wrap'}`}>
          <div className={`d-flex flex-column bg-primary ${isDisplayNav ? 'sidebar-left' : 'sidebar-left-mini'}`}>
            <SiderBar pageTitle={pageTitle} />
            <Footer />
          </div>
        </div>
        <div
          className={`${
            isDisplayNav ? 'col-12 col-md-9 col-lg-9 col-xl-10 min-vh-100 d-flex flex-column' : 'col-12 col-md-11 col-lg-11 col-xl-11 content-wrap'
          }`}
        >
          <Header pageTitle={pageTitle} subTitle={subTitle} />
          <div className="wrapper-main py-4 px-2 flex-grow-1 d-flex flex-column">
            <div className="content-section px-1 h-100 flex-grow-1 d-flex flex-column">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
