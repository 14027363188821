import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { dataCustomerHead } from '~/app/constants';
import { addFilterGroup, addFilterItem, addFilters, addSortOrder, filterEmptyProperties, getWebsiteId, searchCriteriaBuilder } from '~/app/utils';
import { setIsDESC, setKeySearch, setQueryString, getData } from './redux/action';
import { builderCriteriaCustomer } from '~/app/utils/builderCriteria';
import { convertStringToNumber } from '~/app/utils/convertBase64';
import { sorterCriteria } from '~/app/utils/sorterCriteria';
import Pagination from '~/components/common/Pagination';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { Table } from '~/components/common/Table';
import DefaultLayout from '~/components/layout/DefaultLayout';
import { NoRecord } from '../Err';
import countryList from 'react-select-country-list';
import './style.scss';
import {
  NumberFromToFilter,
  TextFilter,
  SelectFilter,
  FilterButtons,
  DateRangeFilter,
  ResetFilterButton,
} from '~/components/common/Filter/filterInput';
import { formatCountry, formatFilterActive, formatGender } from '~/components/common/Filter/formatFilter';
import { iGetAllCustomers } from '~/app/models';
import SearchBar from '~/components/common/Filter/searchBar';
import Separator from '~/components/common/Separator';

type FormFilterValues = {
  customerName: string;
  customersEmail: string;
  customerIdFrom: string;
  customerIdTo: string;
  customerSinceFrom: string;
  customerSinceTo: string;
  customerPhone: string;
  customerProvince: string;
  customerCountry: string;
  customerWebsite: string;
  searchTerm: string;
};

const Customers: React.FC = () => {
  const countries = countryList().getData();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Declare data from Reducers
  const { data, status, message, isDESC, keySearch, controller } = useAppSelector((state) => state.customerReducer);
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { data: userData, storeData } = useAppSelector((s) => s.userReducer);

  const { total_count, items } = data;
  const sort = sorterCriteria(keySearch, isDESC);
  const websiteList = JSON.parse(userData.website_ids || '{}');
  // const websiteKey = Object.keys(websiteList);
  // const convertWebsiteList = Object.keys(websiteList).map((key) => websiteList[key]);

  // Declare data from Query String
  const isFilter = Boolean(searchParams.get('isFilter'));
  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;
  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));

  const customerName = searchParams.get('customerName') || '';
  const customersEmail = searchParams.get('customersEmail') || '';
  const customerIdFrom = searchParams.get('customerIdFrom') || '';
  const customerIdTo = searchParams.get('customerIdTo') || '';
  const customerSinceFrom = searchParams.get('customerSinceFrom') || '';
  const customerSinceTo = searchParams.get('customerSinceTo') || '';
  const customerPhone = searchParams.get('customerPhone') || '';
  const customerProvince = searchParams.get('customerProvince') || '';
  const customerCountry = searchParams.get('customerCountry') || '';
  const customerWebsite = searchParams.get('customerWebsite') || '';
  const searchTerm = searchParams.get('searchTerm') || '';

  // Declare initial Filter Payload
  const initialFilterPayload = {
    customerName,
    customersEmail,
    customerIdFrom,
    customerIdTo,
    customerSinceFrom,
    customerSinceTo,
    customerPhone,
    customerProvince,
    customerCountry,
    customerWebsite,
    searchTerm,
  } as FormFilterValues;

  // Declare useState
  const [query, setQuery] = useState<string>(window.location.search);
  const [filterPayload, setFilterPayload] = useState<FormFilterValues>(initialFilterPayload);
  const [submitFilterPayload, setSubmitFilterPayload] = useState<any>(initialFilterPayload);
  const [isEnableFilter, setIsEnableFilter] = useState<Boolean>(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>();
  const [firstMounted, setFirstMounted] = useState<Boolean>(true);
  const [websiteId, setWebsiteId] = useState<number>();
  // const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  // const [isCheck, setIsCheck] = useState<number[]>([]);

  //Declare useEffect
  useEffect(() => {
    setWebsiteId(getWebsiteId(storeData, currentStore));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, currentStore]);

  useEffect(() => {
    setFirstMounted(false);
    return () => {
      controller && controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstMounted) return;
    setIsEnableFilter(false);
    setFilterPayload({
      ...filterPayload,
      customerName: '',
      customersEmail: '',
      customerIdFrom: '',
      customerIdTo: '',
      customerSinceFrom: '',
      customerSinceTo: '',
      customerPhone: '',
      customerProvince: '',
      customerCountry: '',
      customerWebsite: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      customerName: '',
      customersEmail: '',
      customerIdFrom: '',
      customerIdTo: '',
      customerSinceFrom: '',
      customerSinceTo: '',
      customerPhone: '',
      customerProvince: '',
      customerCountry: '',
      customerWebsite: '',
      searchTerm: '',
    });
    setSearchParams({});
    resetUrlData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    dispatch(setQueryString(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const filter = builderCriteriaCustomer(submitFilterPayload);

    const payload: iGetAllCustomers = {
      accessToken,
      searchUrl: searchCriteriaBuilder(
        convertStringToNumber(pageSize.toString(), 10),
        convertStringToNumber(currentPage.toString(), 1),
        addFilterGroup(...filter, addFilters(addFilterItem('website_id', `${websiteId}`, 'eq'))),
        addSortOrder(...sort),
      ),
      currentStore,
    };

    if ((isFilter || isAdvancedFilter || (!isFilter && !isAdvancedFilter)) && websiteId) {
      dispatch(getData(payload, controller));
      dispatch(setIsDESC(isDESC));
      dispatch(setKeySearch(keySearch));
      dispatch(setQueryString(query));
    }

    if (Object.keys(filterEmptyProperties(submitFilterPayload)).length === 0) {
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, storeData, currentStore, keySearch, isDESC, query, isFilterSubmit, websiteId]);

  //Change Filter Handler
  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isFilter) {
      setSearchParams({
        pageSize: value,
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  // Change current page
  const onChangePage = (currentPage: number) => {
    if (isFilter) {
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: pageSize.toString(), currentPage: currentPage.toString() });
      resetUrlData();
    }
  };

  // reset fillter and search
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      customerName: '',
      customersEmail: '',
      customerIdFrom: '',
      customerIdTo: '',
      customerPhone: '',
      customerProvince: '',
      customerCountry: '',
      customerWebsite: '',
      searchTerm: '',
      customerSinceFrom: '',
      customerSinceTo: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      customerName: '',
      customersEmail: '',
      customerIdFrom: '',
      customerIdTo: '',
      customerPhone: '',
      customerProvince: '',
      customerCountry: '',
      customerWebsite: '',
      searchTerm: '',
      customerSinceFrom: '',
      customerSinceTo: '',
    });
    setSearchParams({});
    resetUrlData();
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${key}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${key}`]: '' });
    setSubmitFilterPayload({ ...filteredObject, [`${key}`]: '' });
    resetUrlData();
  };

  const resetFilterFromToSection = (keyFrom: string, keyTo: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${keyFrom}`]: '',
      [`${keyTo}`]: '',
      sAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    setSubmitFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    resetUrlData();
  };

  const searchKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = e;
    const filteredObject = filterEmptyProperties(filterPayload);
    if (key === 'Enter') {
      if (target instanceof HTMLInputElement) {
        setFilterPayload({ ...filterPayload, searchTerm: target.value });
        setSubmitFilterPayload({ ...filterPayload });
        setSearchParams({
          currentPage: '1',
          pageSize: pageSize.toString(),
          searchTerm: target.value,
          ...filteredObject,
          isFilter: 'true',
        });
        resetUrlData();
        setIsFilterSubmit(true);
      }
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitFilterPayload({ ...filterPayload });
    const filteredObject = filterEmptyProperties(filterPayload);

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
    setIsFilterSubmit(true);
  };

  //Declare sort order function
  const sortOrder = (key: string) => {
    if (!key) return;
    keySearch !== key ? dispatch(setIsDESC(false)) : dispatch(setIsDESC(!isDESC));
    dispatch(setKeySearch(key));
  };

  const renderWebsite = (data: string) => {
    if (websiteList.hasOwnProperty(data)) {
      const obj = websiteList[`${parseInt(data)}`];
      return obj.name;
    }
    // return convertWebsiteList.filter((_: any, index: number) => index + 1 === parseInt(data)).map((item: any) => item.name);
  };

  // const selecAllCustomers = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   setIsCheckAll(!isCheckAll);
  //   setIsCheck(items.map((li) => li.id));
  //   if (isCheckAll) {
  //     setIsCheck([]);
  //   }
  // };

  // const selectCustomer = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   const { id, checked } = e.target;
  //   setIsCheck([...isCheck, parseInt(id)]);
  //   if (!checked) {
  //     setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
  //   }
  // };

  const renderFilterActive = () => {
    const filterSections = [
      { label: 'Keyword', value: searchTerm, resetHandler: () => resetFilterSection('searchTerm') },
      {
        label: 'ID',
        value: customerIdFrom + customerIdTo && `${customerIdFrom ? customerIdFrom : '...'} - ${customerIdTo ? customerIdTo : '...'}`,
        resetHandler: () => resetFilterFromToSection('customerIdFrom', 'customerIdTo'),
      },
      {
        label: 'Customer Since',
        value:
          customerSinceFrom + customerSinceTo &&
          `${customerSinceFrom ? moment(customerSinceFrom).format('MM/DD/YYYY') : '...'} - ${
            customerSinceTo ? moment(customerSinceTo).format('MM/DD/YYYY') : '...'
          } `,
        resetHandler: () => resetFilterFromToSection('customerSinceFrom', 'customerSinceTo'),
      },
      { label: 'Customer Email', value: customersEmail, resetHandler: () => resetFilterSection('customersEmail') },
      { label: 'Customer Name', value: customerName, resetHandler: () => resetFilterSection('customerName') },
      { label: 'Customer Phone', value: customerPhone, resetHandler: () => resetFilterSection('customerPhone') },
      {
        label: 'Country',
        value: customerCountry && countries.filter((item: any) => item.value === customerCountry).map((item: any) => item.label),
        resetHandler: () => resetFilterSection('customerCountry'),
      },
      { label: 'customerProvince', value: customerProvince, resetHandler: () => resetFilterSection('customerProvince') },
      { label: 'Website', value: customerWebsite && renderWebsite(customerWebsite), resetHandler: () => resetFilterSection('customerWebsite') },
    ];

    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={() => resetFilter()} />}
          </div>
          <div className="border-top border-bottom p-3 align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  // Belong to renderContentSection
  const renderTable = () => {
    return (
      <Table
        className="striped-table"
        sortOrder={sortOrder}
        dataTableHead={dataCustomerHead}
        status={status}
        message={message}
        // onChange={(e) => selecAllCustomers(e)}
        // checked={isCheckAll}
        keySearch={keySearch}
        isDESC={isDESC}
      >
        {items.length > 0 ? (
          items.map((item, i: number) => {
            /*const checkboxProps: Props = {
            name: 'id',
            id: item.id,
            onChange: selectCustomer,
            checked: isCheck.includes(item.id),
          }; */
            return (
              <tr key={i}>
                {/* <td className="td-item px-2 fs-14 text-center">
                <CustomCheckBox {...checkboxProps} />
              </td> */}
                <td className="td-item px-4 text-center fs-14">{item.id}</td>
                <td className="td-item px-2 fs-14">{`${item.firstname} ${item.middlename ? item.middlename : ''} ${item.lastname}`}</td>
                <td className="td-item px-2 fs-14">{item.email}</td>
                <td className="td-item px-2 fs-14 text-end">
                  {item.addresses.filter((item) => item.default_billing === true).map((item) => item.telephone)}
                </td>
                <td className="td-item px-2 fs-14">
                  {item.addresses.filter((item) => item.default_billing === true).map((item) => item.region.region)}
                </td>
                <td className="td-item px-2 fs-14">
                  {`${item.addresses.filter((item) => item.default_billing === true).map((item) => formatCountry(item.country_id))}`}
                </td>
                <td className="td-item px-2 fs-14 text-end">
                  {item.addresses.filter((item) => item.default_billing === true).map((item) => item.postcode)}
                </td>
                <td className="td-item px-2 fs-14">{renderWebsite(item.website_id.toString())}</td>
                <td className="td-item px-2 fs-14 text-end">{moment(item.created_at).format('MMM DD, YYYY hh:mm:ss A')}</td>
                <td className="td-item px-2 fs-14">{formatGender(item.gender)}</td>
                <td className="td-item px-2 fs-14 text-center">
                  <Link className="link" to={`/customers/${item.id}/overview`}>
                    Edit
                  </Link>
                </td>
              </tr>
            );
          })
        ) : (
          <NoRecord tableHeaders={dataCustomerHead} />
        )}
      </Table>
    );
  };

  // Belong to renderContentSection
  const renderFilterBoard = () => {
    return (
      <div className="bg-white filter-board p-4 wrapper mb-4 border-rd-20">
        <div className="filter-section bg-white">
          <div className="row">
            <h5 className="col-12 mb-4 text-dark filter-title">Filters</h5>
            <NumberFromToFilter
              filterClass="col-lg-4 col-xl-3"
              label={'ID'}
              name={'customerId'}
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <DateRangeFilter
              filterClass="col-lg-4 col-xl-3"
              label="Customer Since"
              name="customerSince"
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3 mt-lg-0"
              label="Name"
              name="customerName"
              value={filterPayload.customerName}
              onChange={onFilterChangeHandler}
            />
            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3 mt-xl-0"
              label="Email"
              name="customersEmail"
              value={filterPayload.customersEmail}
              onChange={onFilterChangeHandler}
            />
            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              regexEnable={true}
              label="Phone"
              name="customerPhone"
              value={filterPayload.customerPhone}
              onChange={onFilterChangeHandler}
            />
            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              label="State/Province"
              name="customerProvince"
              value={filterPayload.customerProvince}
              onChange={onFilterChangeHandler}
            />
            <SelectFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              label="Country"
              name="customerCountry"
              optionNull={true}
              options={countries}
              value={filterPayload.customerCountry}
              onChange={onFilterChangeHandler}
            />
            {/* <FilterWebsite
              filterClass="col-lg-4 col-xl-3 mt-3"
              name="customerWebsite"
              value={filterPayload.customerWebsite}
              onChange={onFilterChangeHandler}
            /> */}
          </div>
          <FilterButtons onSubmit={(e) => onSubmit(e)} status={status} setIsEnableFilter={() => setIsEnableFilter(false)} />
        </div>
      </div>
    );
  };

  const renderContentSection = () => {
    return (
      <div className="col-sm-12 col-lg-12">
        <Separator className="mb-4 d-flex flex-column flex-lg-row justify-content-end justify-content-lg-between align-items-end align-items-lg-center gap-3">
          <SearchBar
            name="searchTerm"
            status={status}
            isTotalCount={true}
            totalCount={total_count}
            searchKeyPressHandler={searchKeyPressHandler}
            onFilterChangeHandler={onFilterChangeHandler}
            value={filterPayload.searchTerm}
          />
          <div className="filter-group d-flex justify-content-end justify-content-md-start">
            {/* <Link
                  to="/customer/create"
                  className="btn btn-primary btn-border-radius border-rd-25 me-1 me-xl-2 my-1 my-lg-0 text-uppercase fw-medium fs-14 text-decoration-none"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-1 me-xl-2" /> new customer
                </Link> */}
            <button
              className="filters--button btn btn-border-radius text-uppercase me-1 me-xl-2 my-1 my-lg-0 fw-medium fs-14"
              onClick={() => setIsEnableFilter(!isEnableFilter)}
            >
              <FontAwesomeIcon icon={faFilter} /> Filters
            </button>
            {/* <button className="filters--button btn btn-border-radius my-1 my-lg-0 text-uppercase fw-medium fs-14">delete customer</button> */}
          </div>
        </Separator>
        <div className={`filter col-sm-12 col-lg-12${isEnableFilter ? '' : ' d-none'}`}>{renderFilterBoard()}</div>
        {renderFilterActive()}
        <div className="table-section bg-white mt-4 wrapper overflow-auto border-rd-20">{renderTable()}</div>
        <Pagination
          className="p-0"
          currentPage={+currentPage}
          pageSize={+pageSize}
          onChangePageSizeEvent={onChangePageSizeEvent}
          onChangePageEvent={onChangePage}
          status={status}
          totalCount={total_count}
        />
      </div>
    );
  };

  return (
    <DefaultLayout pageTitle="Customer Listing">
      <div className="content-wrapper">
        <div className="page-header">
          <div className="row">{renderContentSection()}</div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Customers;
