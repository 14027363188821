import { FC, useEffect } from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import { iTableHead } from '~/app/models';
import { Input } from '~/components/common/Input';
import { Table } from '~/components/common/Table';
import { NoRecord } from '~/components/pages/Err';

const SHIPMENT_TABLE: iTableHead[] = [
  { name: 'Product', className: 'ps-0 w-75 fs-14 fw-medium py-3' },
  { name: 'Qty', className: 'w-50 fs-14 fw-medium text-end px-2 py-3' },
];

const CREATE_SHIPMENT_TABLE: iTableHead[] = [
  { name: 'Product', className: 'w-50 ps-0 fs-14 fw-medium py-3' },
  { name: 'Qty', className: 'w-20 fs-14 fw-medium text-end px-2 py-3' },
  { name: 'Qty to Ship', className: 'w-30 fs-14 fw-medium text-end ps-2 pe-0 py-3' },
];

interface Props {
  isCreateShipment: boolean;
  formik: any;
}

export const TableShipment: FC<Props> = (props: Props) => {
  const { isCreateShipment = false, formik } = props;
  const { values, errors, handleChange, setFieldError } = formik;

  const { status, shipmentDetail, dataDetails } = useAppSelector((state) => state.orderReducer);
  const { items } = shipmentDetail;
  const arr = items.map((item: any) => item.order_item_id);
  const itemWithOutConfigurable = dataDetails.items.filter((item: any) => arr.includes(item.item_id));

  useEffect(() => {
    if (isCreateShipment) {
      const tableElement = document.querySelector('.list-to-ship');
      setTimeout(() => {
        tableElement?.scrollIntoView();
      }, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetails]);

  const getItemData = (id: number, extension_attributes?: any) => {
    const item: any = items.filter((item: any) => item.order_item_id === id);

    return {
      qty: item[0]?.qty,
      name: item[0]?.name,
      sku: item[0]?.sku,
      extension_attributes,
    };
  };

  const renderDetailsWhenCreate = (item: any) => {
    const options = convertDeliveryCode(item?.parent_item?.extension_attributes?.deliverycode);
    const itemId = item.parent_item ? item.parent_item.item_id : item.item_id;
    const name = `qty-pdt-${itemId}`;
    const value = values[name];

    const itemCheck = item.parent_item ? item.parent_item : item;

    return (
      <>
        <td className="fs-14 text-start ps-0">
          <p className="mb-0 fw-medium" dangerouslySetInnerHTML={{ __html: item?.parent_item?.name ? item?.parent_item?.name : item.name }} />
          <p className="mb-0 sku-product">SKU: {item.sku}</p>
          {options &&
            options.map((option: any, i: number) => (
              <p key={i} className="mb-0 option-product">
                {option.label}: {option.value}
              </p>
            ))}
        </td>

        <td className=" fs-14 text-end text-nowrap">
          <p className="mb-1">{itemCheck.qty_ordered ? `Ordered ${itemCheck.qty_ordered}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_invoiced ? `Invoiced ${itemCheck.qty_invoiced}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_canceled ? `Canceled ${itemCheck.qty_canceled}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_refunded ? `Refunded ${itemCheck.qty_refunded}` : ''}</p>
          <p className="mb-1">{itemCheck.qty_shipped ? `Shipped ${itemCheck.qty_shipped}` : ''}</p>
        </td>

        <td className="fs-14 pe-0">
          <div className="d-flex justify-content-end">
            <Input
              className="form-control--short fs-14"
              type="number"
              name={name}
              value={value || ''}
              onChange={(e) => {
                handleChange(e);
                setFieldError(name, undefined, false);
              }}
            />
          </div>
          {errors[name] && <p className="fs-14 text-danger m-0 p-0 mt-2 text-end">{errors[name]}</p>}
        </td>
      </>
    );
  };

  const renderDetails = (item: any) => {
    const { extension_attributes } = item;

    return item.parent_item ? renderTd(getItemData(item.parent_item.item_id)) : renderTd(getItemData(item.item_id, extension_attributes));
  };

  const renderTd = (item: any) => {
    const options = convertDeliveryCode(item?.extension_attributes?.deliverycode);
    return (
      <>
        <td className="fs-14 w-75 vertical-middle pe-2 ps-0">
          <p className="mb-0 fw-medium" dangerouslySetInnerHTML={{ __html: item.name }} />
          <p className="mb-0 sku-product">SKU: {item.sku}</p>
          {options &&
            options.map((option: any, i: number) => (
              <p key={i} className="mb-0 option-product">
                {option.label}: {option.value}
              </p>
            ))}
        </td>
        <td className="fs-14 vertical-middle px-2 text-end">{item.qty}</td>
      </>
    );
  };

  const convertDeliveryCode = (data: string[]) => {
    if (!data) return undefined;
    const deliveryString = JSON.parse(JSON.stringify(data));

    const result = deliveryString.map((item: any) => JSON.parse(item));

    return result;
  };

  function renderBodyTable() {
    if (isCreateShipment) {
      const listProduct = dataDetails.items;
      const listProductSimple = listProduct.filter((item: any) => item.product_type !== 'configurable');
      const newList = listProductSimple.filter((item: any) => {
        if (item.parent_item) {
          return item.parent_item.qty_ordered - (item.parent_item.qty_refunded || 0) - (item.parent_item.qty_shipped || 0) > 0;
        }
        return item.qty_ordered - (item.qty_refunded || 0) - (item.qty_shipped || 0) > 0;
      });

      return newList.length ? (
        newList.map((item: any, i: number) => {
          return <tr key={i}>{renderDetailsWhenCreate(item)}</tr>;
        })
      ) : (
        <NoRecord tableHeaders={CREATE_SHIPMENT_TABLE} />
      );
    }

    return itemWithOutConfigurable.length ? (
      itemWithOutConfigurable.map((item: any, i: number) => {
        return <tr key={i}>{renderDetails(item)}</tr>;
      })
    ) : (
      <NoRecord tableHeaders={SHIPMENT_TABLE} />
    );
  }

  return (
    <div className="info-table list-to-ship bg-white p-4 wrapper h-100 mt-4 mt-xl-0">
      <p className="title pb-4 mb-0 fs-16 fw-medium text-uppercase">{isCreateShipment ? 'Items to Ship' : 'Items Shipped'}</p>
      <Table status={status} dataTableHead={isCreateShipment ? CREATE_SHIPMENT_TABLE : SHIPMENT_TABLE}>
        {renderBodyTable()}
      </Table>
    </div>
  );
};
