import { ScriptableContext } from 'chart.js';
import { ReactNode, useEffect, useState } from 'react';
import Chart from '~/components/common/Chart';
import Skeleton from 'react-loading-skeleton';

import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { convertGetPastDay, getPastDay } from '~/app/constants';
import { dashboardCriteria, formatMoney } from '~/app/utils';
import moment from 'moment';
import { getDataSalesStatistics } from './redux/actions';
import numeral from 'numeral';
import last12Moths from '~/app/utils/last12Moths';
import { iProductSoldDashBoard } from '~/app/models';

import './style.scss';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props {
  children: ReactNode;
}

interface Profit {
  order_date: string;
  subtotal: string;
}

const SalesStatistics = (props: Props) => {
  const { children } = props;

  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { dataSalesStatistics, statusSalesStatistics: status } = useAppSelector((s) => s.dashboardReducer);

  const [profitOfMonth, setProfitOfMonth] = useState<Profit>({
    order_date: last12Moths[last12Moths.length - 1].split(' ').join('-'),
    subtotal: '0',
  });
  const currentDate = convertGetPastDay(getPastDay(1)[0]);
  const aYearAgo = convertGetPastDay(getPastDay(366)[365]);

  useEffect(() => {
    const payload: iProductSoldDashBoard = {
      accessToken,
      currentStore,
      searchUrl: dashboardCriteria({
        to: moment(new Date(currentDate)).format('YYYY-MM-DD'),
        from: moment(new Date(aYearAgo)).format('YYYY-MM-DD'),
      }),
    };
    dispatch(getDataSalesStatistics(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, accessToken]);

  useEffect(() => {
    if (!dataSalesStatistics.length) return;

    const lastData = dataSalesStatistics[dataSalesStatistics.length - 1];
    const currentMonth = last12Moths[last12Moths.length - 1].split(' ').join('-');
    const lastMothBackend = lastData.order_date;

    if (currentMonth === lastMothBackend) {
      setProfitOfMonth(dataSalesStatistics[dataSalesStatistics.length - 1]);
    }
  }, [dataSalesStatistics]);

  const chartData = {
    labels: last12Moths?.map((item: any) => {
      const month = item.split(' ')[0];
      return `${month.slice(0, 3)}`;
    }),
    datasets: [
      {
        label: 'Total',
        data: last12Moths?.map((item: any) => {
          const check = item.split(' ').join('-');
          const data = dataSalesStatistics.find((item) => item.order_date === check);
          return data ? data.subtotal : 0;
        }),

        // Config
        pointBackgroundColor: '#180767',
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;

          const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.offsetWidth);

          gradient.addColorStop(0.1, 'rgba(26, 37, 171, 0.4)');
          gradient.addColorStop(0.3, 'rgba(255, 255, 255, 0.08)');
          return gradient;
        },
        borderWidth: 2,
        borderColor: '#180767',
        tension: 0.4,
        fill: true,
      },
    ],
  };

  const chartOptions = {
    onClick: function (evt: any, element: any) {
      if (!element[0]) return;

      function covertMoth(mothClick: string) {
        return mothClick.split(' ').join('-');
      }

      const index = element[0].index;
      const mothClick = last12Moths[index];

      const data = dataSalesStatistics.find((item) => item.order_date === covertMoth(mothClick));

      setProfitOfMonth(data ? data : { order_date: covertMoth(mothClick), subtotal: '0' });
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            const index = context[0].dataIndex;
            const element = last12Moths[index];

            return element;
          },
          label: (context: any) => {
            return `Total: ${numeral(context.parsed?.y).format('0,0.00')}`;
          },
          labelColor: function () {
            return {
              borderColor: '#fff',
              backgroundColor: '#180767',
              borderWidth: 1,
            };
          },
        },
        titleAlign: 'center',
        titleMarginBottom: 12,
        padding: 16,
        boxPadding: 8,
      },
    },
  };

  const renderInfoProfit = () => {
    return (
      <div className="profit row align-items-end mt-4 mb-5">
        {status !== 'pending' ? (
          <>
            <div className="col-12 col-md-3">
              <h3 className="title fw-semibold m-0">Net Profit:</h3>
            </div>
            <div className="col-12 col-md-9 d-flex align-items-end">
              <h4 className="price fw-semibold text-primary m-0">{formatMoney(+profitOfMonth?.subtotal || 0, currentStore)}</h4>
              <span className="time fw-light">({profitOfMonth?.order_date?.split('-').join(' - ')})</span>
            </div>
          </>
        ) : (
          <Skeleton width={320} height={40} />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="sales-statistics d-flex flex-column h-100 content-wrap bg-white p-4 mw-100 overflow-x-auto">
        {children}
        <div className="sale-wrapper row">
          <div className="col-12">{renderInfoProfit()}</div>
          <div className="chart-wrap col-12">
            <div className="w-100 chart-wave">
              {status !== 'pending' ? <Chart type="Line" chartData={chartData} options={chartOptions} /> : <Skeleton width={`95%`} height={200} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesStatistics;
