import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { iDataCatePromotion, iCategoryTreeItem, iDataStore, iPromotion, Status, iDataHeaderPromotion, iCouponCode, iPayload } from '~/app/models';
interface iState {
  action: 'create' | 'edit';
  status: Status;
  idPromotionEdit: number | null;
  statusGetCateList: Status;
  couponCodeByRuleId: iCouponCode;
  statusGetData: Status;
  statusGetPromotion: Status;
  statusGetPromotionDetail: Status;
  statusGetAttrPromotionConfig: Status;
  deletePromotionStatus: Status;
  message: string;
  messageCreateSubPromotion: string;
  messageGetPromotionDetail: string;
  deleteProductStatus: Status;
  messageGetProductDetail: string;
  getCategoriesLitst: string;

  data: iPromotion;
  dataStore: iDataStore[];
  // dataAttrSet: iDataAttrSet[];
  dataCatePromotion: iDataCatePromotion[];
  dataCateTree: iCategoryTreeItem[];
  idCategoriesSelected: number[];
  statusGetCategoriesTree: Status;

  dataHeader: iDataHeaderPromotion;
  dataHeaderPromotion: iDataHeaderPromotion;
  dataPromotionList: iPromotion[];
  dataRelatedSelected: iPromotion[];
  dataUpSellSelected: iPromotion[];
  dataCrossSellSelected: iPromotion[];
  dataCateSelected: any;
  dataWebsiteSelect: any;

  dataAttrList: any;
  dataValuesConfigSelected: any;
  dataPromotionGenerate: any;
  listTierPrice: any[];
  dataPromotionsAssociatedUnConverted: iPromotion[];
  PromotionListController: null | AbortController;
}

const initialState: iState = {
  action: 'create',
  couponCodeByRuleId: {} as iCouponCode,
  status: '',
  statusGetData: '',
  statusGetPromotion: '',
  statusGetPromotionDetail: '',
  statusGetAttrPromotionConfig: '',
  deletePromotionStatus: '',
  message: '',
  idPromotionEdit: null,
  messageCreateSubPromotion: '',
  messageGetPromotionDetail: '',
  messageGetProductDetail: '',
  deleteProductStatus: '',
  getCategoriesLitst: '',
  statusGetCateList: '',
  statusGetCategoriesTree: '',

  data: {} as iPromotion,
  dataPromotionList: [],
  dataStore: [],
  // dataAttrSet: [],
  dataCatePromotion: [],
  dataCateTree: [],

  dataRelatedSelected: [],
  dataUpSellSelected: [],
  idCategoriesSelected: [],
  dataCrossSellSelected: [],
  dataCateSelected: [],
  dataWebsiteSelect: [],
  dataValuesConfigSelected: {},
  dataAttrList: {},
  dataPromotionGenerate: [],
  dataPromotionsAssociatedUnConverted: [],
  listTierPrice: [],
  PromotionListController: null,
  dataHeaderPromotion: {} as iDataHeaderPromotion,
  dataHeader: {
    is_active: true,
    uses_per_customer: true,
    simple_action: 'by_percent',
    apply_to: 'order_total',
    discount_mode: 'by_percent',
  } as iDataHeaderPromotion,
};

const createPromotionReducer = createSlice({
  name: 'Create/Edit Promotion',
  initialState,
  reducers: {
    reset: () => initialState,
    changeController: (state, action) => ({ ...state, PromotionListController: action.payload }),

    createEditPromotionPending: (state: any) => {
      state.status = 'pending';
    },
    getPromotionDetails: (state: any) => {
      state.status = 'rejected';
    },

    createSubPromotionRejected: (state: any, { payload }) => {
      state.status = 'rejected';
      state.messageCreateSubPromotion = payload;
    },
    createEditPromotionRejected: (state: any, { payload }) => {
      state.status = 'rejected';
      state.message = payload;
    },
    createEditPromotionFulfilled: (state: any, { payload }) => {
      state.status = 'fulfilled';
      state.idPromotionEdit = payload;
      state.message = 'Successfully';
    },
    updateDataPromotion: (state: any, { payload }) => {
      state.data = payload;
    },

    getCouponCodeById: (state, { payload }: PayloadAction<iCouponCode>) => {
      state.couponCodeByRuleId = payload;
    },

    getDataRelatedSelected: (state: any, { payload }) => {
      state.dataRelatedSelected = payload;
    },
    getDataUpSellSelected: (state: any, { payload }) => {
      state.dataUpSellSelected = payload;
    },
    getDataCrossSellSelected: (state: any, { payload }) => {
      state.dataCrossSellSelected = payload;
    },
    getDataCateSelected: (state: any, { payload }) => {
      state.dataCateSelected = payload;
    },
    getDataWebsiteSelect: (state: any, { payload }) => {
      state.dataWebsiteSelect = payload;
    },

    getDataPending: (state) => {
      state.statusGetData = 'pending';
    },
    getDataRejected: (state, { payload }) => {
      state.statusGetData = 'rejected';
      state.message = payload;
    },
    getDataFulfilled: (state) => {
      state.statusGetData = 'fulfilled';
    },

    getPromotionListPending: (state: any) => {
      state.statusGetPromotion = 'pending';
    },
    getPromotionListRejected: (state: any) => {
      state.statusGetPromotion = 'rejected';
    },
    getPromotionListFulfilled: (state: any, { payload }) => {
      state.statusGetPromotion = 'fulfilled';
      state.dataPromotionList = payload;
    },

    getPromotionDetailPending: (state: any) => {
      state.statusGetPromotionDetail = 'pending';
    },
    getPromotionDetailRejected: (state: any, { payload }) => {
      state.statusGetPromotionDetail = 'rejected';
      state.messageGetPromotionDetail = payload;
    },
    getPromotionDetailFulfilled: (state: any, { payload }) => {
      state.statusGetPromotionDetail = 'fulfilled';
      state.data = payload;
    },

    clearMessage: (state: any) => {
      state.status = '';
      state.message = '';
    },

    getListPromotionAssociated: (state: any, { payload }) => {
      state.dataPromotionsAssociatedUnConverted = payload;
    },

    onChangeAction: (state: any, { payload }) => {
      state.action = payload;
    },
    onChangeType: (state: any, { payload }) => {
      state.type = payload;
    },
    onChangeListPromotionAssociated: (state: any, { payload }) => {
      state.listPromotionAssociated = payload;
    },
    onChangeListRelatedSelected: (state, { payload }) => {
      state.dataRelatedSelected = payload;
    },
    onChangeListUpSellSelected: (state, { payload }) => {
      state.dataUpSellSelected = payload;
    },
    onChangeListCateSelected: (state, { payload }) => {
      state.dataCateSelected = payload;
    },
    onChangeListWebsiteSelected: (state, { payload }) => {
      state.dataWebsiteSelect = payload;
    },
    onChangeDataAttr: (state, { payload }) => {
      state.dataAttrList = payload;
    },
    onChangePromotionGenerate: (state, { payload }) => {
      state.dataPromotionGenerate = payload;
    },

    getCateListFulfilled: (state, { payload }) => {
      state.getCategoriesLitst = payload;
    },

    getAttrPromotionConfigPending: (state: any) => {
      state.statusGetAttrPromotionConfig = 'pending';
    },
    getAttrPromotionConfigRejected: (state: any) => {
      state.statusGetAttrPromotionConfig = 'rejected';
    },
    getAttrPromotionConfigFulfilled: (state: any, { payload }) => {
      state.statusGetAttrPromotionConfig = 'fulfilled';
      state.listAttrOfPromotionConfig = payload;
    },
    onChangeDataHeader: (state, { payload }: iPayload<iDataHeaderPromotion>) => {
      state.dataHeader = payload;
    },
    getCatePending: (state: any) => {
      state.statusGetCateList = 'pending';
    },
    getCateFulfilled: (state: any, { payload }) => {
      state.dataCatePromotion = payload;
      state.statusGetCateList = 'fulfilled';
    },
    getCateRejected: (state: any) => {
      state.statusGetCateList = 'rejected';
    },
    onChangeIdCategoriesSelected: (state, { payload }) => {
      state.idCategoriesSelected = payload;
    },
    getCategoriesTreeFulfilled: (state, { payload }: PayloadAction<iCategoryTreeItem[]>) => ({
      ...state,
      dataCateTree: payload,
      statusGetCategoriesTree: 'fulfilled',
    }),
    getCategoriesTreeReject: (state) => ({
      ...state,
      statusGetCategoriesTree: 'rejected',
    }),
    getCategoriesTreePending: (state) => ({
      ...state,
      statusGetCategoriesTree: 'pending',
    }),

    deletePromotionFulfilled: (state) => ({ ...state, deletePromotionStatus: 'fulfilled' }),
    deletePromotionRejected: (state, action) => ({ ...state, message: action.payload, deletePromotionStatus: 'rejected' }),
    deletePromotionPending: (state) => ({ ...state, deletePromotionStatus: 'pending' }),
    deletePromotionInitState: (state) => ({ ...state, deletePromotionStatus: '' }),
    getCouponCode: (state, action) => ({ ...state, couponCode: action.payload }),
  },
});

export const { ...actions } = createPromotionReducer.actions;

export default createPromotionReducer.reducer;
