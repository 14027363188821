import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import Select from '~/components/common/Select';
import { onChangeDataAdvInventory } from '../redux/actions';
import { Input } from '~/components/common/Input';

const AdvInventory = () => {
  const dispatch = useAppDispatch();
  const { dataAdvInventory } = useAppSelector((state: any) => state.createEditProductReducer);
  const {
    backorders,
    enable_qty_increments,
    is_decimal_divided,
    is_qty_decimal,
    manage_stock,
    max_sale_qty,
    min_qty,
    min_sale_qty,
    notify_stock_qty,
    qty_increments,
    use_config_backorders,
    use_config_enable_qty_inc,
    use_config_manage_stock,
    use_config_max_sale_qty,
    use_config_min_qty,
    use_config_min_sale_qty,
    use_config_notify_stock_qty,
    use_config_qty_increments,
  } = dataAdvInventory;

  const handleChange = (e: any, name?: string, defaultValue?: any) => {
    const defaultConfig = name && defaultValue ? { [name]: defaultValue } : {};

    dispatch(
      onChangeDataAdvInventory({
        ...dataAdvInventory,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
        ...defaultConfig,
      }),
    );
  };

  const DATA_ADVANCED_INVENTORY = useMemo(() => {
    return [
      {
        title: 'Manage Stock',
        type: 'select',
        name: 'manage_stock',
        value: manage_stock === false || manage_stock === '0' ? '0' : '1',
        defaultValue: true,
        options: [
          {
            value: '1',
            name: 'Yes',
          },
          {
            value: '0',
            name: 'No',
          },
        ],
        nameConfig: 'use_config_manage_stock',
        useConfig: use_config_manage_stock,
      },
      {
        showField: manage_stock === true || manage_stock === '1',
        title: 'Out-of-Stock Threshold',
        type: 'number',
        value: min_qty,
        defaultValue: 0,
        name: 'min_qty',
        nameConfig: 'use_config_min_qty',
        useConfig: use_config_min_qty,
      },
      {
        title: 'Minimum Qty Allowed in Shopping Cart',
        type: 'number',
        value: min_sale_qty,
        defaultValue: 1,
        name: 'min_sale_qty',
        nameConfig: 'use_config_min_sale_qty',
        useConfig: use_config_min_sale_qty,
      },
      {
        title: 'Maximum Qty Allowed in Shopping Cart',
        type: 'number',
        value: max_sale_qty,
        defaultValue: 10000,
        name: 'max_sale_qty',
        nameConfig: 'use_config_max_sale_qty',
        useConfig: use_config_max_sale_qty,
      },
      {
        showField: manage_stock === true || manage_stock === '1',
        title: 'Qty Uses Decimals',
        type: 'select',
        name: 'is_qty_decimal',
        value: +is_qty_decimal,
        defaultValue: '0',
        options: [
          {
            value: '1',
            name: 'Yes',
          },
          {
            value: '0',
            name: 'No',
          },
        ],
      },
      {
        showField: manage_stock === true || manage_stock === '1',
        title: 'Allow Multiple Boxes for Shipping',
        type: 'select',
        name: 'is_decimal_divided',
        value: +is_decimal_divided,
        defaultValue: '0',
        options: [
          {
            value: '1',
            name: 'Yes',
          },
          {
            value: '0',
            name: 'No',
          },
        ],
      },
      {
        showField: manage_stock === true || manage_stock === '1',
        title: 'Backorders',
        type: 'select',
        name: 'backorders',
        value: backorders,
        defaultValue: 0,
        options: [
          {
            value: '0',
            name: 'No backorders',
          },
          {
            value: '1',
            name: 'Allow Qty Below 0',
          },
          {
            value: '2',
            name: 'Allow Qty Below 0 and Notify Customer',
          },
        ],
        nameConfig: 'use_config_backorders',
        useConfig: use_config_backorders,
      },
      {
        showField: manage_stock === true || manage_stock === '1',
        title: 'Notify for Quantity Below',
        type: 'number',
        value: notify_stock_qty,
        defaultValue: 1,
        name: 'notify_stock_qty',
        nameConfig: 'use_config_notify_stock_qty',
        useConfig: use_config_notify_stock_qty,
      },
      {
        title: 'Enable Qty Increments',
        type: 'select',
        name: 'enable_qty_increments',
        value: +enable_qty_increments,
        defaultValue: '0',
        options: [
          {
            value: '1',
            name: 'Yes',
          },
          {
            value: '0',
            name: 'No',
          },
        ],
        nameConfig: 'use_config_enable_qty_inc',
        useConfig: use_config_enable_qty_inc,
      },
      {
        showField: enable_qty_increments === true || enable_qty_increments === '1',
        title: 'Qty Increments',
        type: 'number',
        value: qty_increments,
        defaultValue: '1',
        name: 'qty_increments',
        nameConfig: 'use_config_qty_increments',
        useConfig: use_config_qty_increments,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAdvInventory]);

  function renderDataAvdInventory() {
    return DATA_ADVANCED_INVENTORY.map((item, index) => {
      const { title, type, name, nameConfig, defaultValue, value, useConfig, options = [], showField = true } = item;
      return (
        showField && (
          <div className="d-flex align-items-center justify-content-end field-wrap" key={index}>
            <h3 className={`${nameConfig ? 'has-checkbox' : ''} title fs-16 m-0 me-4 fw-normal`}>{title}</h3>
            <div className="d-flex flex-column">
              {type === 'number' && (
                <Input
                  type="number"
                  className="px-2 w-500px"
                  name={name}
                  value={value.toString() || ''}
                  disabled={useConfig}
                  onChange={handleChange}
                />
              )}

              {type === 'select' && (
                <Select value={value} name={name} disabled={useConfig} onChange={handleChange} className="w-500px">
                  {options.map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              )}

              {!!nameConfig && (
                <div className="d-flex align-items-center checkbox-custom mt-2">
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    name={nameConfig}
                    checked={useConfig}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e, name, defaultValue);
                    }}
                    id={nameConfig}
                  />

                  <label htmlFor={nameConfig} className="fs-16 fw-normal ms-3 cursor-pointer">
                    Use Config Settings
                  </label>
                </div>
              )}
            </div>
          </div>
        )
      );
    });
  }

  return <div className="content-adv-inventory d-grid gap-4">{renderDataAvdInventory()}</div>;
};

export default AdvInventory;
