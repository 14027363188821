import { ChangeEvent, FC } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { onChangeListApplyAllStores, onChangeSearchOptimize } from '../../redux/actions';
import './style.scss';
import { iKeyAllowApplyAllStores } from '~/app/models';
import CheckBoxApplyAllStores from '~/components/common/CheckBox/CheckBoxApplyAllStores';
import { Input } from '~/components/common/Input';

const SearchEngineOptimization: FC = () => {
  const dispatch = useAppDispatch();
  const { dataSearchOptimize, listApplyAllStores } = useAppSelector((s) => s.createEditProductReducer);
  const { url_key, meta_title, meta_keyword } = dataSearchOptimize;

  function handleCheckApplyAllStores(e: ChangeEvent<HTMLInputElement>, name: iKeyAllowApplyAllStores) {
    dispatch(onChangeListApplyAllStores({ ...listApplyAllStores, [name]: e.target.checked }));
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;

    const newData = { ...dataSearchOptimize, [name]: value, isGeneralMetaTitle: value && name === 'meta_title' ? false : true };
    dispatch(onChangeSearchOptimize(newData));
  }

  function renderOptimizeItem(label: string, name: 'url_key' | 'meta_title' | 'meta_keyword', value: string) {
    return (
      <>
        <label htmlFor={name} className="optimize-label fs-16 m-0 fw-normal text-end cursor-pointer">
          {label}
        </label>

        <div className="d-flex flex-column gap-2">
          <Input className="optimize-control" type="text" name={name} id={name} value={value || ''} onChange={handleChange} />
          <CheckBoxApplyAllStores isApplyAllStores={listApplyAllStores[name]} onApplyAllStores={(e) => handleCheckApplyAllStores(e, name)} />
        </div>
      </>
    );
  }

  return (
    <div className="wrapper mw-100 overflow-x-auto optimize-list mw-100 overflow-x-auto" id="search-optimize">
      {renderOptimizeItem('URL Key', 'url_key', url_key)}
      {renderOptimizeItem('Meta Title', 'meta_title', meta_title)}
      {renderOptimizeItem('Meta Keywords', 'meta_keyword', meta_keyword)}
    </div>
  );
};

export default SearchEngineOptimization;
