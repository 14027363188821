import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iGetCreditMemosDetail, iGetInvoiceDetail, iGetShipmentDetail, iOrderCommnent, iTrackingNumber } from '~/app/models';
import AsyncSection from '~/components/common/AsyncSection';
import Button from '~/components/common/Button';

import numeral from 'numeral';
import {
  createInvoice,
  createRefund,
  createShipment,
  createTrackingNumber,
  getInvoiceDetail,
  getMemosDetail,
  getShipmentsDetail,
  invoiceComment,
  memoComment,
  postOrderComment,
  shipmentComment,
} from '../redux/actions';
import Comment from './Comment';
import { TableInvoice, TableMemo, TableOrdered, TableShipment } from './Tables';
import * as yup from 'yup';
import { formatMoney } from '~/app/utils';
import TableCreateInvoice from './Tables/TableCreateInvoice';
import { TableCreateCreditMemo } from './Tables/TableCreateCreditMemo';
import OrderInfo from './components/OrderInfo';
import OrderAddress from './components/OrderAddress';
import OrderPaymentMethod from './components/OrderPaymentMethod';
import OrderShipmentMethod from './components/OrderShipmentMethod';
import { Input } from '~/components/common/Input';

type Props = {
  isInvoiceInfo?: boolean;
  isShippingInfo?: boolean;
  isMemosInfo?: boolean;
  isCreateShipment?: boolean;
  isCreateCreditMemo?: boolean;
  isCreateInvoice?: boolean;
};

type resultTotalCreateInvoice = {
  subTotal: number;
  discountTotal: number;
  taxTotal: number;
};

type resultTotalCreateRefund = {
  subTotal: number;
  taxTotal: number;
  discountTotal: number;
};

const Information: FC<Props> = (props: Props) => {
  const {
    isInvoiceInfo = false,
    isShippingInfo = false,
    isMemosInfo = false,
    isCreateShipment = false,
    isCreateCreditMemo = false,
    isCreateInvoice = false,
  } = props;
  const { orderId = 0, entity_id } = useParams();

  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { status, dataDetails, invoiceDetail, shipmentDetail, memoDetail, createShipmentStatus, createRefundStatus, createInvoiceStatus } =
    useAppSelector((state) => state.orderReducer);

  const [submitCreateShipment, setSubmitCreateShipment] = useState<boolean>(false);
  const [submitCreateRefund, setSubmitCreateRefund] = useState<boolean>(false);
  const [submitCreateInvoice, setSubmitCreateInvoice] = useState<boolean>(false);
  const [showFormTrackingNumber, setShowFormTrackingNumber] = useState<boolean>(false);

  const maxRefundShip = useMemo(() => (+dataDetails.shipping_amount || 0) - (+dataDetails.shipping_refunded || 0), [dataDetails]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (entity_id && isInvoiceInfo) {
      const payload: iGetInvoiceDetail = {
        entity_id,
        accessToken,
      };
      dispatch(getInvoiceDetail(payload, currentStore));
    }

    if (entity_id && isShippingInfo) {
      const payload: iGetShipmentDetail = {
        entity_id,
        accessToken,
      };
      dispatch(getShipmentsDetail(payload, currentStore));
    }

    if (entity_id && isMemosInfo) {
      const payload: iGetCreditMemosDetail = {
        entity_id,
        accessToken,
      };
      dispatch(getMemosDetail(payload, currentStore));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    shipmentFormik.resetForm();
    commentFormik.resetForm();
    createTrackingNumberFormik.resetForm();
    refundFormik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const hasError = !!Object.keys(shipmentFormik.errors).concat(Object.keys(createTrackingNumberFormik.errors)).length;

    if (!hasError && submitCreateShipment) {
      handleCreateShipment();
    } else {
      setSubmitCreateShipment(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCreateShipment]);

  useEffect(() => {
    const hasError = !!Object.keys(refundFormik.errors).length;

    if (!hasError && submitCreateRefund) {
      handleCreateRefund();
    } else {
      setSubmitCreateRefund(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCreateRefund]);

  useEffect(() => {
    const hasError = !!Object.keys(createInvoiceFormik.errors).length;

    if (!hasError && submitCreateInvoice) {
      handleCreateInvoice();
    } else {
      setSubmitCreateInvoice(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCreateInvoice]);

  useEffect(() => {
    if (status !== 'fulfilled') return;

    function redirect(shouldRedirect: boolean) {
      if (shouldRedirect && Object.keys(dataDetails).length) {
        const arrPathName = pathname.split('/');
        const newPathName = arrPathName
          .filter((item) => item)
          .slice(0, arrPathName.length - 2)
          .join('/');

        navigate(`/${newPathName}`);
      }
    }

    const listProductSimple = dataDetails.items.filter((item: any) => item.product_type !== 'configurable');

    // If create shipment
    if (isCreateShipment) {
      const canShip = dataDetails?.extension_attributes?.can_ship === '1';

      redirect(!canShip);

      const newList = listProductSimple.filter((item: any) => {
        const itemCheck = item.parent_item ? item.parent_item : item;
        return itemCheck.qty_ordered - (itemCheck.qty_refunded || 0) - (itemCheck.qty_shipped || 0) > 0;
      });

      const result = newList
        .map((item: any) => {
          const itemId = item.parent_item ? item.parent_item.item_id : item.item_id;
          const itemCheck = item.parent_item ? item.parent_item : item;

          const field = `qty-pdt-${itemId}`;
          const qtyCanShip = itemCheck.qty_ordered - (itemCheck.qty_refunded || 0) - (itemCheck.qty_shipped || 0);

          return [[field, qtyCanShip]];
        })
        .flat();

      const resultObject = Object.fromEntries(result);

      shipmentFormik.setValues({ ...shipmentFormik.values, ...resultObject });
      return;
    }

    // If create refund
    if (isCreateCreditMemo) {
      const canCreateMemo = dataDetails?.extension_attributes?.can_creditmemo === '1';
      redirect(!canCreateMemo);

      const newList = listProductSimple.filter((item: any) => {
        const itemCheck = item.parent_item ? item.parent_item : item;
        return (itemCheck.qty_invoiced || 0) - (itemCheck.qty_refunded || 0) > 0;
      });

      const result = newList
        .map((item: any) => {
          const itemId = item.parent_item ? item.parent_item.item_id : item.item_id;
          const itemCheck = item.parent_item ? item.parent_item : item;
          const field = `qty-pdt-${itemId}`;
          const qtyCanRefund = (itemCheck.qty_invoiced || 0) - (itemCheck.qty_refunded || 0);

          return [[field, qtyCanRefund]];
        })
        .flat();

      const resultObject = Object.fromEntries(result);

      refundFormik.setValues({ ...refundFormik.values, shipping_amount: numeral(maxRefundShip).format('0,0.00'), ...resultObject });
      return;
    }

    // If create refund
    if (isCreateInvoice) {
      const canInvoice = dataDetails?.extension_attributes?.can_invoice === '1';
      redirect(!canInvoice);

      const newList = listProductSimple.filter((item: any) => {
        const itemCheck = item.parent_item ? item.parent_item : item;
        return itemCheck.qty_ordered > itemCheck.qty_invoiced;
      });

      const result = newList
        .map((item: any) => {
          const itemId = item.parent_item ? item.parent_item.item_id : item.item_id;
          const itemCheck = item.parent_item ? item.parent_item : item;

          const field = `qty-pdt-${itemId}`;
          const qtyCanInvoice = itemCheck.qty_ordered - itemCheck.qty_invoiced;

          return [[field, qtyCanInvoice]];
        })
        .flat();

      const resultObject = Object.fromEntries(result);

      createInvoiceFormik.setValues({ ...createInvoiceFormik.values, ...resultObject });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetails]);

  // Validate shipment
  const initialShipment = () => {
    const listProduct = dataDetails.items;
    const listProductSimple = listProduct.filter((item: any) => item.product_type !== 'configurable');
    const newList = listProductSimple.filter((item: any) => {
      if (item.parent_item) {
        return item.parent_item.qty_ordered - (item.parent_item.qty_refunded || 0) - (item.parent_item.qty_shipped || 0) > 0;
      }
      return item.qty_ordered - (item.qty_refunded || 0) - (item.qty_shipped || 0) > 0;
    });

    const yupValidate = newList
      .map((item: any) => {
        const itemId = item.parent_item ? item.parent_item.item_id : item.item_id;
        const field = `qty-pdt-${itemId}`;
        const qtyCanShip = item.parent_item
          ? item.parent_item.qty_ordered - (item.parent_item.qty_refunded || 0) - (item.parent_item.qty_shipped || 0)
          : item.qty_ordered - (item.qty_refunded || 0) - (item.qty_shipped || 0);

        return [
          [
            field,
            yup
              .number()
              .required('This field is required')
              .integer('Please enter an integer, without comma in this field.')
              .max(qtyCanShip || 0, `Maximum shipping quantity for item is ${qtyCanShip}.`),
          ],
        ];
      })
      .flat();

    const newYupObj = Object.fromEntries(yupValidate);

    return {
      initialValues: {},
      validateOnChange: false,
      validationSchema: yup.object().shape(newYupObj),
      onSubmit: () => {},
    };
  };
  const shipmentFormik = useFormik(initialShipment());

  // Validate comment
  const commentFormik = useFormik<iOrderCommnent>({
    initialValues: {
      comment: '',
      accessToken,
      id: +orderId,
      is_customer_notified: false,
      is_visible_on_front: false,
      status: dataDetails.status,
      entity_id: 0,
      parent_id: +orderId,
      notify: false,
      appendComment: false,
      pageType: 'infomation',
    },
    validationSchema: yup.object().shape({
      comment: isCreateShipment ? yup.string() : yup.string().required('Comment is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleAddComment,
  });

  const createTrackingNumberFormik = useFormik({
    initialValues: {
      title: '',
      carrier_code: 'custom',
      track_number: '',
      order_id: 0,
      entity_id: 0,
    },
    validateOnChange: false,
    validationSchema: yup.object().shape({
      track_number: yup.string().required('This field is required'),
    }),
    onSubmit: handleAddTrackingNumber,
  });

  const initialRefund = () => {
    const listProduct = dataDetails.items;
    const listProductSimple = listProduct.filter((item: any) => item.product_type !== 'configurable');
    const newList = listProductSimple.filter((item: any) => {
      if (item.parent_item) {
        return (item.parent_item.qty_invoiced || 0) - (item.parent_item.qty_refunded || 0) > 0;
      }
      return (item.qty_invoiced || 0) - (item.qty_refunded || 0) > 0;
    });

    const yupValidate = newList
      .map((item: any) => {
        const itemId = item.parent_item ? item.parent_item.item_id : item.item_id;
        const field = `qty-pdt-${itemId}`;
        const qtyCanRefund = item.parent_item
          ? (item.parent_item.qty_invoiced || 0) - (item.parent_item.qty_refunded || 0)
          : (item.qty_invoiced || 0) - (item.qty_refunded || 0);

        return [
          [
            field,
            yup
              .number()
              .required('This field is required')
              .integer('Please enter an integer, without comma in this field.')
              .max(qtyCanRefund || 0, `Maximum refund quantity for item is ${qtyCanRefund}.`),
          ],
        ];
      })
      .flat();

    const newYupObj = Object.fromEntries(yupValidate);

    return {
      initialValues: {
        return_to_stock_items: [],
        shipping_amount: numeral(maxRefundShip).format('0,0.00'),
      },
      validationSchema: yup.object().shape({
        ...newYupObj,
        shipping_amount: yup
          .number()
          .required('This field is required')
          .min(0)
          .max(maxRefundShip, `Maximum shipping amount allowed to refund is: ${formatMoney(maxRefundShip, currentStore)}`),
      }),
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: () => {},
    };
  };
  const initialCreateInvoice = () => {
    const listProductSimple = dataDetails.items.filter((item: any) => item.product_type !== 'configurable');
    const listItemCanInvoice = listProductSimple.filter((item: any) => {
      const itemCheck = item.parent_item ? item.parent_item : item;

      // Filter item has qty ordered greater than qty invoiced
      return itemCheck.qty_ordered > itemCheck.qty_invoiced;
    });

    const yupValidate = listItemCanInvoice
      .map((item: any) => {
        const itemId = item.parent_item ? item.parent_item.item_id : item.item_id;
        const itemCheck = item.parent_item ? item.parent_item : item;

        const field = `qty-pdt-${itemId}`;
        const maxQtyToInvoice = itemCheck.qty_ordered - itemCheck.qty_invoiced || 0;

        return [
          [
            field,
            yup
              .number()
              .required('This field is required')
              .integer('Please enter an integer, without comma in this field.')
              .max(maxQtyToInvoice, `Maximum quantity to invoice for item is ${maxQtyToInvoice}.`),
          ],
        ];
      })
      .flat();

    const newYupObj = Object.fromEntries(yupValidate);

    return {
      initialValues: {},
      validationSchema: yup.object().shape({
        ...newYupObj,
      }),
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: () => {},
    };
  };

  const refundFormik = useFormik(initialRefund());
  const createInvoiceFormik = useFormik(initialCreateInvoice());

  function handleAddTrackingNumber(values: any) {
    const { title, carrier_code, track_number, order_id, entity_id } = values;

    const payload: iTrackingNumber = {
      entity: { order_id, parent_id: entity_id, weight: null, qty: null, description: null, track_number, title, carrier_code },
    };

    dispatch(createTrackingNumber(payload, accessToken, currentStore, createTrackingNumberFormik.resetForm, setShowFormTrackingNumber));
  }

  function handleAddComment(values: any) {
    if (values.pageType === 'infomation') dispatch(postOrderComment(values, currentStore));
    if (values.pageType === 'invoices') dispatch(invoiceComment(values, currentStore));
    if (values.pageType === 'shipment') dispatch(shipmentComment(values, currentStore));
    if (values.pageType === 'memo') dispatch(memoComment(values, currentStore));
    commentFormik.resetForm();
  }

  function handleCreateShipment() {
    const { title, carrier_code, track_number } = createTrackingNumberFormik.values;
    const arrShipment = Object.entries(shipmentFormik.values);
    const { comment, appendComment, notify } = commentFormik.values;

    const listProductShip = arrShipment.map((item) => {
      const [productId, qty] = item;
      const arr = productId.split('-');
      const newProductId = arr[arr.length - 1];
      return {
        order_item_id: +newProductId,
        qty,
      };
    });
    const trackingNumber = track_number
      ? {
          tracks: [
            {
              track_number,
              title,
              carrier_code,
            },
          ],
        }
      : {};

    const commentPayload = comment
      ? {
          comment: {
            comment,
            is_visible_on_front: 1,
          },
        }
      : {};

    const payload = {
      items: listProductShip,
      ...trackingNumber,
      ...commentPayload,
      appendComment: appendComment ? 1 : 0,
      notify: notify ? 1 : 0,
    };
    setSubmitCreateShipment(false);
    dispatch(createShipment(+orderId, payload, accessToken, currentStore, navigate));
  }

  function handleCreateRefund() {
    const arrRefund = Object.entries(refundFormik.values);
    const { comment, appendComment, notify } = commentFormik.values;

    const listProductRefund = arrRefund
      .filter((item) => item[0].startsWith('qty-pdt'))
      .map((item) => {
        const [productId, qty] = item;

        const arr = productId.split('-');
        const newProductId = arr[arr.length - 1];
        return {
          order_item_id: +newProductId,
          qty: +qty,
        };
      });

    const commentPayload = comment
      ? {
          comment: {
            comment,
            is_visible_on_front: 1,
          },
        }
      : {};

    const payload = {
      items: listProductRefund,
      ...commentPayload,
      appendComment: appendComment ? 1 : 0,
      notify: notify ? 1 : 0,
      arguments: {
        shipping_amount: +refundFormik.values.shipping_amount,
        adjustment_positive: 0,
        adjustment_negative: 0,
        extension_attributes: {
          return_to_stock_items: refundFormik.values.return_to_stock_items,
        },
      },
    };

    setSubmitCreateRefund(false);
    dispatch(createRefund(+orderId, payload, accessToken, currentStore, navigate));
  }

  function handleCreateInvoice() {
    const arrInvoice: [string, number][] = Object.entries(createInvoiceFormik.values);
    const { comment, appendComment, notify } = commentFormik.values;

    const listProductInvoice = arrInvoice.map((item) => {
      const [field, qty] = item;

      const productId = field.split('-').slice(-1).toString();

      return {
        order_item_id: +productId,
        qty: +qty || 0,
      };
    });

    const commentPayload = comment
      ? {
          comment: {
            comment,
            is_visible_on_front: 1,
          },
        }
      : {};

    const payload = {
      items: listProductInvoice,
      ...commentPayload,
      appendComment: appendComment ? 1 : 0,
      notify: notify ? 1 : 0,
    };

    setSubmitCreateInvoice(false);
    dispatch(createInvoice(+orderId, payload, accessToken, currentStore, navigate));
  }

  const renderTotal = () => {
    return (
      <div className="info-total bg-white p-4 wrapper">
        <div className="row">
          <div className="col-sm-6 col-xl-5">
            <Comment formik={commentFormik} pageType={'infomation'} orderId={orderId} />
          </div>
          <div className="col-sm-6 col-xl-7">
            <div className="ps-4">
              <p className="mb-4 fs-16 fw-medium">ORDER TOTALS</p>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="fs-14 fw-normal">Subtotal</span>
                <span className="fs-14 fw-semibold text-dark">{formatMoney(dataDetails.subtotal_incl_tax, currentStore)}</span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="fs-14 fw-normal">Discount {dataDetails.discount_description ? `(${dataDetails.discount_description})` : ''}</span>
                <span className="fs-14 fw-semibold text-dark">
                  {dataDetails.base_discount_amount
                    ? '-' + formatMoney(Math.abs(dataDetails.base_discount_amount), currentStore)
                    : formatMoney(0, currentStore)}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="fs-14 fw-normal">Tax</span>
                <span className="fs-14 fw-semibold text-dark">
                  {formatMoney(dataDetails.base_tax_amount ? dataDetails.base_tax_amount : 0, currentStore)}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="fs-14 fw-normal">Shipping & Handling</span>
                <span className="fs-14 fw-semibold text-dark">{formatMoney(dataDetails.base_shipping_amount, currentStore)}</span>
              </div>
              <div className="seperate-line-dark-200"></div>
              <div className="d-flex justify-content-between align-items-center my-3">
                <span className="fs-14 fw-normal">Grand Total</span>
                <span className="fs-14 fw-semibold text-danger">{formatMoney(dataDetails.grand_total, currentStore)}</span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="fs-14 fw-normal">Total Paid</span>
                <span className="fs-14 fw-semibold text-danger">
                  {formatMoney(dataDetails.total_paid ? dataDetails.total_paid : 0, currentStore)}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="fs-14 fw-normal">Total Refunded</span>
                <span className="fs-14 fw-semibold text-danger">
                  {formatMoney(dataDetails.total_refunded ? dataDetails.total_refunded : 0, currentStore)}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <span className="fs-14 fw-normal">Total Due</span>
                <span className="fs-14 fw-semibold text-danger">
                  {formatMoney(dataDetails.base_total_due ? dataDetails.base_total_due : 0, currentStore)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTotalInvoice = () => {
    const listSimple = dataDetails.items.filter((item: any) => item.product_type !== 'configurable');

    const invoiceTotals: resultTotalCreateInvoice = listSimple.reduce((result: resultTotalCreateInvoice, item: any): resultTotalCreateInvoice => {
      const itemCheck = item.parent_item ? item.parent_item : item || {};
      const { item_id, price_incl_tax, qty_ordered, tax_amount, discount_amount } = itemCheck || {};

      const qtyToInvoice: number = +createInvoiceFormik.values?.[`qty-pdt-${item_id}` as keyof typeof createInvoiceFormik.values] || 0;

      const price = price_incl_tax * qtyToInvoice;
      const tax = (tax_amount / qty_ordered) * qtyToInvoice;
      const discount = (discount_amount / qty_ordered) * qtyToInvoice;

      return {
        subTotal: (result.subTotal || 0) + price,
        taxTotal: (result.taxTotal || 0) + tax,
        discountTotal: (result.discountTotal || 0) + discount,
      };
    }, {});

    return (
      <div className="info-total bg-white px-4 py-3 wrapper">
        <div className="row">
          <div className="col-sm-6 col-xl-5">
            <Comment formik={commentFormik} pageType="invoices" orderId={orderId} isCreateInvoice={isCreateInvoice} />
          </div>
          {/* Render invoice total */}
          <div className="col-sm-6 col-xl-7">
            {isCreateInvoice ? (
              <div className="ps-4">
                <p className="mb-4 fs-16 fw-medium text-uppercase mt-3 mt-lg-0">Invoice Totals</p>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="fs-14 fw-normal">Subtotal</span>
                  <span className="fs-14 fw-semibold text-dark">{formatMoney(invoiceTotals.subTotal, currentStore)}</span>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="fs-14 fw-normal">Discount {dataDetails.discount_description ? `(${dataDetails.discount_description})` : ''}</span>
                  <span className="fs-14 fw-semibold text-dark">
                    {invoiceTotals.discountTotal
                      ? '-' + formatMoney(Math.abs(invoiceTotals.discountTotal), currentStore)
                      : formatMoney(0, currentStore)}
                  </span>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="fs-14 fw-normal">Tax</span>
                  <span className="fs-14 fw-semibold text-dark">{formatMoney(invoiceTotals.taxTotal, currentStore)}</span>
                </div>

                <div
                  className={`d-flex justify-content-between align-items-center mb-3 ${
                    (dataDetails.shipping_amount || 0) - (dataDetails.shipping_invoiced || 0) ? '' : 'd-none'
                  }`}
                >
                  <span className="fs-14 fw-normal">Shipping & Handling</span>
                  <span className="fs-14 fw-semibold text-dark">{formatMoney(dataDetails.shipping_amount || 0, currentStore)}</span>
                </div>

                <div className="seperate-line-dark-200"></div>
                <div className="d-flex justify-content-between align-items-center my-3 my-lg-4">
                  <span className="fs-16 fw-medium text-uppercase">Grand Total</span>
                  <span className="fs-16 fw-medium text-danger">
                    {formatMoney(
                      invoiceTotals.subTotal -
                        invoiceTotals.discountTotal +
                        ((dataDetails.shipping_amount || 0) - (dataDetails.shipping_invoiced || 0)) || 0,
                      currentStore,
                    )}
                  </span>
                </div>
              </div>
            ) : (
              <div className="ps-4">
                <p className="mb-4 fs-16 fw-medium text-uppercase mt-3 mt-lg-0">Invoice Totals</p>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="fs-14 fw-normal">Subtotal</span>
                  <span className="fs-14 fw-semibold text-dark">{formatMoney(invoiceDetail.subtotal_incl_tax, currentStore)}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="fs-14 fw-normal">
                    Discount {invoiceDetail.discount_description ? `(${invoiceDetail.discount_description})` : ''}
                  </span>
                  <span className="fs-14 fw-semibold text-dark">
                    {invoiceDetail.base_discount_amount
                      ? '-' + formatMoney(Math.abs(invoiceDetail.base_discount_amount), currentStore)
                      : formatMoney(0, currentStore)}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="fs-14 fw-normal">Tax</span>
                  <span className="fs-14 fw-semibold text-dark">{formatMoney(invoiceDetail.base_tax_amount, currentStore)}</span>
                </div>
                <div className={`d-flex justify-content-between align-items-center mb-3 ${invoiceDetail.shipping_amount ? '' : 'd-none'}`}>
                  <span className="fs-14 fw-normal">Shipping & Handling</span>
                  <span className="fs-14 fw-semibold text-dark">
                    {invoiceDetail.shipping_amount ? formatMoney(invoiceDetail.shipping_amount, currentStore) : formatMoney(0, currentStore)}
                  </span>
                </div>
                <div className="seperate-line-dark-200"></div>
                <div className="d-flex justify-content-between align-items-center my-3 my-lg-4">
                  <span className="fs-16 fw-medium text-uppercase">Grand Total</span>
                  <span className="fs-16 fw-medium text-danger">{formatMoney(invoiceDetail.base_grand_total, currentStore)}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderTotalShipment = () => {
    return (
      <div className="bg-white wrapper p-4 h-100">
        <div className="col-12">
          <Comment formik={commentFormik} orderId={orderId} pageType="shipment" isCreateShipment={isCreateShipment} />
        </div>
      </div>
    );
  };

  const renderTotalCreditMemo = () => {
    const listSimple = dataDetails.items.filter((item: any) => item.product_type !== 'configurable') || [];

    const refundTotal: resultTotalCreateRefund = listSimple.reduce((result: resultTotalCreateRefund, item: any): resultTotalCreateRefund => {
      const { discountTotal: discountPrev = 0, subTotal: subTotalPrev = 0, taxTotal: taxPrev = 0 } = result;
      const newItem = item.parent_item ? item.parent_item : item || {};
      const itemId = item.parent_item ? item.parent_item.item_id : item.item_id;

      const qtyToRefund: number = +refundFormik.values?.[`qty-pdt-${itemId}` as keyof typeof refundFormik.values] || 0;
      const subTotal = newItem.price_incl_tax * qtyToRefund || 0;
      const tax = (newItem.tax_amount / newItem.qty_ordered) * qtyToRefund || 0;
      const discount = (newItem.discount_amount / newItem.qty_ordered) * qtyToRefund || 0;

      return {
        subTotal: subTotalPrev + subTotal,
        taxTotal: taxPrev + tax,
        discountTotal: discountPrev + discount,
      };
    }, {});

    return (
      <div className="info-total bg-white px-4 py-3 h-100 wrapper">
        <div className="row">
          <div className="col-sm-6 col-xl-5">
            <Comment formik={commentFormik} orderId={orderId} pageType="memo" isCreateCreditMemo={isCreateCreditMemo} />
          </div>
          <div className="col-sm-6 col-xl-7">
            {isCreateCreditMemo ? (
              <div className="ps-4">
                <p className="mb-4 fs-16 fw-medium text-uppercase mt-3 mt-lg-0">Refund Totals</p>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="fs-14 fw-normal">Subtotal</span>
                  <span className="fs-14 fw-semibold text-dark">{formatMoney(refundTotal.subTotal, currentStore)}</span>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="fs-14 fw-normal">Discount {dataDetails.discount_description && `(${dataDetails.discount_description})`}</span>
                  <span className="fs-14 fw-semibold text-dark">
                    {`${refundTotal.discountTotal ? '-' : ''}${formatMoney(refundTotal.discountTotal, currentStore)}`}
                  </span>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="fs-14 fw-normal">Tax</span>
                  <span className="fs-14 fw-semibold text-dark">{formatMoney(refundTotal.taxTotal, currentStore)}</span>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="fs-14 fw-normal">Refund Shipping</span>
                  <div className="d-flex flex-column align-items-end">
                    <Input
                      className="form-control--short text-end fs-14"
                      type="number"
                      name="shipping_amount"
                      value={refundFormik.values.shipping_amount}
                      onChange={(e) => {
                        const hasError =
                          (+e.target.value || 0) > maxRefundShip &&
                          refundFormik.errors.shipping_amount !== 'This field is required' &&
                          refundFormik.errors.shipping_amount &&
                          +e.target.value.toString().length > +refundFormik.values.shipping_amount.toString().length;

                        if (!hasError) {
                          refundFormik.handleChange(e);
                          refundFormik.setFieldError('shipping_amount', undefined);
                        }
                      }}
                    />

                    {refundFormik.errors['shipping_amount'] && (
                      <p className="fs-14 text-danger m-0 p-0 mt-2 text-end">{refundFormik.errors['shipping_amount'].toString()}</p>
                    )}
                  </div>
                </div>

                <div className="seperate-line-dark-200"></div>
                <div className="d-flex justify-content-between align-items-center my-3 my-lg-4">
                  <span className="fs-16 fw-medium text-uppercase">Grand Total</span>
                  <span className="fs-16 fw-medium text-danger">
                    {formatMoney(refundTotal.subTotal + (+refundFormik.values.shipping_amount || 0) - refundTotal.discountTotal, currentStore)}
                  </span>
                </div>
              </div>
            ) : (
              <div className="ps-4">
                <p className="mb-4 fs-16 fw-medium text-uppercase mt-3 mt-lg-0">Credit memo Totals</p>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="fs-14 fw-normal">Subtotal</span>
                  <span className="fs-14 fw-semibold text-dark">{formatMoney(memoDetail.subtotal_incl_tax, currentStore)}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="fs-14 fw-normal">Discount {memoDetail.discount_description ? `(${memoDetail.discount_description})` : ''}</span>
                  <span className="fs-14 fw-semibold text-dark">
                    {memoDetail.base_discount_amount
                      ? '-' + formatMoney(Math.abs(memoDetail.base_discount_amount), currentStore)
                      : formatMoney(0, currentStore)}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="fs-14 fw-normal">Tax</span>
                  <span className="fs-14 fw-semibold text-dark">
                    {formatMoney(memoDetail.base_tax_amount ? memoDetail.base_tax_amount : 0, currentStore)}
                  </span>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="fs-14 fw-normal">Shipping & Handling</span>
                  <span className="fs-14 fw-semibold text-dark">
                    {memoDetail.base_shipping_amount ? formatMoney(memoDetail.base_shipping_amount, currentStore) : formatMoney(0, currentStore)}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="fs-14 fw-normal">Adjustment Refund</span>
                  <span className="fs-14 fw-semibold text-dark">
                    {memoDetail.adjustment_positive ? formatMoney(memoDetail.adjustment_positive, currentStore) : formatMoney(0, currentStore)}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <span className="fs-14 fw-normal">Adjustment Fee</span>
                  <span className="fs-14 fw-semibold text-dark">
                    {memoDetail.adjustment_negative ? formatMoney(memoDetail.adjustment_negative, currentStore) : formatMoney(0, currentStore)}
                  </span>
                </div>
                <div className="seperate-line-dark-200"></div>
                <div className="d-flex justify-content-between align-items-center my-3 my-lg-4">
                  <span className="fs-16 fw-medium text-uppercase">Grand Total</span>
                  <span className="fs-16 fw-medium text-danger">{formatMoney(memoDetail.base_grand_total, currentStore)}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderBacklink = () => {
    return (
      <div className="mb-4 ms-2">
        {isInvoiceInfo && (
          <Link className="text-decoration-none fs-6 fw-semibold text-primary" to={`/orders/${orderId}/invoice`}>
            <FontAwesomeIcon className="backlink-icon" icon={faChevronLeft} /> INVOICE #{invoiceDetail.increment_id}
          </Link>
        )}
        {isShippingInfo && (
          <Link className="text-decoration-none fs-6 fw-semibold text-primary text-uppercase" to={`/orders/${orderId}/shipments`}>
            <FontAwesomeIcon className="backlink-icon" icon={faChevronLeft} /> Shipment #{shipmentDetail.increment_id}
          </Link>
        )}
        {isMemosInfo && (
          <Link className="text-decoration-none fs-6 fw-semibold text-primary text-uppercase" to={`/orders/${orderId}/credit-memos`}>
            <FontAwesomeIcon className="backlink-icon" icon={faChevronLeft} /> Credit Memo #{memoDetail.increment_id}
          </Link>
        )}
      </div>
    );
  };

  const renderButtonSubmit = () => {
    return (
      <>
        {isCreateShipment && (
          <Button
            onClick={() => {
              showFormTrackingNumber && createTrackingNumberFormik.validateForm();
              shipmentFormik.validateForm();
              setTimeout(() => {
                setSubmitCreateShipment(true);
              }, 0);
            }}
            disabled={submitCreateShipment === true || createShipmentStatus === 'pending'}
            className="text-uppercase mt-4 mt-xl-0"
          >
            Submit Shipment
          </Button>
        )}

        {isCreateCreditMemo && (
          <Button
            onClick={() => {
              refundFormik.validateForm();
              setTimeout(() => {
                setSubmitCreateRefund(true);
              }, 0);
            }}
            disabled={submitCreateRefund === true || createRefundStatus === 'pending'}
            className="text-uppercase mt-4"
          >
            REFUND OFFLINE
          </Button>
        )}

        {isCreateInvoice && (
          <Button
            onClick={() => {
              createInvoiceFormik.validateForm();
              setTimeout(() => {
                setSubmitCreateInvoice(true);
              }, 0);
            }}
            disabled={submitCreateInvoice === true || createInvoiceStatus === 'pending'}
            className="text-uppercase mt-4"
          >
            SUBMIT INVOICE
          </Button>
        )}
      </>
    );
  };

  return (
    <AsyncSection status={status} className="info-wrapper mt-4">
      {renderBacklink()}
      <div className="row">
        <div className="col-lg-12 col-xl-5">
          <OrderInfo />
        </div>
        <div className="col-lg-12 col-xl-7 mt-4 mt-xl-0">
          <OrderAddress />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12 col-xl-5">
          <OrderPaymentMethod />
        </div>
        <div className="col-lg-12 col-xl-7 mt-4 mt-xl-0">
          <OrderShipmentMethod
            formik={createTrackingNumberFormik}
            isCreateShipment={isCreateShipment}
            shouldFormTrackingNumber={isCreateShipment || isShippingInfo}
          />
        </div>
      </div>
      <div className="row mt-4">
        {(!isShippingInfo || !isCreateShipment) && (
          <div className="col-sm-12">
            {isInvoiceInfo && <TableInvoice />}
            {isCreateInvoice && <TableCreateInvoice formik={createInvoiceFormik} />}
            {isMemosInfo && <TableMemo />}
            {isCreateCreditMemo && <TableCreateCreditMemo formik={refundFormik} />}
            {!isInvoiceInfo && !isShippingInfo && !isCreateShipment && !isMemosInfo && !isCreateCreditMemo && !isCreateInvoice && (
              <TableOrdered currentStore={currentStore} />
            )}
          </div>
        )}

        <div className="col-sm-12 col-xl-5">{(isShippingInfo || isCreateShipment) && renderTotalShipment()}</div>
        <div className="col-sm-12 col-xl-7">
          {(isShippingInfo || isCreateShipment) && <TableShipment isCreateShipment={isCreateShipment} formik={shipmentFormik} />}
        </div>
      </div>
      <>
        {(!isShippingInfo || !isCreateShipment) && (
          <div className="row mt-4">
            <div className="col-sm-12">
              {(isInvoiceInfo || isCreateInvoice) && renderTotalInvoice()}
              {(isMemosInfo || isCreateCreditMemo) && renderTotalCreditMemo()}
              {!isInvoiceInfo && !isShippingInfo && !isCreateShipment && !isMemosInfo && !isCreateCreditMemo && !isCreateInvoice && renderTotal()}
            </div>
          </div>
        )}
      </>

      {renderButtonSubmit()}
    </AsyncSection>
  );
};

export default Information;
