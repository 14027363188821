import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import useDebounce from '~/app/hooks/useDebounce';
import { addFilterGroup, addFilterItem, addFilters, searchCriteriaBuilder } from '~/app/utils';
import { MultiSelect } from '~/components/common/MultiSelect';
import { getProductList, onChangeListCrossSellSelected, onChangeListRelatedSelected, onChangeListUpSellSelected } from '../../redux/actions';
import './style.scss';
import { iOptions } from '~/components/common/MultiSelect/MultiSelect';

const RelatedProduct = () => {
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { data, dataProductList, dataUpSellSelected, dataRelatedSelected, dataCrossSellSelected, statusGetProduct, productListController } =
    useAppSelector((state) => state.createEditProductReducer);

  const [queryRelated, setQueryRelated] = useState<string>('');
  const [queryUpsell, setQueryUpsell] = useState<string>('');
  const [queryCrossSell, setQueryCrossSell] = useState<string>('');

  const debouncedValueRelated = useDebounce(queryRelated, queryRelated ? 500 : 0);
  const debouncedValueUpsell = useDebounce(queryUpsell, queryUpsell ? 500 : 0);
  const debouncedValueCrossSell = useDebounce(queryCrossSell, queryCrossSell ? 500 : 0);

  useEffect(() => {
    dispatch(
      getProductList(
        searchCriteriaBuilder(50, 1, addFilterGroup(addFilters(addFilterItem('name', `%${debouncedValueRelated}%`, 'like')))),
        accessToken,
        currentStore,
        productListController,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValueRelated]);

  useEffect(() => {
    dispatch(
      getProductList(
        searchCriteriaBuilder(50, 1, addFilterGroup(addFilters(addFilterItem('name', `%${debouncedValueUpsell}%`, 'like')))),
        accessToken,
        currentStore,
        productListController,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValueUpsell]);

  useEffect(() => {
    dispatch(
      getProductList(
        searchCriteriaBuilder(50, 1, addFilterGroup(addFilters(addFilterItem('name', `%${debouncedValueCrossSell}%`, 'like')))),
        accessToken,
        currentStore,
        productListController,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValueCrossSell]);

  const DATA = [
    {
      title: 'Related Products',
      desc: 'Related products are shown to customers in addition to the item the customer is looking at.',
      dataSelected: dataRelatedSelected.filter((item) => item.id !== data.id),
      onSelectItem: (item: iOptions) => {
        dispatch(onChangeListRelatedSelected([...dataRelatedSelected, item]));
      },
      onRemoveItem: (itemRemove: iOptions) => {
        dispatch(onChangeListRelatedSelected(dataRelatedSelected.filter((item) => item.id !== itemRemove.id)));
      },
      onRemoveAllItem: () => {
        dispatch(onChangeListRelatedSelected([]));
      },
      query: queryRelated,
      setQuery: setQueryRelated,
    },
    {
      title: 'Up-Sell Products',
      desc: 'An up-sell item is offered to the customer as a pricier or higher-quality alternative to the product the customer is looking at.',
      dataSelected: dataUpSellSelected.filter((item) => item.id !== data.id),
      onSelectItem: (item: iOptions) => {
        dispatch(onChangeListUpSellSelected([...dataUpSellSelected, item]));
      },
      onRemoveItem: (itemRemove: iOptions) => {
        dispatch(onChangeListUpSellSelected(dataUpSellSelected.filter((item) => item.id !== itemRemove.id)));
      },
      onRemoveAllItem: () => {
        dispatch(onChangeListUpSellSelected([]));
      },
      query: queryUpsell,
      setQuery: setQueryUpsell,
    },
    {
      title: 'Cross-Sell Products',
      desc: 'These "impulse-buy" products appear next to the shopping cart as cross-sells to the items already in the shopping cart.',
      dataSelected: dataCrossSellSelected.filter((item) => item.id !== data.id),
      onSelectItem: (item: iOptions) => {
        dispatch(onChangeListCrossSellSelected([...dataCrossSellSelected, item]));
      },
      onRemoveItem: (itemRemove: iOptions) => {
        dispatch(onChangeListCrossSellSelected(dataCrossSellSelected.filter((item) => item.id !== itemRemove.id)));
      },
      onRemoveAllItem: () => {
        dispatch(onChangeListCrossSellSelected([]));
      },
      query: queryCrossSell,
      setQuery: setQueryCrossSell,
    },
  ];

  function renderList() {
    return DATA.map((item: any, index: number) => {
      const { title, desc, dataSelected, query, setQuery, onSelectItem, onRemoveItem, onRemoveAllItem } = item;

      const listNameSelect = dataSelected.map((item: any) => item.name);
      return (
        <div className="d-flex align-items-center" key={index}>
          <h3 className="title m-0 me-4 fw-normal fs-16">{title}</h3>
          <div className="multi-select-wrap d-flex flex-column flex-grow-1">
            <MultiSelect
              removeSearchWhenSelected
              status={statusGetProduct}
              options={dataProductList.filter((item) => item.id !== data.id).filter((item: any) => listNameSelect.includes(item.name) === false)}
              listSelected={dataSelected}
              query={query}
              setQuery={setQuery}
              onRemoveAllItem={onRemoveAllItem}
              onRemoveItem={onRemoveItem}
              onSelectItem={onSelectItem}
            />
            <p className="desc m-0 mt-2 fw-medium text-dark-600">{desc}</p>
          </div>
        </div>
      );
    });
  }

  return <div className="related-product d-flex flex-column gap-3 wrapper mw-100">{renderList()}</div>;
};

export default RelatedProduct;
