import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iGetDataById, iPayloadChangeStatusOrder, typeChangeStatusOrder } from '~/app/models';
import { formatFilterStatus } from '~/components/common/Filter/formatFilter';
import DefaultLayout from '~/components/layout/DefaultLayout';

import { cancelOrder, clearData, getDataById, holdOrder, unHoldOrder } from '../redux/actions';
import './style.scss';
import Button from '~/components/common/Button';

interface Props {}

const TABS = [
  { name: 'Information', path: 'information' },
  { name: 'Invoices', path: 'invoice' },
  { name: 'Credit Memos', path: 'credit-memos' },
  { name: 'Shipments', path: 'shipments' },
  { name: 'History', path: 'history' },
];

const OrderDetails: FC<Props> = (props: Props) => {
  const [, setTab] = useState<string>('Information');
  const { orderId = '0' } = useParams();
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const {
    dataDetails,
    commentStatus,
    queryString,
    trackingNumberStatus,
    deleteTrackingNumberStatus,
    createShipmentStatus,
    createInvoiceStatus,
    messCreateInvoice,
    messCreateShipment,
    messCreateTrackingNumber,
    createRefundStatus,
    messCreateRefund,
    cancelOrderStatus,
    holdOrderStatus,
    unHoldOrderStatus,
  } = useAppSelector((state) => state.orderReducer);

  const tabSwitching = (tab: string) => {
    setTab(tab);
  };

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const formatStatus = (status: string) => {
    if (!status) return;
    if (['canceled', 'failed', 'closed'].includes(status))
      return (
        <span className="page-status d-inline-block table-status fs-5 table-status-danger text-capitalize fw-semibold">
          {formatFilterStatus(status)}
        </span>
      );

    if (['complete', 'processing'].includes(status))
      return (
        <span className="page-status d-inline-block table-status table-status-success text-capitalize fw-semibold fs-5">
          {formatFilterStatus(status)}
        </span>
      );

    return (
      <span className="page-status d-inline-block table-status fs-5 table-status-warning text-capitalize fw-semibold">
        {formatFilterStatus(status)}
      </span>
    );
  };
  const swalToast = Swal.mixin({
    toast: true,
    position: 'top-right',
    buttonsStyling: false,
    showConfirmButton: false,
    timer: 2000,
  });

  useEffect(() => {
    if (commentStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (commentStatus === 'rejected') {
      swalToast.fire({
        title: 'Something went wrong, please try again!',
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentStatus]);

  useEffect(() => {
    if (trackingNumberStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (trackingNumberStatus === 'rejected') {
      swalToast.fire({
        title: messCreateTrackingNumber || 'Something went wrong, please try again!',
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingNumberStatus]);

  useEffect(() => {
    if (createShipmentStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (createShipmentStatus === 'rejected') {
      swalToast.fire({
        title: messCreateShipment || 'Something went wrong, please try again!',
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createShipmentStatus]);

  useEffect(() => {
    if (createInvoiceStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (createInvoiceStatus === 'rejected') {
      swalToast.fire({
        title: messCreateInvoice || 'Something went wrong, please try again!',
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInvoiceStatus]);

  useEffect(() => {
    if (createRefundStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (createRefundStatus === 'rejected') {
      swalToast.fire({
        title: messCreateRefund || 'Something went wrong, please try again!',
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRefundStatus]);

  useEffect(() => {
    if (cancelOrderStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (cancelOrderStatus === 'rejected') {
      swalToast.fire({
        title: 'Something went wrong, please try again!',
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelOrderStatus]);

  useEffect(() => {
    if (holdOrderStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (holdOrderStatus === 'rejected') {
      swalToast.fire({
        title: 'Something went wrong, please try again!',
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdOrderStatus]);

  useEffect(() => {
    if (unHoldOrderStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
    } else if (unHoldOrderStatus === 'rejected') {
      swalToast.fire({
        title: 'Something went wrong, please try again!',
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unHoldOrderStatus]);

  useEffect(() => {
    if (deleteTrackingNumberStatus === 'fulfilled') {
      swalToast.fire({
        title: 'Delete Successfully!',
        icon: 'success',
      });
    } else if (deleteTrackingNumberStatus === 'rejected') {
      swalToast.fire({
        title: 'Something went wrong, please try again!',
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTrackingNumberStatus]);

  useEffect(() => {
    if (orderId) {
      const payload: iGetDataById = {
        id: orderId,
        accessToken,
      };
      dispatch(getDataById(payload, currentStore));
    }
    return () => {
      dispatch(clearData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeStatusOrder(statusWillChange: typeChangeStatusOrder) {
    const payload: iPayloadChangeStatusOrder = {
      accessToken,
      currentStore,
      orderId,
    };

    if (statusWillChange === 'cancel') {
      dispatch(cancelOrder(payload, navigate));
    } else if (statusWillChange === 'hold') {
      dispatch(holdOrder(payload, navigate));
    } else {
      dispatch(unHoldOrder(payload, navigate));
    }
  }

  const renderTabs = () => {
    return (
      <div className="tabs bg-white p-4 pb-0 border-rd-20">
        <div className="header d-flex justify-content-between align-items-center mb-3">
          <span className="fw-semibold fs-3">#{dataDetails.increment_id}</span>

          <div className="d-flex align-items-center gap-3">
            {dataDetails?.extension_attributes?.can_cancel === '1' && (
              <Button
                className="text-uppercase"
                onClick={() => handleChangeStatusOrder('cancel')}
                disabled={cancelOrderStatus === 'pending' || holdOrderStatus === 'pending'}
              >
                Cancel
              </Button>
            )}
            {dataDetails?.extension_attributes?.can_hold === '1' && (
              <Button
                className="text-uppercase"
                onClick={() => handleChangeStatusOrder('hold')}
                disabled={holdOrderStatus === 'pending' || cancelOrderStatus === 'pending'}
              >
                Hold
              </Button>
            )}
            {dataDetails?.extension_attributes?.can_unhold === '1' && (
              <Button className="text-uppercase" onClick={() => handleChangeStatusOrder('unhold')} disabled={unHoldOrderStatus === 'pending'}>
                Unhold
              </Button>
            )}
          </div>
        </div>
        {/* formatFilterStatus return status as text capitalize */}
        {formatStatus(dataDetails.status)}
        <div className="tab-wrapper d-flex">
          {TABS.map((item, i: number) => {
            return (
              <div
                onClick={() => tabSwitching(item.name)}
                className={`tabs--item cursor-pointer me-4 ${pathname.split('/').includes(item.path) ? 'active' : ''} `}
                key={i}
              >
                <Link
                  to={`${item.path}`}
                  className={`pb-12 text-decoration-none fs-6 fw-medium d-inline-block ${
                    pathname.split('/').includes(item.path) ? 'text-primary' : 'text-dark-600'
                  }`}
                >
                  {item.name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <DefaultLayout pageTitle={`Order Details`}>
      <div className="previous-link-wrap py-3 mb-4">
        <Link
          className="previous-link text-uppercase btn btn-outline-primary w-fit-content fw-medium rounded-pill fs-14 px-32 text-decoration-none d-flex align-items-center text-nowrap"
          to={`/orders/${queryString}`}
        >
          <FontAwesomeIcon className="me-3" icon={faArrowLeft} />
          Back To Order Listing
        </Link>
      </div>

      <div className="details-wrapper">
        <div className="row">
          <div className="col-sm-12 col-xxl-12">
            {renderTabs()}
            <Outlet />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default OrderDetails;
