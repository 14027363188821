import { FC, useEffect, useMemo } from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import Button from '~/components/common/Button';
import { Input } from '~/components/common/Input';
import Select from '~/components/common/Select';
import { Table } from '~/components/common/Table';

interface Props {
  formik: any;
  isCreateShipment?: boolean;
  showForm: boolean;
  setShowForm: any;
}

const TrackingNumber: FC<Props> = (props: Props) => {
  const { formik, isCreateShipment = false, showForm, setShowForm } = props;

  const { shipmentDetail, trackingNumberStatus: status } = useAppSelector((state) => state.orderReducer);
  const { order_id, entity_id } = shipmentDetail;

  useEffect(() => {
    setFieldValue('order_id', order_id);
    setFieldValue('entity_id', entity_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values, errors, handleSubmit, resetForm, handleChange, setFieldValue, setFieldError } = formik;

  const tableHeader = useMemo(
    () => [
      { name: 'Carrier', className: 'text-white bg-primary fs-14 fw-medium py-3' },
      { name: 'Title', className: 'text-white bg-primary fs-14 fw-medium py-3' },
      { name: 'Number', className: 'text-white bg-primary fs-14 fw-medium py-3' },
    ],
    [],
  );

  const optionsCarrier = useMemo(
    () => [
      { value: 'custom', label: 'Custom Value' },
      { value: 'zto', label: 'ZTO' },
      { value: 'dhl', label: 'DHL' },
      { value: 'fedex', label: 'Federal Express' },
      { value: 'ups', label: 'United Parcel Service' },
      { value: 'usps', label: 'United States Postal Service' },
    ],
    [],
  );

  function handleChangeSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    const objectSelected = JSON.parse(e.target.value);
    const { value, label } = objectSelected;

    setFieldValue('carrier_code', value);
    setFieldValue('title', label === 'Custom Value' ? '' : label);
  }

  function handleReset() {
    setShowForm(false);
    resetForm();
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <table className="w-100"></table>
        <div className="border-rd-20 overflow-auto wrapper">
          <Table status="fulfilled" dataTableHead={tableHeader}>
            <tr>
              <td className="td-item px-2 py-3 align-top">
                <div className="w-auto p-0">
                  <Select value={values.code} onChange={handleChangeSelect} className="w-100 ps-12 pe-32" name="carrier_code">
                    {optionsCarrier.map((o) => (
                      <option value={JSON.stringify(o)} key={o.value}>
                        {o.label}
                      </option>
                    ))}
                  </Select>
                </div>
              </td>
              <td className="td-item px-2 py-3 align-top">
                <Input type="text" name="title" value={values.title} onChange={handleChange} />
              </td>
              <td className="td-item px-2 py-3 align-top">
                <Input
                  type="text"
                  name="track_number"
                  value={values.track_number}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldError('track_number', undefined, false);
                  }}
                />
                {errors.track_number && <p className="fs-14 text-danger m-0 p-0 mt-2">{errors.track_number}</p>}
              </td>
            </tr>
          </Table>
        </div>

        <div className="d-flex gap-3 mt-3">
          {!isCreateShipment && (
            <Button type="submit" className="px-32" width="120px" disabled={status === 'pending'}>
              SAVE
            </Button>
          )}
          <Button outline className="px-32" width="120px" onClick={handleReset}>
            CANCEL
          </Button>
        </div>
      </form>
    );
  }

  return showForm ? (
    renderForm()
  ) : (
    <Button onClick={() => setShowForm(true)} className="text-uppercase">
      Add Tracking Number
    </Button>
  );
};

export default TrackingNumber;
