import { faChevronDown, faChevronUp, faPenToSquare, faFilter } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { dataProductHead, defaultMessageError } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import {
  addFilterGroup,
  addFilterItem,
  addFilters,
  addSortOrder,
  checkSite,
  filterEmptyProperties,
  formatMoney,
  getWebsiteId,
  searchCriteriaBuilder,
  swalConfirmDelete,
} from '~/app/utils';
import { builderCriteriaProduct } from '~/app/utils/builderCriteria';
import { convertStringToNumber } from '~/app/utils/convertBase64';
import { sorterCriteria } from '~/app/utils/sorterCriteria';
import Pagination from '~/components/common/Pagination';
import { Table } from '~/components/common/Table';
import DefaultLayout from '~/components/layout/DefaultLayout';
import { NoRecord } from '../Err';
import { getData, resetDataAndStatus, setIsDESC, setKeySearch, setQueryString } from './redux/action';
import { deleteProduct } from '../CreateEditProduct/redux/actions';
import './style.scss';
import { NumberFromToFilter, TextFilter, SelectFilter, FilterButtons, ResetFilterButton } from '~/components/common/Filter/filterInput';
import {
  formatEnableStatus,
  formatFilterActive,
  formatFilterEnableStatus,
  formatFilterVisibility,
  formatType,
  formatVisibility,
} from '~/components/common/Filter/formatFilter';
import { STATUS_OPTIONS, visibilityOptions, productTypeOptions } from '~/app/constants/filter';
import SearchBar from '~/components/common/Filter/searchBar';
import successImg from '~/app/images/success.png';
import Separator from '~/components/common/Separator';
import { iGetAllProduct } from '~/app/models';

export interface iApiStatus {
  name: string;
  btnStatus: string;
}

type FormFilterValues = {
  name: string;
  sku: string;
  idFrom: string;
  idTo: string;
  priceFrom: string;
  priceTo: string;
  qtyFrom: string;
  qtyTo: string;
  visibility: string;
  type: string;
  status: string;
  searchTerm: string;
};

const ProductListing: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Declare data from Reducers
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { data: dataUser, storeData } = useAppSelector((state) => state.userReducer);
  const { deleteProductStatus } = useAppSelector((state) => state.createEditProductReducer);
  const { data, status: productStatus, message, isDESC, keySearch, controller } = useAppSelector((state) => state.productReducer);

  const imgPath = process.env.REACT_APP_IMAGE_CATALOG_URL;
  const sitePath = currentStore ? checkSite(currentStore) : '';

  const { items, total_count } = data;
  const sort = sorterCriteria(keySearch, isDESC);
  const websiteList = JSON.parse(dataUser.website_ids || '{}');

  // Declare data from Query String
  const isFilter = Boolean(searchParams.get('isFilter'));
  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));

  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;
  const name = searchParams.get('name') || '';
  const sku = searchParams.get('sku') || '';
  const idFrom = searchParams.get('idFrom') || '';
  const idTo = searchParams.get('idTo') || '';
  const priceFrom = searchParams.get('priceFrom') || '';
  const priceTo = searchParams.get('priceTo') || '';
  const qtyFrom = searchParams.get('qtyFrom') || '';
  const qtyTo = searchParams.get('qtyTo') || '';
  const visibility = searchParams.get('visibility') || '';
  const type = searchParams.get('type') || '';
  const status = searchParams.get('status') || '';
  const searchTerm = searchParams.get('searchTerm') || '';

  // Declare initial Filter Payload
  const initialFilterPayload = {
    name,
    sku,
    idFrom,
    idTo,
    priceFrom,
    priceTo,
    qtyFrom,
    qtyTo,
    visibility,
    type,
    status,
    searchTerm,
  } as FormFilterValues;

  // Declare useState
  const [query, setQuery] = useState<string>(window.location.search);
  const [filterPayload, setFilterPayload] = useState<any>(initialFilterPayload);
  const [submitFilterPayload, setSubmitFilterPayload] = useState<any>(initialFilterPayload);
  const [showMenuAddProduct, setShowMenuAddProduct] = useState<Boolean>(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>();
  const [firstMounted, setFirstMounted] = useState<Boolean>(true);
  const [isEnableFilter, setIsEnableFilter] = useState<boolean>(false);
  const [websiteId, setWebsiteId] = useState<number>();

  //Declare getData Payload
  const filter = builderCriteriaProduct(submitFilterPayload);

  const payload: iGetAllProduct = {
    accessToken,
    searchUrl: searchCriteriaBuilder(
      convertStringToNumber(pageSize.toString(), 10),
      convertStringToNumber(currentPage.toString(), 1),
      // addFilterGroup(...filter, addFilters(addFilterItem('website_id', `${websiteId}`, 'in'))),
      websiteId == 1 ?  addFilterGroup(...filter) : addFilterGroup(...filter, addFilters(addFilterItem('website_id', `${websiteId}`, 'in'))),
      // addFilterGroup(...filter),
      addSortOrder(...sort),
    ),
    currentStore,
  };

  // Declare useEffect
  useEffect(() => {
    setWebsiteId(getWebsiteId(storeData, currentStore));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, currentStore]);

  useEffect(() => {
    setFirstMounted(false);
    return () => {
      controller && controller.abort();
      dispatch(resetDataAndStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstMounted) return;
    setIsEnableFilter(false);
    setFilterPayload({
      ...filterPayload,
      name: '',
      sku: '',
      idFrom: '',
      idTo: '',
      priceFrom: '',
      priceTo: '',
      qtyFrom: '',
      qtyTo: '',
      visibility: '',
      type: '',
      status: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      name: '',
      sku: '',
      idFrom: '',
      idTo: '',
      priceFrom: '',
      priceTo: '',
      qtyFrom: '',
      qtyTo: '',
      visibility: '',
      type: '',
      status: '',
      searchTerm: '',
    });
    setSearchParams({});
    resetUrlData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    dispatch(setQueryString(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (deleteProductStatus === 'fulfilled') {
      swalConfirmDelete
        .fire({
          title: `<img src="${successImg}" alt="success" className="mx-auto" />`,
          text: 'You have successfully deleted product',
          showCancelButton: false,
          showCloseButton: false,
          confirmButtonText: 'ok',
          customClass: {
            popup: 'p-32px border-rd-25 content-success bg-white d-flex flex-column gap-3',
            title: 'h3 text-dark text-center m-0 fw-semibold p-0',
            htmlContainer: 'message text-center m-0 fw-semibold',
            actions: 'mt-0 w-100',
            confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-100',
          },
        })
        .then(() => {
          if (websiteId) {
            dispatch(getData(payload, controller));
          }
        });
    } else if (deleteProductStatus === 'rejected') {
      swalConfirmDelete.fire({
        title: 'Delete Product',
        text: defaultMessageError,
        showCancelButton: false,
        showCloseButton: false,
        confirmButtonText: 'ok',
        customClass: {
          popup: 'p-32px border-rd-25 content-success bg-white d-flex flex-column gap-3',
          title: 'h3 text-dark text-center m-0 fw-semibold p-0',
          htmlContainer: 'message text-center m-0 fw-semibold',
          actions: 'mt-0 w-100',
          confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-100',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProductStatus]);

  useEffect(() => {
    // Run at first load
    if ((isFilter || isAdvancedFilter || (!isFilter && !isAdvancedFilter)) && websiteId) {
      dispatch(getData(payload, controller));
    }

    if (Object.keys(filterEmptyProperties(submitFilterPayload)).length === 0) {
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, storeData, currentStore, keySearch, isDESC, query, isFilterSubmit, websiteId]);

  //Change Filter Handler
  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isFilter) {
      setSearchParams({
        pageSize: value,
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  // Change current Page
  const onChangePage = (currentPage: number) => {
    if (isFilter) {
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: pageSize.toString(), currentPage: currentPage.toString() });
      resetUrlData();
    }
  };

  //Declare reset function
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      name: '',
      sku: '',
      idFrom: '',
      idTo: '',
      priceFrom: '',
      priceTo: '',
      qtyFrom: '',
      qtyTo: '',
      visibility: '',
      type: '',
      status: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      name: '',
      sku: '',
      idFrom: '',
      idTo: '',
      priceFrom: '',
      priceTo: '',
      qtyFrom: '',
      qtyTo: '',
      visibility: '',
      type: '',
      status: '',
      searchTerm: '',
    });
    setSearchParams({});
    resetUrlData();
    !isEnableFilter && setIsEnableFilter(true);
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${key}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${key}`]: '' });
    setSubmitFilterPayload({ ...filteredObject, [`${key}`]: '' });
    resetUrlData();
  };

  const resetFilterFromToSection = (keyFrom: string, keyTo: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${keyFrom}`]: '',
      [`${keyTo}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    setSubmitFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    resetUrlData();
  };

  //Declare submit filter function
  const searchKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = e;
    const filteredObject = filterEmptyProperties(filterPayload);
    if (key === 'Enter') {
      if (target instanceof HTMLInputElement) {
        setFilterPayload({ ...filterPayload, searchTerm: target.value });
        setSubmitFilterPayload(filterPayload);
        setSearchParams({
          currentPage: '1',
          pageSize: pageSize.toString(),
          searchTerm: target.value,
          ...filteredObject,
          isFilter: 'true',
        });
        resetUrlData();
        setIsFilterSubmit(true);
      }
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitFilterPayload({ ...filterPayload });
    const filteredObject = filterEmptyProperties(filterPayload);

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
    setIsFilterSubmit(true);
  };

  //Render Website option
  const renderWebsite = (data: any[]) => {
    return data.map((item: any, key: number) => (
      <p className="mb-0" key={key}>
        {websiteList[item]?.name}
      </p>
    ));
  };

  //Declare sort order function
  const sortOrder = (key: string) => {
    if (!key) return;
    keySearch !== key ? dispatch(setIsDESC(false)) : dispatch(setIsDESC(!isDESC));
    dispatch(setKeySearch(key));
  };

  //Declare delete product function
  const onClickDeleteProduct = (idProductDelete: number) => {
    swalConfirmDelete
      .fire({
        title: 'Delete Product',
        html: 'Are you sure?<br />This product will also be deleted from other stores.',
        width: 'auto',
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteProduct(idProductDelete, currentStore));
        }
      });
  };

  //Declare render component
  const renderFilterActive = () => {
    const filterSections = [
      { label: 'Keyword', value: searchTerm, resetHandler: () => resetFilterSection('searchTerm') },
      {
        label: 'Price',
        value: priceFrom + priceTo ? `${priceFrom ? priceFrom : '...'} - ${priceTo ? priceTo : '...'}` : '',
        resetHandler: () => resetFilterFromToSection('priceFrom', 'priceTo'),
      },
      {
        label: 'ID',
        value: idFrom + idTo ? `${idFrom ? idFrom : '...'} - ${idTo ? idTo : '...'}` : '',
        resetHandler: () => resetFilterFromToSection('idFrom', 'idTo'),
      },
      {
        label: 'Quantity',
        value: qtyFrom + qtyTo ? `${qtyFrom ? qtyFrom : '...'} - ${qtyTo ? qtyTo : '...'}` : '',
        resetHandler: () => resetFilterFromToSection('qtyFrom', 'qtyTo'),
      },
      { label: 'Type', value: formatType(type), resetHandler: () => resetFilterSection('type') },
      { label: 'Product Name', value: name, resetHandler: () => resetFilterSection('name') },
      { label: 'SKU', value: sku, resetHandler: () => resetFilterSection('sku') },
      {
        label: 'Visibility',
        value: formatFilterVisibility(visibility),
        resetHandler: () => resetFilterSection('visibility'),
      },
      {
        label: 'Status',
        value: formatFilterEnableStatus(status),
        resetHandler: () => resetFilterSection('status'),
      },
    ];

    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={() => resetFilter()} />}
          </div>
          <div className="border-top border-bottom p-3 align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  const renderFilter = () => {
    return (
      <div className={`filter col-sm-12 col-lg-12${isEnableFilter ? '' : ' d-none'}`}>
        <div className="filter-section bg-white mb-4 p-4 wrapper border-rd-20">
          <div className="row">
            <h5 className="col-12 mb-4 text-dark filter-title">Filters</h5>
            <NumberFromToFilter
              filterClass="col-lg-4 col-xl-3"
              label={'Price'}
              name={'price'}
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <NumberFromToFilter filterClass="col-lg-4 col-xl-3" label={'ID'} name={'id'} value={filterPayload} onChange={onFilterChangeHandler} />
            <NumberFromToFilter
              filterClass="col-lg-4 col-xl-3 mt-3 mt-lg-0"
              label={'Quantity'}
              name={'qty'}
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <SelectFilter
              filterClass="col-lg-4 col-xl-3 mt-3 mt-xl-0"
              label="Type"
              name="type"
              options={productTypeOptions}
              value={filterPayload.type}
              onChange={onFilterChangeHandler}
            />
            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              label="Product Name"
              name="name"
              value={filterPayload.name}
              onChange={onFilterChangeHandler}
            />
            <TextFilter filterClass="col-lg-4 col-xl-3 mt-3" label="SKU" name="sku" value={filterPayload.sku} onChange={onFilterChangeHandler} />
            <SelectFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              label="Visibility"
              name="visibility"
              options={visibilityOptions}
              value={filterPayload.visibility}
              onChange={onFilterChangeHandler}
            />
            <SelectFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              label="Status"
              name="status"
              options={STATUS_OPTIONS}
              value={filterPayload.status}
              onChange={onFilterChangeHandler}
            />
            <FilterButtons onSubmit={onSubmit} status={status} setIsEnableFilter={() => setIsEnableFilter(false)} />
          </div>
        </div>
      </div>
    );
  };

  const renderContentSection = () => {
    return (
      <div className="col-sm-12 col-lg-12">
        <Separator className="mb-4 d-flex flex-column flex-lg-row justify-content-end justify-content-lg-between align-items-end align-items-lg-center gap-3">
          <SearchBar
            name="searchTerm"
            status={productStatus}
            isTotalCount={true}
            totalCount={total_count}
            searchKeyPressHandler={searchKeyPressHandler}
            onFilterChangeHandler={onFilterChangeHandler}
            value={filterPayload.searchTerm}
          />
          <div className="text-end w-fit-content d-flex align-items-center justify-content-end justify-content-md-start">
            <div className="btn-add-product btn-border-radius btn btn-primary border-rd-25 fw-medium me-2 p-0 d-flex justify-content-between align-items-center cursor-pointer text-nowrap">
              <Link
                to="/product/create"
                className="border-0 text-white text-decoration-none d-flex align-items-center justify-content-center text-uppercase h-100 px-3 px-xl-4 flex-grow-1 fw-medium"
              >
                New Product
              </Link>
              <div className="toggle-select h-100 d-flex align-items-center" onClick={() => setShowMenuAddProduct(!showMenuAddProduct)}>
                <FontAwesomeIcon icon={showMenuAddProduct ? faChevronUp : faChevronDown} />
              </div>
              {showMenuAddProduct && (
                <div className="menu-add-product text-start">
                  <Link to="/product/create/simple" className="p-2 px-3 d-block text-decoration-none fw-normal">
                    Simple Product
                  </Link>
                  <Link to="/product/create/config" className="p-2 px-3 d-block text-decoration-none fw-normal">
                    Configurable Product
                  </Link>
                </div>
              )}
            </div>
            <div
              className="filters--button fs-14 d-flex justify-content-center align-items-center btn btn-border-radius fw-medium border-rd-25 text-uppercase mt-0"
              onClick={() => setIsEnableFilter(!isEnableFilter)}
            >
              <FontAwesomeIcon icon={faFilter} /> Filters
            </div>
          </div>
        </Separator>

        {renderFilter()}
        {renderFilterActive()}
        <div className="table-section bg-white wrapper overflow-auto border-rd-20">{renderTable()}</div>
        <Pagination
          className=""
          currentPage={+currentPage}
          pageSize={+pageSize}
          onChangePageSizeEvent={onChangePageSizeEvent}
          onChangePageEvent={onChangePage}
          status={productStatus}
          totalCount={total_count}
        />
      </div>
    );
  };

  // Belong to renderContentSection
  const renderTable = () => {
    return (
      <Table
        sortOrder={sortOrder}
        dataTableHead={dataProductHead}
        status={productStatus}
        message={message}
        className="striped-table table-responsive"
        keySearch={keySearch}
        isDESC={isDESC}
      >
        {productStatus === 'fulfilled' && items && items.length ? (
          items.map((item, i: number) => (
            <tr key={i}>
              <td className="td-item py-3 px-2 text-center fs-14 fw-light">{item.id}</td>
              <td className="td-item py-3 px-2">
                <a
                  className="d-flex justify-content-start align-items-center text-decoration-none text-dark"
                  href={`${sitePath}${item.custom_attributes
                    ?.filter((filterItem: any) => filterItem.value && filterItem.attribute_code === 'url_key')
                    .map((item: any) => item.value)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* Take picture has type = thumbnail */}
                  <img
                    alt="product"
                    className="logo-product-sm me-2"
                    src={
                      item.media_gallery_entries.filter((item: any) => item.types.includes('thumbnail')).map((item: any) => item.file).length !== 0
                        ? (imgPath || '') +
                          item.media_gallery_entries.filter((item: any) => item.types.includes('thumbnail')).map((item: any) => item.file)
                        : require('~/app/images/thumbnail.png')
                    }
                  />
                  <span className="fw-medium fs-14 position-relative product-name text-capitalize">{item.name}</span>
                </a>
              </td>
              <td className="td-item py-3 px-2 fs-14 fw-normal">{formatType(item.type_id)}</td>
              <td className="td-item py-3 px-2 fw-normal fs-14">{item.sku}</td>
              <td className="td-item py-3 px-2 fw-normal fs-14 text-end">
                {item.type_id !== 'configurable' ? formatMoney(item.price || 0, currentStore) : ''}
              </td>
              <td className="td-item py-3 px-2 fw-normal fs-14 text-end">{item.extension_attributes.stock_item?.qty}</td>
              <td className="td-item py-3 px-2 fw-normal fs-14 text-end">
                {item.type_id !== 'configurable' ? (
                  <>
                    <p className="mb-0">Default Stock: </p>
                    <p className="mb-0">{item.extension_attributes?.salable_quantity || 0}</p>
                  </>
                ) : (
                  ''
                )}
              </td>
              <td className="td-item py-3 px-2 fw-normal fs-14">{formatVisibility(item.visibility)}</td>

              <td className="td-item py-3 px-2 fw-normal fs-14 text-nowrap">{renderWebsite(item.extension_attributes?.website_ids || ' ')}</td>
              <td className="td-item py-3 px-2 fw-normal text-center fs-14">
                <p className="mb-0 fw-semibold text-center">{formatEnableStatus(item.status)}</p>
              </td>
              <td className="td-item py-3 px-2 text-center">
                <div className="d-flex justify-content-center">
                  <div className="fw-light mb-0 text-center cursor-pointer text-primary edit-icon">
                    <Link to={`/product/edit/${item.id}`}>
                      <FontAwesomeIcon className="fs-4" icon={faPenToSquare} />
                    </Link>
                  </div>
                  <div className="fw-light mb-0 text-center cursor-pointer text-danger trash-icon ms-3">
                    <span onClick={() => onClickDeleteProduct(item.id)}>
                      <FontAwesomeIcon className="fs-4" icon={faTrashCan} />
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <NoRecord tableHeaders={dataProductHead} message={message} />
        )}
      </Table>
    );
  };

  return (
    <DefaultLayout pageTitle="Product Listing">
      <div className="content-wrapper">
        <div className="page-header">
          <div className="row">{renderContentSection()}</div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ProductListing;
