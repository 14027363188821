import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import './style.scss';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  width?: string;
  outline?: boolean;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  showLoadingLeft?: boolean;
  showLoadingRight?: boolean;
  onClick?: () => void;
}

const Button = (props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const {
    children,
    type = 'button',
    disabled = false,
    className = '',
    outline = '',
    width,
    iconLeft,
    iconRight,
    showLoadingLeft,
    showLoadingRight,
    onClick,
  } = props;

  const classNames = `button ${className} ${outline && 'btn-outline'}`;

  return (
    <button ref={ref} disabled={disabled} className={classNames} type={type} onClick={onClick} style={{ width: width || 'unset' }}>
      {iconLeft && <FontAwesomeIcon icon={iconLeft} />}
      {showLoadingLeft && <FontAwesomeIcon icon={faSpinner} className="loading-icon" />}
      {children}
      {iconRight && <FontAwesomeIcon icon={iconRight} />}
      {showLoadingRight && <FontAwesomeIcon icon={faSpinner} className="loading-icon" />}
    </button>
  );
};

export default forwardRef(Button);
