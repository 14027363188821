import * as types from '.';
import ticketApi from '~/app/apis/ticket.api';
import { iPostAttachment, iPostMessage, iPostQuickAnswer, iPutQuickAnswer, iPutTicket } from '~/app/models';
import orderApi from '~/app/apis/order.api';
import { addFilterGroup, addFilterItem, addFilters, getStoreId, searchCriteriaBuilder } from '~/app/utils';
import sellerApi from '~/app/apis/seller.api';
import { getQtyNewTicket } from '~/components/layout/FullWidthLayout/redux/actions';
import { defaultMessageError } from '~/app/constants';

const { rest: actions } = types;

export const resetData = () => (dispatch: any) => {
  dispatch(actions.resetData());
};

export const getTicketListing =
  (searchURL: any, accessToken: string, store: string = 'all', cancelController: AbortController | null) =>
  async (dispatch: any) => {
    cancelController && cancelController.abort();
    dispatch(actions.getTicketListingPending());
    const controller = new AbortController();
    dispatch(actions.changeController(controller));
    try {
      const res = await ticketApi.getTicketListing(searchURL, accessToken, store, controller);
      const { data } = res;
      !data ? dispatch(actions.getTicketListingRejected()) : dispatch(actions.getTicketListingFulfilled(data));
    } catch (error: any) {
      if (error.message === 'canceled') return;

      dispatch(actions.getTicketListingRejected());
    }
  };

export const setUrlData = (payload: { pageSize: number; currentPage: number }) => (dispatch: any) => {
  dispatch(actions.setUrlData(payload));
};

export const setQueryString = (q: string) => (dispatch: any) => {
  dispatch(actions.setQueryString(q));
};

export const resetProductQueryString = () => (dispatch: any) => {
  dispatch(actions.resetQueryString());
};

export const getListQuickAnswer = (searchURL: string, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.getListQuickAnswerPending());

  try {
    const res = await ticketApi.getQuickAnswer(searchURL, accessToken, currentStore);
    const { items } = res.data;
    dispatch(actions.getListQuickAnswerFulfilled(items));
  } catch (error) {
    dispatch(actions.getListQuickAnswerRejected());
  }
};

export const getTicketDetails = (ticketId: string, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.getTicketDetailsPending());
  try {
    let data;

    const { data: dataGetInfoTicket } = await ticketApi.getTicketDetails(ticketId, accessToken, currentStore);
    const { category_id, department_id, order_id } = dataGetInfoTicket;

    // GET department
    const { data: dataGetDepartment } = await ticketApi.getDepartmentById(department_id, accessToken, currentStore);
    const { title: department_name } = dataGetDepartment;
    const { data: dataGetCate } = await ticketApi.getCategoryById(category_id, accessToken, currentStore);
    const { title: category_name } = dataGetCate;
    data = { ...dataGetInfoTicket, department_name, category_name };

    if (order_id) {
      try {
        const { data: dataGetOrderById } = await orderApi.getOrderDetails(
          {
            id: order_id,
            accessToken,
          },
          currentStore,
        );
        dispatch(actions.getOrderByIdFulfilled(dataGetOrderById));
      } catch (error) {
      } finally {
        dispatch(actions.getTicketDetailsFulfilled(data));
      }
    } else {
      dispatch(actions.getTicketDetailsFulfilled(data));
    }
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.getTicketDetailsRejected(message || defaultMessageError));
  }
};

export const getMessageByTicketId = (searchURL: string, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.getListMessageByTicketIdPending());
  try {
    const { data } = await ticketApi.getMessageByTicketId(searchURL, accessToken, currentStore);
    const { items: listMessage } = data;
    const listMessageId = listMessage.map((message: any) => message.message_id);

    try {
      const searchAttachmentOfMessageId = searchCriteriaBuilder(
        Number.MAX_VALUE,
        1,
        addFilterGroup(addFilters(addFilterItem('message_id', `${listMessageId.join(',')}`, 'in'))),
      );
      const { data } = await ticketApi.getAttachmentOfMessageId(searchAttachmentOfMessageId, accessToken, currentStore);
      const { items: listAttachment } = data;

      const newListAttachment = listAttachment.filter((attachment: any) => attachment.body);

      dispatch(actions.getListMessageByTicketIdFulfilled(listMessage));
      dispatch(actions.getListAttachmentByMessageId(newListAttachment));
    } catch (error) {
      dispatch(actions.getListMessageByTicketIdRejected());
    }
  } catch (error) {
    dispatch(actions.getListMessageByTicketIdRejected());
  }
};

export const getAllVender = (searchURL: string, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.getAllVenderPending());
  try {
    const { data } = await sellerApi.getAllSeller({
      accessToken,
      currentStore,
      searchUrl: searchURL,
    });

    const { items: listVender } = data;

    dispatch(actions.getAllVenderFulfilled(listVender));
  } catch (error) {
    dispatch(actions.getAllVenderRejected());
  }
};

export const postQuickAnswer = (payload: iPostQuickAnswer, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.postQuickAnswerPending());
  try {
    await ticketApi.postQuickAnswer(payload, accessToken, currentStore);
    dispatch(actions.postQuickAnswerFulfilled());
  } catch (error) {
    dispatch(actions.postQuickAnswerRejected());
  }
};

export const postMessage =
  (
    payloadMessage: iPostMessage,
    payloadAttachment: iPostAttachment | undefined,
    accessToken: string,
    currentStore: string,
    messageEditorRef: React.RefObject<HTMLParagraphElement>,
    ticketId: string,
    resetAttachment: React.Dispatch<any>,
    setDisabledButtonSendMessage: React.Dispatch<boolean>,
  ) =>
  async (dispatch: any) => {
    dispatch(actions.postMessagePending());
    setDisabledButtonSendMessage(true);

    try {
      const { data } = await ticketApi.postMessage(payloadMessage, accessToken, currentStore);

      // Remove text when success
      if (messageEditorRef.current) {
        messageEditorRef.current.innerHTML = '';
      }

      // POST attachment
      if (payloadAttachment) {
        const { message_id } = data;

        const newPayloadAttachment = {
          attachment: {
            ...payloadAttachment?.attachment,
            message_id,
          },
        };

        try {
          await ticketApi.postAttachmentOfMessageId(newPayloadAttachment, accessToken, currentStore);

          // Reset attachment
          resetAttachment(undefined);

          dispatch(actions.postMessageFulfilled());
        } catch (error: any) {
          const { message } = error?.response?.data || {};
          dispatch(actions.postMessageRejected(message || defaultMessageError));
        } finally {
          dispatch(
            getMessageByTicketId(
              searchCriteriaBuilder(Number.MAX_VALUE, 1, addFilterGroup(addFilters(addFilterItem('ticket_id', ticketId, 'eq')))),
              accessToken,
              currentStore,
            ),
          );
          dispatch(actions.postMessageFulfilled());
        }
      } else {
        dispatch(
          getMessageByTicketId(
            searchCriteriaBuilder(Number.MAX_VALUE, 1, addFilterGroup(addFilters(addFilterItem('ticket_id', ticketId, 'eq')))),
            accessToken,
            currentStore,
          ),
        );
        dispatch(actions.postMessageFulfilled());
      }
    } catch (error) {
      //  Enable button send message
      setDisabledButtonSendMessage(false);
      dispatch(actions.postMessageRejected(defaultMessageError));
    }
  };

export const putTicket = (ticketId: string, payload: iPutTicket, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.putTicketPending());
  try {
    const { data } = await ticketApi.putTicket(ticketId, payload, accessToken, currentStore);

    dispatch(actions.getTicketDetailsFulfilled(data));
    dispatch(actions.putTicketFulfilled());
  } catch (error) {
    dispatch(actions.putTicketRejected());
  }
};

export const putTicketToRead =
  (ticketId: string, payload: iPutTicket, accessToken: string, currentStore: string, storeData: any) => async (dispatch: any) => {
    try {
      await ticketApi.putTicket(ticketId, payload, accessToken, currentStore);

      if (getStoreId(storeData, currentStore)) {
        dispatch(
          getQtyNewTicket(
            searchCriteriaBuilder(
              Number.MAX_VALUE,
              1,
              addFilterGroup(
                addFilters(addFilterItem('is_read', '0', 'eq')),
                addFilters(addFilterItem('store_id', `${getStoreId(storeData, currentStore)}`, 'eq')),
              ),
            ),
            accessToken,
            currentStore,
          ),
        );
      }
    } catch (error) {}
  };

export const deleteQuickAnswer =
  (
    quickAnswerId: string,
    accessToken: string,
    currentStore: string,
    setActiveTooltipSettingId: React.Dispatch<React.SetStateAction<string | undefined>>,
  ) =>
  async (dispatch: any) => {
    dispatch(actions.deleteQuickAnswerPending());
    try {
      await ticketApi.deleteQuickAnswer(quickAnswerId, accessToken, currentStore);

      dispatch(actions.deleteQuickAnswerFulfilled());
      setActiveTooltipSettingId(undefined);
    } catch (error) {
      dispatch(actions.deleteQuickAnswerRejected());
    }
  };

export const putQuickAnswerListFieldIsActive = (payload: any, accessToken: string, currentStore: string) => async (dispatch: any) => {
  dispatch(actions.putQuickAnswerPending());

  const arrPayload = Object.entries(payload).map((item) => {
    const [quickAnswerId, isActive] = item;

    const payload: iPutQuickAnswer = {
      Quickanswer: {
        quickanswer_id: quickAnswerId,
        is_active: isActive ? '1' : '0',
      },
    };
    return ticketApi.putQuickAnswer(quickAnswerId, payload, accessToken, currentStore);
  });

  Promise.all(arrPayload)
    .then((res) => {
      dispatch(actions.putQuickAnswerFulfilled());
    })
    .catch((error) => {
      dispatch(actions.putQuickAnswerRejected());
    });
};
export const putQuickAnswer =
  (quickAnswerId: string, payload: iPutQuickAnswer, accessToken: string, currentStore: string) => async (dispatch: any) => {
    dispatch(actions.putQuickAnswerPending());

    try {
      await ticketApi.putQuickAnswer(quickAnswerId, payload, accessToken, currentStore);
      dispatch(actions.putQuickAnswerFulfilled());
    } catch (error) {
      dispatch(actions.putQuickAnswerRejected());
    }
  };

// SYNCHRONIZED
export const resetStatusQuickAnswer = () => (dispatch: any) => {
  dispatch(actions.resetStatusQuickAnswer());
};
