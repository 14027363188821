import { Status, iTableHead } from './../../../app/models';
import { FC } from 'react';

import './style.scss';
import Loading from '../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { NoRecord } from '~/components/pages/Err';
import CustomCheckBox from '../CustomCheckBox';

interface Props {
  children: JSX.Element | JSX.Element[];
  dataTableHead: iTableHead[];
  className?: string;
  status: Status;
  message?: string;
  sortOrder?: any;
  keySearch?: string;
  isDESC?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
}

export const Table: FC<Props> = (props: Props) => {
  const { className = '', dataTableHead, children, status, message, sortOrder, keySearch, isDESC, onChange, checked } = props;

  //   Render table header
  const RenderTableHeader = () => {
    return (
      <thead>
        <tr>
          {dataTableHead.map((item) => (
            <td
              className={`${item.className}`}
              key={Math.random()}
              data-title={item.name}
              id={item.key}
              onClick={() => item.key && sortOrder(item.key)}
            >
              {item.isCheckbox && onChange && (
                // <input className="rounded-3 custom-input-checkbox cursor-pointer" type="checkbox" onChange={onChange} checked={checked} />
                <div className="checkbox-sizing">
                  <CustomCheckBox className="checkbox-all-custom rounded-3 cursor-pointer" onChange={onChange} checked={checked} />
                </div>
              )}

              <span>{item.name}</span>
              {keySearch && keySearch === item.key && <FontAwesomeIcon icon={isDESC ? faArrowDown : faArrowUp} className="ms-1" />}
            </td>
          ))}
        </tr>
      </thead>
    );
  };

  return (
    <table className={`table m-0 ${className}`}>
      <RenderTableHeader />
      <tbody>
        {(status === 'pending' || !status) && (
          <tr>
            <td className="text-center" colSpan={dataTableHead.length}>
              <Loading />
            </td>
          </tr>
        )}
        {status === 'fulfilled' && children}
        {status === 'rejected' && <NoRecord tableHeaders={dataTableHead} message={message} />}
      </tbody>
    </table>
  );
};
