import { faArrowLeft, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iGetCustomer } from '~/app/models';
import AsyncSection from '~/components/common/AsyncSection';
import DefaultLayout from '~/components/layout/DefaultLayout';

import { clearData, getDataById, getDataStatusById, setIsEnableFilter } from '../redux/action';
import Separator from '~/components/common/Separator';

interface Props {}

const CustomerDetails: FC<Props> = (props: Props) => {
  const { pathname } = useLocation();
  const arrPathName = pathname.split('/');
  const { customerId } = useParams();

  // Declare useState
  const [, setTab] = useState<string>('Overview');

  // Declare data from Reducers
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { status, isEnableFilter, queryOrderString, queryString } = useAppSelector((state) => state.customerReducer);
  const { status: oderStatus, data } = useAppSelector((state) => state.orderReducer);

  const { total_count } = data;

  const TABS = [
    { name: 'Overview', path: 'overview/' },
    { name: 'Account Information', path: 'information/' },
    { name: 'Orders', path: `customer-orders/${queryOrderString}` },
    // { name: 'Cart', path: `cart/${queryCartString}` },
    // { name: 'Product Reviews', path: `product-review/${queryReviewString}` },
  ];

  useEffect(() => {
    if (customerId) {
      const payload: iGetCustomer = {
        customerId: customerId,
        currentStore: currentStore,
        accessToken,
      };
      dispatch(getDataById(payload));
      dispatch(getDataStatusById(payload));
    }
    return () => {
      dispatch(clearData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabSwitching = (tab: string) => {
    setTab(tab);
  };

  const renderContentSection = () => {
    return (
      <div className="col-sm-12 col-lg-12">
        <Separator className="mb-4">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 col-xl-6 order-0 order-md-1 d-flex align-items-center justify-content-end">
              {oderStatus === 'fulfilled' && pathname.split('/').includes('customer-orders') && (
                <span className="fw-normal fs-14  border-0 w-70 text-end me-4 w-fit-content text-nowrap">Total {total_count} Record(s) Found</span>
              )}
            </div>
            <div className="col-12 col-lg-6 col-xl-6 order-1 order-md-0">
              <div className="btn-customer-group">
                {pathname.split('/').includes('customer-orders') || pathname.split('/').includes('cart') ? (
                  <>
                    <button
                      className="back--button btn btn-outline-primary btn-border-radius  text-uppercase me-2"
                      onClick={() => dispatch(setIsEnableFilter(!isEnableFilter))}
                    >
                      <FontAwesomeIcon icon={faFilter} /> Filters
                    </button>
                  </>
                ) : (
                  ''
                )}

                <Link
                  to={`/customers/${queryString}`}
                  className="back--button btn btn-outline-primary btn-border-radius  text-uppercase text-decoration-none"
                >
                  <FontAwesomeIcon className="fw-medium fs-14 me-2" icon={faArrowLeft} />
                  Back to Customer Listing
                </Link>
              </div>
            </div>
          </div>
        </Separator>

        <div className="tab-wrapper d-flex">
          {TABS.map((item, i: number) => {
            return (
              <div
                onClick={() => tabSwitching(item.name)}
                className={`tabs--item cursor-pointer pb-12 me-4${arrPathName[3]?.includes(item.path.split('/')[0]) ? ' active' : ''}`}
                key={i}
              >
                <Link
                  to={`${item.path}`}
                  className={`text-decoration-none fw-medium link-hover-primary ${
                    arrPathName[3]?.includes(item.path.split('/')[0]) ? 'text-primary' : 'text-dark-600'
                  }`}
                >
                  {item.name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <DefaultLayout pageTitle={`Customer Details`}>
      <div className="content-wrapper">
        <div className="page-header">
          <div className="row">
            {renderContentSection()}
            <AsyncSection status={status} className="col-sm-12 col-xxl-12">
              <Outlet />
            </AsyncSection>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default CustomerDetails;
