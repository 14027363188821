import { defaultMessageError } from '../constants';

export function slugify(str: string) {
  str = str.toLowerCase().trim();

  // Remove viename language
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');

  return str
    .replace(/[&/\\#,+()$~%.'":*?<>{}!]/g, '')
    .replace(/-+/g, ' ')
    .trim()
    .split(' ')
    .filter((item) => item)
    .join('-');
}

export function convertSize(sizeBytes: number): string {
  if (sizeBytes === 0 || Number.isNaN(+sizeBytes)) {
    return '0 B';
  }

  const sizeNames = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = 0;
  while (sizeBytes > 1024 && i < sizeNames.length - 1) {
    sizeBytes /= 1024;
    i++;
  }
  return `${Math.round(sizeBytes * 100) / 100} ${sizeNames[i]}`;
}

export function convertDescriptionToPayload(description: string) {
  if (!description || typeof description !== 'string') return '';

  return `<div style="max-width:1400px">${description
    .replaceAll('<div data-oembed-url', '<div style="width: 100%; height: auto;; max-width:1400px" data-oembed-url')
    .replaceAll('<table', '<table style="border: 1px solid black;border-collapse: collapse;max-width: 1400px;"')
    .replaceAll('<th', '<th style="border: 1px solid black;border-collapse: collapse;"')
    .replaceAll('<td', '<td style="border: 1px solid black;border-collapse: collapse"')}</div>`
    .replaceAll('alt="desktop-image"', 'class="pagebuilder-mobile-hidden" data-element="desktop_image"')
    .replaceAll('alt="mobile_image"', 'class="pagebuilder-mobile-only" data-element="mobile_image"');
}

export function convertReviewStatusIdToText(statusId: number): string {
  switch (statusId) {
    case 1:
      return 'Approved';
    case 2:
      return 'Pending';
    case 3:
      return 'Not Approved';
    default:
      return 'Unknown';
  }
}

export function convertReviewStatusIdToClassName(statusId: number): string {
  switch (statusId) {
    case 1:
      return 'text-success';
    case 2:
      return 'text-warning';
    case 3:
      return 'text-danger';
    default:
      return 'text-primary';
  }
}

export function convertReviewValueTypeToText(typeId: string): string {
  switch (typeId) {
    case '1':
      return 'Administrator';
    case '2':
      return 'Customer';
    case '3':
      return 'Guest';
    default:
      return '';
  }
}

export function convertKeyToLabelOfPromotionFeature(key: string): string {
  switch (key) {
    case 'sku_in':
      return 'Is Select';
    case 'name':
      return 'Product Name';
    case 'sku':
      return 'SKU';
    case 'type_id':
      return 'Type';
    case 'attribute_set_id':
      return 'Attribute Set';
    default:
      return '';
  }
}

export function convertScope(scope: string): string {
  switch (scope) {
    case 'global':
      return 'Global';
    case 'store':
      return 'Store View';
    case 'website':
      return 'Web Site';
    default:
      return scope;
  }
}

export function convertFormInputToText(text: string): string {
  switch (text) {
    case 'text':
      return '[TEXT FIELD]';
    case 'textarea':
      return '[TEXT AREA]';
    case 'boolean':
      return '[YES/NO]';
    case 'select':
      return '[DROPDOWN]';
    case 'multiselect':
      return '[MULTIPLE SELECT]';
    case 'date':
      return '[DATE]';
    case 'image':
      return '[IMAGE]';
    case 'rating':
      return '[RATING]';
    default:
      return `[${text.toLocaleLowerCase()}]`;
  }
}

export function convertMessageError(responseError: any): string {
  const parameters = responseError?.response?.data?.parameters;
  let message = responseError?.response?.data?.message || defaultMessageError;

  for (let i = 0; i < parameters?.length; i++) {
    message = message.replace(`%${i + 1}`, parameters[i]);
  }

  return message;
}

export function convertImageBase64ToPayload(imageBase64: string): string {
  if (typeof imageBase64 !== 'string') return imageBase64;

  return imageBase64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
}
