import { faFilter, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { convertStatus, dataRefundedReportHead, keysName, reportTimeConvert, STATUS } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iQueries, iReport } from '~/app/models/report';
import { filterEmptyProperties, formatMoney, reportSearchCriteria } from '~/app/utils';
import { FilterButtons, ResetFilterButton } from '~/components/common/Filter/filterInput';
import Pagination from '~/components/common/Pagination';
import { Table } from '~/components/common/Table';
import { NoRecord } from '~/components/pages/Err';
import { getRefundedReport, reportReset, resetStatus, setQueries } from '../redux/actions';
import filterSchema from '../schema';
import Select from '~/components/common/Select';
import { Input } from '~/components/common/Input';

interface props {}

interface iInitialValues {
  period: string;
  from: string;
  to: string;
  status: string;
}

export const RefundedReport: React.FC<props> = (props: props) => {
  const dispatch = useAppDispatch();
  const [isEnableFilterBoard, setIsEnableFilterBoard] = useState<boolean>(false);
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);

  const { status: refundStatus, refunded, refundedQueries } = useAppSelector((s) => s.reportReducer);
  const { items, total_count, total } = refunded;

  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;
  const from = searchParams.get('from') || '';
  const to = searchParams.get('to') || '';
  const status = searchParams.get('status') || '';
  const period = searchParams.get('period') || '';
  const isFilter = searchParams.get('isFilter') || '';

  const initialValues: iInitialValues = {
    period,
    from,
    to,
    status,
  };

  const type = 'refund';

  const { values, handleChange, resetForm, handleSubmit, setFieldValue, errors } = useFormik({
    initialValues,
    validationSchema: filterSchema,
    validateOnChange: false,

    onSubmit: (values) => {
      const filteredObject = filterEmptyProperties(values);
      setSearchParams({
        ...filteredObject,
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        isFilter: 'true',
      });
      resetUrlData();
    },
  });

  const fetchData = () => {
    const data = {
      period,
      from,
      to,
      status,
    };
    const filteredObject: any = filterEmptyProperties(data);

    if (!Object.keys(filteredObject).length) {
      return;
    }

    const payload: iReport = {
      accessToken,
      currentStore,
      searchUrl: reportSearchCriteria({
        pageSize: +pageSize,
        currentPage: +currentPage,
        ...filteredObject,
      }),
    };
    dispatch(getRefundedReport(payload));
  };

  // Change Page Size
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const data = {
      period,
      from,
      to,
      status,
    };
    const filteredObject: any = filterEmptyProperties(data);
    setSearchParams({ ...filteredObject, pageSize: value, currentPage: '1', isFilter: (!!Object.keys(filteredObject).length).toString() });
    resetUrlData();
  };

  // Change current Page
  const onChangePage = (currentPage: number) => {
    const filteredObject = filterEmptyProperties(values);
    setSearchParams({
      ...filteredObject,
      pageSize: pageSize.toString(),
      currentPage: currentPage.toString(),
      isFilter: 'true',
    });
    resetUrlData();
  };

  const resetUrlData = (): void => {
    const { search } = window.location;

    const payload: iQueries = {
      type,
      query: search,
    };

    dispatch(setQueries(payload));
  };

  useEffect(() => {
    if (Object.keys(filterEmptyProperties(values)).length) {
      setSearchParams({
        ...values,
        isFilter: 'true',
        pageSize: pageSize.toString(),
        currentPage: currentPage.toString(),
      });
    }

    return () => {
      dispatch(resetStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFilter) {
      fetchData();
      resetUrlData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage, refundedQueries]);

  useEffect(() => {
    if (!Object.keys(filterEmptyProperties(values)).length) {
      dispatch(reportReset(type));
      setSearchParams({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const removeFilterSection = (key: string) => {
    if (['from', 'to'].includes(key)) {
      setFieldValue('from', '');
      setFieldValue('to', '');
      setSearchParams({ ...values, currentPage: currentPage.toString(), pageSize: pageSize.toString(), from: '', to: '', isFilter: 'true' });
    } else {
      setFieldValue(key, '');
      setSearchParams({ ...values, currentPage: currentPage.toString(), pageSize: pageSize.toString(), [key]: '', isFilter: 'true' });
    }
    resetUrlData();
  };

  const resetFilterSection = () => {
    !isEnableFilterBoard && setIsEnableFilterBoard(true);
    resetForm();
    setSearchParams({});
    setFieldValue('from', '');
    setFieldValue('to', '');
    setFieldValue('period', '');
    setFieldValue('status', '');
    dispatch(reportReset(type));
  };

  const convertReportStatus = (key: string) => {
    if (!key) return;
    return convertStatus[key];
  };

  const renderResetFilter = () => {
    return (
      <div className="text-start w-100 my-4 mt-xxl-0 d-flex align-items-center justify-content-end justify-content-md-start">
        <div
          className="filters--button w-fit-content d-flex justify-content-center align-items-center btn btn-border-radius fw-medium border-rd-25 fs-14 text-uppercase mt-0"
          onClick={() => setIsEnableFilterBoard(!isEnableFilterBoard)}
        >
          <FontAwesomeIcon icon={faFilter} className="me-12" /> Filters
        </div>

        {refundStatus !== 'pending' && <span className="ms-4 fw-normal border-0 fs-14">Total {total_count} Record(s) Found</span>}
      </div>
    );
  };

  const renderFilterSelected = () => {
    const data = {
      period,
      from,
      to,
      status,
    };
    const filteredObject: any = filterEmptyProperties(data);
    const filterData: any = Object.keys(filteredObject);

    if (!filterData.length) return;

    return (
      <div className="col-12 mb-5">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className="p-3 fs-6 fw-medium">Active Filters</span>
          <ResetFilterButton onClick={() => resetFilterSection()} />
        </div>
        <div className="border-top border-bottom p-3 align-items-center">
          {filterData.map((key: string, i: number) => {
            return (
              <div className="filter-active justify-content-center align-items-center" key={i}>
                {key === 'from' && (
                  <>
                    <span className="fw-medium me-1">Filter Date:</span>
                    <span className="fw-light">
                      {moment(from).format('MM/DD/YYYY')} - {moment(to).format('MM/DD/YYYY')}
                    </span>
                    <FontAwesomeIcon
                      className="ms-2 btn btn-gray reset-filter-active fs-7 rounded-circle"
                      icon={faXmark}
                      onClick={() => removeFilterSection(key)}
                    />
                  </>
                )}

                {key === 'status' && (
                  <>
                    <span className="fw-medium me-1">{keysName[key]}:</span>
                    <span className="fw-light">{convertReportStatus(status)}</span>
                    <FontAwesomeIcon
                      className="ms-2 btn btn-gray reset-filter-active fs-7 rounded-circle"
                      icon={faXmark}
                      onClick={() => removeFilterSection(key)}
                    />
                  </>
                )}
                {key === 'period' && (
                  <>
                    <span className="fw-medium me-1">{keysName[key]}:</span>
                    <span className="fw-light text-capitalize">{period}</span>
                    <FontAwesomeIcon
                      className="ms-2 btn btn-gray reset-filter-active fs-7 rounded-circle"
                      icon={faXmark}
                      onClick={() => removeFilterSection(key)}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <div className="overflow-auto">
        <Table className="bg-white order-table wrapper overflow-hidden" status={refundStatus || 'rejected'} dataTableHead={dataRefundedReportHead}>
          <>
            {items?.length ? (
              items.map((item, i: number) => (
                <tr key={i}>
                  <td className="fs-14 td-item px-2 py-3 text-end white-space-pre w-10">{reportTimeConvert(item.period, period)}</td>
                  <td className="fs-14 td-item px-2 py-3 text-end">{item.orders_count}</td>
                  <td className="fs-14 td-item px-2 py-3 text-end">{formatMoney(+item.refunded, currentStore)}</td>
                  <td className="fs-14 td-item px-2 py-3 text-end">{formatMoney(+item.online_refunded, currentStore)}</td>
                  <td className="fs-14 td-item px-2 py-3 text-end">{formatMoney(+item.offline_refunded, currentStore)}</td>
                </tr>
              ))
            ) : (
              <NoRecord tableHeaders={dataRefundedReportHead} />
            )}
            {items?.length ? (
              <tr>
                <td className="fs-14 td-item bg-total fw-medium px-2 py-3 text-end w-10">TOTAL</td>
                <td className="fs-14 td-item bg-total fw-medium px-2 py-3 text-end">{total[0]['orders_count']}</td>
                <td className="fs-14 td-item bg-total fw-medium px-2 py-3 text-end">{formatMoney(+total[0].refunded, currentStore)}</td>
                <td className="fs-14 td-item bg-total fw-medium px-2 py-3 text-end">{formatMoney(+total[0].online_refunded, currentStore)}</td>
                <td className="fs-14 td-item bg-total fw-medium px-2 py-3 text-end">{formatMoney(+total[0].offline_refunded, currentStore)}</td>
              </tr>
            ) : (
              ''
            )}
          </>
        </Table>
      </div>
    );
  };

  const renderFilterBoard = () => {
    return (
      <div className={`bg-white filter-board${isEnableFilterBoard ? '' : ' d-none'} p-4 wrapper mb-5`}>
        <div className="filter-section bg-white">
          <div className="row">
            <h5 className="col-12 mb-4 text-dark filter-title">Filters</h5>
            <div className="col-sm-12 col-lg-3 mb-3 col-xl-3">
              <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
                Period
              </label>
              <div className="col-lg-12 d-flex align-items-center">
                <Select name="period" className="w-100" onChange={handleChange} value={values.period}>
                  <option value="day">Day</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                </Select>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3 mb-3 col-xl-3">
              <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
                Start Date<span className="fw-semibold text-danger">*</span>
              </label>
              <Input type="date" className="w-100" name="from" value={values.from} onChange={handleChange} />
              <span className="fs-7 text-danger">{errors?.from}</span>
            </div>
            <div className="col-sm-12 col-lg-3 mb-3 col-xl-3">
              <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
                End Date<span className="fw-semibold text-danger">*</span>
              </label>
              <Input type="date" className="w-100" name="to" value={values.to} onChange={handleChange} />
              <span className="fs-7 text-danger">{errors?.to}</span>
            </div>
            <div className="col-sm-12 col-lg-3 mb-3 col-xl-3">
              <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
                Status
              </label>
              <div className="col-lg-12 d-flex align-items-center">
                <Select name="status" className="w-100" onChange={handleChange} value={values.status}>
                  {STATUS.map((stt, i: number) => (
                    <option value={stt.value} key={i}>
                      {stt.title}
                    </option>
                  ))}
                </Select>
              </div>
              <span className="fs-7 text-dark-600 fw-medium mt-2">Applies to Any of the Specified Order Statuses except canceled orders</span>
            </div>
          </div>

          <FilterButtons onSubmit={() => handleSubmit()} status={refundStatus} setIsEnableFilter={() => setIsEnableFilterBoard(false)} />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderResetFilter()}
      {renderFilterBoard()}
      {isFilter && renderFilterSelected()}
      {renderTable()}
      <Pagination
        currentPage={+currentPage}
        pageSize={+pageSize}
        status={refundStatus}
        totalCount={total_count}
        onChangePageEvent={onChangePage}
        onChangePageSizeEvent={onChangePageSizeEvent}
      />
    </>
  );
};
