import React, { useEffect, useRef, useState } from 'react';
import logo from '~/app/images/logo.png';
import { Link } from 'react-router-dom';
import './style.scss';
import Navigation from './Navigation';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import { getAllStores, getAllWebsites, getUser } from '~/components/pages/Account/redux/action';

interface iHeader {
  pageTitle: string;
}

const Header: React.FC<iHeader> = (props: iHeader) => {
  const dispatch = useAppDispatch();

  // const imgPath = process.env.REACT_APP_IMAGE_URL;
  // const [isDisplayNav, setIsDisplayNav] = useState<boolean>(true);
  // const [isDisplayTooltip, setIsDisplayToolTip] = useState<boolean>(false);
  const [isDisplayPopup, setIsDisplayPopup] = useState<boolean>(false);

  const popupRef = useRef<HTMLDivElement>(null);

  const { data } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    if (!Object.keys(data).length) {
      dispatch(getUser());
      dispatch(getAllStores());
      dispatch(getAllWebsites());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onDisplayNav = () => {
  //   setIsDisplayNav((prev) => !prev);
  // };
  // const onDisplayTooltip = () => {
  //   setIsDisplayToolTip((prev) => !prev);
  // };
  // const logout = () => {
  //   dispatch(logOutAction(navigate));
  //   dispatch(resetPathname());
  // };

  useClickOutside(popupRef, () => {
    setIsDisplayPopup(!isDisplayPopup);
  });

  // const renderTooltips = () => {
  //   return (
  //     <div className={`tooltips ${isDisplayTooltip ? '' : 'd-none'} p-3 bg-white d-flex justify-content-center align-items-start flex-column`}>
  //       <Link className="tooltips--items text-decoration-none fw-medium text-dark text-start mb-3" to={'/profile/overview'}>
  //         <FontAwesomeIcon icon={faUser} /> My Profile
  //       </Link>
  //       <Link className="tooltips--items text-decoration-none fw-medium text-dark text-start mb-3" to={'/profile/settings'}>
  //         <FontAwesomeIcon icon={faUserPen} /> Profile Settings
  //       </Link>
  //       <button onClick={logout} className="logout-btn rounded-pill text-white fw-semibold w-100">
  //         Logout
  //       </button>
  //     </div>
  //   );
  // };

  return (
    <>
      <Link to="/dashboard">
        <div className="text-center py-4 logo-wrap">
          <img src={logo} className="logo" alt="Addin" />
        </div>
      </Link>
      <div className="main-menu flex-grow-1 overflow-auto">
        <Navigation />
      </div>
    </>
  );
};

export default Header;
