import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iGetOrderData } from '~/app/models';
import AsyncSection from '~/components/common/AsyncSection';
import { getInvoices } from '../redux/actions';
import { addFilterGroup, addFilterItem, addFilters, formatMoney, searchCriteriaBuilder } from '~/app/utils';
import Pagination from '~/components/common/Pagination';
import noRecord from '~/app/images/no-record.png';
import { timeConvert } from '~/app/constants';

import receipt from '~/app/images/receipt.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from '~/components/common/Button';

const Invoice: FC = () => {
  const { status, invoices, dataDetails } = useAppSelector((s) => s.orderReducer);
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const dispatch = useAppDispatch();

  const { orderId } = useParams();

  const { billing_address } = dataDetails;

  const { items, total_count, search_criteria } = invoices;

  const inititalState = {
    pageSize: 10,
    currentPage: 1,
    accessToken,
  };

  const [dataPayload, setDataPayload] = useState(inititalState);
  const { pageSize, currentPage } = dataPayload;

  const navigate = useNavigate();

  useEffect(() => {
    const payload: iGetOrderData = {
      accessToken,
      searchURL: searchCriteriaBuilder(pageSize, currentPage, addFilterGroup(addFilters(addFilterItem('order_id', `${orderId}`, 'eq')))),
    };
    dispatch(getInvoices(payload, currentStore));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage, currentStore]);

  const onChangeHandler = (e: any) => {
    const { value, name } = e.target;
    setDataPayload({ ...dataPayload, [name]: value });
  };

  const onChangePage = (currentPage: number) => {
    setDataPayload({ ...dataPayload, currentPage });
  };

  const renderTable = () => {
    return items.length > 0 ? (
      items.map((item: any, key: number) => {
        return (
          <div className="col-12 col-lg-6" key={key}>
            <div className="ticket bg-white p-4">
              <div className="ticket--header pb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="receipt-icon d-flex align-items-center">
                    <span className="d-flex justify-content-between align-items-center circle me-3 rounded-circle p-12">
                      <img src={receipt} alt="" />
                    </span>
                    <span className="fw-medium fs-16">{item.increment_id}</span>
                  </div>
                  <div className="ticket--header_group">
                    <span className="badge bg-success border-rd-25 px-4 py-1 fs-14 fw-medium">Paid</span>
                  </div>
                </div>
              </div>
              <div className="ticket--body py-3">
                <div className="row mb-3">
                  <div className="col-4 col-lg-3 fs-14 fw-medium">Bill-to Name</div>
                  <div className="col-8 col-lg-9 fs-14 fw-normal">{`${billing_address.firstname} ${billing_address.lastname}`}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-4 col-lg-3 fs-14 fw-medium">Order #</div>
                  <div className="col-8 col-lg-9 fs-14 fw-normal">{`#${dataDetails.increment_id}`}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-4 col-lg-3 fs-14 fw-medium">Invoice Date</div>
                  <div className="col-8 col-lg-9 fs-14 fw-normal d-flex align-items-center">{timeConvert(item.created_at)}</div>
                </div>
                <div className="row">
                  <div className="col-4 col-lg-3 fs-14 fw-medium">Order Date</div>
                  <div className="col-8 col-lg-9 fs-14 fw-normal d-flex align-items-center">{timeConvert(item.created_at)}</div>
                </div>
              </div>
              <div className="ticket--body__total ticket--footer">
                <div className="row">
                  <div className="col-4 col-lg-3 fs-16 fw-medium">Amount</div>
                  <div className="col-5 col-lg-6 fs-16 cash text-primary">{formatMoney(item.base_grand_total)}</div>
                  <div className="col-3 col-lg-3 fs-16 fw-medium text-end">
                    <Link className="fw-normal link fs-14 text-link text-decoration-none" to={`${item.entity_id}`}>
                      <FontAwesomeIcon icon={faArrowRight} className="fs-5" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="col-12 p-4 bg-white text-center wrapper">
        <img className="mt-4" src={noRecord} alt="" />
        <p className="mt-3 fs-6 fw-medium text-dark">We couldn't find any records.</p>
      </div>
    );
  };

  return (
    <AsyncSection status={status} className="">
      {status === 'fulfilled' ? (
        <div className="row">
          {dataDetails?.extension_attributes?.can_invoice === '1' && (
            <div className="d-flex justify-content-end">
              <Button className="mb-3" onClick={() => navigate('create')}>
                CREATE INVOICE
              </Button>
            </div>
          )}
          {renderTable()}
        </div>
      ) : (
        <></>
      )}

      <Pagination
        status={status}
        className="pb-4"
        currentPage={search_criteria.current_page}
        totalCount={total_count}
        pageSize={search_criteria.page_size}
        onChangePageSizeEvent={onChangeHandler}
        onChangePageEvent={onChangePage}
      />
    </AsyncSection>
  );
};

export default Invoice;
