import { CSSProperties, ChangeEvent, ForwardedRef, ReactNode, forwardRef } from 'react';
import './style.scss';

interface Props {
  value?: any;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLSelectElement>) => void;
  children: ReactNode;
  className?: string;
  name?: string;
  disabled?: boolean;
  multiple?: boolean;
  id?: string;
  style?: CSSProperties;
}

const Select = (props: Props, ref: ForwardedRef<HTMLSelectElement>) => {
  const { children, value, className = '', name, disabled = false, multiple = false, id, style, onChange, onBlur } = props;

  const classNames = `select form-control cursor-pointer fw-normal text-truncate ${className}`;

  return (
    <select
      ref={ref}
      className={classNames}
      value={value}
      onChange={onChange}
      disabled={disabled}
      name={name}
      multiple={multiple}
      id={id}
      style={style}
      onBlur={onBlur}
    >
      {children}
    </select>
  );
};

export default forwardRef(Select);
