import React, { useEffect, useMemo } from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import CustomCheckBox from '~/components/common/CustomCheckBox';
import { useParams } from 'react-router-dom';
import Button from '~/components/common/Button';

type Props = {
  pageType: string;
  orderId: number | string;
  isCreateShipment?: boolean;
  isCreateCreditMemo?: boolean;
  isCreateInvoice?: boolean;
  formik: any;
};

const Comment: React.FC<Props> = (props: Props) => {
  const { pageType, isCreateShipment, isCreateCreditMemo, isCreateInvoice, formik } = props;
  const { dataDetails, commentStatus } = useAppSelector((state) => state.orderReducer);

  const { entity_id = 0 } = useParams();

  useEffect(() => {
    setFieldValue('entity_id', entity_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity_id]);

  const { values, handleChange, handleSubmit, errors, setFieldValue } = formik || {};
  const labelCheckboxNotify = useMemo(() => {
    if (isCreateCreditMemo) {
      return 'Email Copy of Credit Memo';
    } else if (isCreateShipment) {
      return 'Email Copy of Shipment';
    } else if (isCreateInvoice) {
      return 'Email Copy of Invoice';
    } else {
      return 'Notify Customer by Email';
    }
  }, [isCreateCreditMemo, isCreateShipment, isCreateInvoice]);

  useEffect(() => {
    setFieldValue('status', dataDetails.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetails]);

  useEffect(() => {
    setFieldValue('pageType', pageType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType]);

  return (
    <form onSubmit={handleSubmit}>
      <p className="mb-4 fs-16 fw-medium">NOTES</p>
      <label className="fs-14 fw-normal d-block" htmlFor="note-textarea">
        Comment
      </label>
      <textarea
        className="note-textarea w-100 p-2 mt-2 mb-12 fs-14"
        name="comment"
        id="note-textarea"
        value={values.comment}
        onChange={handleChange}
      ></textarea>
      {errors.comment ? <p className="fs-7 text-danger fw-medium">{errors.comment}</p> : ''}
      {isCreateShipment || isCreateCreditMemo || isCreateInvoice ? (
        <div className="d-flex flex-column gap-2">
          <div className="d-flex">
            <CustomCheckBox
              checked={values.notify}
              name="notify"
              label={labelCheckboxNotify}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked === false) {
                  values.appendComment && setFieldValue('appendComment', false);
                }
                handleChange(e);
              }}
              value={values.notify}
              checkBoxClass={'fs-14 fw-normal'}
            />
          </div>
          <div className="d-flex justify-content-start">
            <CustomCheckBox
              checked={values.appendComment}
              name="appendComment"
              label={'Append Comments'}
              onChange={handleChange}
              value={values.appendComment}
              checkBoxClass={'fs-14 fw-normal'}
              disabled={!values.notify}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column gap-2">
          <div className="d-flex">
            <CustomCheckBox
              checked={values.is_customer_notified}
              name="is_customer_notified"
              label={'Notify Customer by Email'}
              onChange={handleChange}
              value={values.is_customer_notified}
              checkBoxClass={'fs-14 fw-normal'}
            />
          </div>
          <div className="d-flex justify-content-start">
            <CustomCheckBox
              checked={values.is_visible_on_front}
              name="is_visible_on_front"
              label={'Visible on Storefront'}
              onChange={handleChange}
              value={values.is_visible_on_front}
              checkBoxClass={'fs-14 fw-normal'}
            />
          </div>
        </div>
      )}
      {!isCreateShipment && !isCreateCreditMemo && !isCreateInvoice && (
        <Button type="submit" disabled={commentStatus === 'pending'} width="200px" className="text-uppercase mt-3">
          Submit
        </Button>
      )}
    </form>
  );
};

export default Comment;
