import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
// import { slugify } from '~/app/utils';
import Button from '~/components/common/Button';
import Loading from '~/components/common/Loading';
import DefaultLayout from '~/components/layout/DefaultLayout';
import Header from './Header';
import Body from './Body';
import {
  actionCreateEditPromotion,
  actionReset,
  deletePromotion,
  getPromotionId,
  onChangeDataHeader,
  onChangeListWebsiteSelected,
} from './redux/actions';
import './style.scss';
import RenderPopup from './Popup';
import Swal from 'sweetalert2';
import Separator from '~/components/common/Separator';
import successImg from '~/app/images/success.png';

import { onChangeAction } from './redux/actions';
import { iDataHeaderPromotion } from '~/app/models';
import * as Yup from 'yup';
import { actionReset as actionResetCreateEditProduct } from '../CreateEditProduct/redux/actions';

interface Props {
  action: 'create' | 'edit';
}

export interface iFormik {
  name: string;
  discount_amount?: string | number;
}

const CreateEditPromotion = ({ action }: Props) => {
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { queryString: promotionQuery } = useAppSelector((s) => s.promotionReducer);
  const { idCategoriesSelected } = useAppSelector((state) => state.createEditProductReducer);

  const { data, status, statusGetData, dataHeader, statusGetPromotionDetail, messageGetPromotionDetail, deletePromotionStatus, dataWebsiteSelect } =
    useAppSelector((state) => state.createEditPromotionReducer);
  const { simple_action } = dataHeader;

  const { data: dataUser } = useAppSelector((state) => state.userReducer);
  const { website_ids } = dataUser || {};

  const schemaValidateName = Yup.object().shape({
    name: Yup.string().required('Name is required').max(255, 'Name maximum 255 characters'),
  });
  const schemaValidateDiscountAmount = Yup.object().shape({
    discount_amount: Yup.string().required('Discount Amount is required'),
  });
  // const schema =
  //   simple_action === 'by_percent'
  //     ? data.simple_free_shipping === '1'
  //       ? schemaValidateName
  //       : schemaValidateName.concat(schemaValidateDiscountAmount)
  //     : simple_action === 'buy_x_get_y'
  //     ? schemaValidateName.concat(schemaValidateDiscountAmount)
  //     : schemaValidateName;
  const schema = simple_action === 'free_shipping' ? schemaValidateName : schemaValidateName.concat(schemaValidateDiscountAmount);

  const formik = useFormik<iFormik>({
    initialValues: {
      discount_amount: '',
      name: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: schema,
    onSubmit: handleSubmitForm,
  });

  const { idPromotion = 0 } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (action === 'edit') {
      dispatch(getPromotionId(+idPromotion, currentStore));
    }
    return () => {
      dispatch(actionReset());
      dispatch(actionResetCreateEditProduct());
    };
  }, [action, currentStore, dispatch, idPromotion]);
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const {
        is_active,
        uses_per_customer,
        // uses_per_coupon,
        name,
        description,
        from_date,
        to_date,
        sort_order,
        discount_amount,
        value_sku,
        coupon_code,
        // value_sub_total,
        simple_free_shipping,
        simple_action,
        action_serialized,
        // discount_qty,
        discount_step,
        condition_serialized,
        // website_ids,
      } = data;

      formik.setValues({ name, discount_amount });

      //when edit promotion => change website select
      changeListWebsite();

      const isCheckBuyXGetOff = action_serialized.conditions?.find((item) => item.attribute_name === 'quote_item_qty');

      let newSimpleAction = simple_action;

      let newDiscountMode = 'by_percent';

      if (simple_action === 'by_percent') {
        if (simple_free_shipping === '1') {
          newSimpleAction = 'free_shipping';
        }
        if (isCheckBuyXGetOff && simple_action === 'by_percent') {
          newSimpleAction = 'buy_one_get_one_off';
        }
      }

      if (simple_action === 'cart_fixed' && isCheckBuyXGetOff) {
        newSimpleAction = 'buy_one_get_one_off';
        newDiscountMode = 'cart_fixed';
      }
      let newDataHeader: iDataHeaderPromotion = {
        ...dataHeader,
        // uses_per_coupon: !!uses_per_coupon,
        uses_per_customer: !!uses_per_customer,
        is_active,
        name,
        description,
        from_date,
        to_date,
        coupon_code,
        sort_order,
        discount_amount,
        value_sku,
        // value_sub_total,
        simple_action: newSimpleAction,
        // discount_qty,
        discount_step,
        condition_serialized,
        // website_ids,
        discount_mode: newDiscountMode,
      };

      const { conditions = [] } = action_serialized || {};
      const subtotalObj = condition_serialized.conditions?.find((item) => item.attribute_name === 'base_subtotal_total_incl_tax');
      if (subtotalObj) {
        const value_sub_total = subtotalObj.value;
        newDataHeader = { ...newDataHeader, value_sub_total };
      }

      //find sku
      const skuSelected = conditions.find((item) => item.attribute_name === 'sku');
      const categorySelected = conditions.find((item) => item.attribute_name === 'category_ids');

      if (skuSelected) {
        newDataHeader = { ...newDataHeader, value_sku: skuSelected.value };
      }

      if (skuSelected || categorySelected) {
        newDataHeader = { ...newDataHeader, apply_to: 'order_line_item' };
      }
      dispatch(onChangeDataHeader(newDataHeader));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const swalConfirmDelete = Swal.mixin({
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: 'ok',
    cancelButtonText: 'cancel',
    customClass: {
      popup: 'p-32px border-rd-25',
      closeButton: 'mb-n4 text-dark btn-not-hover',
      title: 'h3 text-dark text-center m-0 fw-semibold p-0',
      htmlContainer: 'text-dark text-center mt-3 mb-0 mx-0 fw-normal',
      actions: 'mt-3 w-100',
      confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-40',
      cancelButton: 'btn btn-primary btn-cancel fs-14 fw-medium btn-border-radius border-rd-25 order-0 w-40',
    },
  });

  const onClickDeletePromotion = () => {
    swalConfirmDelete
      .fire({
        title: 'Delete Coupon',
        html: 'Are you sure?<br/>This coupon will also be deleted from other stores.',
        width: 'auto',
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deletePromotion(+idPromotion, accessToken, currentStore));
        }
      });
  };

  useEffect(() => {
    if (deletePromotionStatus === 'fulfilled') {
      swalConfirmDelete
        .fire({
          title: `<img src="${successImg}" alt="success" className="mx-auto" />`,
          text: 'You have successfully deleted coupon.',
          showCancelButton: false,
          showCloseButton: false,
          confirmButtonText: 'ok',
          customClass: {
            popup: 'p-32px border-rd-25 content-success bg-white d-flex flex-column gap-3',
            title: 'h3 text-dark text-center m-0 fw-semibold p-0',
            htmlContainer: 'message text-center m-0 fw-semibold',
            actions: 'mt-0 w-100',
            confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-100',
          },
        })
        .then(() => {
          navigate('/promotions');
        });
    } else if (deletePromotionStatus === 'rejected') {
      swalConfirmDelete.fire({
        title: 'Delete Promotion',
        text: 'Something went wrong. Please wait and try again.',
        showCancelButton: false,
        showCloseButton: false,
        confirmButtonText: 'ok',
        customClass: {
          popup: 'p-32px border-rd-25 content-success bg-white d-flex flex-column gap-3',
          title: 'h3 text-dark text-center m-0 fw-semibold p-0',
          htmlContainer: 'message text-center m-0 fw-semibold',
          actions: 'mt-0 w-100',
          confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-100',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePromotionStatus]);

  useEffect(() => {
    dispatch(onChangeAction(action));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  function changeListWebsite() {
    try {
      const storeListObj = JSON.parse(website_ids || '');
      const storeList = Object.entries(storeListObj);
      const storeListSelected = storeList.filter((item) => {
        const id: number = +item[0];
        return data.website_ids.includes(id);
      });

      const newListWebsiteId = storeListSelected.map((item: any) => {
        const [id, storeList] = item;
        return { id: +id, name: storeList.name };
      });

      dispatch(onChangeListWebsiteSelected(newListWebsiteId));
    } catch (error) {
      console.log(error);
    }
  }

  function handleSubmitForm() {
    const {
      name,
      sort_order = null,
      description = null,
      from_date = null,
      to_date = null,
      is_active,
      simple_action,
      // uses_per_coupon,
      uses_per_customer,
      coupon_code = null,
      value_sku = null,
      value_sub_total = null,
      discount_amount = null,
      apply_to,
      // discount_qty = null,
      discount_step = null,
      discount_mode = null,
    } = dataHeader;

    const payload: any = {
      rule: {
        is_active,
        // uses_per_coupon: +uses_per_coupon,
        uses_per_customer: +uses_per_customer,
        name,
        description,
        from_date,
        to_date,
        sort_order,
        coupon_type: 'SPECIFIC_COUPON',
        use_auto_generation: false,
        website_ids: dataWebsiteSelect.map((item: any) => item.id),
        customer_group_ids: [0],
        value_sku,
        simple_action,
        value_sub_total,
        value_category: idCategoriesSelected?.map((idCategoriesSelected: any) => +idCategoriesSelected).join(',') || null,
        apply_to,
        coupon_code,
        discount_amount,
        // discount_qty,
        discount_step: discount_step || 0,
        discount_mode,
      },
    };
    dispatch(actionCreateEditPromotion(payload, accessToken, currentStore, +idPromotion));
  }
  function renderContent() {
    if (statusGetPromotionDetail === 'rejected' && messageGetPromotionDetail) {
      return <p className="m-0 text-dark-600 text-center fs-6">{messageGetPromotionDetail}</p>;
    }
    if (status === 'pending' || statusGetData === 'pending' || statusGetPromotionDetail === 'pending') {
      return <Loading />;
    }
    return (
      <form id="create-edit__product">
        <Header formik={formik} />
        <Body formik={formik} />

        <div className="action d-flex">
          <Button
            className="mt-3 text-uppercase"
            onClick={() => {
              formik.handleSubmit();

              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }, 0);
            }}
            width="200px"
          >
            {action === 'create' ? 'Create Promotion' : 'Update'}
          </Button>

          {action === 'edit' && (
            <Button className="mt-3 ms-3 text-uppercase" onClick={() => onClickDeletePromotion()} width="200px">
              Delete Promotion
            </Button>
          )}
        </div>
      </form>
    );
  }

  return (
    <DefaultLayout pageTitle={action === 'create' ? 'Create New Promotion' : 'Edit Promotion'}>
      <Separator className="mb-4">
        <Link
          to={`/promotions/${promotionQuery}`}
          className="back-product-list d-inline-block text-uppercase w-fit-content rounded-pill fs-14 px-32 text-decoration-none d-flex align-items-center fw-medium"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="ms-3 text-nowrap">Back To Promotion Listing</span>
        </Link>
      </Separator>
      <RenderPopup action={action} formik={formik} />
      {renderContent()}
    </DefaultLayout>
  );
};
export default CreateEditPromotion;
