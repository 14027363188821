import { useFormik } from 'formik';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import Button from '~/components/common/Button';
import { PopupWrap } from '~/components/common/Popup';
import { addNewQuickAnswerSchema } from '../Schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEllipsisVertical, faXmark } from '@fortawesome/free-solid-svg-icons';
import Select from '~/components/common/Select';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import {
  deleteQuickAnswer,
  getListQuickAnswer,
  postQuickAnswer,
  putQuickAnswer,
  putQuickAnswerListFieldIsActive,
  resetStatusQuickAnswer,
} from '../redux/action';
import SearchBar from '~/components/common/Filter/searchBar';
import { iQuickAnswer } from '~/app/models';
import { NoRecord } from '../../Err';
import CheckboxRounded from '~/components/common/CheckboxRounded';
import Swal from 'sweetalert2';
import { addSortOrder, searchCriteriaBuilder } from '~/app/utils';
import Loading from '~/components/common/Loading';
import { defaultMessageError } from '~/app/constants';

interface Props {
  closePopup: () => void;
}

interface initFormik {
  title: string;
  content: string;
  is_active: string;
  quickanswer_id?: string;
}

const AddNewQuickAnswer = ({ closePopup }: Props) => {
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { statusPostQuickAnswer, statusDeleteQuickAnswer, listQuickAnswer, statusPutQuickAnswer, statusGetQuickAnswer } = useAppSelector(
    (s) => s.ticketReducer,
  );

  const [currentActivePopup, setCurrentActivePopup] = useState<'add' | 'edit' | 'setting'>('add');
  const [valueSearch, setValueSearch] = useState<string>('');
  const [listQuickAnswerShow, setListQuickAnswerShow] = useState<iQuickAnswer[]>(listQuickAnswer);
  const [activeTooltipSettingId, setActiveTooltipSettingId] = useState<undefined | string>();
  const [listQuickAnswerIdAndIsActive, setListQuickAnswerIdAndIsActive] = useState<any>(
    Object.fromEntries(listQuickAnswer.map((item) => [item.quickanswer_id, item.is_active === '1' ? true : false])),
  );
  const [listUpdateFieldIsActive, setListUpdateFieldIsActive] = useState<any>({});

  const contentRef = useRef<HTMLDivElement>(null);
  const actionRef = useRef<HTMLDivElement>(null);
  useClickOutside(contentRef, () => statusDeleteQuickAnswer !== 'pending' && closePopup());
  useClickOutside(actionRef, () => statusDeleteQuickAnswer !== 'pending' && setActiveTooltipSettingId(undefined));

  useEffect(() => {
    return () => {
      resetForm();
      resetState();
      dispatch(resetStatusQuickAnswer());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (currentActivePopup === 'setting') {
      setListQuickAnswerShow(listQuickAnswer);

      const newList = listQuickAnswer.map((item) => [item.quickanswer_id, item.is_active === '1' ? true : false]);
      setListQuickAnswerIdAndIsActive(Object.fromEntries(newList));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQuickAnswer]);

  useEffect(() => {
    if (statusDeleteQuickAnswer === 'fulfilled') {
      swalToast.fire({
        title: 'Delete Successfully!',
        icon: 'success',
      });
      setValueSearch('');
      resetForm();
      resetState();
      dispatch(resetStatusQuickAnswer());
      dispatch(
        getListQuickAnswer(
          searchCriteriaBuilder(Number.MAX_VALUE, 1, undefined, addSortOrder({ field: 'quickanswer_id', direction: 'DESC' })),
          accessToken,
          currentStore,
        ),
      );
    } else if (statusDeleteQuickAnswer === 'rejected') {
      swalToast.fire({
        title: defaultMessageError,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDeleteQuickAnswer]);

  useEffect(() => {
    if (statusPutQuickAnswer === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
      setValueSearch('');
      resetForm();
      resetState();
      dispatch(resetStatusQuickAnswer());
      setCurrentActivePopup('setting');
      dispatch(
        getListQuickAnswer(
          searchCriteriaBuilder(Number.MAX_VALUE, 1, undefined, addSortOrder({ field: 'quickanswer_id', direction: 'DESC' })),
          accessToken,
          currentStore,
        ),
      );
    } else if (statusPutQuickAnswer === 'rejected') {
      swalToast.fire({
        title: defaultMessageError,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPutQuickAnswer]);

  useEffect(() => {
    if (statusPostQuickAnswer === 'fulfilled') {
      swalToast.fire({
        title: 'Save Successfully!',
        icon: 'success',
      });
      setValueSearch('');
      resetForm();
      resetState();
      dispatch(resetStatusQuickAnswer());
      setCurrentActivePopup('setting');
      dispatch(
        getListQuickAnswer(
          searchCriteriaBuilder(Number.MAX_VALUE, 1, undefined, addSortOrder({ field: 'quickanswer_id', direction: 'DESC' })),
          accessToken,
          currentStore,
        ),
      );
    } else if (statusPostQuickAnswer === 'rejected') {
      swalToast.fire({
        title: defaultMessageError,
        icon: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPostQuickAnswer]);

  const addNewQuickAnswerFormik = useFormik<initFormik>({
    initialValues: {
      title: '',
      content: '',
      is_active: '1',
    },
    validationSchema: addNewQuickAnswerSchema,
    validateOnChange: false,
    onSubmit: handleSubmitFormQuickAnswer,
  });

  const swalToast = Swal.mixin({
    toast: true,
    position: 'top-right',
    buttonsStyling: false,
    showConfirmButton: false,
    timer: 2000,
  });

  const { values, errors, resetForm, setFieldError, handleSubmit, handleChange, setValues } = addNewQuickAnswerFormik;

  function resetState() {
    setActiveTooltipSettingId(undefined);
    setListUpdateFieldIsActive({});
  }

  function handleSubmitFormQuickAnswer(values: initFormik) {
    const { title, content, is_active, quickanswer_id } = values;

    const payload = {
      Quickanswer: {
        title: title.trim(),
        content: content.trim(),
        is_active,
      },
    };

    currentActivePopup === 'edit'
      ? dispatch(putQuickAnswer(quickanswer_id || '0', payload, accessToken, currentStore))
      : dispatch(postQuickAnswer(payload, accessToken, currentStore));
  }

  function handleChangeCurrentActivePopup(newCurrentActivePopup: 'add' | 'edit' | 'setting') {
    setCurrentActivePopup(newCurrentActivePopup);
  }

  function handleChangeValueSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setValueSearch(value);
  }

  function searchKeyPressHandler(e: React.KeyboardEvent<HTMLInputElement>) {
    const { key, target } = e;

    if (key === 'Enter') {
      if (target instanceof HTMLInputElement) {
        const newList = listQuickAnswer.filter((item) => item.title.toLowerCase().trim().includes(valueSearch.toLowerCase().trim()));
        setListQuickAnswerShow(newList);
      }
    }
  }

  function handleChangeActiveAction(quickanswer_id: string) {
    setActiveTooltipSettingId(quickanswer_id === activeTooltipSettingId ? undefined : quickanswer_id);
  }

  function handleRemoveQuickAnswer(quickanswer_id: string) {
    setValueSearch('');
    dispatch(deleteQuickAnswer(quickanswer_id, accessToken, currentStore, setActiveTooltipSettingId));
  }

  function handleChangeListUpdateFieldIsActive(e: React.ChangeEvent<HTMLInputElement>) {
    const { name: quickanswer_id, checked } = e.target;

    if (listQuickAnswerIdAndIsActive[quickanswer_id] !== checked) {
      const newListUpdate = { ...listUpdateFieldIsActive, [quickanswer_id]: checked };
      setListUpdateFieldIsActive(newListUpdate);
    } else {
      const newList = { ...listUpdateFieldIsActive };
      delete newList[quickanswer_id];

      setListUpdateFieldIsActive(newList);
    }
  }

  function handleUpdateFiledIsActive() {
    if (!Object.keys(listUpdateFieldIsActive).length) return;
    dispatch(putQuickAnswerListFieldIsActive(listUpdateFieldIsActive, accessToken, currentStore));
  }

  function handleClearSearch() {
    setListQuickAnswerShow(listQuickAnswer);
    setValueSearch('');
  }

  function renderContentSetting() {
    return (
      <>
        <div className="row g-0 px-4">
          <SearchBar
            disabled={statusDeleteQuickAnswer === 'pending'}
            className="col-12 col-md-12"
            classNameInputSearch="search-btn-custom"
            name="searchTermCustomer"
            status="fulfilled"
            value={valueSearch}
            isTotalCount={false}
            searchKeyPressHandler={searchKeyPressHandler}
            onFilterChangeHandler={handleChangeValueSearch}
            onClearSearch={handleClearSearch}
            showIconClearSearch
          />
        </div>

        {statusGetQuickAnswer === 'pending' ? (
          <Loading />
        ) : (
          <div className="quick-answer-list d-flex flex-column gap-4 ps-4 pe-3 me-2">
            {listQuickAnswerShow.length ? (
              listQuickAnswerShow.map((item) => {
                const { quickanswer_id, title, content, is_active } = item;
                const isActiveAction = quickanswer_id === activeTooltipSettingId;

                return (
                  <div className="quick-answer-item d-flex align-items-start" key={quickanswer_id}>
                    <img src={require('~/app/images/quick-answer.svg').default} alt="quick answer" className="quick-answer-icon me-4" />

                    <div className="body me-32">
                      <h3 className="title fs-16 text-truncate mb-2 fw-normal">{title}</h3>
                      <p className="desc m-0 fs-14 text-dark-600">{content}</p>
                    </div>

                    <CheckboxRounded
                      check={listUpdateFieldIsActive[quickanswer_id] !== undefined ? listUpdateFieldIsActive[quickanswer_id] : is_active === '1'}
                      name={quickanswer_id}
                      onChange={handleChangeListUpdateFieldIsActive}
                      className="me-4"
                    />

                    <div className="actions">
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        className="cursor-pointer"
                        onClick={() => statusDeleteQuickAnswer !== 'pending' && handleChangeActiveAction(quickanswer_id)}
                      />

                      {isActiveAction && (
                        <div className={`action-list bg-white py-12 ${statusDeleteQuickAnswer === 'pending' ? 'disabled' : ''}`} ref={actionRef}>
                          <span
                            className="d-block px-12 py-1 fs-14 cursor-pointer action-item"
                            onClick={() => {
                              if (statusDeleteQuickAnswer !== 'pending') {
                                setCurrentActivePopup('edit');
                                setActiveTooltipSettingId(undefined);
                                setValues({ title, content, is_active, quickanswer_id });
                              }
                            }}
                          >
                            Edit
                          </span>
                          <span
                            className="d-block px-12 py-1 fs-14 cursor-pointer action-item"
                            onClick={() => statusDeleteQuickAnswer !== 'pending' && handleRemoveQuickAnswer(quickanswer_id)}
                          >
                            {statusDeleteQuickAnswer === 'pending' ? 'Removing...' : 'Remove'}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <table>
                <tbody>
                  <NoRecord tableHeaders={[]} />
                </tbody>
              </table>
            )}
          </div>
        )}
      </>
    );
  }

  function renderContentAddAndEdit() {
    return (
      <>
        <div className="d-flex flex-column">
          <label htmlFor="active" className="form-label cursor-pointer fs-6 fw-medium">
            Active
          </label>

          <Select className="select-active ps-3 w-100" name="is_active" value={values.is_active} onChange={handleChange}>
            <option value="1">Enabled</option>
            <option value="0">Disabled</option>
          </Select>
        </div>

        <div>
          <label htmlFor="title" className="form-label cursor-pointer fs-6 fw-medium">
            Title <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control px-3 fs-16"
            id="title"
            name="title"
            value={values.title}
            onChange={(e) => {
              errors.title && setFieldError('title', undefined);
              handleChange(e);
            }}
          />
          {errors.title && <p className="mb-0 mt-2 text-danger fs-14">{errors.title}</p>}
        </div>

        <div className="d-flex flex-column flex-grow-1">
          <label htmlFor="content" className="form-label cursor-pointer fs-6 fw-medium">
            Content <span className="text-danger">*</span>
          </label>
          <textarea
            className="form-control px-3 fs-16 desc-textarea flex-grow-1"
            id="content"
            name="content"
            value={values.content}
            onChange={(e) => {
              errors.content && setFieldError('content', undefined);
              handleChange(e);
            }}
          ></textarea>
          {errors.content && <p className="mb-0 mt-2 text-danger fs-14">{errors.content}</p>}

          <p className="m-0 mt-3 note-add-quick-answer">
            You can use variables: [ticket_customer_name], [ticket_customer_email], [ticket_code], [user_firstname], [user_lastname], [user_email]
          </p>
        </div>
      </>
    );
  }

  function renderNavbar() {
    return (
      ['add', 'setting'].includes(currentActivePopup) && (
        <nav className="nav fs-6 fw-medium cursor-pointer mx-4">
          <p
            className={`nav-item pb-2 m-0 flex-grow-1 text-center ${currentActivePopup === 'add' ? 'active' : ''}`}
            onClick={() => statusDeleteQuickAnswer !== 'pending' && handleChangeCurrentActivePopup('add')}
          >
            Add New Quick Answer
          </p>

          <p
            className={`nav-item pb-2 m-0 flex-grow-1 text-center ${currentActivePopup === 'setting' ? 'active' : ''}`}
            onClick={() => statusDeleteQuickAnswer !== 'pending' && handleChangeCurrentActivePopup('setting')}
          >
            Settings
          </p>
        </nav>
      )
    );
  }

  function renderHeading() {
    const isEdit = currentActivePopup === 'edit';
    return (
      <div className="heading d-flex align-items-center justify-content-between fs-4 fw-medium px-4">
        <div
          className={`d-flex align-items-center gap-3 ${isEdit ? 'heading-edit cursor-pointer' : ''}`}
          onClick={() => {
            if (currentActivePopup === 'edit') {
              setCurrentActivePopup('setting');
              resetForm();
            }
          }}
        >
          {isEdit && <FontAwesomeIcon icon={faChevronLeft} className="fs-14" />}
          <h3 className={`title fw-medium m-0`}>{isEdit && 'Edit '} Quick Answer</h3>
        </div>
        <FontAwesomeIcon icon={faXmark} className="close-icon cursor-pointer" onClick={closePopup} />
      </div>
    );
  }

  return (
    <PopupWrap>
      <div className="content-popup wrapper bg-white py-4 d-flex flex-column gap-4" ref={contentRef}>
        {renderHeading()}

        {renderNavbar()}

        <div className={`body d-flex flex-column gap-3 flex-grow-1 overflow-auto ${currentActivePopup === 'setting' ? '' : 'px-4'}`}>
          {currentActivePopup === 'setting' ? renderContentSetting() : renderContentAddAndEdit()}
        </div>

        <div className="footer-add-new-quick-answer mt-auto px-4">
          <Button
            className="text-uppercase"
            outline
            onClick={closePopup}
            disabled={
              statusDeleteQuickAnswer === 'pending' ||
              statusPostQuickAnswer === 'pending' ||
              statusPutQuickAnswer === 'pending' ||
              statusGetQuickAnswer === 'pending'
            }
          >
            Cancel
          </Button>
          <Button
            className="text-uppercase"
            onClick={currentActivePopup === 'setting' ? handleUpdateFiledIsActive : handleSubmit}
            disabled={
              currentActivePopup !== 'setting'
                ? !values.title || !values.content
                : statusDeleteQuickAnswer === 'pending' ||
                  statusPostQuickAnswer === 'pending' ||
                  statusPutQuickAnswer === 'pending' ||
                  statusGetQuickAnswer === 'pending' ||
                  (currentActivePopup === 'setting' && !Object.keys(listUpdateFieldIsActive).length)
            }
          >
            {currentActivePopup === 'edit' ? 'Update' : 'Save'}
          </Button>
        </div>
      </div>
    </PopupWrap>
  );
};

export default AddNewQuickAnswer;
