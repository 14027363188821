export const listPaypal = [
  {
    title: 'Payer ID:',
    key: 'paypal_payer_id',
  },
  {
    title: 'Payer Email:',
    key: 'paypal_payer_email',
  },
  {
    title: 'Payer Status:',
    key: 'paypal_payer_status',
  },
  {
    title: 'Payer Address Status:',
    key: 'paypal_address_status',
  },
  {
    title: 'Merchant Protection Eligibility:',
    key: 'paypal_protection_eligibility',
  },
  {
    title: 'Last Correlation ID:',
    key: 'paypal_correlation_id',
  },
  {
    title: 'Last Transaction ID:',
    key: 'last_trans_id',
  },
];

export const listAtome = [
  {
    title: 'Method title:',
    key: 'method_title',
  },
  {
    title: 'Atome payment reference id:',
    key: 'atome_payment_reference_id',
  },
  {
    title: 'Atome payment amount:',
    key: 'atome_payment_amount_formatted',
  },
  {
    title: 'Atome payment currency code:',
    key: 'atome_payment_currency_code',
  },
];
