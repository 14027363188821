import { Link } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '~/components/common/Button';

import './style.scss';

type Props = {};

export const ErrorPage = (props: Props) => {
  return (
    <div className="text-center bg-white d-flex flex-column align-items-center py-4 gap-4">
      <p className="m-0 fw-medium text-danger fs-6 fw-medium">Sorry we got some error</p>

      <Link to="/dashboard" className="text-decoration-none">
        <Button className="w-fit-content text-uppercase" outline iconLeft={faArrowLeft}>
          Back to Dashboard
        </Button>
      </Link>
    </div>
  );
};
