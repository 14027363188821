import userApi from '~/app/apis/user.api';
import { iInfoUser, iPayloadChangePassword, iPayloadUpdateInfoUser, iResponseAxios, iStoreViewItem, iWebsiteItem } from '~/app/models';
import * as types from './index';
import { tokenExpired } from '~/components/layout/FullWidthLayout/redux/actions';
import { defaultMessageError } from '~/app/constants';

const { rest: actions } = types;

export const getUser = () => async (dispatch: any) => {
  dispatch(actions.getUserPending());

  try {
    const { data }: iResponseAxios<iInfoUser> = await userApi.getInfo();
    !data ? dispatch(actions.getUserRejected(defaultMessageError)) : dispatch(actions.getUserFulfilled(data));
  } catch (error: any) {
    if (error?.response?.status === 401) {
      dispatch(tokenExpired());
    }

    const { message } = error?.response?.data || {};
    dispatch(actions.getUserRejected(message || defaultMessageError));
  }
};

export const updateUser =
  (payload: iPayloadUpdateInfoUser<Pick<iInfoUser, 'first_name' | 'last_name' | 'email' | 'phone_number'>>) => async (dispatch: any) => {
    dispatch(actions.updateUserPending());

    try {
      const { data }: iResponseAxios<iInfoUser> = await userApi.updateInfo(payload);

      !data ? dispatch(actions.updateUserRejected(defaultMessageError)) : dispatch(actions.updateUserFulfilled(data));
      setTimeout(() => {
        dispatch(actions.updateUserInitState());
      }, 3000);
    } catch (error: any) {
      const { message } = error?.response?.data || {};
      dispatch(actions.updateUserRejected(message || defaultMessageError));
      setTimeout(() => {
        dispatch(actions.updateUserInitState());
      }, 3000);
    }
  };

export const updateAvatarUser = (payload: iPayloadUpdateInfoUser<{ image: string }>) => async (dispatch: any) => {
  dispatch(actions.updateUserAvatarPending());

  try {
    const { data }: iResponseAxios<iInfoUser> = await userApi.updateInfo(payload);
    !data ? dispatch(actions.updateUserAvatarRejected(defaultMessageError)) : dispatch(actions.updateUserAvatarFulfilled(data));
    setTimeout(() => {
      dispatch(actions.updateUserAvatarInitState());
    }, 3000);
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.updateUserAvatarRejected(message || defaultMessageError));
    setTimeout(() => {
      dispatch(actions.updateUserAvatarInitState());
    }, 3000);
  }
};

export const updatePasswordUser = (payload: iPayloadUpdateInfoUser<iPayloadChangePassword>) => async (dispatch: any) => {
  dispatch(actions.updateUserPasswordPending());

  try {
    const res = await userApi.updateInfo(payload);
    const { data } = res;
    !data ? dispatch(actions.updateUserPasswordRejected(defaultMessageError)) : dispatch(actions.updateUserPasswordFulfilled());
    setTimeout(() => {
      dispatch(actions.updateUserPasswordInitState());
    }, 3000);
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.updateUserPasswordRejected(message || defaultMessageError));
    setTimeout(() => {
      dispatch(actions.updateUserPasswordInitState());
    }, 3000);
  }
};

export const deleteAvatar = () => async (dispatch: any) => {
  dispatch(actions.deleteAvatarPending());

  try {
    const res = await userApi.deleteAvatar();
    const { data } = res;

    try {
      const { data }: iResponseAxios<iInfoUser> = await userApi.getInfo();
      !data ? dispatch(actions.getUserRejected(defaultMessageError)) : dispatch(actions.getUserFulfilled(data));
    } catch (error) {
    } finally {
      !data ? dispatch(actions.deleteAvatarRejected(defaultMessageError)) : dispatch(actions.deleteAvatarFulfilled());
    }
    setTimeout(() => {
      dispatch(actions.deleteAvatarInitState());
    }, 3000);
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.deleteAvatarRejected(message || defaultMessageError));
    setTimeout(() => {
      dispatch(actions.deleteAvatarInitState());
    }, 3000);
  }
};

export const resetDataUser = () => async (dispatch: any) => {
  dispatch(actions.resetDataUser());
};

export const getAllStores = () => async (dispatch: any) => {
  dispatch(actions.getAllStoresPending());

  try {
    const res = await userApi.getAllStores();
    const { data }: iResponseAxios<iStoreViewItem[]> = res;

    !data ? dispatch(actions.getAllStoresRejected(defaultMessageError)) : dispatch(actions.getAllStoresFulfilled(data));
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.getAllStoresRejected(message || defaultMessageError));
  }
};

export const getAllWebsites = () => async (dispatch: any) => {
  dispatch(actions.getAllWebsitesPending());

  try {
    const res = await userApi.getAllWebsites();
    const { data }: iResponseAxios<iWebsiteItem[]> = res;

    !data ? dispatch(actions.getAllWebsitesRejected(defaultMessageError)) : dispatch(actions.getAllWebsitesFulfilled(data));
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.getAllWebsitesRejected(message || defaultMessageError));
  }
};
