import moment from 'moment';
import { useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { convertGetPastDay, getPastDay } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { statusOrderReportDashboard } from '~/app/models';
import { dashboardCriteria } from '~/app/utils';
import formatNumber from '~/app/utils/formatNumber';
import Chart from '~/components/common/Chart';
import { formatFilterStatus } from '~/components/common/Filter/formatFilter';
import { NoRecord } from '../Err';
import { getDataOrderReport } from './redux/actions';

import './style.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const OrderReport = (props: Props) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { statusOrderReport: status, dataOrderReport } = useAppSelector((s) => s.dashboardReducer);

  const currentDate = convertGetPastDay(getPastDay(1)[0]);
  const aYearAgo = convertGetPastDay(getPastDay(366)[365]);

  useEffect(() => {
    const payload = {
      accessToken,
      currentStore,
      searchUrl: dashboardCriteria({
        to: moment(new Date(currentDate)).format('YYYY-MM-DD'),
        from: moment(new Date(aYearAgo)).format('YYYY-MM-DD'),
      }),
    };
    dispatch(getDataOrderReport(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, accessToken]);

  const data = useMemo(() => {
    return dataOrderReport
      .filter((item) => ['complete', 'pending', 'processing', 'pending_payment'].includes(item.status))
      .sort((f, s) => {
        if (f.status > s.status) return 1;
        if (f.status < s.status) return -1;
        return 0;
      });
  }, [dataOrderReport]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalOrdered = dataOrderReport
    .filter((item) => ['complete', 'pending', 'processing', 'pending_payment'].includes(item.status))
    .reduce((total, item) => total + parseInt(item.subtotal), 0);

  const chartData = {
    labels: data.map((item: any) => item.status),
    datasets: [
      {
        label: 'Total',
        data: data.map((item) => item.subtotal),

        // Config
        backgroundColor: data.map((item) => covertStatusToBgc(item.status)),
        cutout: '60%',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            const status = context[0].label;
            return formatFilterStatus(status);
          },
        },
        titleAlign: 'center',
        titleMarginBottom: 12,
        padding: 16,
        boxPadding: 8,
      },
    },
  };

  function covertStatusToBgc(status: statusOrderReportDashboard) {
    switch (status) {
      case 'complete':
        return '#7FBC02';
      case 'pending':
        return '#E22626';
      case 'pending_payment':
        return '#bb2222';
      case 'processing':
        return '#FBBC04';
      case 'holded':
        return '#70BDDE';
      case 'closed':
        return '#AB1A60';
      case 'refund':
        return '#FF005C';
      default:
        return '#180767';
    }
  }

  function renderInfoRefunds() {
    if (status === 'pending') {
      return (
        <div style={{ marginTop: '-16px' }}>
          <Skeleton width={200} height={24} count={3} className="mt-3" />
        </div>
      );
    }

    return data.map((item, index: number) => {
      const { status, subtotal } = item;

      return (
        <div className="item" key={index}>
          <div className="d-flex align-items-center">
            <div className="rect" style={{ background: covertStatusToBgc(status) }} />
            <h3 className="qty m-0">{subtotal}</h3>
          </div>
          <div className="d-flex mb-3 mt-2">
            <div className="rect"></div>
            <p className="desc m-0 fw-medium text-capitalize">{formatFilterStatus(status)}</p>
          </div>
        </div>
      );
    });
  }

  function renderChart() {
    return status !== 'pending' ? (
      <>
        <div className="total d-flex flex-column text-center align-items-center">
          <p className="qty mb-2 text-break w-100 fw-semibold">{formatNumber(totalOrdered)}</p>
          <h3 className="m-0 fw-semibold fs-16 text-dark-600">Total</h3>
        </div>

        <Chart type="Doughnut" chartData={chartData} options={chartOptions} />
      </>
    ) : (
      <Skeleton width={'95%'} height={'95%'} circle />
    );
  }

  return (
    <div className="refunds flex-grow-1 d-flex flex-column content-wrap bg-white p-4  mw-100 overflow-x-auto">
      {children}
      {status === 'rejected' || (status === 'fulfilled' && !dataOrderReport.length) ? (
        <div className="d-flex justify-content-center">
          <table>
            <tbody>
              <NoRecord tableHeaders={[]} />
            </tbody>
          </table>
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center mw-100 overflow-x-auto">
          <div className="info mt-4 gap-3">{renderInfoRefunds()}</div>
          <div className="chart-wrap">{renderChart()}</div>
        </div>
      )}
    </div>
  );
};

export default OrderReport;
