import * as yup from 'yup';

const phoneRegex = /^[0-9\-+()#_]+$/; //only numbers, hyphens, plus signs, underscores, pound signs, and parentheses

const pwdRule = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-])(?=.*?[0-9]).{8,}$/;
const emailRule = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const SettingsProfileSchema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Contact email is required')
    .matches(emailRule, "Your email doesn't appear to be valid, please double check the spelling of your email."),
  // address: yup.string().required('Address is required'),
});

export const SettingsContactPhoneSchema = yup.object().shape({
  phone_number: yup.string().required('Contact phone is required').matches(phoneRegex, 'Please enter a valid phone'),
});

export const SignInMethodSchema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .notOneOf([yup.ref('currentPassword'), null], 'New password can not be the same as your old password')
    .required('New password is required')
    .matches(pwdRule, 'New password is not valid'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Confirm new password must match')
    .required('Confirm new password is required'),
});

export const uploadAvatarSchema = yup.object().shape({
  image: yup.mixed().test('required', 'image is required', (value) => {
    return value && value.length;
  }),
});
