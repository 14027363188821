import { createSlice } from '@reduxjs/toolkit';
import {
  baseResponse,
  customerItems,
  iCustomerTotal,
  iInitState,
  iInvoiceTotal,
  invoiceItems,
  iOrderTotal,
  iProductTotal,
  iRefundTotal,
  orderItems,
  productItems,
  refundedItems,
} from '../../../../app/models';

interface iInitialState extends iInitState {
  orders: baseResponse<orderItems, iOrderTotal>;
  invoices: baseResponse<invoiceItems, iInvoiceTotal>;
  refunded: baseResponse<refundedItems, iRefundTotal>;
  products: baseResponse<productItems, iProductTotal>;
  customers: baseResponse<customerItems, iCustomerTotal>;

  invoiceQueries: string;
  productQueries: string;
  customerQueries: string;
  refundedQueries: string;
  orderQueries: string;
}

const initialState = {
  status: '',
  orders: {
    items: [] as orderItems[],
    search_criteria: {},
    total_count: 0,
    total: [] as iOrderTotal[],
  } as baseResponse<orderItems, iOrderTotal>,
  invoices: {
    items: [] as invoiceItems[],
    search_criteria: {},
    total_count: 0,
    total: [] as iInvoiceTotal[],
  } as baseResponse<invoiceItems, iInvoiceTotal>,
  refunded: {
    items: [] as refundedItems[],
    search_criteria: {},
    total_count: 0,
    total: [] as iRefundTotal[],
  } as baseResponse<refundedItems, iRefundTotal>,
  products: {
    items: [] as productItems[],
    search_criteria: {},
    total_count: 0,
    total: [] as iProductTotal[],
  } as baseResponse<productItems, iProductTotal>,
  customers: {
    items: [] as customerItems[],
    search_criteria: {},
    total_count: 0,
    total: [] as iCustomerTotal[],
  } as baseResponse<customerItems, iCustomerTotal>,
  invoiceQueries: '',
  productQueries: '',
  customerQueries: '',
  refundedQueries: '',
  orderQueries: '',
} as iInitialState;

const reportSlice = createSlice({
  name: 'REPORT',
  initialState,
  reducers: {
    getOrderReportFulfilled: (state, action) => ({ ...state, status: 'fulfilled', orders: action.payload }),
    getInvoiceFulfilled: (state, action) => ({ ...state, status: 'fulfilled', invoices: action.payload }),
    getRefundedFulfilled: (state, action) => ({ ...state, status: 'fulfilled', refunded: action.payload }),
    getProductsFulfilled: (state, action) => ({ ...state, status: 'fulfilled', products: action.payload }),
    getCustomerFulfilled: (state, action) => ({ ...state, status: 'fulfilled', customers: action.payload }),
    isRejected: (state) => ({ ...state, status: 'rejected' }),
    isPending: (state) => ({ ...state, status: 'pending' }),
    resetData: () => ({ ...initialState }),
    setInvoiceQueries: (state, action) => ({ ...state, invoiceQueries: action.payload }),
    setProductQueries: (state, action) => ({ ...state, productQueries: action.payload }),
    setRefundedQueries: (state, action) => ({ ...state, refundedQueries: action.payload }),
    setCustomerQueries: (state, action) => ({ ...state, customerQueries: action.payload }),
    setOrderQueries: (state, action) => ({ ...state, orderQueries: action.payload }),

    resetProducts: (state) => ({ ...state, products: initialState.products, productQueries: '' }),
    resetInvoices: (state) => ({ ...state, invoices: initialState.invoices, invoiceQueries: '' }),
    resetRefunded: (state) => ({ ...state, refunded: initialState.refunded, refundedQueries: '' }),
    resetCustomers: (state) => ({ ...state, customers: initialState.customers, customerQueries: '' }),
    resetOrder: (state) => ({ ...state, orders: initialState.orders, orderQueries: '' }),
    resetStatus: (state) => ({ ...state, status: '' }),
  },
});

export const { ...rest } = reportSlice.actions;
const reportReducer = reportSlice.reducer;

export default reportReducer;
