import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import Button from '~/components/common/Button';
import Select from '~/components/common/Select';
import { onChangeListTierPrice } from '../redux/actions';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { formatCurrency } from '~/app/utils';
import { Input } from '~/components/common/Input';

const TableAdvPrice = ({ formik }: any) => {
  const dispatch = useAppDispatch();
  const { currentStore } = useAppSelector((state) => state.authReducer);
  const { listTierPrice } = useAppSelector((state) => state.createEditProductReducer);
  const { errors, values, touched, handleChange, handleBlur, setFieldTouched, setValues } = formik;
  const { special_from_date, special_to_date } = values;

  const [isAddItem, setIsAddItem] = useState<number>(1);

  useEffect(() => {
    buttonRef.current?.scrollIntoView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddItem]);

  useEffect(() => {
    let listValue = {};

    listTierPrice.forEach((item: any) => {
      const { id } = item;
      const percent = `percentage_value${id}`;
      const qty = `qty${id}`;
      const value = `value${id}`;
      listValue = {
        ...listValue,
        [percent]: item[percent],
        [qty]: item[qty],
        [value]: item[value],
      };
    });

    setValues({ special_from_date, special_to_date, ...listValue });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTierPrice]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const tableHeader = [
    { className: '', name: 'Website' },
    { className: '', name: 'Customer Group' },
    { className: '', name: 'Quantity', required: true },
    { className: '', name: 'Price' },
    { className: 'text-center', name: 'Action' },
  ];

  function handleChangeInput(id: string, e: React.ChangeEvent<HTMLInputElement>) {
    const index = listTierPrice.findIndex((item: any) => item.id === id);
    const item = listTierPrice.find((item: any) => item.id === id);
    const newList = listTierPrice.filter((item: any) => item.id !== id);

    if (index < 0 || !item) {
      return;
    }
    newList.splice(index, 0, { ...item, [e.target.name]: e.target.value });
    dispatch(onChangeListTierPrice(newList));
  }

  function handleChangeTypePrice(id: string, e: React.ChangeEvent<HTMLSelectElement>) {
    const index = listTierPrice.findIndex((item: any) => item.id === id);
    const item = listTierPrice.find((item: any) => item.id === id);
    const newList = listTierPrice.filter((item: any) => item.id !== id);

    const isPercent = e.target.value === 'percent';

    newList.splice(index, 0, { ...item, [`percentage_value${id}`]: isPercent ? '' : undefined, [`value${id}`]: '' });
    return dispatch(onChangeListTierPrice(newList));
  }

  function handleChangeSelect(id: string, e: React.ChangeEvent<HTMLSelectElement>) {
    const index = listTierPrice.findIndex((item: any) => item.id === id);
    const item = listTierPrice.find((item: any) => item.id === id);
    const newList = listTierPrice.filter((item: any) => item.id !== id);

    newList.splice(index, 0, { ...item, [e.target.name]: +e.target.value });
    return dispatch(onChangeListTierPrice(newList));
  }

  function handleAddItem() {
    setIsAddItem((prev) => prev + 1);
    const id = uuidv4();
    const item = {
      id,
      website_id: 0,
      customer_group_id: 32000,
      [`percentage_value${id}`]: undefined,
      [`qty${id}`]: '',
      [`value${id}`]: '',
    };

    dispatch(onChangeListTierPrice([...listTierPrice, item]));
  }

  function handleRemoveItem(id: any) {
    dispatch(onChangeListTierPrice(listTierPrice.filter((item) => item.id !== id)));
  }

  function renderHeader() {
    return (
      <thead>
        <tr>
          {tableHeader.map((col, i: number) => (
            <th className={col.className} key={i}>
              {col.name}&nbsp;
              {col.required && <span className="text-danger">*</span>}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  function renderBody() {
    return (
      <tbody>
        {listTierPrice.map((item) => {
          const { id, customer_group_id, website_id } = item;
          const percent = item[`percentage_value${id}`];
          const valuePrice = item[`value${id}`];

          return (
            <tr key={id}>
              <td>
                <Select
                  name="website_id"
                  value={website_id}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChangeSelect(id, e)}
                  className="w-100"
                >
                  <option value="0">All Websites [SGD]</option>
                </Select>
              </td>
              <td>
                <Select
                  name="customer_group_id"
                  value={customer_group_id}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChangeSelect(id, e)}
                  className="w-100"
                >
                  <option value="32000">ALL GROUPS</option>
                  <option value="0">NOT LOGGED IN</option>
                  <option value="1">General</option>
                  <option value="2">Wholesale</option>
                  <option value="3">Retailer</option>
                </Select>
              </td>
              <td>
                <Input
                  type="number"
                  className="form-control--custom-qty-tier w-100"
                  name={`qty${id}`}
                  value={item[`qty${id}`]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeInput(id, e);
                    handleChange(e);
                    setFieldTouched(`qty${id}`, false, false);
                  }}
                  onBlur={handleBlur}
                />
                {errors[`qty${id}`] && touched[`qty${id}`] && <p className="mess-err-tier fs-14 text-danger m-0 mt-2">{errors[`qty${id}`]}</p>}
              </td>
              <td>
                <>
                  <div className="d-flex w-100">
                    <Select
                      className="form-control-custom-select me-3"
                      value={percent !== undefined ? 'percent' : 'fixed'}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        handleChangeTypePrice(id, e);
                      }}
                    >
                      <option value="fixed">Fixed</option>
                      <option value="percent">Discount</option>
                    </Select>

                    <div className="price-wrap tier d-flex flex-grow-1 bg-white">
                      <span className="ps-2 pe-2 d-flex align-items-center fs-14">{percent !== undefined ? '%' : formatCurrency(currentStore)}</span>
                      <Input
                        type="text"
                        name={percent !== undefined ? `percentage_value${id}` : `value${id}`}
                        className="h-100 d-block w-100 border-0"
                        value={percent !== undefined ? `${percent}` : valuePrice}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChangeInput(id, e);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="error-price">
                    {errors[percent !== undefined ? `percentage_value${id}` : `value${id}`] &&
                      touched[percent !== undefined ? `percentage_value${id}` : `value${id}`] && (
                        <p className="mess-err-tier fs-14 text-danger m-0 mt-2">
                          {errors[percent !== undefined ? `percentage_value${id}` : `value${id}`]}
                        </p>
                      )}
                  </div>
                </>
              </td>
              <td className="text-center">
                <div className="d-inline-block cursor-pointer trash-icon" onClick={() => handleRemoveItem(id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  return (
    <>
      {listTierPrice.length > 0 && (
        <table id="table-adv-price" className="mb-4">
          {renderHeader()}
          {renderBody()}
        </table>
      )}
      <Button className="btn-add-customer-group" ref={buttonRef} onClick={handleAddItem}>
        Add
      </Button>
    </>
  );
};

export default TableAdvPrice;
