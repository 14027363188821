import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { dataSellerHead } from '~/app/constants';
import { addFilterGroup, addSortOrder, criteriaBuilder, filterEmptyProperties } from '~/app/utils';
import { setIsDESC, setKeySearch, setQueryString, getData, getUpdateStatus } from './redux/action';
import { NumberFromToFilter, TextFilter, SelectFilter, FilterButtons, ResetFilterButton } from '~/components/common/Filter/filterInput';
import { formatFilterActive, formatEnableStatus, formatFilterEnableStatus } from '~/components/common/Filter/formatFilter';
import { builderCriteriaSeller } from '~/app/utils/builderCriteria';
import { convertStringToNumber } from '~/app/utils/convertBase64';
import { sorterCriteria } from '~/app/utils/sorterCriteria';
import Pagination from '~/components/common/Pagination';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { Table } from '~/components/common/Table';
import DefaultLayout from '~/components/layout/DefaultLayout';
import { STATUS_OPTIONS } from '~/app/constants/filter';
import { iGetAllSeller, iUpdateStatusSeller } from '~/app/models';
import { NoRecord } from '../Err';
import './style.scss';
// import CustomCheckBox, { Props } from '~/components/common/CustomCheckBox';
import SearchBar from '~/components/common/Filter/searchBar';
import Separator from '~/components/common/Separator';

type FormFilterValues = {
  sellerFirstName: string;
  sellerLastName: string;
  sellerEmail: string;
  sellerIdFrom: string;
  sellerIdTo: string;
  sellerCreatedFrom: string;
  sellerCreatedTo: string;
  statusSeller: string;
  productsQty: string;
  ordersQty: string;
  searchTerm: string;
};

type Checkbox = {
  vendor_id: number;
  email: string;
};

const Sellers: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Declare data from Reducers
  const { data: userData } = useAppSelector((s) => s.userReducer);
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { data, status: sellerStatus, message, isDESC, keySearch } = useAppSelector((state) => state.sellerReducer);
  const { total_count, items } = data;

  const sort = sorterCriteria(keySearch, isDESC);
  const websiteList = JSON.parse(userData.website_ids || '{}');
  // const convertWebsiteList = Object.keys(websiteList).map((key) => websiteList[key]);

  // Declare data from Query String
  const isFilter = Boolean(searchParams.get('isFilter'));
  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;
  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));

  const sellerFirstName = searchParams.get('sellerFirstName') || '';
  const sellerLastName = searchParams.get('sellerLastName') || '';
  const sellerEmail = searchParams.get('sellerEmail') || '';
  const sellerIdFrom = searchParams.get('sellerIdFrom') || '';
  const sellerIdTo = searchParams.get('sellerIdTo') || '';
  const sellerCreatedFrom = searchParams.get('sellerCreatedFrom') || '';
  const sellerCreatedTo = searchParams.get('sellerCreatedTo') || '';
  const statusSeller = searchParams.get('statusSeller') || '';
  const productsQty = searchParams.get('productsQty') || '';
  const ordersQty = searchParams.get('ordersQty') || '';
  const searchTerm = searchParams.get('searchTerm') || '';

  // Declare initial Filter Payload
  const initialFilterPayload = {
    sellerFirstName,
    sellerLastName,
    sellerEmail,
    sellerIdFrom,
    sellerIdTo,
    sellerCreatedFrom,
    sellerCreatedTo,
    statusSeller,
    productsQty,
    ordersQty,
    searchTerm,
  } as FormFilterValues;

  // Declare useState
  const [filterPayload, setFilterPayload] = useState<FormFilterValues>(initialFilterPayload);
  const [submitFilterPayload, setSubmitFilterPayload] = useState<any>(initialFilterPayload);
  const [isEnableFilter, setIsEnableFilter] = useState<boolean>(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>();
  const [query, setQuery] = useState<string>(window.location.search);
  const [isCheckAll /* setIsCheckAll */] = useState<boolean>(false);
  // const [isCheck, setIsCheck] = useState<number[]>([]);
  const [list, setList] = useState<Checkbox[]>([]);

  //Declare getData Payload
  const filter = builderCriteriaSeller(submitFilterPayload);

  const payload: iGetAllSeller = {
    accessToken,
    searchUrl: criteriaBuilder(
      convertStringToNumber(pageSize.toString(), 10),
      convertStringToNumber(currentPage.toString(), 1),
      addFilterGroup(...filter),
      addSortOrder(...sort),
    ),
    currentStore,
  };

  const swalConfirm = Swal.mixin({
    title: 'Disable Seller',
    text: 'Are you sure?',
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: 'ok',
    cancelButtonText: 'cancel',
    customClass: {
      popup: 'p-32px border-rd-25',
      closeButton: 'mb-n4 text-dark btn-not-hover',
      title: 'h3 text-dark text-center m-0 fw-semibold p-0',
      htmlContainer: 'text-dark text-center mt-3 mb-0 mx-0 fw-normal',
      actions: 'mt-4 w-100',
      confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-40',
      cancelButton: 'btn btn-primary btn-cancel fs-14 fw-medium btn-border-radius border-rd-25 order-0 w-40',
    },
  });

  //Declare useEffect
  useEffect(() => {
    dispatch(setQueryString(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setList(items);

    if (isFilter || isAdvancedFilter || (!isFilter && !isAdvancedFilter)) {
      dispatch(getData(payload));
    }

    if (Object.keys(filterEmptyProperties(submitFilterPayload)).length === 0) {
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, currentStore, isDESC, query, keySearch, isFilterSubmit, list]);

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isFilter) {
      setSearchParams({
        pageSize: value,
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  // Change current page
  const onChangePage = (currentPage: number) => {
    if (isFilter) {
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: pageSize.toString(), currentPage: currentPage.toString() });
      resetUrlData();
    }
  };

  // reset fillter and search
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      sellerFirstName: '',
      sellerLastName: '',
      sellerEmail: '',
      sellerIdFrom: '',
      sellerIdTo: '',
      sellerCreatedFrom: '',
      sellerCreatedTo: '',
      statusSeller: '',
      productsQty: '',
      ordersQty: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      sellerFirstName: '',
      sellerLastName: '',
      sellerEmail: '',
      sellerIdFrom: '',
      sellerIdTo: '',
      sellerCreatedFrom: '',
      sellerCreatedTo: '',
      statusSeller: '',
      productsQty: '',
      ordersQty: '',
      searchTerm: '',
    });
    setSearchParams({});
    resetUrlData();
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${key}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${key}`]: '' });
    setSubmitFilterPayload({ ...filteredObject, [`${key}`]: '' });
    resetUrlData();
  };

  const resetFilterFromToSection = (keyFrom: string, keyTo: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${keyFrom}`]: '',
      [`${keyTo}`]: '',
      sAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    setSubmitFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    resetUrlData();
  };

  //Declare submit filter function
  const searchKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = e;
    const filteredObject = filterEmptyProperties(filterPayload);
    if (key === 'Enter') {
      if (target instanceof HTMLInputElement) {
        setFilterPayload({ ...filterPayload, searchTerm: target.value });
        setSubmitFilterPayload({ ...filterPayload });
        setSearchParams({
          currentPage: '1',
          pageSize: pageSize.toString(),
          searchTerm: target.value,
          ...filteredObject,
          isFilter: 'true',
        });
        resetUrlData();
        setIsFilterSubmit(true);
      }
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitFilterPayload({ ...filterPayload });
    const filteredObject = filterEmptyProperties(filterPayload);

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
    setIsFilterSubmit(true);
  };

  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  const sortOrder = (key: string) => {
    if (!key) return;
    keySearch !== key ? dispatch(setIsDESC(false)) : dispatch(setIsDESC(!isDESC));
    dispatch(setKeySearch(key));
  };

  const renderWebsiteTable = (data: any[]) => {
    return data.map(
      (item: any, key: number) =>
        !!websiteList[item] && (
          <p className="mb-0" key={key}>
            {websiteList[item].name}
          </p>
        ),
    );
  };

  // const selecAllSellers = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   setIsCheckAll(!isCheckAll);
  //   setIsCheck(list.map((li) => li.vendor_id));
  //   if (isCheckAll) {
  //     setIsCheck([]);
  //   }
  // };

  // const selectSeller = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   const { id, checked } = e.target;
  //   setIsCheck([...isCheck, parseInt(id)]);
  //   if (!checked) {
  //     setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
  //   }
  // };

  const onClickDisableSeller = (id: string) => {
    const idPayload: iUpdateStatusSeller = {
      accessToken,
      vendorId: id,
      currentStore,
    };
    swalConfirm.fire({}).then(function (result) {
      if (result.isConfirmed) {
        dispatch(getUpdateStatus(idPayload, payload));
      }
    });
  };

  const onClickEnableSeller = (id: string) => {
    const idPayload: iUpdateStatusSeller = {
      accessToken,
      vendorId: id,
      currentStore,
    };
    swalConfirm
      .fire({
        title: 'Enable Seller',
        text: 'Are you sure?',
      })
      .then(function (result) {
        if (result.isConfirmed) {
          dispatch(getUpdateStatus(idPayload, payload));
        }
      });
  };

  const renderFilterActive = () => {
    const filterSections = [
      { label: 'Keyword', value: searchTerm, resetHandler: () => resetFilterSection('searchTerm') },
      {
        label: 'ID',
        value: sellerIdFrom + sellerIdTo && `${sellerIdFrom ? sellerIdFrom : '...'} - ${sellerIdTo ? sellerIdTo : '...'}`,
        resetHandler: () => resetFilterFromToSection('sellerIdFrom', 'sellerIdTo'),
      },
      // {
      //   label: 'Created',
      //   value:
      //     sellerCreatedFrom + sellerCreatedTo &&
      //     `${sellerCreatedFrom ? moment(sellerCreatedFrom).format('MM/DD/YYYY') : '...'} - ${
      //       sellerCreatedTo ? moment(sellerCreatedTo).format('MM/DD/YYYY') : '...'
      //     } `,
      //   resetHandler: () => resetFilterFromToSection('sellerCreatedFrom', 'sellerCreatedTo'),
      // },
      { label: 'Firstname', value: sellerFirstName, resetHandler: () => resetFilterSection('sellerFirstName') },
      { label: 'Lastname', value: sellerLastName, resetHandler: () => resetFilterSection('sellerLastName') },
      { label: 'Email', value: sellerEmail, resetHandler: () => resetFilterSection('sellerEmail') },
      {
        label: 'Status',
        value: formatFilterEnableStatus(statusSeller),
        resetHandler: () => resetFilterSection('statusSeller'),
      },
    ];

    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={() => resetFilter()} />}
          </div>
          <div className="border-top border-bottom p-3 align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  // Belong to renderContentSection
  const renderTable = () => {
    return (
      <Table
        keySearch={keySearch}
        isDESC={isDESC}
        dataTableHead={dataSellerHead}
        status={sellerStatus}
        message={message}
        sortOrder={sortOrder}
        className="striped-table table-responsive"
        // onChange={(e) => selecAllSellers(e)}
        checked={isCheckAll}
      >
        {items.length > 0 ? (
          items.map((item: any, i: number) => {
            // const checkboxProps: Props = {
            //   name: 'id',
            //   id: item.vendor_id,
            //   onChange: selectSeller,
            //   checked: isCheck.includes(parseInt(item.vendor_id)),
            // };
            return (
              <tr key={i}>
                {/* <td className="td-item px-2 fs-14 text-center">
                <CustomCheckBox {...checkboxProps} />
              </td> */}
                <td className="td-item px-3 fs-14 text-center">
                  <span className="">{item.vendor_id}</span>
                </td>
                <td className="td-item px-2 fs-14">{`${item.firstname}`}</td>
                <td className="td-item px-2 fs-14">{`${item.lastname}`}</td>
                <td className="td-item px-2 fs-14">{item.email}</td>
                <td className="td-item px-2 fs-14">{renderWebsiteTable(item.website_id)}</td>
                <td className="td-item px-2 fs-14 text-end">{item.products_quantity}</td>
                <td className="td-item px-2 fs-14 text-end">{item.orders_quantity}</td>
                <td className="td-item px-2 fs-14 text-center">{formatEnableStatus(parseInt(item.is_active))}</td>
                <td className="td-item px-2 fs-14 text-center ">
                  {item.is_active === '1' ? (
                    <span className="link hover-underline" onClick={() => onClickDisableSeller(item.vendor_id)}>
                      Disabled
                    </span>
                  ) : (
                    <span className="link hover-underline" onClick={() => onClickEnableSeller(item.vendor_id)}>
                      Enabled
                    </span>
                  )}
                </td>
              </tr>
            );
          })
        ) : (
          <NoRecord tableHeaders={dataSellerHead} />
        )}
      </Table>
    );
  };

  // Belong to renderContentSection
  const renderFilterBoard = () => {
    return (
      <div className="bg-white filter-board p-4 wrapper mb-4 border-rd-20">
        <div className="filter-section bg-white">
          <div className="row">
            <h5 className="col-12 mb-4 text-dark filter-title">Filters</h5>
            <NumberFromToFilter
              filterClass="col-lg-4 col-xl-3"
              label={'ID'}
              name={'sellerId'}
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            {/* <DateRangeFilter
              filterClass="col-lg-6 col-xl-3"
              labelText={'Created'}
              fromName={'sellerCreatedFrom'}
              fromValue={filterPayload.sellerCreatedFrom}
              toName={'sellerCreatedTo'}
              toValue={filterPayload.sellerCreatedTo}
              onChange={onFilterChangeHandler}
            /> */}
            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3 mt-lg-0"
              label="Firstname"
              name="sellerFirstName"
              value={filterPayload.sellerFirstName}
              onChange={onFilterChangeHandler}
            />
            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3 mt-lg-0"
              label="Lastname"
              name="sellerLastName"
              value={filterPayload.sellerLastName}
              onChange={onFilterChangeHandler}
            />
            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3 mt-xl-0"
              label="Email"
              name="sellerEmail"
              value={filterPayload.sellerEmail}
              onChange={onFilterChangeHandler}
            />
            <SelectFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              label="Status"
              name="statusSeller"
              options={STATUS_OPTIONS}
              value={filterPayload.statusSeller}
              onChange={onFilterChangeHandler}
            />
          </div>
          <FilterButtons onSubmit={(e) => onSubmit(e)} status={sellerStatus} setIsEnableFilter={() => setIsEnableFilter(false)} />
        </div>
      </div>
    );
  };

  const renderContentSection = () => {
    return (
      <div className="col-sm-12 col-lg-12">
        <Separator className="mb-4 d-flex flex-column flex-lg-row justify-content-end justify-content-lg-between align-items-end align-items-lg-center gap-3">
          <SearchBar
            name="searchTerm"
            status={sellerStatus}
            isTotalCount={true}
            totalCount={total_count}
            searchKeyPressHandler={searchKeyPressHandler}
            onFilterChangeHandler={onFilterChangeHandler}
            value={filterPayload.searchTerm}
          />
          <div className="filter-group d-flex flex-wrap justify-content-end justify-content-md-start">
            {/*
                <button className="filters--button btn btn-border-radius text-uppercase fw-medium fs-14 me-2 px-lg-5">lock</button>
                <button className="filters--button btn btn-border-radius text-uppercase fw-medium fs-14 me-2 px-lg-5">unlock</button>
                 <Link
                  className="btn btn-primary btn-border-radius border-rd-25 border-0 text-uppercase text-white fw-medium fs-14 me-2 my-1"
                  to="/sellers/create"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" /> new seller
                </Link> */}

            <button
              className="filters--button btn btn-border-radius text-uppercase fw-medium fs-14 me-2"
              onClick={() => setIsEnableFilter(!isEnableFilter)}
            >
              <FontAwesomeIcon icon={faFilter} /> Filters
            </button>
          </div>
        </Separator>

        <div className={`filter col-sm-12 col-lg-12${isEnableFilter ? '' : ' d-none'}`}>{renderFilterBoard()}</div>
        {renderFilterActive()}
        <div className="table-section bg-white mt-3 wrapper overflow-auto border-rd-20">{renderTable()}</div>
        <Pagination
          className="p-0"
          currentPage={+currentPage}
          pageSize={+pageSize}
          onChangePageSizeEvent={onChangePageSizeEvent}
          onChangePageEvent={onChangePage}
          status={sellerStatus}
          totalCount={total_count}
        />
      </div>
    );
  };

  return (
    <DefaultLayout pageTitle="Seller Listing">
      <div className="content-wrapper">
        <div className="page-header">
          <div className="row">{renderContentSection()}</div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Sellers;
