import { useAppSelector } from '~/app/hooks/hooks';
import { listAtome, listPaypal } from '../dataPaymentTable';

const OrderPaymentMethod = () => {
  const { status, dataDetails } = useAppSelector((state) => state.orderReducer);
  const titlePaymentMethod = dataDetails.extension_attributes?.payment_additional_info.find((item: any) => item.key === 'method_title')?.value;

  function renderTablePayment() {
    switch (dataDetails.payment.method) {
      // Paypal
      case 'paypal_express':
        return (
          <table className="table-payment">
            <tbody>
              {listPaypal.map((item: any, i: number) => {
                const value =
                  item.key === 'last_trans_id'
                    ? dataDetails.payment[item.key]
                    : dataDetails.extension_attributes?.payment_additional_info.find((info: any) => info.key === item.key)?.value;

                return (
                  <tr key={i}>
                    <td className="px-2 py-3 fs-14 td-label">{item.title}</td>
                    <td className="px-2 py-3 fs-14 text-end">{value === 'null' || !value ? '' : value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      // Atome
      case 'atome_payment_gateway':
        return (
          <table className="table-payment">
            <tbody>
              {listAtome.map((item: any, i: number) => {
                const value = dataDetails.extension_attributes?.payment_additional_info.find((info: any) => info.key === item.key)?.value;

                return (
                  <tr key={i}>
                    <td className="px-2 py-3 fs-14 td-label">{item.title}</td>
                    <td className="px-2 py-3 fs-14 text-end">{value === 'null' || !value ? '' : value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      default:
        return null;
    }
  }

  return (
    <>
      {status === 'fulfilled' && (
        <div className="info-payment wrapper px-4 py-3 bg-white h-100">
          <p className="mb-4 fs-16 fw-semibold">PAYMENT METHOD</p>
          <div className="d-flex flex-column gap-3">
            {titlePaymentMethod !== 'null' && titlePaymentMethod && <p className="fs-14 fw-normal m-0">{titlePaymentMethod}</p>}
            {renderTablePayment()}
            <p className="fs-14 fw-normal m-0">The order was placed using {dataDetails.order_currency_code}.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderPaymentMethod;
