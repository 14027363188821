import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';

import './style.scss';

export interface iOverviewData {
  title: string;
  subtitle: string;
  icon: IconDefinition;
  qty: number;
  bgc: string;
  status?: string;
  moveIconTop?: boolean;
  path: string;
}

const InfoTag = (props: iOverviewData) => {
  const { title, subtitle, qty, status, path } = props;

  return (
    <div className="overview-item d-flex flex-column h-100">
      <div className="item">
        <h3 className="qty fw-semibold">{status !== 'pending' ? qty : <Skeleton width={140} height={22} />}</h3>
        <div className="unit fs-14 fw-500">{status !== 'pending' ? subtitle : <Skeleton width={120} height={22} />}</div>
      </div>
      <div className="title fw-normal">
        <Link to={path}>{status !== 'pending' ? title : <Skeleton width={80} height={18} />}</Link>
      </div>
    </div>
  );
};

export default InfoTag;
