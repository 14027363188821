import moment from 'moment';

function ProductReview() {
  return (
    <div className="row">
      <div className="col-12 col-lg-6 mt-4">
        <div className="d-flex">
          <div className="">
            <div className="calendar shadow">
              <div className="date">{`${moment('2023-02-03 04:38:34').format('DD')}`}</div>
              <div className="month">{`${moment('2023-02-03 04:38:34').format('MMM')}`}</div>
            </div>
          </div>
          <div className="ms-3">
            <div className="d-flex flex-column align-items-start">
              <div className="status mb-1 fs-14">
                <span className="text-success text-status">Approved</span>
              </div>
              <div className="product mb-1">
                <span className="fw-normal text-dark product-label">Product: </span>
                <span className="fw-normal text-dark product-label">ZMJ Modern Modular Sliding Door Wardrobe</span>
              </div>
              <div className="customer fs-14">
                <span className="customer-name me-1 pe-1 border-dark border-end">By: {`Ngoc`}</span>
                <span className="time">{`${moment('2023-02-03 04:38:34').format('h:mm:ss A')}`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-6 mt-4">
        <div className="d-flex">
          <div className="">
            <div className="calendar shadow">
              <div className="date">{`${moment('2023-02-03 04:38:34').format('DD')}`}</div>
              <div className="month">{`${moment('2023-02-03 04:38:34').format('MMM')}`}</div>
            </div>
          </div>
          <div className="ms-3">
            <div className="d-flex flex-column align-items-start">
              <div className="status mb-1 fs-14">
                <span className="text-warning text-status">Pending</span>
              </div>
              <div className="product mb-1">
                <span className="fw-normal text-dark product-label">Product: </span>
                <span className="fw-normal text-dark product-label">ZMJ Modern Modular Sliding Door Wardrobe</span>
              </div>
              <div className="customer fs-14">
                <span className="customer-name me-1 pe-1 border-dark border-end">By: {`Ngoc`}</span>
                <span className="time">{`${moment('2023-02-03 04:38:34').format('h:mm:ss A')}`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-6 mt-4">
        <div className="d-flex">
          <div className="">
            <div className="calendar shadow">
              <div className="date">{`${moment('2023-02-03 04:38:34').format('DD')}`}</div>
              <div className="month">{`${moment('2023-02-03 04:38:34').format('MMM')}`}</div>
            </div>
          </div>
          <div className="ms-3">
            <div className="d-flex flex-column align-items-start">
              <div className="status mb-1 fs-14">
                <span className="text-danger text-status">Not Approved</span>
              </div>
              <div className="product mb-1">
                <span className="fw-normal text-dark product-label">Product: </span>
                <span className="fw-normal text-dark product-label">ZMJ Modern Modular Sliding Door Wardrobe</span>
              </div>
              <div className="customer fs-14">
                <span className="customer-name me-1 pe-1 border-dark border-end">By: {`Ngoc`}</span>
                <span className="time">{`${moment('2023-02-03 04:38:34').format('h:mm:ss A')}`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductReview;
