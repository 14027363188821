import { ChangeEvent, FocusEvent, ForwardedRef, HTMLInputTypeAttribute, forwardRef } from 'react';
import './style.scss';

interface Props {
  value?: string | number;
  type?: HTMLInputTypeAttribute;
  name?: string;
  id?: string;
  placeholder?: string;
  onlyInteger?: boolean;
  className?: string;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}
const numberAllowDotRegex = /^[0-9.]+$/;

const Input = (
  {
    className = '',
    name = '',
    id = '',
    type = 'text',
    value = '',
    placeholder = '',
    onlyInteger = false,
    disabled = false,
    onChange,
    onBlur,
    onKeyPress,
  }: Props,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  function handleKeyPress(e: any) {
    if (type === 'number') {
      e = e || window.event;
      const charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
      const charStr = String.fromCharCode(charCode);
      const dotInvalid = onlyInteger ? charStr === '.' && true : e.target.value.includes('.') && charStr === '.';

      (dotInvalid || !charStr.match(numberAllowDotRegex)) && e.preventDefault();
    }
  }

  function handlePaste(e: any) {
    if (type === 'number') {
      let valueCopied = e.clipboardData.getData('text/plain');
      const oldValue = +e.target.value;

      if (Number.isNaN(+valueCopied) || ((oldValue % 1 !== 0 || onlyInteger) && +valueCopied % 1 !== 0) || +valueCopied < 0) e.preventDefault();
    }
  }

  return (
    <input
      className={`form-control form-control--custom form-control--custom-input text-truncate ${className} ${
        type === 'date' && !value ? 'text-dark-600' : ''
      }`}
      ref={ref}
      type={type}
      disabled={disabled}
      name={name}
      id={id}
      value={value}
      placeholder={placeholder}
      onKeyPress={onKeyPress ? onKeyPress : handleKeyPress}
      onPaste={handlePaste}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default forwardRef(Input);
