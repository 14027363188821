import { ChangeEvent, useId } from 'react';
import './style.scss';
import CheckBoxApplyAllStores from '../CheckBox/CheckBoxApplyAllStores';

interface Props {
  check: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
  name?: string;
  id?: string;
  className?: string;
  showCheckbox?: boolean;
  isApplyAllStores?: boolean;
  onApplyAllStores?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxRounded = (props: Props) => {
  const { check, name, className = '', label, showCheckbox, onChange, onApplyAllStores, isApplyAllStores, id } = props;
  const defaultId = useId();

  const classNames = `checkbox d-flex ${check ? 'checked' : ''} ${className}`;

  return (
    <div className={classNames}>
      {label && (
        <label htmlFor={id || defaultId} className="title m-0 me-4 fs-16 fw-normal cursor-pointer d-flex justify-content-end">
          {label}
        </label>
      )}
      <div className="d-flex flex-column gap-2">
        <input type="checkbox" id={id || defaultId} hidden checked={check} onChange={onChange} name={name} />
        <label htmlFor={id || defaultId} className="checkbox-custom cursor-pointer"></label>
        {showCheckbox && <CheckBoxApplyAllStores onApplyAllStores={onApplyAllStores} isApplyAllStores={isApplyAllStores} />}
      </div>
    </div>
  );
};

export default CheckboxRounded;
