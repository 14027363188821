import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SettingsContactPhoneSchema, SettingsProfileSchema } from '../Schema';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import Loading from '~/components/common/Loading';
import { updateUser } from '../../redux/action';
import { FC, useEffect } from 'react';
import { iInfoUser, iPayloadUpdateInfoUser } from '~/app/models';

type Props = {};

type FormSettingValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  // address: string;
};

const Settings: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { data: infoUser, status, message, updateStatus, updateAvatarStatus, updatePasswordStatus } = useAppSelector((state) => state.userReducer);
  const { first_name, last_name, email, phone_number, user_type, user_name } = infoUser;

  const {
    setValue,
    register: registerSettings,
    formState: { errors: errorsSettings },
    handleSubmit: handleSubmitSettings,
    reset,
  } = useForm<FormSettingValues>({
    resolver: yupResolver(user_type !== null ? SettingsProfileSchema.concat(SettingsContactPhoneSchema) : SettingsProfileSchema),
  });
  useEffect(() => {
    const { first_name, last_name, email, phone_number } = infoUser;

    setValue('first_name', first_name || '');
    setValue('last_name', last_name || '');
    setValue('email', email || '');
    setValue('phone_number', phone_number || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoUser]);

  useEffect(() => {
    setValue('first_name', first_name || '');
    setValue('last_name', last_name || '');
    setValue('email', email || '');
    setValue('phone_number', phone_number || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoUser]);

  useEffect(() => {
    if (
      (updateAvatarStatus === 'fulfilled' && updateStatus !== 'fulfilled') ||
      (updatePasswordStatus === 'fulfilled' && updateStatus !== 'fulfilled')
    ) {
      reset({
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone_number: phone_number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, updatePasswordStatus, updateAvatarStatus]);

  const onSubmit = async (data: any) => {
    const dataPayload: iPayloadUpdateInfoUser<Pick<iInfoUser, 'first_name' | 'last_name' | 'email' | 'phone_number'>> = {
      data: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
        // address: data.address,
      },
    };
    dispatch(updateUser(dataPayload));
    window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const renderHeader = () => {
    return (
      <div className="pb-2 border-bottom d-flex justify-content-between align-items-center mb-4 w-100">
        <div>
          <Link className="account-tab fs-16 fw-medium text-dark-600 text-decoration-none me-3" to="../overview">
            Overview
          </Link>
          <Link
            className="account-tab-setting fs-16 fw-medium text-primary active text-decoration-none border-bottom border-3 border-primary pt-4 pb-2"
            to=""
          >
            Information
          </Link>
        </div>
      </div>
    );
  };
  const renderSubmit = () => {
    return (
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-apply btn-primary btn-update-profile btn-border-radius border-rd-25 fw-medium"
          disabled={updateStatus === 'pending'}
        >
          {updateStatus === 'pending' && (
            <span className="indicator-progress me-2">
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
          Save Changes
        </button>
      </div>
    );
  };

  const renderNameInput = () => {
    return (
      <div className="row mb-3 align-items-center">
        <label htmlFor="" className="col-12 col-xxl-4 fw-normal text-start py-2">
          First Name & Last Name
        </label>
        <div className="col-12 col-xxl-8">
          <div className="row">
            <div className="col-6">
              <input
                type="text"
                placeholder="First Name"
                className={`form-control form-control-solid col-6  text-truncate ${errorsSettings.first_name ? 'is-invalid' : ''}`}
                {...registerSettings('first_name')}
                defaultValue={first_name}
              />
              <div className="invalid-feedback">{errorsSettings.first_name?.message}</div>
            </div>
            <div className="col-6">
              <input
                type="text"
                placeholder="Last Name"
                className={`form-control form-control-solid col-6  text-truncate ${errorsSettings.last_name ? 'is-invalid' : ''}`}
                {...registerSettings('last_name')}
                defaultValue={last_name}
              />
              <div className="invalid-feedback">{errorsSettings.last_name?.message}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContactPhoneInput = () => {
    const regex = /^[0-9\-+()#_]+$/; //only numbers, hyphens, plus signs, underscores, pound signs, and parentheses

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const char = event.currentTarget.value + event.key;
      const isValidChar = regex.test(char);
      if (!isValidChar) {
        event.preventDefault();
      }
    };

    return (
      <div className="row mb-2 align-items-center">
        <label htmlFor="" className="col-12 col-xxl-4 fw-normal text-start py-2">
          Contact Phone
        </label>
        <div className="col-12 col-xxl-8">
          <input
            type="text"
            className={`form-control form-control-solid w-100 text-truncate ${errorsSettings.phone_number ? 'is-invalid' : ''}`}
            {...registerSettings('phone_number')}
            defaultValue={phone_number}
            {...{ onKeyPress: handleKeyPress }}
          />
          <div className="invalid-feedback">{errorsSettings.phone_number?.message}</div>
        </div>
      </div>
    );
  };

  const renderContactEmailInput = () => {
    return (
      <div className="row mb-3 align-items-center">
        <label htmlFor="" className="col-12 col-xxl-4 fw-normal text-start py-2">
          Contact Email
        </label>
        <div className="col-12 col-xxl-8">
          <input
            type="text"
            disabled
            className={`form-control form-control-solid w-100 text-truncate ${errorsSettings.email ? 'is-invalid' : ''}`}
            {...registerSettings('email')}
            defaultValue={email}
          />
          <div className="invalid-feedback">{errorsSettings.email?.message}</div>
        </div>
      </div>
    );
  };

  const renderUserNameInput = () => {
    return (
      <div className="row mb-3 align-items-center">
        <label htmlFor="" className="col-12 col-xxl-4 fw-normal text-start py-2">
          User Name
        </label>
        <div className="col-12 col-xxl-8">
          <input type="text" className={`form-control form-control-solid w-100 text-truncate`} defaultValue={user_name} disabled />
        </div>
      </div>
    );
  };

  // const renderAddressInput = () => {
  //   return (
  //     <div className="row mb-3  align-items-center">
  //       <label htmlFor="" className="col-12 col-md-3 fw-medium text-end py-2">
  //         Address
  //       </label>
  //       <div className="col-12 col-md-8">
  //         <input
  //           type="text"
  //           className={`form-control form-control-solid w-100 ${errorsSettings.address ? 'is-invalid' : ''}`}
  //           {...registerSettings('address')}
  //           defaultValue={profile.address}
  //         />
  //         <div className="invalid-feedback">{errorsSettings.address?.message}</div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="col-lg-12 col-xl-6 col-xxl-12">
      <div className="card bg-white shadow-sm border-0 border-rd-20 h-100">
        <div className="card-body d-flex flex-wrap align-items-center p-4">
          {/* Render Header Form Section */}
          {renderHeader()}
          {status === 'pending' && (
            <div className="bg-white d-flex justify-content-center w-100">
              <Loading />
            </div>
          )}
          {status === 'fulfilled' && (
            <form className="w-100" onSubmit={handleSubmitSettings(onSubmit)}>
              <div className="flex-row-fluid mt-3">
                <div className="row align-items-end">
                  <div className="col-lg-12">
                    {/* Render User Name Input Section */}
                    {renderUserNameInput()}
                    {/* Render Name Input Section */}
                    {renderNameInput()}
                    {/* Render Contact Email Input Section */}
                    {renderContactEmailInput()}
                    {/* Render Contact Phone Input Section */}
                    {user_type !== null && renderContactPhoneInput()}
                    {/* Render Address Input Section */}
                    {/* {renderAddressInput()} */}
                  </div>
                  <div className="col-lg-12 d-flex justify-content-end align-items-end h-xl-155px mt-2">{renderSubmit()}</div>
                </div>
              </div>
            </form>
          )}
          {status === 'rejected' && (
            <div className="card-body px-4 d-flex align-items-center justify-content-center text-danger">
              <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
              {message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
