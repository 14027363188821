import { createSlice } from '@reduxjs/toolkit';
import { iInfoUser, iInitState, iPayload, iStoreViewItem, iWebsiteItem } from '~/app/models';

interface iState extends iInitState {
  isSignIn: boolean;
  data: iInfoUser;
  message: string;
  status: '' | 'fulfilled' | 'rejected' | 'pending';
  updateStatus: '' | 'fulfilled' | 'rejected' | 'pending';
  updateAvatarStatus: '' | 'fulfilled' | 'rejected' | 'pending';
  updatePasswordStatus: '' | 'fulfilled' | 'rejected' | 'pending';
  storeData: iStoreViewItem[];
  websiteData: any;
}

const initialState: iState = {
  status: '',
  updateStatus: '',
  updateAvatarStatus: '',
  updatePasswordStatus: '',
  isSignIn: false,
  data: {} as iInfoUser,
  storeData: [],
  websiteData: {},
  message: '',
};

const userSlide = createSlice({
  name: 'USERS',
  initialState,
  reducers: {
    resetDataUser: () => initialState,

    getUserFulfilled: (state, action: iPayload<iInfoUser>) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getUserRejected: (state, action: iPayload<string>) => ({ ...state, message: action.payload, status: 'rejected' }),
    getUserPending: (state) => ({ ...state, status: 'pending' }),

    updateUserFulfilled: (state, action: iPayload<iInfoUser>) => ({ ...state, updateStatus: 'fulfilled', data: action.payload }),
    updateUserRejected: (state, action: iPayload<string>) => ({ ...state, message: action.payload, updateStatus: 'rejected' }),
    updateUserPending: (state) => ({ ...state, updateStatus: 'pending' }),
    updateUserInitState: (state) => ({ ...state, updateStatus: '' }),

    updateUserAvatarFulfilled: (state, action: iPayload<iInfoUser>) => ({ ...state, updateAvatarStatus: 'fulfilled', data: action.payload }),
    updateUserAvatarRejected: (state, action: iPayload<string>) => ({ ...state, message: action.payload, updateAvatarStatus: 'rejected' }),
    updateUserAvatarPending: (state) => ({ ...state, updateAvatarStatus: 'pending' }),
    updateUserAvatarInitState: (state) => ({ ...state, updateAvatarStatus: '' }),

    deleteAvatarFulfilled: (state) => ({ ...state, updateAvatarStatus: 'fulfilled' }),
    deleteAvatarRejected: (state, action: iPayload<string>) => ({ ...state, message: action.payload, updateAvatarStatus: 'rejected' }),
    deleteAvatarPending: (state) => ({ ...state, updateAvatarStatus: 'pending' }),
    deleteAvatarInitState: (state) => ({ ...state, updateAvatarStatus: '' }),

    updateUserPasswordFulfilled: (state) => ({ ...state, updatePasswordStatus: 'fulfilled' }),
    updateUserPasswordRejected: (state, action: iPayload<string>) => ({ ...state, message: action.payload, updatePasswordStatus: 'rejected' }),
    updateUserPasswordPending: (state) => ({ ...state, updatePasswordStatus: 'pending' }),
    updateUserPasswordInitState: (state) => ({ ...state, updatePasswordStatus: '' }),

    getAllStoresFulfilled: (state, action: iPayload<iStoreViewItem[]>) => ({ ...state, storeData: action.payload, status: 'fulfilled' }),
    getAllStoresRejected: (state, action: iPayload<string>) => ({ ...state, message: action.payload, status: 'rejected' }),
    getAllStoresPending: (state) => ({ ...state, status: 'pending' }),

    getAllWebsitesFulfilled: (state, action: iPayload<iWebsiteItem[]>) => ({ ...state, websiteData: action.payload, status: 'fulfilled' }),
    getAllWebsitesRejected: (state, action: iPayload<string>) => ({ ...state, message: action.payload, status: 'rejected' }),
    getAllWebsitesPending: (state) => ({ ...state, status: 'pending' }),
  },
});

export const { ...rest } = userSlide.actions;
const userReducer = userSlide.reducer;

export default userReducer;
