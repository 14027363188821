import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import './style.scss';
import { Status } from '~/app/models';

interface SearchProps {
  status: Status;
  totalCount?: number;
  searchKeyPressHandler: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFilterChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name: string;
  isTotalCount: Boolean;
  className?: string;
  classNameInputSearch?: string;
  showIconClearSearch?: boolean;
  onClearSearch?: () => void;
  disabled?: boolean;
}

const SearchBar: React.FC<SearchProps> = ({
  status,
  totalCount,
  value,
  name,
  isTotalCount,
  disabled,
  classNameInputSearch = '',
  className = '',
  showIconClearSearch,
  searchKeyPressHandler,
  onFilterChangeHandler,
  onClearSearch,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (status === 'pending') {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [status]);

  return (
    <div className={`order-lg-1 d-flex align-items-center justify-content-end flex-grow-1 ${className}`}>
      <div className={`search-btn cursor-pointer flex-grow-1 order-1 ${classNameInputSearch}`}>
        <input
          type="text"
          name={name}
          placeholder="Press Enter To Search"
          disabled={isDisabled || disabled}
          onKeyPress={searchKeyPressHandler}
          value={value}
          onChange={onFilterChangeHandler}
          className="w-100 user-select-none"
        />
        <FontAwesomeIcon icon={faMagnifyingGlass} />
        {showIconClearSearch && value && <FontAwesomeIcon icon={faXmark} className="close-icon" onClick={onClearSearch} />}
      </div>
      {isTotalCount && (
        <span className="fw-normal fs-14 border-0 text-end me-4 w-fit-content text-lg-wrap text-nowrap">
          Total {totalCount ? totalCount : '0'} Record(s) Found
        </span>
      )}
    </div>
  );
};

export default SearchBar;
