import React, { FC } from 'react';
import './style.scss';

export type Props = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: any;
  checked?: boolean;
  label?: string;
  checkBoxClass?: string;
  id?: string;
  circle?: boolean;
  disabled?: boolean;
  className?: string;
};

const CustomCheckBox: FC<Props> = (props: Props) => {
  const { name, value, checked, label, checkBoxClass = '', id, circle, disabled = false, className = '', onChange } = props;

  return (
    <label className={`custom-checkbox d-flex align-items-center ${className} ${disabled ? 'disabled' : ''}`}>
      <input type="checkbox" id={id} value={value} checked={checked} name={name} onChange={onChange} disabled={disabled} hidden />
      <span className={`checkmark ${circle ? 'rounded-circle' : ''}`}></span>
      <span className={checkBoxClass}>{label}</span>
    </label>
  );
};

export default CustomCheckBox;
